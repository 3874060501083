import React, { useState } from "react";
import Dialog from "components/dialog/Dialog";
import { useAxios } from "utils/hooks/useAxios";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { routes } from "utils/appConfig";
import { IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { passwordRegex } from "components/modals/helper";
import { changeMyPassword } from "services/changeMyPassword";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const visibilityInitialState = {
  old: false,
  new: false,
  confirm: false,
};

const ChangePasswordForm = ({ open, setOpen }) => {
  const { isLoading, callApi } = useAxios();
  const [showPassword, setShowPassword] = useState(visibilityInitialState);
  const handlePasswordVisibility = (key) => {
    setShowPassword((pre) => ({
      ...pre,
      [key]: !pre[key],
    }));
  };
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object().shape({
      oldPassword: Yup.string().required().label("Old Password"),
      newPassword: Yup.string()
        .required()
        .matches(
          passwordRegex,
          " Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        )
        .label("New Password"),
      confirmNewPassword: Yup.string()
        .required()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .label("Confirm New Password"),
    }),
    onSubmit: async (values) => {
      const { confirmNewPassword, ...rest } = values;
      changeMyPassword(callApi, rest, (res, error) => {
        if (res) {
          setOpen(null);
          formik.resetForm();
          setShowPassword(visibilityInitialState);
        } else if (error) {
          console.log("error~: ", error);
        }
      });
    },
  });
  return (
    <Dialog
      title="Change Password"
      open={open}
      onClose={() => {
        formik.resetForm();
        setOpen(null);
      }}
      agree={formik.handleSubmit}
      agreeText="Update"
      loading={isLoading}
    >
      <form onSubmit={formik.handleSubmit}>
        <Stack gap={2}>
          <TextField
            id="oldPassword"
            name="oldPassword"
            label="Old Password"
            type={showPassword.old ? "text" : "password"}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handlePasswordVisibility("old")}
                    //   onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    {!showPassword.old ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...formik.getFieldProps("oldPassword")}
            error={
              formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
            }
            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
          />
          <TextField
            id="newPassword"
            name="newPassword"
            label="New Password"
            type={showPassword.new ? "text" : "password"}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handlePasswordVisibility("new")}
                    //   onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    {!showPassword.new ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...formik.getFieldProps("newPassword")}
            error={
              formik.touched.newPassword && Boolean(formik.errors.newPassword)
            }
            helperText={formik.touched.newPassword && formik.errors.newPassword}
          />
          <TextField
            id="confirmNewPassword"
            name="confirmNewPassword"
            label="Confirm New Password"
            type={showPassword.confirm ? "text" : "password"}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handlePasswordVisibility("confirm")}
                    //   onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    {!showPassword.confirm ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...formik.getFieldProps("confirmNewPassword")}
            error={
              formik.touched.confirmNewPassword &&
              Boolean(formik.errors.confirmNewPassword)
            }
            helperText={
              formik.touched.confirmNewPassword &&
              formik.errors.confirmNewPassword
            }
          />
        </Stack>
      </form>
    </Dialog>
  );
};

export default ChangePasswordForm;
