import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { PERSONAL_INFO } from "components/PremiumProviderEditProfileComponents/constants/editProfileConstants";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";

export const languageColor = {
  FLUENT: { backgroundColor: "#DAF8FF", color: "#2DACC8" },
  NATIVE: { backgroundColor: "#D9F2EC", color: "#3CAA91" },
  BEGINNER: { backgroundColor: "#FFF3F4", color: "#E34850" },
  INTERMEDIATE: { backgroundColor: "#FFF9E4", color: "#E9BA16" },
};

// USED IN NEW SCOPE

const Languages = ({ user, editAble = false }) => {
  const navigate = useNavigate();
  const handleLanguageEditClick = () => {
    navigate("/profile/edit", { state: { goToStep: PERSONAL_INFO } });
  };

  return (
    <div className=" w-full rounded-[8px] border border-borderColor p-6 mt-4">
      <div className="flex ">
        <Typography
          sx={{ color: "#002A5C", fontSize: 20, fontWeight: 600, pb: 1 }}
          gutterBottom
        >
          Languages
        </Typography>
        {editAble && (
          <Typography
            onClick={handleLanguageEditClick}
            sx={{
              color: "#00A4DA",
              fontSize: 16,
              fontWeight: 600,
              mt: "6px",
              pl: 1,
              cursor: "pointer",
            }}
            gutterBottom
          >
            Edit
          </Typography>
        )}
      </div>
      <Stack direction="row" gap={4} flexWrap="wrap">
        {user?.languages?.map((language, index) => (
          <RenderLanguage
            key={language.language}
            language={language}
            index={index}
          />
        ))}
      </Stack>
    </div>
  );
};

export default Languages;

export const RenderLanguage = ({ language, index }) => {
  const languages = useSelector(
    (state) => state.categoriesReducer?.languages ?? []
  );
  const fetchedLanguage = languages?.find((item) => {
    return item._id == language.language;
  });
  return (
    !!language.language && (
      <Stack>
        <Stack direction="row" gap={1}>
          <Typography
            sx={{
              color: "#5A5A5A",
              fontSize: 18,
              fontWeight: 500,
            }}
            gutterBottom
          >
            {fetchedLanguage?.name}
          </Typography>
          <Typography
            sx={{
              color: "#5A5A5A80",
              fontSize: 14,
              fontWeight: 500,
              mt: "4px",
              pl: 1,
            }}
            gutterBottom
          >
            {index === 0 ? "Primary" : "Secondary"}
          </Typography>
        </Stack>
        <Stack direction="row" gap={6}>
          <Stack gap={2}>
            <Typography
              sx={{
                color: "#5A5A5A80",
                fontSize: 16,
                fontWeight: 500,
                mt: "4px",
                width: "80px",
              }}
              gutterBottom
            >
              Oral
            </Typography>
            <LanguageChip text={language.oralProficiency} />
          </Stack>
          <Stack gap={2}>
            <Typography
              sx={{
                color: "#5A5A5A80",
                fontSize: 16,
                fontWeight: 500,
                mt: "4px",
                width: "80px",
              }}
              gutterBottom
            >
              Written
            </Typography>
            <LanguageChip text={language.writtenProficiency} />
          </Stack>
        </Stack>
      </Stack>
    )
  );
};

export const LanguageChip = ({ text }) => {
  return (
    <Button
      sx={{
        textTransform: "none",
        fontSize: 14,
        fontWeight: 700,
        backgroundColor: "#F6F6F6",
        color: "#5A5A5A",
        py: 1,
        mt: -0.25,
      }}
    >
      {text}
    </Button>
  );
};
