import * as React from "react";
import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import user1 from "../imgs/user3.png";
import arrow from "../imgs/arow.svg";

import { CardActionArea, CardActions } from "@mui/material";

export default function MultiActionAreaCard() {
  return (
    <Card
      sx={{
        maxWidth: 400,
        pt: 2,
        pl: 2,
        pr: 2,
        border: "1px solid #C6C7C4",
        borderRadius: "10px",
        boxShadow: "none",
      }}
    >
      <CardActionArea>
        <Box sx={{ display: "flex", gap: 2 }}>
          <img
            className="w-[73px] h-[80px] rounded-[10px]"
            src={user1}
            alt=""
          />
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{ fontWeight: 700, textSize: 16, color: "#002A5C" }}
              gutterBottom
              // component="div"
            >
              Esther Howard
            </Typography>
            <Typography
              sx={{ fontWeight: 500, textSize: 16, color: "#5A5A5A" }}
              gutterBottom
              component="div"
            >
              Zfoodize Company
            </Typography>
          </Box>
        </Box>
        {/* </CardContent> */}
        <Typography
          sx={{ fontWeight: 400, textSize: 14, color: "#5A5A5A", mt: 2 }}
        >
          "Our lawyer, Matt, was outstanding. Calm, patient, reasonable in the
          turmoil we were going through. We would definitely use Boyd Law again
          although we hope not to have to."
        </Typography>
      </CardActionArea>
      <CardActions>
        {/* <Button size="small" color="primary">
          Share
        </Button> */}
      </CardActions>
      {/* <div className="absolute mr-1">
        <img className="ml-[390px] mt-[-130px]" src={arrow} alt="" />
      </div> */}
    </Card>
  );
}
