import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Experiences from "./experience/Expiences";
import { Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { REPRESENTATION } from "components/PremiumProviderEditProfileComponents/constants/editProfileConstants";

// USED IN NEW SCOPE

const ExperienceMain = ({ user, editable = false, isNewScope, showRelevantRepresentations = false }) => {
  const [viewAll, setViewAll] = useState(user?.representations.length < 3);
  const providerRepresentations = showRelevantRepresentations ? user.relevantRepresentations : user.representations
  return (
    <div className="w-[100%]">
      <Stack direction="row" alignItems="center" my={2}>
        <Typography sx={{ color: "#002A5C", fontSize: 22, fontWeight: 700 }}>
        {(showRelevantRepresentations ? "Relevant " : "") + "Representations"}
        </Typography>
        {isNewScope && editable && (
          <Link to="/profile/edit" state={{ goToStep: REPRESENTATION }}>
            <Typography
              sx={{
                color: "#00A4DA",
                fontSize: 16,
                fontWeight: 600,
                pl: 1,
                pt: 0.5,
                cursor: "pointer",
              }}
            >
              Edit
            </Typography>
          </Link>
        )}
      </Stack>
      <Grid container columnSpacing={8} rowSpacing={3}>
        {(viewAll
          ? providerRepresentations
          : providerRepresentations.slice(0, 2)
        ).map((experience, index) => (
          <Grid key={index} item xs={12} md={6}>
            <Experiences experience={experience} user={user} />
          </Grid>
        ))}
      </Grid>
      <Typography
        sx={{
          color: "#00A4DA",
          fontSize: 20,
          fontWeight: 600,
          mt: 4,
          textAlign: "center",
          cursor: "pointer",
        }}
        gutterBottom
        onClick={() => setViewAll(!viewAll)}
      >
        {providerRepresentations.length < 3
          ? ""
          : !viewAll
          ? "View More"
          : "View Less"}
      </Typography>
    </div>
  );
};

export default ExperienceMain;
