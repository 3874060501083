import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { formatUrl } from "utils/helperFunctions/helper";

import { ACHIEVEMENTS } from "components/PremiumProviderEditProfileComponents/constants/editProfileConstants";
import { Link } from "react-router-dom";
import PublicationIcon from "./imgs/publication.svg";

const Publication = ({ editAble, publications }) => {
  return (
    <Box className=" w-full rounded-[8px] border border-borderColor p-6 mt-4">
      <Stack direction="row">
        <Typography
          sx={{ color: "#002A5C", fontSize: 20, fontWeight: 600, pb: 1 }}
          gutterBottom
        >
          Publications
        </Typography>
        {editAble && (
          <Link to="/profile/edit" state={{ goToStep: ACHIEVEMENTS }}>
            <Typography
              sx={{
                color: "#00A4DA",
                fontSize: 16,
                fontWeight: 600,
                pl: 1,
                pt: 0.5,
              }}
            >
              Edit
            </Typography>
          </Link>
        )}
      </Stack>
      <Stack
        direction="row"
        spacing={0}
        sx={{ flexWrap: "wrap", gap: { xs: "10px", sm: "60px" } }}
      >
        {publications.map((item, index) => {
          return (
              <Box key={index}
                   sx={{ display: "flex", gap: "20px", width: "40vw" }}>
                <Box>
                  <img src={PublicationIcon} alt="Publication" />
                </Box>
                <Box>
                  <Typography
                    variant="h4"
                    sx={{ color: "#002A5C", fontSize: "18px" }}
                  >
                    {item?.name}
                  </Typography>
                  <Typography sx={{ color: "#5A5A5A80" }}>
                    Published on {item?.publishedAt}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#002A5C",
                      fontSize: 14,
                      fontWeight: 600,
                      marginY: "8px",
                    }}
                  >
                URL <a
                  href={formatUrl(item.url)}
                  color="#5A5A5A"
                  target="_blank"
                  rel="noreferrer"
                  >
                  <Typography sx={{ lineBreak: "anywhere" }}>
                    {item.url}
                  </Typography>
                </a>
              </Typography>
              </Box>
            </Box>  
            );
           })}
      </Stack>
    </Box>
  );
};

export default Publication;
