import { routes } from "utils/appConfig";

export const subscribeToNewsletter = async (callApi, data, callBack) => {
  const { url, method } = routes.newsletterSubscribe;

  try {
    await callApi(url, method, data, {
      onSuccess: ({ data }) => {
        callBack(data);
      },
      onError: (res) => {
        callBack(null, res);
      },
      successMessage: "Thank you for subscribing to our newsletter!",
      errorMessage: "An error occurred. Please try again later.",
    });
  } catch (e) {
    throw new Error(e.response);
  }
};
