import React, { Fragment } from "react";
import Step from "components/PremiumProviderEditProfileComponents/editProfileSteps/Step";
import { FIRM_INFO } from "utils/constants/constants";

const EditProfileSteps = ({ step, stepsCompleted, setStep, stepData, saveStep }) => {
  const stepper = stepData[0].profile;
  const stepperSpacing = stepper === "INDIVIDUAL" ? "start" : "between";
  const stepperGap = stepper === "PREMIUM" ? 4 : 4;
  return (
    <div
      className={`steps flex my-10 justify-${stepperSpacing} w-[100%] items-center flex-wrap gap-${stepperGap} `}
    >
      {stepData.map(({ stepName, stepNumber, key }, index) => {
        return (
          <Fragment key={index}>
            <Step
              key={index}
              stepName={stepName}
              stepNumber={stepNumber}
              currentStep={step}
              code={key}
              stepsCompleted={stepsCompleted}
              setStep={setStep}
              stepper={stepper}
              lastItem={stepData?.length - 1 !== index}
              saveStep={saveStep}
            />
            {key === FIRM_INFO && (
              <div className="hidden sm:block h-1 rounded-md bg-gray w-[53px] mr-4"></div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default EditProfileSteps;
