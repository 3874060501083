import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

const LanguagesAutoComplete = ({ options, languageId, onChange, ...rest }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  useEffect(() => {
    if (languageId) {
      let findLanguageById = options.find((lang) => lang._id === languageId);
      setSelectedLanguage(findLanguageById ?? null);
    }
  }, [languageId]);

  const handleChange = (language) => {
    setSelectedLanguage(language);
    onChange(language?._id || undefined);
  };
  return (
    <Autocomplete
      options={options}
      value={selectedLanguage}
      onChange={(e, value) => handleChange(value)}
      getOptionLabel={(option) => {
        return option?.name + ", " + option?.nativeName;
      }}
      renderOption={(props, option) => {
        if (!option?.name) {
          return "please select";
        }
        if (option.name === "divider") {
          return (
            <li
              key={option.name}
              className=" border-borderColor border-b-2 ml-2"
            />
          );
        }
        return (
          <li {...props} key={option?._id}>
            {option?.name}
            {option?.nativeName && `, ${option?.nativeName}`}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Please select"
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
      {...rest}
    />
  );
};

export default LanguagesAutoComplete;
