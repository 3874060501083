import React from "react";
import { openModal } from "store/actions/modalActions";
import { PLANS_MODAL } from "utils/constants/constants";
import { useDispatch } from "react-redux";
import {
  formattedCurrency,
  isPremiumSubscription,
} from "utils/helperFunctions/helper";
import { updateMemberShipTo } from "store/actions/actions";

const MembershipPlans = ({
  type,
  price,
  active,
  subscription = "",
  productId,
  productDescription,
}) => {
  const hoverStyle = `hover:bg-[#00A4DA] hover:bg-opacity-30  hover:outline-[#00A4DA] ${
    !active && "cursor-pointer"
  }`;
  const dispatch = useDispatch();

  const openPlansModal = () => {
    dispatch(openModal(PLANS_MODAL));
  };
  const currentPackage = () => {
    if (isPremiumSubscription(subscription)) {
      return "Premium Package";
    }
    return "Standard Package";
  };
  const handlePlanClick = () => {
    if (currentPackage() !== type && !active) {
      dispatch(updateMemberShipTo({ type, price, productId }));
      openPlansModal();
    }
  };
  return (
    <div
      className={`plan relative px-8 py-7 outline-2 outline-borderColor outline-dashed outline-offset-1 ${
        active && "bg-[#00A4DA] bg-opacity-30 outline-[#00A4DA]"
      } ${
        currentPackage() !== type ? hoverStyle : ""
      } transition ease-in-out duration-300 rounded-md max-w-[500px]`}
      onClick={handlePlanClick}
    >
      <div className="flex justify-between items-center mb-4">
        <h5 className="text-base text-textColor font-medium ">{type}</h5>
        <h3
          className={`text-2xl font-bold text-secondary ${
            active ? "" : "sm:mr-8"
          }`}
        >
          {formattedCurrency(price)}/y
        </h3>
      </div>
      <p className={`text-sm text-textColor ${active ? "" : "sm:w-[75%]"}`}>
        {productDescription}
      </p>
    </div>
  );
};

export default MembershipPlans;
