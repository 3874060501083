import React from "react";
// import  { useState } from React;
import logo from "../PremiumProviderEditProfileComponents/imgs/logo.png";
import client from "./imgs/client.png";

const Nav = () => {
  return (
    <div>
      <div className="flex justify-between py-4">
        <div className=" ">
          <img src={logo} width="200px" alt="Lawyer Up Logo" />
        </div>
        <div className="flex -ml-4 items-center">
          <a href="#">
            <i className="fa-regular fa-bell text-[#5A5A5A99] text-2xl pr-6"></i>
          </a>
          <img className="rounded-md" src={client} width="40px" alt="" />
          <a href="#">
            <i
              className="fa-solid fa-chevron-down text-[#5A5A5A99] text-lg pl-1"
              // onClick={() => setShowMenu(!showMenu)}
            ></i>
          </a>
          {/* {showMenu && (
            <div className="absolute right-4 mt-[224px] py-2 bg-white rounded-md shadow-lg">
              <a
                href="#"
                className="block px-4 py-1 text-secondary hover:text-primary hover:bg-[#f2f0f385] text-[17px] font-[700] "
              >
                Alex John
              </a>
              <a
                href="#"
                className="block px-4 py-1 text-textColor hover:text-primary hover:bg-[#f2f0f385]  text-[14px] font-[600] "
              >
                Dekley Locks
              </a>
              . */}
          {/* <div className="flex justify-center -mt-4 pb-2"> */}
          {/* {" "} */}
          {/* <hr className="w-[80%] text-borderColor" />
              </div>
          
              <a
                href="#"
                className="block px-4 py-1 text-secondary hover:text-primary hover:bg-[#f2f0f385]  text-[14px] font-[600]"
              >
                Dashboard
              </a>
              <a
                href="#"
                className="block px-4 py-1 text-secondary hover:text-primary hover:bg-[#f2f0f385]  text-[14px] font-[600]"
              >
                Profile
              </a>
              <a
                href="#"
                className="block px-4 py-1 text-secondary hover:text-primary hover:bg-[#f2f0f385]  text-[14px] font-[600]"
              >
                Logout
              </a>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Nav;
