import React, { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import BreadCrumb from "components/Dashboard/Bredcrums/BreadCrum";
import { Container } from "@mui/system";
import { useSelector } from "react-redux";
import { firmInfoData } from "components/firm/helper";
import Description from "components/Business/businessmui/Description";
import { Link, useParams } from "react-router-dom";
import {
  fetchCategoryName,
  formatDate,
  formatPhone,
} from "utils/helperFunctions/helper";
import { ShowAddress } from "components/Business/businessmui/Address";
import { FIRM_INFO } from "utils/constants/constants";
import { ADDRESS } from "components/PremiumProviderEditProfileComponents/constants/editProfileConstants";
import { fetchBusinessById } from "services/business";
import { useAxios } from "utils/hooks/useAxios";
import Loader from "utils/loader/Loader";
import NoDataAvailable from "components/noDataAvailable/NoDataAvailable";

const Firm = () => {
  const [business, setBusiness] = useState({});
  const [noResponseFromApi, setNoResponseFromApi] = useState(false);
  const states = useSelector((state) => state.categoriesReducer.states);
  const params = useParams();
  const { isLoading, callApi } = useAxios();

  useEffect(() => {
    if (params?.id) {
      fetchBusinessById(callApi, params.id, (data, error) => {
        if (data) {
          setBusiness(data.data);
        } else if (error) {
          setNoResponseFromApi(true);
        }
      });
    }
  }, [params?.id]);

  const isLogoValid = (business) => {
    return business?.businessLogo?.includes("http")
      ? business.businessLogo
      : null;
  };
  return (
    <Container maxWidth="xl">
      <BreadCrumb label="Firm" />
      <Typography
        sx={{
          color: "#002A5C",
          fontSize: 22,
          fontWeight: 700,
          py: 2,
        }}
      >
        Firm
      </Typography>
      {!isLoading && !noResponseFromApi ? (
        <Stack gap={4} mb={4}>
          <Stack
            position="relative"
            direction={{ sm: "row" }}
            border="1px solid #C6C7C4"
            borderRadius="8px"
            p={3}
            gap={2}
          >
            {/* <Stack
              direction="row"
              gap={1}
              position="absolute"
              top={{ xs: 221, sm: 25 }}
              right={30}
            >
              <VerifiedIcon color="success" />
              <Typography>Verified</Typography>
            </Stack> */}
            {isLogoValid(business) && (
              <img
                className="h-[181px] w-[180px] rounded-md object-contain"
                src={isLogoValid(business)}
                alt=""
              />
            )}
            {!isLogoValid(business) && (
              <div className=" bottom-8 right-8 flex justify-center items-center w-[180px] h-[180px] text-textColor bg-[#C6C7C41A] rounded-[8px]">
                <div className="flex justify-center items-center w-[108px] h-[108px] text-textColor bg-[#EFEFEF] rounded-[50px]">
                  <p className=" text-textColor self-center">Logo</p>
                </div>
              </div>
            )}
            <Stack gap={3} position="relative">
              <Stack direction="row">
                <Typography
                  sx={{
                    color: "#002A5C",
                    fontSize: 22,
                    fontWeight: 700,
                  }}
                  gutterBottom
                >
                  {business?.name}
                </Typography>
                <Typography
                  sx={{
                    color: "#00A4DA",
                    fontSize: 16,
                    fontWeight: 600,
                    textAlign: "center",
                    pl: 1,
                    mt: { md: 1 },
                  }}
                  gutterBottom
                >
                  <Link
                    to="/editfirm"
                    state={{ goToStep: FIRM_INFO, id: params.id }}
                  >
                    Edit
                  </Link>
                </Typography>
              </Stack>
              <Stack direction="row" flexWrap="wrap" gap={2}>
                {firmInfoData.map(({ title, key }) => {
                  let tempValue = business[key];
                  if (key === "registeredDate") {
                    if (!tempValue) return;
                    tempValue = formatDate(tempValue, "MM/YYYY");
                  }
                  if (key === "businessPhone") {
                    tempValue = formatPhone(tempValue);
                  }
                  return (
                    <Stack key={key} gap={2}>
                      <Typography>{title}</Typography>
                      <Typography sx={{ color: "#5A5A5A" }}>
                        {tempValue}
                      </Typography>
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          </Stack>
          <Box mt={-2}>
            <Description
              description={business.businessDescription}
              rounded={8}
              url="/editfirm"
              step={FIRM_INFO}
              id={params?.id}
            />
          </Box>
          <Box border="1px solid #C6C7C4" borderRadius="8px" p={3} gap={4}>
            <Stack direction="row" mb={3}>
              <Typography
                sx={{
                  color: "#002A5C",
                  fontSize: 22,
                  fontWeight: 700,
                }}
                gutterBottom
              >
                Address
              </Typography>
              <Typography
                sx={{
                  color: "#00A4DA",
                  fontSize: 16,
                  fontWeight: 600,
                  textAlign: "center",
                  pl: 1,
                  mt: { md: 1 },
                }}
                gutterBottom
              >
                <Link
                  to="/editfirm"
                  state={{ goToStep: ADDRESS, id: params?.id }}
                >
                  Edit
                </Link>
              </Typography>
            </Stack>
            <Stack direction="row" gap={8} alignItems="center" mb={2}>
              <Typography>Office Type</Typography>
              <Typography>Location</Typography>
            </Stack>
            {business?.addresses?.map((address, i) => {
              const fetchStateName = fetchCategoryName(
                states,
                address?.state
              )?.name;
              return (
                <Stack
                  key={i}
                  direction="row"
                  gap={5}
                  alignItems="center"
                  mb={1}
                >
                  <Typography
                    variant="contained"
                    sx={{ width: "105px", p: 0.5 }}
                    align="center"
                    bgcolor={
                      address?.officeType === "VIRTUAL" ? "#DAF8FF" : "#F6F6F6"
                    }
                  >
                    {address.officeType}
                  </Typography>
                  {/* <Typography>{address?.address}</Typography> */}
                  <ShowAddress address={address} state={fetchStateName} />
                </Stack>
              );
            })}
          </Box>
        </Stack>
      ) : !isLoading && noResponseFromApi ? (
        <NoDataAvailable />
      ) : (
        <Stack justifyContent="center" alignItems="center" minHeight="400px">
          <Loader width={50} height={50} />
        </Stack>
      )}
    </Container>
  );
};

export default Firm;
