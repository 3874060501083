import React from "react";
import { formattedCurrency } from "utils/helperFunctions/helper";

const subscribedPlan = ({ type, price, description }) => {
  return (
    <div className="plan px-8 py-7 bg-[#00A4DA] bg-opacity-30 outline-2 outline-[#00A4DA] outline-dashed outline-offset-1 rounded-md max-w-[500px]">
      <div className="flex justify-between items-center mb-4">
        <h5 className="text-base text-textColor font-medium ">
          {type} Package
        </h5>
        <h3 className="text-2xl font-bold text-secondary sm:mr-8">
          {formattedCurrency(price)}/y
        </h3>
      </div>
      <p className="text-sm text-textColor sm:w-[75%]">{description}</p>
    </div>
  );
};

export default subscribedPlan;
