import {
  CHANGE_MEMBERSHIP_TO,
  UPDATE_PAYMENT_CARDS,
} from "utils/constants/constants";

const initialState = { paymentCards: [], changeMembershipTo: null };

export const membershipReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAYMENT_CARDS:
      return { ...state, paymentCards: [...action.payload] };
    case CHANGE_MEMBERSHIP_TO:
      return { ...state, changeMembershipTo: { ...action.payload } };
    default:
      return state;
  }
};
