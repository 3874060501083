import React, { useEffect, useState } from "react";
import { Autocomplete as MUIAutoComplete, TextField } from "@mui/material";

const AutoComplete = ({
  options,
  onChange,
  defaultValue,
  placeholder,
  variant,
  borderRadius,
}) => {
  const [value, setValue] = useState(null);
  const handleChange = (value) => {
    setValue(value);
    onChange(value);
  };

  useEffect(() => {
    if (Object.keys(defaultValue)?.length) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <MUIAutoComplete
      disablePortal
      id="combo-box-demo"
      options={options}
      value={value}
      onChange={(e, value) => handleChange(value)}
      sx={{
        width: "100%",
        "&:not(:last-child)": {
          borderBottom: { xs: "1px solid #c6c7c481", md: "none" },
        },
      }}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant={variant ?? "standard"}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            sx: {
              borderRadius,
            },
          }}
        />
      )}
    />
  );
};

export default AutoComplete;
