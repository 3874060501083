import React from "react";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import BrokenGavelSounderIcon from "assets/broken-gavel-sounding-block.svg"
import Typography from "@mui/material/Typography";

const NoDataAvailable = ({ animationWidth, animationHeight, ...props }) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      minHeight="560px"
      {...props}>
      <Box >
        <img src={BrokenGavelSounderIcon} alt="No data available" style={{ marginBottom: '50px', marginLeft: '95px' }}/>
      </Box>
      <Stack direction="row" spacing={0} sx={{ flexWrap: "wrap"}}>
        <Typography
          sx={{ color: "#002A5C", fontSize: 20, fontWeight: 300, pb: 1 }}
          gutterBottom
        >
          The evidence could not be found
        </Typography>

      </Stack>
    </Stack>
  );
};

export default NoDataAvailable;
