import check from "assets/check.svg";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "store/actions/modalActions";
import { EXPERIENCE_MODAL } from "utils/constants/constants";
import { EXPERIENCE, REPRESENTATION } from "../constants/editProfileConstants";
import { isPremiumSubscriber } from "components/PremiumProviderEditProfileComponents/editProfileSteps//helper";

const Step = ({
  stepName,
  stepNumber,
  currentStep,
  code,
  stepsCompleted,
  setStep,
  stepper,
  lastItem,
  saveStep,
}) => {
  const subscription = useSelector((state) => state.user?.subscription);
  const isStepActive = currentStep === code;
  const isCompleted = stepsCompleted >= +stepNumber;
  const isClickAllowed = stepsCompleted + 1 >= +stepNumber;
  const dispatch = useDispatch();

  const handleClick = () => {
    saveStep(code);
    setStep(code);

    // EXPERIENCE MODAL LOGIC IF USER IS STANDARD

    // if (
    //   (code === EXPERIENCE || code === REPRESENTATION) &&
    //   !isPremiumSubscriber(subscription)
    // ) {
    //   dispatch(openModal(EXPERIENCE_MODAL));
    // } else {
    //   setStep(code);
    // }
  };
  return (
    <>
      <div className="flex flex-col md:flex-row gap-2 items-center">
        <button
          className={`w-[41px] h-[41px] flex justify-center items-center font-semibold rounded-[60px] text-white bg-${
            isCompleted ? "primary" : isStepActive ? "primary" : "borderColor"
          } enabled:hover:bg-hoverColor `}
          onClick={handleClick}
          disabled={!isClickAllowed && stepsCompleted !== 5}
        >
          {isCompleted && !isStepActive ? <img src={check} /> : stepNumber}
        </button>
        <h1 className={`text-${isStepActive} font-semibold mx-3`}>
          {stepName}
        </h1>
      </div>
      {stepper !== "INDIVIDUAL" && lastItem && (
        <div className="hidden sm:block h-1 rounded-md bg-gray flex-grow mr-4"></div>
      )}
    </>
  );
};

export default Step;
