import { useEffect } from "react";

// Calls the given `onClickOutside` function when a click oustide of the given `refs` sub-tree is
// detected.
const useClickOutside = (refs, onClickOutside) => {
  useEffect(() => {
    const isOutsideOfTarget = (ref, target) => {
      return ref.current && !ref.current.contains(target);
    };

    const handleClick = (event) => {
      if (
        event.target &&
        refs.every((ref) => isOutsideOfTarget(ref, event.target))
      ) {
        onClickOutside(event);
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [refs, onClickOutside]);
};

export default useClickOutside;
