import React from "react";
import { Autocomplete, Chip } from "@mui/material";
import { LargeTextField } from "components/datePicker/DatePicker";


const MultipleValues = ({ options, label, value, onChange }) => {
  return (
    <Autocomplete
    multiple
    id="tags-filled"
    options={options.map((option) => option)}
    value={options.map((option) => option)}
    freeSolo
    renderTags={(value, getTagProps) =>
      value.map((option, index) => {
        const { key, ...tagProps } = getTagProps({ index });
        return (
          <Chip variant="outlined" label={option} key={key} {...tagProps} />
        );
      })
    }
    renderInput={(params) => (
      <LargeTextField
        {...params}
        variant="outlined"
        placeholder="Please enter one activity or honor and then press Enter to add additional activities or honors"
      />
    )}
    onChange={(_, newValue) => {
      onChange(newValue);
    }}
    />

  );
};

export default MultipleValues;
