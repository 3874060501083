import { Container, Stack, Typography } from "@mui/material";
import ExternalLink from "components/ExternalLink";
import React from "react";
import { Link } from "react-router-dom";

const TermsOfService = () => {
  return (
    <Container sx={{ maxWidth: "1300px" }}>
      <Stack gap={4}>
        <Typography variant="h5" fontWeight={600} align="center">
          Terms of Service
        </Typography>
        <Typography variant="h6">Last Modified: May 16, 2023</Typography>
        <Typography
          variant="h4"
          align="center"
          className="underline"
          fontWeight={600}
        >
          ACCEPTANCE OF THE TERMS OF SERVICE
        </Typography>
        <Typography variant="h6" align="justify">
          These terms of service are entered into by and between You and
          LawyerUp Holdings, LLC, d/b/a JuriCon.ai, a Delaware limited liability
          company ("<strong>Company</strong>," "<strong>we</strong>," or "
          <strong>us</strong>"). The following terms and conditions, together
          with any documents they expressly incorporate by reference
          (collectively, "<strong>Terms of Service</strong>"), govern your
          access to and use, including any content, functionality, and services
          offered on or through{" "}
          <ExternalLink
            href="https://www.juricon.ai"
            target="_blank"
            rel="noreferrer"
          >
            www.juricon.ai
          </ExternalLink>{" "}
          (the "<strong>Service</strong>"), whether as a guest or a registered
          user.
        </Typography>
        <Typography variant="h6" align="justify">
          Please read these Terms of Service carefully before you start to use
          the Service.{" "}
          <strong>
            By using the Service, you accept and agree to be bound and abide by
            these Terms of Service and our Privacy Policy, found at{" "}
            <ExternalLink
              to="#"
              className="cursor-pointer text-primary hover:underline"
            >
              Privacy Policy
            </ExternalLink>
            , incorporated herein by reference.
          </strong>{" "}
          If you do not want to agree to these Terms of Service or the Privacy
          Policy, you must not access or use the Service.
        </Typography>
        <Typography variant="h6" align="justify">
          This Service is offered and available to users who are 18 years of age
          or older and reside in the United States or any of its territories or
          possessions. By using this Service, you represent and warrant that you
          are of legal age to form a binding contract with the Company and meet
          all of the foregoing eligibility requirements. If you do not meet all
          of these requirements, you must not access or use the Service.
        </Typography>
        {/* CHANGES TO THE TERMS OF SERVICE */}
        <Typography
          variant="h4"
          align="center"
          className="underline"
          fontWeight={600}
          upper
        >
          CHANGES TO THE TERMS OF SERVICE
        </Typography>
        <Typography variant="h6" align="justify">
          We may revise and update these Terms of Service from time to time in
          our sole discretion. All changes are effective immediately when we
          post them, and apply to all access to and use of the Service
          thereafter. Your continued use of the Service following the posting of
          revised Terms of Service means that you accept and agree to the
          changes.
        </Typography>
        {/* Accessing the Service and Account Security*/}
        <Typography
          variant="h4"
          align="center"
          className="underline uppercase"
          fontWeight={600}
        >
          Accessing the Service and Account Security
        </Typography>
        <Typography variant="h6" align="justify">
          We reserve the right to withdraw or amend this Service, and any
          service or material we provide on the Service, in our sole discretion
          without notice. We will not be liable if for any reason all or any
          part of the Service is unavailable at any time or for any period. From
          time to time, we may restrict access to some parts of the Service, or
          the entire Service, to users, including registered users.
        </Typography>
        <Stack gap={2}>
          <Typography variant="h6" align="justify">
            You are responsible for both:
          </Typography>
          <ul className="list-disc">
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Making all arrangements necessary for you to have access to the
                Service.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Ensuring that all persons who access the Service through your
                internet connection are aware of these Terms of Service and
                comply with them.
              </Typography>
            </li>
          </ul>
        </Stack>
        <Typography variant="h6" align="justify">
          To access the Service or some of the resources it offers, you may be
          asked to provide certain registration details or other information. It
          is a condition of your use of the Service that all the information you
          provide on the Service is correct, current, and complete. You agree
          that all information you provide to register with this Service or
          otherwise, including, but not limited to, through the use of any
          interactive features on the Service, is governed by our{" "}
          <ExternalLink
            to="#"
            className="cursor-pointer text-primary hover:underline"
          >
            Privacy Policy
          </ExternalLink>
          , and you consent to all actions we take with respect to your
          information consistent with our Privacy Policy.
        </Typography>
        <Typography variant="h6" align="justify">
          If you choose, or are provided with, a user name, password, or any
          other piece of information as part of our security procedures, you
          must treat such information as confidential, and you must not disclose
          it to any other person or entity. You also acknowledge that your
          account is personal to you and agree not to provide any other person
          with access to this Service or portions of it using your user name,
          password, or other security information. You agree to notify us
          immediately of any unauthorized access to or use of your user name or
          password or any other breach of security. You also agree to ensure
          that you exit from your account at the end of each session. You should
          use particular caution when accessing your account from a public or
          shared computer so that others are not able to view or record your
          password or other personal information.
        </Typography>
        <Typography variant="h6" align="justify">
          We have the right to disable any user name, password, or other
          identifier, whether chosen by you or provided by us, at any time in
          our sole discretion for any or no reason, including if, in our
          opinion, you have violated any provision of these Terms of Service.
        </Typography>
        {/* Intellectual Property Rights */}
        <Typography
          variant="h4"
          align="center"
          className="underline uppercase"
          fontWeight={600}
        >
          Intellectual Property Rights
        </Typography>
        <Typography variant="h6" align="justify">
          The Service and its entire contents, features, and functionality
          (including but not limited to all information, software, text,
          displays, images, video, and audio, and the design, selection, and
          arrangement thereof) are owned by the Company, its licensors, or other
          providers of such material and are protected by United States and
          international copyright, trademark, patent, trade secret, and other
          intellectual property or proprietary rights laws.
        </Typography>
        <Typography variant="h6" align="justify">
          These Terms of Service permit you to use the Service for your
          personal, non-commercial use only. You must not reproduce, distribute,
          modify, create derivative works of, publicly display, publicly
          perform, republish, download, store, or transmit any of the material
          on our Service, except as follows:
        </Typography>
        <Stack gap={2} mt={-2}>
          <ul className="list-disc">
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Your computer may temporarily store copies of such materials in
                RAM incidental to your accessing and viewing those materials.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                You may store files that are automatically cached by your Web
                browser for display enhancement purposes.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                You may print or download one copy of a reasonable number of
                pages of the Service for your own personal, non-commercial use
                and not for further reproduction, publication, or distribution.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                If we provide desktop, mobile, or other applications for
                download, you may download a single copy to your computer or
                mobile device solely for your own personal, non-commercial use,
                provided you agree to be bound by our end user license agreement
                for such applications.
              </Typography>
            </li>
          </ul>
          <Typography variant="h6" align="justify">
            You must not:
          </Typography>
          <ul className="list-disc">
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Modify copies of any materials from this site.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Use any illustrations, photographs, video or audio sequences, or
                any graphics separately from the accompanying text.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Delete or alter any copyright, trademark, or other proprietary
                rights notices from copies of materials from this site.
              </Typography>
            </li>
          </ul>
        </Stack>
        <Typography variant="h6" align="justify">
          You must not access or use for any commercial purposes any part of the
          Service or any services or materials available through the Service.
        </Typography>
        <Typography variant="h6" align="justify">
          If you wish to make any use of material on the Service other than that
          set out in this section, please address your request to:
          <span className="text-primary ">legal@juricon.ai</span>.
        </Typography>
        <Typography variant="h6" align="justify">
          If you print, copy, modify, download, or otherwise use or provide any
          other person with access to any part of the Service in breach of the
          Terms of Service, your right to use the Service will stop immediately
          and you must, at our option, return or destroy any copies of the
          materials you have made. No right, title, or interest in or to the
          Service or any content on the Service is transferred to you, and all
          rights not expressly granted are reserved by the Company. Any use of
          the Service not expressly permitted by these Terms of Service is a
          breach of these Terms of Service and may violate copyright, trademark,
          and other laws.
        </Typography>
        {/* Trademarks */}
        <Typography
          variant="h4"
          align="center"
          className="underline uppercase"
          fontWeight={600}
        >
          Trademarks
        </Typography>
        <Typography variant="h6" align="justify">
          The Company name, the Company logo, and all related names, logos,
          product and service names, designs, and slogans are trademarks of the
          Company or its affiliates or licensors. You must not use such marks
          without the prior written permission of the Company. All other names,
          logos, product and service names, designs, and slogans on this Service
          are the trademarks of their respective owners.
        </Typography>
        {/* Prohibited Uses */}
        <Typography
          variant="h4"
          align="center"
          className="underline uppercase"
          fontWeight={600}
        >
          Prohibited Uses
        </Typography>
        <Typography variant="h6" align="justify">
          You may use the Service only for lawful purposes and in accordance
          with these Terms of Service. You agree not to use the Service:
        </Typography>
        <Stack gap={2} mt={-2}>
          <ul className="list-disc">
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                In any way that violates any applicable federal, state, local,
                or international law or regulation (including, without
                limitation, any laws regarding the export of data or software to
                and from the US or other countries).
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                For the purpose of exploiting, harming, or attempting to exploit
                or harm minors in any way by exposing them to inappropriate
                content, asking for personally identifiable information, or
                otherwise.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                To send, knowingly receive, upload, download, use, or re-use any
                material that does not comply with the Content Standards set out
                in these Terms of Service.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                To transmit, or procure the sending of, any advertising or
                promotional material, including any "junk mail," "chain letter,"
                "spam," or any other similar solicitation.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                To impersonate or attempt to impersonate the Company, a Company
                employee, another user, or any other person or entity
                (including, without limitation, by using email addresses or
                usernames associated with any of the foregoing).
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                To engage in any other conduct that restricts or inhibits
                anyone's use or enjoyment of the Service, or which, as
                determined by us, may harm the Company or users of the Service,
                or expose them to liability.
              </Typography>
            </li>
          </ul>
          <Typography variant="h6" align="justify">
            Additionally, you agree not to:
          </Typography>
          <ul className="list-disc">
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Use the Service in any manner that could disable, overburden,
                damage, or impair the site or interfere with any other party's
                use of the Service, including their ability to engage in real
                time activities through the Service.{" "}
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Use any robot, spider, or other automatic device, process, or
                means to access the Service for any purpose, including
                monitoring or copying any of the material on the Service.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Use any manual process to monitor or copy any of the material on
                the Service, or for any other purpose not expressly authorized
                in these Terms of Service, without our prior written consent.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Use any device, software, or routine that interferes with the
                proper working of the Service.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Introduce any viruses, Trojan horses, worms, logic bombs, or
                other material that is malicious or technologically harmful.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Attempt to gain unauthorized access to, interfere with, damage,
                or disrupt any parts of the Service, the server on which the
                Service is stored, or any server, computer, or database
                connected to the Service.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Attack the Service via a denial-of-service attack or a
                distributed denial-of-service attack.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Otherwise attempt to interfere with the proper working of the
                Service.
              </Typography>
            </li>
          </ul>
        </Stack>
        {/* User Contributions */}
        <Typography
          variant="h4"
          align="center"
          className="underline uppercase"
          fontWeight={600}
        >
          User Contributions
        </Typography>
        <Typography variant="h6" align="justify">
          The Service may contain message boards, chat rooms, personal web pages
          or profiles, forums, bulletin boards and other interactive features
          (collectively, "<strong>Interactive Services</strong>") that allow
          users to post, submit, publish, display, or transmit to other users or
          other persons (hereinafter, "<strong>post</strong>") content or
          materials (collectively, "<strong>User Contributions</strong>") on or
          through the Service.
        </Typography>
        <Typography variant="h6" align="justify">
          All User Contributions must comply with the Content Standards set out
          in these Terms of Service.
        </Typography>
        <Typography variant="h6" align="justify">
          Any User Contribution you post to the site will be considered
          non-confidential and non-proprietary. By providing any User
          Contribution on the Service, you grant us and our affiliates and
          service providers, and each of their and our respective licensees,
          successors, and assigns the right to use, reproduce, modify, perform,
          display, distribute, and otherwise disclose to third parties any such
          material for any purpose according to your account settings.
        </Typography>
        <Typography variant="h6" align="justify">
          You represent and warrant that:
        </Typography>
        <Stack gap={2} mt={-2}>
          <ul className="list-disc">
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                You own or control all rights in and to the User Contributions
                and have the right to grant the license granted above to us and
                our affiliates and service providers, and each of their and our
                respective licensees, successors, and assigns.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                All of your User Contributions do and will comply with these
                Terms of Service.
              </Typography>
            </li>
          </ul>
        </Stack>
        <Typography variant="h6" align="justify">
          You understand and acknowledge that you are responsible for any User
          Contributions you submit or contribute, and you, not the Company, have
          full responsibility for such content, including its legality,
          reliability, accuracy, and appropriateness.
        </Typography>
        <Typography variant="h6" align="justify">
          We are not responsible or liable to any third party for the content or
          accuracy of any User Contributions posted by you or any other user of
          the Service.
        </Typography>
        {/* Monitoring and Enforcement; Termination */}
        <Typography
          variant="h4"
          align="center"
          className="underline uppercase"
          fontWeight={600}
        >
          Monitoring and Enforcement; Termination
        </Typography>
        <Typography variant="h6" align="justify">
          We have the right to:
        </Typography>
        <Stack gap={2} mt={-2}>
          <ul className="list-disc">
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Remove or refuse to post any User Contributions for any or no
                reason in our sole discretion.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Take any action with respect to any User Contribution that we
                deem necessary or appropriate in our sole discretion, including
                if we believe that such User Contribution violates the Terms of
                Service, including the Content Standards, infringes any
                intellectual property right or other right of any person or
                entity, threatens the personal safety of users of the Service or
                the public, or could create liability for the Company.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Disclose your identity or other information about you to any
                third party who claims that material posted by you violates
                their rights, including their intellectual property rights or
                their right to privacy.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Take appropriate legal action, including without limitation,
                referral to law enforcement, for any illegal or unauthorized use
                of the Service.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Terminate or suspend your access to all or part of the Service
                for any or no reason, including without limitation, any
                violation of these Terms of Service.
              </Typography>
            </li>
          </ul>
        </Stack>
        <Typography variant="h6" align="justify">
          Without limiting the foregoing, we have the right to cooperate fully
          with any law enforcement authorities or court order requesting or
          directing us to disclose the identity or other information of anyone
          posting any materials on or through the Service. YOU WAIVE AND HOLD
          HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES, AND SERVICE
          PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE
          COMPANY/ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE
          OF, INVESTIGATIONS BY EITHER THE COMPANY OR LAW ENFORCEMENT
          AUTHORITIES.
        </Typography>
        <Typography variant="h6" align="justify">
          However, we do not undertake to review all material before it is
          posted on the Service, and cannot ensure prompt removal of
          objectionable material after it has been posted. Accordingly, we
          assume no liability for any action or inaction regarding
          transmissions, communications, or content provided by any user or
          third party. We have no liability or responsibility to anyone for
          performance or nonperformance of the activities described in this
          section.
        </Typography>
        {/* Content Standards */}
        <Typography
          variant="h4"
          align="center"
          className="underline uppercase"
          fontWeight={600}
        >
          Content Standards
        </Typography>
        <Typography variant="h6" align="justify">
          These content standards apply to any and all User Contributions and
          use of Interactive Services. User Contributions must in their entirety
          comply with all applicable federal, state, local, and international
          laws and regulations. Without limiting the foregoing, User
          Contributions must not:
        </Typography>
        <Stack gap={2} mt={-2}>
          <ul className="list-disc">
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Contain any material that is defamatory, obscene, indecent,
                abusive, offensive, harassing, violent, hateful, inflammatory,
                or otherwise objectionable.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Promote sexually explicit or pornographic material, violence, or
                discrimination based on race, sex, religion, nationality,
                disability, sexual orientation, gender identity or age.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Infringe any patent, trademark, trade secret, copyright, or
                other intellectual property or other rights of any other person.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Violate the legal rights (including the rights of publicity and
                privacy) of others or contain any material that could give rise
                to any civil or criminal liability under applicable laws or
                regulations or that otherwise may be in conflict with these
                Terms of Service and our{" "}
                <Link
                  to="#"
                  className="cursor-pointer text-primary hover:underline"
                >
                  Privacy Policy
                </Link>
                .
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Be likely to deceive any person.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Promote any illegal activity, or advocate, promote, or assist
                any unlawful act.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Cause annoyance, inconvenience, or needless anxiety or be likely
                to upset, embarrass, alarm, or annoy any other person.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Impersonate any person, or misrepresent your identity or
                affiliation with any person or organization.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Involve commercial activities or sales, such as contests,
                sweepstakes, and other sales promotions, barter, or advertising.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Give the impression that they emanate from or are endorsed by us
                or any other person or entity, if this is not the case.
              </Typography>
            </li>
          </ul>
        </Stack>
        {/* Reliance on Information Posted */}
        <Typography
          variant="h4"
          align="center"
          className="underline uppercase"
          fontWeight={600}
        >
          Reliance on Information Posted
        </Typography>
        <Typography variant="h6" align="justify">
          The information presented on or through the Service is made available
          solely for general information purposes. We do not warrant the
          accuracy, completeness, or usefulness of this information. Any
          reliance you place on such information is strictly at your own risk.
          We disclaim all liability and responsibility arising from any reliance
          placed on such materials by you or any other visitor to the Service,
          or by anyone who may be informed of any of its contents.
        </Typography>
        <Typography variant="h6" align="justify">
          This Service may include content provided by third parties, including
          materials provided by other users, bloggers, and third-party
          licensors, syndicators, aggregators, and/or reporting services. All
          statements and/or opinions expressed in these materials, and all
          articles and responses to questions and other content, other than the
          content provided by the Company, are solely the opinions and the
          responsibility of the person or entity providing those materials.
          These materials do not necessarily reflect the opinion of the Company.
          We are not responsible, or liable to you or any third party, for the
          content or accuracy of any materials provided by any third parties.
        </Typography>
        {/* Changes to the Service*/}
        <Typography
          variant="h4"
          align="center"
          className="underline uppercase"
          fontWeight={600}
        >
          Changes to the Service
        </Typography>
        <Typography variant="h6" align="justify">
          We may update the content on this Service from time to time, but its
          content is not necessarily complete or up-to-date. Any of the material
          on the Service may be out of date at any given time, and we are under
          no obligation to update such material.
        </Typography>
        {/*  Information About You and Your Visits to the Service */}
        <Typography
          variant="h4"
          align="center"
          className="underline uppercase"
          fontWeight={600}
        >
          Information About You and Your Visits to the Service
        </Typography>
        <Typography variant="h6" align="justify">
          All information we collect on this Service is subject to our{" "}
          <ExternalLink href="#">Privacy Policy</ExternalLink>. By using the
          Service, you consent to all actions taken by us with respect to your
          information in compliance with the Privacy Policy.
        </Typography>
        {/*  Linking to the Service */}
        <Typography
          variant="h4"
          align="center"
          className="underline uppercase"
          fontWeight={600}
        >
          Linking to the Service
        </Typography>
        <Typography variant="h6" align="justify">
          You may link to our homepage, provided you do so in a way that is fair
          and legal and does not damage our reputation or take advantage of it,
          but you must not establish a link in such a way as to suggest any form
          of association, approval, or endorsement on our part without our
          express consent.
        </Typography>
        <Typography variant="h6" align="justify">
          This Service may provide certain social media features that enable you
          to:
        </Typography>
        <Stack gap={2} mt={-2}>
          <ul className="list-disc">
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Link from your own or certain third-party Services to certain
                content on this Service.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Send emails or other communications with certain content, or
                links to certain content, on this Service.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Cause limited portions of content on this Service to be
                displayed or appear to be displayed on your own or certain
                third-party Services.
              </Typography>
            </li>
          </ul>
        </Stack>
        <Typography variant="h6" align="justify">
          You may use these features solely as they are provided by us, and
          solely with respect to the content they are displayed with and
          otherwise in accordance with any additional terms and conditions we
          provide with respect to such features. Subject to the foregoing, you
          must not:
        </Typography>
        <Stack gap={2} mt={-2}>
          <ul className="list-disc">
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Establish a link from any Service that is not owned by you.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Cause the Service or portions of it to be displayed on, or
                appear to be displayed by, any other site, for example, framing,
                deep linking, or in-line linking.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Link to any part of the Service other than the homepage.
              </Typography>
            </li>
            <li className="ml-12">
              <Typography variant="h6" align="justify">
                Otherwise take any action with respect to the materials on this
                Service that is inconsistent with any other provision of these
                Terms of Service.
              </Typography>
            </li>
          </ul>
        </Stack>
        <Typography variant="h6" align="justify">
          The Service from which you are linking, or on which you make certain
          content accessible, must comply in all respects with the Content
          Standards set out in these Terms of Service.
        </Typography>
        <Typography variant="h6" align="justify">
          You agree to cooperate with us in causing any unauthorized framing or
          linking immediately to stop. We reserve the right to withdraw linking
          permission without notice.
        </Typography>
        <Typography variant="h6" align="justify">
          We may disable all or any social media features and any links at any
          time without notice in our discretion.
        </Typography>
        {/*  Links from the Service */}
        <Typography
          variant="h4"
          align="center"
          className="underline uppercase"
          fontWeight={600}
        >
          Links from the Service
        </Typography>
        <Typography variant="h6" align="justify">
          If the Service contains links to other sites and resources provided by
          third parties, these links are provided for your convenience only.
          This includes links contained in advertisements, including banner
          advertisements and sponsored links. We have no control over the
          contents of those sites or resources, and accept no responsibility for
          them or for any loss or damage that may arise from your use of them.
          If you decide to access any of the third-party Services linked to this
          Service, you do so entirely at your own risk and subject to the terms
          and conditions of use for such Services.
        </Typography>
        {/* Geographic Restrictions */}
        <Typography
          variant="h4"
          align="center"
          className="underline uppercase"
          fontWeight={600}
        >
          Geographic Restrictions
        </Typography>
        <Typography variant="h6" align="justify">
          The owner of the Service is based in the State of Colorado in the
          United States. We provide this Service for use only by persons located
          in the United States. We make no claims that the Service or any of its
          content is accessible or appropriate outside of the United States.
          Access to the Service may not be legal by certain persons or in
          certain countries. If you access the Service from outside the United
          States, you do so on your own initiative and are responsible for
          compliance with local laws.
        </Typography>
        {/* Disclaimer of Warranties */}
        <Typography
          variant="h4"
          align="center"
          className="underline uppercase"
          fontWeight={600}
        >
          Disclaimer of Warranties
        </Typography>
        <Typography variant="h6" align="justify">
          You understand that we cannot and do not guarantee or warrant that
          files available for downloading from the internet or the Service will
          be free of viruses or other destructive code. You are responsible for
          implementing sufficient procedures and checkpoints to satisfy your
          particular requirements for anti-virus protection and accuracy of data
          input and output, and for maintaining a means external to our site for
          any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY
          LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
          DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
          TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
          EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE
          TO YOUR USE OF THE SERVICE OR ANY SERVICES OR ITEMS OBTAINED THROUGH
          THE SERVICE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON
          ANY SERVICE LINKED TO IT.
        </Typography>
        <Typography variant="h6" align="justify">
          YOUR USE OF THE SERVICE, ITS CONTENT, AND ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE SERVICE IS AT YOUR OWN RISK. THE SERVICE, ITS
          CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE ARE
          PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY
          WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY
          NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR
          REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
          RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICE.
          WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE
          ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE SERVICE,
          ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE
          WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS
          WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE
          ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE SERVICE
          OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE WILL OTHERWISE
          MEET YOUR NEEDS OR EXPECTATIONS.
        </Typography>
        <Typography variant="h6" align="justify">
          TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS
          ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
          OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
          MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
        </Typography>
        <Typography variant="h6" align="justify">
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </Typography>
        {/* Limitation on Liability */}
        <Typography
          variant="h4"
          align="center"
          className="underline uppercase"
          fontWeight={600}
        >
          Limitation on Liability
        </Typography>
        <Typography variant="h6" align="justify">
          TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY,
          ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
          AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND,
          UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE,
          OR INABILITY TO USE, THE SERVICE, ANY SERVICES LINKED TO IT, ANY
          CONTENT ON THE SERVICE OR SUCH OTHER SERVICES, INCLUDING ANY DIRECT,
          INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES,
          INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING,
          EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS
          OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA,
          AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT,
          OR OTHERWISE, EVEN IF FORESEEABLE.
        </Typography>
        {/* Indemnification */}
        <Typography
          variant="h4"
          align="center"
          className="underline uppercase"
          fontWeight={600}
        >
          Indemnification
        </Typography>
        <Typography variant="h6" align="justify">
          You agree to defend, indemnify, and hold harmless the Company, its
          affiliates, licensors, and service providers, and its and their
          respective officers, directors, employees, contractors, agents,
          licensors, suppliers, successors, and assigns from and against any
          claims, liabilities, damages, judgments, awards, losses, costs,
          expenses, or fees (including reasonable attorneys' fees) arising out
          of or relating to your violation of these Terms of Service or your use
          of the Service, including, but not limited to, your User
          Contributions, any use of the Service's content, services, and
          products other than as expressly authorized in these Terms of Service,
          or your use of any information obtained from the Service.
        </Typography>
        {/* Governing Law and Jurisdiction */}
        <Typography
          variant="h4"
          align="center"
          className="underline uppercase"
          fontWeight={600}
        >
          Governing Law and Jurisdiction
        </Typography>
        <Typography variant="h6" align="justify">
          All matters relating to the Service and these Terms of Service, and
          any dispute or claim arising therefrom or related thereto (in each
          case, including non-contractual disputes or claims), shall be governed
          by and construed in accordance with the internal laws of the State of
          Colorado without giving effect to any choice or conflict of law
          provision or rule (whether of the State of Colorado or any other
          jurisdiction).
        </Typography>
        <Typography variant="h6" align="justify">
          Any legal suit, action, or proceeding arising out of, or related to,
          these Terms of Service or the Service shall be instituted exclusively
          in the federal courts of the United States or the courts of the State
          of Colorado. You waive any and all objections to the exercise of
          jurisdiction over you by such courts and to venue in such courts.
        </Typography>
        {/* Limitation on Time to File Claims */}
        <Typography
          variant="h4"
          align="center"
          className="underline uppercase"
          fontWeight={600}
        >
          Limitation on Time to File Claims
        </Typography>
        <Typography variant="h6" align="justify">
          ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
          TO THESE TERMS OF SERVICE OR THE SERVICE MUST BE COMMENCED WITHIN ONE
          (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF
          ACTION OR CLAIM IS PERMANENTLY BARRED.
        </Typography>
        {/* Waiver and Severability */}
        <Typography
          variant="h4"
          align="center"
          className="underline uppercase"
          fontWeight={600}
        >
          Waiver and Severability
        </Typography>
        <Typography variant="h6" align="justify">
          No waiver by the Company of any term or condition set out in these
          Terms of Service shall be deemed a further or continuing waiver of
          such term or condition or a waiver of any other term or condition, and
          any failure of the Company to assert a right or provision under these
          Terms of Service shall not constitute a waiver of such right or
          provision.
        </Typography>
        <Typography variant="h6" align="justify">
          If any provision of these Terms of Service is held by a court or other
          tribunal of competent jurisdiction to be invalid, illegal, or
          unenforceable for any reason, such provision shall be eliminated or
          limited to the minimum extent such that the remaining provisions of
          the Terms of Service will continue in full force and effect.
        </Typography>
        {/* Entire Agreement */}
        <Typography
          variant="h4"
          align="center"
          className="underline uppercase"
          fontWeight={600}
        >
          Entire Agreement
        </Typography>
        <Typography variant="h6" align="justify">
          The Terms of Service and our Privacy Policy constitute the sole and
          entire agreement between you and the Company regarding the Service and
          supersede all prior and contemporaneous understandings, agreements,
          representations, and warranties, both written and oral, regarding the
          Service.
        </Typography>
        {/* Your Comments and Concerns */}
        <Typography
          variant="h4"
          align="center"
          className="underline uppercase"
          fontWeight={600}
        >
          Your Comments and Concerns
        </Typography>
        <Typography variant="h6" align="justify">
          This Service is operated by LawyerUp Holdings, LLC d/b/a{" "}
          <ExternalLink
            href="https://www.juricon.ai"
            target="_blank"
            rel="noreferrer"
          >
            JuriCon.ai
          </ExternalLink>
          , 1550 Larimer Street, Suite 128, Denver, Colorado 80202.
        </Typography>
        <Typography variant="h6" align="justify">
          All other feedback, comments, requests for technical support, and
          other communications relating to the Service should be directed to:
          <span className="text-primary">support@juricon.ai</span>.
        </Typography>
        <br />
        <br />
      </Stack>
    </Container>
  );
};

export default TermsOfService;
