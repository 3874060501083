import React from "react";
import Biography from "./Biography";
import ProviderDetailHeader from "./ProviderDetailHeader";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Exclamation from "./imgs/exclamation.svg";
import { Container } from "@mui/material";
import Languages from "./Languages";
import CardsMain from "./Cards/CardsMain";
import ExperienceMain from "./ExperienceMain";
import { PREMIUM, PREMIUM_PACKAGE } from "utils/constants/constants";

const ProviderDetail = ({
  user,
  editAble = false,
  isMyProfilePage = false,
}) => {
  return (
    <Container maxWidth="xl" sx={{ width: "100%", pb: 6 }}>
      <div className=" my-7">
        <ProviderDetailHeader
          provider={user}
          isMyProfilePage={isMyProfilePage}
          editAble={editAble}
        />
        <Biography />
        {!!user?.languages?.length && (
          <Languages user={user} editAble={editAble} />
        )}
        {(user?.subscription?.includes(PREMIUM_PACKAGE) ||
          user?.subscription?.includes(PREMIUM)) &&
          !!user?.experiences?.length && <ExperienceMain user={user} />}
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{ color: "#002A5C", fontSize: 22, fontWeight: 700, my: 8 }}
            gutterBottom
          >
            Feedback and Ratings
          </Typography>
          <img className="pl-2" src={Exclamation} alt="" />
        </Box>
        <CardsMain />
      </div>
    </Container>
  );
};
export default ProviderDetail;
