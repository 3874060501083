import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const sortOptions = [
  {
    value: "postalCode__",
    label: "Distance",
  },
  {
    value: "rate__",
    label: "Hourly Rate",
  },
  {
    value: "experience__",
    label: "Time in Practice",
  },
  {
    value: "relevantRepresentations__",
    label: "Relevant Prior Representations",
  },
];

const SortDropdown = ({ sortOption, onChange, isAuthenticated }) => {
  const sortOptionsUpdated = isAuthenticated ? sortOptions : sortOptions.slice(1, 4)
  return (
    <FormControl variant="outlined" sx={{ minWidth: 60 }}>
      <Select
        value={sortOption}
        onChange={onChange}
        variant="standard"
        disableUnderline={true}
        displayEmpty
        IconComponent={ExpandMoreIcon}
        sx={{
          color: "#00A4DA",
          minWidth: "100px",
          "& .MuiSelect-icon": { color: "#00A4DA", border: "none" },
        }}
      >
        <MenuItem value="">Select</MenuItem>
        {sortOptionsUpdated.map(({ value, label }) => (
          <MenuItem value={value} key={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SortDropdown;
