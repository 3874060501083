import { routes } from "utils/appConfig";

export const addBusiness = (callApi, data, cb) => {
  const { url, method } = routes.business.post;
  try {
    callApi(url, method, data, {
      onSuccess: ({ data }) => {
        cb(data);
      },
      onError: (error) => {
        cb(null, error);
      },
    });
  } catch (e) {
    console.error("🚀 ~ e:", e);
    throw new Error();
  }
};

export const fetchBusinessList = (callApi, cb) => {
  const { url, method } = routes.business.get;

  try {
    callApi(
      url,
      method,
      {},
      {
        onSuccess: ({ data }) => {
          cb(data);
        },
        onError: (error) => {
          cb(null, error);
        },
        turnOffNotification: true,
      }
    );
  } catch (e) {
    console.error("🚀 ~ e:", e);
    throw new Error();
  }
};

export const fetchBusinessById = (callApi, id, cb) => {
  const { url, method } = routes.businessById.get;
  try {
    callApi(
      url(id),
      method,
      {},
      {
        onSuccess: ({ data }) => {
          cb(data);
        },
        onError: (error) => {
          cb(null, error);
        },
        turnOffNotification: true,
      }
    );
  } catch (e) {
    console.error("🚀 ~ e:", e);
    throw new Error();
  }
};

export const updateBusinessById = (callApi, id, data, cb) => {
  const { url, method } = routes.businessById.patch;

  try {
    callApi(url(id), method, data, {
      onSuccess: ({ data }) => {
        cb(data);
      },
      onError: (error) => {
        cb(null, error);
      },
    });
  } catch (e) {
    console.error("🚀 ~ e:", e);
    throw new Error();
  }
};
