import { deleteSearchById } from "services/searchById";
import { useAxios } from "utils/hooks/useAxios";
import Loader from "utils/loader/Loader";

const { Menu, MenuItem, IconButton } = require("@mui/material");
const { useState } = require("react");

export default function RenderActionsMenu(props) {
  const {
    options,
    children,
    onClick,
    searchRecord,
    isMatterTab = false,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const { isLoading, callApi } = useAxios();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option) => {
    if (option === "delete") {
      if (!isMatterTab) {
        deleteSearchById(
          callApi,
          searchRecord._id,
          () => {
            onClick(option);
            setAnchorEl(null);
          },
          (e) => {
            console.log(e);
          }
        );
      } else {
        console.log("clicked option", option);
      }
    } else {
      if (isMatterTab) {
        onClick(option);
        setAnchorEl(null);
      }
      onClick(option);
      setAnchorEl(null);
    }
  };

  return (
    <div className="relative">
      <IconButton onClick={handleClick} size="large">
        {children}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ marginLeft: "-30px" }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.option}
            onClick={() => handleClose(option.option)}
            sx={{ width: "170px", textAlign: "center" }}
          >
            {isLoading ? <Loader /> : option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
