import { formattedCurrency } from "utils/helperFunctions/helper";

const SubscriptionCard = ({ title, price, content, isActive, onClick }) => {
  return (
    <div
      className={`border-dashed border-2 bg-primary" border-[#C6C7C4] rounded-md cursor-pointer ${
        isActive ? "bg-[#00A4DA] bg-opacity-30 border-[#00A4DA]" : ""
      }`}
      onClick={onClick}
    >
      <div className="flex justify-between px-4 mt-5 mb-2 ">
        <label className="block  text-[16px] font-[500] text-gray-900 text-textColor">
          {title}
        </label>
        <h3 className="text-secondary  text-[22px] font-[700] ">
          {formattedCurrency(price)}/y
        </h3>
      </div>
      <p className="px-4 block mb-5  text-[12px] font-[400] text-gray-900 text-textColor">
        {content}
      </p>
    </div>
  );
};

export default SubscriptionCard;
