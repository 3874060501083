import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { styled } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

export const SmallTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& input": {
      borderRadius: "0.575rem",
      padding: ".6rem",
    },

    "&:hover fieldset": {
      borderColor: "#C6C7C4",
      borderWidth: "1px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#00A4DA !important",
      borderWidth: "2px",
    },
  },
});
export const LargeTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "6px",
    },

    "&:hover fieldset": {
      borderColor: "#C6C7C4",
      borderWidth: "1px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#00A4DA !important",
      borderWidth: "1px",
    },
  },
});

const DatePicker = ({ placeholder, ...props }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {!isMobile ? (
        <DesktopDatePicker
          {...props}
          maxDate={new Date()}
          renderInput={(params) =>
            props?.size !== "large" ? (
              <SmallTextField
                {...params}
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  placeholder,
                }}
              />
            ) : (
              <LargeTextField
                {...params}
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  placeholder,
                }}
                sx={{
                  borderRadius: "12px",
                  height: "34px",
                  "&:hover fieldset": {
                    borderColor: "#C6C7C4",
                    borderWidth: "1px",
                  },
                  ...props.textFieldProps,
                }}
              />
            )
          }
        />
      ) : (
        <MobileDatePicker
          inputFormat="MM/DD/YYYY"
          {...props}
          maxDate={new Date()}
          renderInput={(params) =>
            props?.size !== "large" ? (
              <SmallTextField
                {...params}
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  placeholder,
                }}
              />
            ) : (
              <LargeTextField
                {...params}
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  placeholder,
                }}
                sx={{ ...props.textFieldProps }}
              />
            )
          }
        />
      )}
    </LocalizationProvider>
  );
};

export default DatePicker;
