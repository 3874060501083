import React from "react";

const LogoPlaceholder = ({ className }) => {
  return (
    <div
      className={`bottom-8 right-8 flex justify-center items-center w-[180px] h-[180px] mt-[-40px] text-textColor bg-[#C6C7C41A] rounded-[8px] ${className}`}
    >
      <div className="flex justify-center items-center w-[60%] h-[60%] text-textColor bg-[#EFEFEF] rounded-[50px]">
        <p className=" text-textColor self-center">Logo</p>
      </div>
    </div>
  );
};

export default LogoPlaceholder;
