import React from "react";
import { Stack, Typography } from "@mui/material";
import DashboardCard from "components/Dashboard/dashboardCard/DashboardCard";
import { camelToTitle } from "utils/helperFunctions/helper";
import Loader from "utils/loader/Loader";
import NoDataAvailable from "components/noDataAvailable/NoDataAvailable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch } from "react-redux";
import { openModal } from "store/actions/modalActions";
import { DATE_RANGE_MODAL } from "utils/constants/constants";

const ProgressSummary = ({ ProviderStats, isLoading, startDate, endDate }) => {
  const dispatch = useDispatch();
  const handleCalendarClick = () => {
    dispatch(openModal(DATE_RANGE_MODAL));
  };
  return (
    <Stack py={2} gap={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography fontWeight={700} fontSize={20}>
          Progress Summary
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography fontWeight={500} fontSize={16} sx={{ cursor: "default" }}>
            Filter By : {startDate ?? "mm/dd/yyyy"} - {endDate ?? "mm/dd/yyyy"}{" "}
          </Typography>
          <ExpandMoreIcon
            color="info"
            sx={{ cursor: "pointer", fontSize: "35px" }}
            onClick={handleCalendarClick}
          />
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent={{ xs: "center", md: "space-between" }}
        flexWrap="wrap"
        gap={2}
      >
        {isLoading ? (
          <Loader width={35} height={35} />
        ) : Object.keys(ProviderStats).length ? (
          Object.entries(ProviderStats).map(([key, value], index) => {
            return (
              <DashboardCard
                key={index}
                label={camelToTitle(key)}
                count={value.count}
                percentageChange={value.percentage}
                //   percentageChange={value.percentage}
              />
            );
          })
        ) : (
          <NoDataAvailable
            minHeight="100px"
            width="100%"
            animationWidth="400px"
            animationHeight="200px"
          />
        )}
      </Stack>
    </Stack>
  );
};

export default ProgressSummary;
