import { useNavigate } from "react-router-dom";
import { urlFor } from "../sanity/lib/image";

const BlogCard = ({ title, excerpt, mainImage, slug }) => {
  const navigate = useNavigate();

  const imageUrl =
    mainImage && mainImage._type === "image"
      ? urlFor(mainImage).width(400).height(225).url()
      : mainImage;

  const handleClick = () => {
    navigate(`/blog/${slug}`);
  };

  return (
    <div
      className="h-full overflow-hidden transition-shadow duration-300 bg-white rounded-lg shadow-md cursor-pointer hover:shadow-xl"
      onClick={handleClick}
    >
      {imageUrl && (
        <div className="aspect-[16/9] overflow-hidden">
          <img
            src={imageUrl}
            alt={title}
            className="object-cover w-full h-full transition-transform duration-300 hover:scale-105"
          />
        </div>
      )}
      <div className="p-6">
        <h2 className="mb-4 text-2xl font-semibold text-secondary line-clamp-2">
          {title}
        </h2>
        <p className="text-secondary line-clamp-3">{excerpt}</p>
      </div>
    </div>
  );
};

export default BlogCard;
