import { routes } from "utils/appConfig";

export const enableUserByAdmin = async (callApi, userId, callBack) => {
  const { url, method } = routes.admin.enableUser;
  try {
    callApi(
      url(userId),
      method,
      { status: "ACTIVE" },
      {
        onSuccess: ({ data }) => callBack(data),
        onError: (e) => callBack(null, e),
        successMessage: "User Restored successfully!",
      }
    );
  } catch (e) {
    throw new Error(e);
  }
};
