import React from "react";
import filter from "assets/filter.svg";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "store/actions/modalActions";
import { FILTER_MODAL } from "utils/constants/constants";
import { resetFilters } from "store/actions/actions";
import SortDropdown from "./SortBy";


const SearchResultsHeader = ({
  results,
  isLoading,
  selectedSchools,
  selectedLocations,
  selectedLanguages,
  sortBy,
  setSorBy,
  isAuthenticated,
}) => {
  const schools = useSelector((state) => state.filtersReducer.schools);
  const locations = useSelector((state) => state.filtersReducer.locations);
  const languages = useSelector((state) => state.filtersReducer.languages);
  const dispatch = useDispatch();
  if (isLoading) return <div />;
  return (
    <div className="w-[94%] mt-[6rem] lg:mt-12 py-6 relative mx-auto">
      <div className="flex justify-between items-center flex-wrap py-2">
        <div className="">
          <h3 className="text-xl text-secondary font-extrabold text-center">
            Match result {results}
          </h3>
        </div>
        {!!results && (
          <div className="flex items-center overflow-auto">
            <h3 className="text-md  text-secondary font-semibold  mr-2 flex-none">
              Sort By
            </h3>
            <SortDropdown
              sortOption={sortBy}
              onChange={(e) => setSorBy(e.target.value)}
              isAuthenticated={isAuthenticated}
            />
            {(!!schools?.length || !!locations?.length || !!languages?.length) && (
              <div
                onClick={() => dispatch(openModal(FILTER_MODAL))}
                className="w-[116px] h-[44px] bg-[#F6F6F6] ml-6 rounded-[6px] flex justify-center items-center text-[#00A4DA] cursor-pointer"
              >
                <p className="text-lg">Filters</p>
                <img
                  src={filter}
                  className="transform rotate-90 ml-2 text-lg"
                />
              </div>
            )}
          </div>
        )}
      </div>
      {(!!selectedSchools?.length || !!selectedLocations?.length || !!selectedLanguages?.length) && (
        <div className="flex flex-wrap relative mt-2 mb-9 pr-20 gap-3">
          <h3 className="text-lg text-secondary font-bold ">Filtered by</h3>
          {selectedSchools?.map((chip, i) => (
            <CustomChip name={`${chip?.university}`} key={i} />
          ))}
          {selectedLocations?.map((chip, i) => (
            <CustomChip name={`${chip?.name}`} key={i} />
          ))}
          {selectedLanguages?.map((chip, i) => (
            <CustomChip name={`${chip?.name}`} key={i} />
          ))}
          <button
            onClick={() => dispatch(resetFilters())}
            className="text-[#D65745] text-base font-semibold absolute right-3 top-[6px]"
          >
            Clear All
          </button>
        </div>
      )}
    </div>
  );
};

export default SearchResultsHeader;

const CustomChip = ({ name }) => {
  return (
    <div className="flex items-center gap-3 bg-[#F6F6F6] py-2 pl-4 pr-3 rounded-xs">
      <span className="text-sm text-[#11142D] font-normal">{name}</span>
      {/* <span className="overflow-hidden cursor-pointer w-3 hover:opacity-60 transition-all">
        <img src={Close} alt="close" />
      </span> */}
    </div>
  );
};
