import React, { useState } from "react";
import CustomTooltip from "components/Tooltip/Tooltip";
import { cutLabels, sortedPracticeAreas } from "utils/helperFunctions/helper";
import { Button, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import TreeViewComponent from "components/treeView/TreeView";
import { Close } from "@mui/icons-material";
import {toast} from "react-toastify";

export const colors = [
  { textColor: "#2DACC8", backgroundColor: "#DAF8FF" },
  { textColor: "#3CAA91", backgroundColor: "#D9F2EC" },
  {
    textColor: "#E9BA16",
    backgroundColor: "#FFF7DA",
  },
  {
    textColor: "#E34850",
    backgroundColor: "#FFEDEE",
  },
];

const RenderTag = ({ index, categoryName }) => {
  return (
    <Button
      sx={{
        color: colors[index]?.textColor,
        backgroundColor: colors[index]?.backgroundColor,
        textTransform: "none",
        fontSize: 14,
        fontWeight: 700,
        cursor: "default",
        height: "34px",
      }}
    >
      <CustomTooltip label={categoryName} className="py-0">
        {cutLabels(categoryName, 10)}
      </CustomTooltip>
    </Button>
  );
};

export default RenderTag;

export const RenderRemainingTagButton = ({ tags, startCount = 4, ...rest }) => {
  return (
    tags?.length > startCount && (
      <Button
        sx={{
          textTransform: "none",
          color: "#5A5A5A",
          fontSize: 12,
          fontWeight: 700,
          backgroundColor: "#C6C7C44D",
          minWidth: "30px",
        }}
        {...rest}
      >
        {tags?.length - startCount}+
      </Button>
    )
  );
};

const showInfoMessage = () => {
    const message = "Clients may search as an individual or as a business. If you wish to appear in searches for only one or the other, please only select the appropriate box";
    const type = "info";

    toast[type](message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
}

const getPreciseLabel= (text, count, hideBubbles) => {
  if (hideBubbles === false) {
    return cutLabels(text, 10)
  } else if (count > 3) {
    return cutLabels(text, 16)
  } else {
    return cutLabels(text, 54/count)
  }
}

export const RenderPracticeAreas = ({ practiceAreas, gap = 2, limit = 3, hideBubbles = false }) => {
  const [showCategoriesModal, setShowCategoriesModal] = useState(false);
  const alphabetizedPracticeAreas = sortedPracticeAreas(practiceAreas)

  return (
    <Stack direction="row" flexWrap={{ xs: "wrap", sm: "no-wrap" }} gap={gap}>
      {!!alphabetizedPracticeAreas?.length &&
        alphabetizedPracticeAreas
          .map((practiceArea, index) => {
            if (index < limit) {
              return (
                <Button
                  key={practiceArea.category_id}
                  sx={{
                    color: "#5A5A5A",
                    backgroundColor: "#F6F6F6",
                    textTransform: "none",
                    fontSize: 12,
                    fontWeight: 500,
                    cursor: "default",
                    wordBreak: "normal",
                    overFlow: "no-wrap",
                    minWidth: "90px",
                    position: "relative",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowCategoriesModal(true);
                  }}
                >
                  <CustomTooltip label={practiceArea.name} className="py-0">
                    {getPreciseLabel(practiceArea.name, alphabetizedPracticeAreas.length, hideBubbles)}
                  </CustomTooltip>
                  {(!!practiceArea?.subCatagories?.length && !hideBubbles) && (
                    <span className="absolute text-center -right-3 -top-2 w-5 h-5 text-sm font-semibold text-white bg-blue-600 rounded-[100%] z-20">
                      {practiceArea?.subCatagories?.length}
                    </span>
                  )}
                </Button>
              );
            }
          })
          .filter(Boolean)}
      <RenderRemainingTagButton
        tags={alphabetizedPracticeAreas}
        startCount={limit}
        onClick={(e) => {
          e.stopPropagation();
          setShowCategoriesModal(true);
        }}
      />
      {showCategoriesModal && (
        <div
          className="fixed bg-[#a8a0a059] top-0 right-0 left-0  w-[100%] h-[100%] py-4 z-50 cursor-default"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="w-[100%] h-[100%] flex justify-center items-center">
            <div className="w-[400px] h-[500px] bg-white p-4 m-3 rounded-md">
              <div className="flex justify-between">
                <h3 className="mb-4 text-[20px] font-[600] text-secondary">
                  Practice Areas Selected
                </h3>
                <Close
                  className="cursor-pointer"
                  onClick={() => setShowCategoriesModal(false)}
                />
              </div>
              <div className="max-h-[430px] overflow-auto thin-scrollbar">
                <TreeViewComponent data={alphabetizedPracticeAreas} />
              </div>
            </div>
          </div>
        </div>
      )}
    </Stack>
  );
};

export const RenderNAICCodes = ({ NAICCodes, gap = 2, limit = 3 }) => {
  const { naicCodes } = useSelector((state) => state.categoriesReducer);
  return (
    <Stack direction="row" gap={gap}>
      {!!NAICCodes?.length &&
        NAICCodes.map((tag, index) => {
          const category = naicCodes?.find((category) => category._id === tag);
          if (category && index < limit) {
            return (
              <Button
                key={tag}
                sx={{
                  color: colors[index]?.textColor,
                  backgroundColor: colors[index]?.backgroundColor,
                  textTransform: "none",
                  fontSize: 12,
                  fontWeight: 700,
                  cursor: "default",
                  wordBreak: "normal",
                  overFlow: "no-wrap",
                  minWidth: "90px",
                }}
              >
                <CustomTooltip label={category.title} className="py-0">
                  {cutLabels(category.title, 10)}
                </CustomTooltip>
              </Button>
            );
          }
        })}
      <RenderRemainingTagButton tags={NAICCodes} startCount={limit} />
    </Stack>
  );
};
