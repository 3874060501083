import { routes } from "utils/appConfig";
import { notify } from "utils/toast/toast";
import * as Yup from "yup";

export const subscriptionCardsData = [
  {
    title: "Standard Package",
    type: "STANDARD",
    amount: "480",
    content: `It is a long established fact that a reader will be distracted
    by the readable content of a page when looking at its layout.`,
  },
  {
    title: "Premium Package",
    type: "PREMIUM",
    amount: "1250",
    content: `It is a long established fact that a reader will be distracted
    by the readable content of a page when looking at its layout.`,
  },
];
export const expiryRegex = /^(1[0-2]|0[1-9]|\d)\/([2-9]\d[1-9]\d|[1-9]\d)$/;
export const stripeModalValidationSchema = Yup.object().shape({
  number: Yup.string().min(16).max(16).required().label("Card Number"),
  cvv: Yup.string().min(3).max(3).required().label("CVV/CVC"),
  name: Yup.string().min(3).max(50).label("Name"),
  expiry: Yup.string()
    .required()
    .min(5)
    .matches(expiryRegex, " please provide valid date format!, 10/2023"),
});

export const stripePaymentMethodHandler = async (data, cb) => {
  const { result, productId, token } = data;
  if (result.error) {
    cb(result);
  } else {
    const paymentResponse = await stripePayment(
      {
        paymentMethodId: result.paymentMethod.id,
        productId,
      },
      token
    );
    cb(paymentResponse);
  }
};

// place backend API call for payment
const stripePayment = async (data, token) => {
  try {
    const res = await fetch(routes.subscription.url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    return await res.json();
  } catch (e) {
    console.log(e);
    notify("Something went wrong", "error");
  }
};
