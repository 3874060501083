import React, { useEffect } from "react";
import { Checkbox } from "@material-tailwind/react";
import EditProfileFooter from "components/PremiumProviderEditProfileComponents/editProfilFooter/EditProfileFooter";
import { useFormik } from "formik";
import { billingValidation } from "components/PremiumProviderEditProfileComponents/editProfileValidation";
import Error from "components/error/Error";
import { removeEmptyValuesFromObject } from "components/PremiumProviderEditProfileComponents/helper";
import { stepDataNewScope } from "../helper";
import EditProfileSteps from "../../PremiumProviderEditProfileComponents/editProfileSteps/EditProfileSteps";
import { cleanUpValues } from "../../../utils/helperFunctions/helper";

const BillingNewScope = ({ pre, user, step, setStep, onSubmit }) => {
  const stepsCompleted =  user?.stepsCompleted;
  useEffect(() => {
    if (user) {
      formik.setValues({
        ratePerHourMin: user?.ratePerHourMin ?? "",
        retainer: user?.retainer ?? "",
        onContingency: user?.onContingency ?? "",
        billingStructure: user?.billingStructure?.length
          ? user.billingStructure
          : ["PER_HOUR"],
      });
    }
  }, [user]);

  const formik = useFormik({
    initialValues: {
      ratePerHourMin: "",
      retainer: "",
      onContingency: "",
      billingStructure: ["PER_HOUR"],
    },
    validationSchema: billingValidation,
    onSubmit: (values) => {
      const temp = removeEmptyValuesFromObject(values);
      if (stepsCompleted <= 3) temp.stepsCompleted = 4;
      onSubmit(temp);
    },
  });
  const validateAndSubmit = async (values) => {
    const temp = removeEmptyValuesFromObject(values);
    if (stepsCompleted <= 3) temp.stepsCompleted = 4;
    onSubmit(temp, true);
  };
  const saveStep = () => {
    validateAndSubmit(formik.values);
  };
  return (
    <div className="pb-[20%]">
      <EditProfileSteps
        step={step}
        stepsCompleted={user?.stepsCompleted}
        setStep={setStep}
        stepData={stepDataNewScope}
        saveStep={saveStep}
      />
      <div className="popup-modal  ">
        <div className="flex-wrap ">
          <div className=" py-6 ">
            <h3 className="mb-4 text-xl flex-wrap text-secondary font-semibold">
              Billing
            </h3>
            <div className="flex gap-4 mt-8 w-full flex-col sm:flex-row sm:items-center">
              <label className="block  text-[14px] font-[500] text-textColor">
                Billing Structure
              </label>
              <div className="flex gap-3 items-center">
                <Checkbox
                  className="rounded-xs"
                  name="billingStructure"
                  value="PER_HOUR"
                  onChange={formik.handleChange}
                  checked={formik.values.billingStructure.includes("PER_HOUR")}
                />
                <label className="self-center block ml-[-15px]  text-[14px] font-[500] text-textColor">
                  Rate Per Hour
                </label>
              </div>
              <div className="flex gap-3 items-center">
                <Checkbox
                  type="checkbox"
                  name="billingStructure"
                  className="rounded-xs"
                  value="FIXED_FEE"
                  onChange={formik.handleChange}
                  checked={formik.values.billingStructure.includes("FIXED_FEE")}
                />
                <label className="self-center block ml-[-15px] text-[14px] font-[500] text-textColor">
                  Fixed Fee
                </label>
              </div>
              <div className="flex gap-3 items-center">
                <Checkbox
                  type="checkbox"
                  name="billingStructure"
                  className="rounded-xs"
                  value="ON_CONTINGENCY"
                  onChange={formik.handleChange}
                  checked={formik.values.billingStructure.includes(
                    "ON_CONTINGENCY"
                  )}
                />
                <label className="self-center block sm:ml-[-15px]  text-[14px] font-[500] text-textColor">
                  On Contingency
                </label>
              </div>
              <div className="flex gap-3 items-center">
                <Checkbox
                  type="checkbox"
                  name="billingStructure"
                  className="rounded-xs"
                  value="RETAINER"
                  onChange={formik.handleChange}
                  checked={formik.values.billingStructure.includes(
                    "RETAINER"
                  )}
                />
                <label className="self-center block sm:ml-[-15px]  text-[14px] font-[500] text-textColor">
                  Retainer Required
                </label>
              </div>
            </div>
            <Error formik={formik} errorField="billingStructure" force />

            {/* Form */}
            <form className="space-y-12 profile-form mb-[40px] mt-8">
              <div className="flex flex-col md:flex-row justify-between gap-3 items-center">
                {formik.values.billingStructure.includes("PER_HOUR") ? (
                  <div className="w-[100%] padding-input padding-input-t">
                    <div className="flex">
                      <label className="block mb-2 text-[14px] font-[500] text-textColor">
                        Rate Per Hour
                      </label>
                    </div>
                    <div className="flex justify-between">
                      <div className="flex w-[100%]">
                        <div className="relative">
                          <p
                            className="absolute top-[15px] left-3 text-secondary text-[16px] font-[600] ">
                            $
                          </p>
                          <input
                            type="number"
                            name="ratePerHourMin"
                            id="ratePerHourMin"
                            className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-noneplaceholder-opacity-secondary scroll-none w-full  pl-9"
                            placeholder="200"
                            value={formik.values.ratePerHourMin}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <Error formik={formik} errorField="ratePerHourMin" />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="w-[100%]"></div>
                )}

                {formik.values.billingStructure.includes("ON_CONTINGENCY") ? (
                  <div className="w-[100%] padding-input padding-input-t">
                    <label className="block mb-2 text-[14px] font-[500] stext-textColor">
                      % of Recovery on Contingency
                    </label>

                    <input
                      type="number"
                      name="onContingency"
                      id="onContingency"
                      className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                      placeholder="30"
                      value={formik.values.onContingency}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <Error formik={formik} errorField="onContingency" />
                  </div>
                ) : (
                  <div className="w-[100%]"></div>
                )}
                <span className="mb-56 "></span>
                {/*formik.values.billingStructure.includes("RETAINER") ? (
                  <div className="w-[100%] padding-input padding-input-t">
                    <label className="block mb-2 text-[14px] font-[500] stext-textColor">
                      Retainer Required
                    </label>
                    <div className="flex justify-between">
                      <div className="flex w-[100%]">
                        <div className="relative">
                          <p
                            className="absolute top-[15px] left-3 text-secondary text-[16px] font-[600] ">
                            $
                          </p>
                          <input
                            type="number"
                            name="retainer"
                            id="retainer"
                            className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-noneplaceholder-opacity-secondary scroll-none w-full  pl-9"
                            placeholder="40"
                            value={formik.values.retainer}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <Error formik={formik} errorField="retainer" />
                        </div>
                      </div>  
                    </div>
                  </div>
                ) : (
                  <div className="w-[100%]"></div>
                )} */}
                <span className="mb-56 "></span>
              </div>
            </form>
            <EditProfileFooter pre={pre} next={formik.handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingNewScope;
