import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { closeCurrentModal } from "store/actions/modalActions";
import SubscriptionCard from "components/modals/subscriptionModal/SubscriptionCard";
import Button from "components/button/Button";
import { useAxios } from "utils/hooks/useAxios";
import Loader from "utils/loader/Loader";
import { routes } from "utils/appConfig";
import { Stack, TextField } from "@mui/material";
import { applyCoupon } from "services/applyCoupon";

const SubscriptionModal = ({
  onClose,
  onSubmit,
  loader,
  subscriptionDetail,
  setSubscriptionDetail,
  promoCode,
  setPromoCode,
  couponStatus,
  setCouponStatus,
}) => {
  const { productId } = subscriptionDetail;
  const currentSubscription = useSelector((state) => state.user?.subscription);
  const [subscriptions, setSubscriptions] = useState([]);
  const { isLoading, callApi } = useAxios();
  const { isLoading: couponLoading, callApi: couponApi } = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const subscriptionCardClick = (subscription) => {
    setSubscriptionDetail(subscription);
  };

  useEffect(() => {
    const { url, method } = routes.subscriptionPlans;
    callApi(
      url,
      method,
      {},
      {
        onSuccess: ({ data }) => {
          setSubscriptions(data.data);
        },
        turnOffNotification: true,
      }
    );
  }, []);
  useEffect(() => {
    if (currentSubscription) {
      setSubscriptionDetail((pre) => ({
        ...pre,
        productId: currentSubscription,
      }));
    }
  }, [currentSubscription]);
  useEffect(() => {
    if (subscriptions?.length) {
      let subscriptionDetails = subscriptions.find(
        (subscription) => subscription.productId === productId
      );
      subscriptionDetails?.productId &&
        setSubscriptionDetail({ ...subscriptionDetails });
    }
  }, [subscriptions]);

  const handlePromoCodeChange = (e) => {
    const value = e.target.value;
    setPromoCode(value);
  };

  const handleApplyCouponButton = async () => {
    setCouponStatus({});
    const res = await applyCoupon(couponApi, promoCode);
    if (res.status === 200 && res?.data?.valid) {
      const { percent_off, valid } = res.data || {};
      const message = `Coupon successfully applied, You got ${percent_off}% off on your subscription`;
      setCouponStatus({ message, percent_off, valid, error: false });
    } else {
      const message = `Coupon is not valid. Please renter or try a different code`;
      setCouponStatus({ message, valid: false, percent_off: 0, error: true });
    }
  };

  const handleSubmit = () => {
    onSubmit(productId);
  };
  const handleClose = () => {
    setPromoCode("");
    if (onClose) {
      onClose();
    } else {
      dispatch(closeCurrentModal());
    }
  };
  return (
    <div className="relative bg-[#fff] w-[326px] md:w-[544px] rounded-lg shadow dark:bg-gray-700 rounded-[20px] px-[2rem] py-[1rem]">
      {isLoading ? (
        <Stack minHeight="500px">
          <Loader />
        </Stack>
      ) : (
        <>
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex  dark:hover:text-primary text-textColor"
            data-modal-toggle="authentication-modal"
          >
            <svg
              onClick={handleClose}
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="px-6 py-6 lg:px-8">
            <h3 className="mb-1  text-[22px] font-[700] text-secondary">
              Package Options
            </h3>
            <label className="block mb-5  text-[16px] font-[400] text-gray-900 text-textColor">
              Join the future today!
            </label>
            <div className="space-y-6">
              {subscriptions?.map((subscription) => {
                const {
                  productName,
                  productId,
                  productPrice,
                  productDescription,
                } = subscription;
                return (
                  <SubscriptionCard
                    key={productId}
                    title={productName}
                    price={productPrice}
                    content={productDescription}
                    isActive={subscriptionDetail?.productId === productId}
                    onClick={() => subscriptionCardClick(subscription)}
                  />
                );
              })}
              <Stack
                direction={{ sm: "column", md: "row" }}
                gap={2}
                alignItems="center"
              >
                <TextField
                  className="dashedBorder-sm"
                  size="small"
                  variant="standard"
                  fullWidth
                  value={promoCode}
                  placeholder="Enter promo code here..."
                  onChange={handlePromoCodeChange}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
                <Button
                  onClick={handleApplyCouponButton}
                  className="hover:bg-hoverColor max-w-[10rem] h-[40px] text-[14px] font-[400] leading-5 disabled:text-secondary disabled:bg-borderColor px-2 border-0"
                  loader={couponLoading}
                  disabled={loader || !promoCode}
                >
                  Apply Coupon
                </Button>
              </Stack>
              {couponStatus.message && (
                <p
                  className={`text-${
                    couponStatus.error ? "red-600" : "secondary"
                  }`}
                >
                  {couponStatus.message}
                </p>
              )}
              <Button
                onClick={handleSubmit}
                className="hover:bg-hoverColor w-[100%] h-[50px] text-[16px] font-[600]"
                loader={loader}
                disabled={loader}
              >
                Next
              </Button>
              {/* <div className="text-sm font-medium text-gray-500 dark:text-gray-300 text-textColor text-center">
                <p
                  className=" text-[14px] font-[400] hover:text-hoverColor over:underline dark:text-blue-500 text-center text-primary cursor-pointer"
                  onClick={() => {
                    dispatch(closeCurrentModal());
                    navigate("/dashboard");
                  }}
                >
                  Goto Dashboard
                </p>
              </div> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SubscriptionModal;
