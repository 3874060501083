import React, { useEffect, useRef, useState, Fragment } from "react";
import { useFormik } from "formik";
import { Checkbox } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import Error from "components/error/Error";
import client from "assets/user.svg";
import {
  addFields,
  capitalizeFirstLetter,
  languageObject,
  oralProficiencies,
  removeFields,
} from "components/PremiumProviderEditProfileComponents/helper";
import EditProfileFooter from "components/PremiumProviderEditProfileComponents/editProfilFooter/EditProfileFooter";
import { routes } from "utils/appConfig";
import { signInAction } from "store/actions/authActions";
import {
  cleanUpValues,
  cutLabels,
  filterByTopItems,
  findDuplicateIndexes,
} from "utils/helperFunctions/helper";
import PracticeArea from "components/modals/practiceArea/PracticeArea";
import Tooltip from "components/Tooltip/Tooltip";
import DynamicFieldsHeader from "components/dynamicFeildsHeader/DynamicFieldsHeader";
import SocialField from "../socialField/SocialField";
import { personalInfoValidationNewScope, socialObject, stepDataNewScope } from "../helper";
import StepLabel from "../stepLabel/StepLabel";
import AddFieldButton from "../addFieldButton/AddFieldButton";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import PhoneInput from "components/phoneInput/PhoneInput";
import { notify } from "utils/toast/toast";
import LanguagesAutoComplete from "components/languages/LanguagesAutocomplete";
import { topLanguages } from "data/topLanguages";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Step from "../../PremiumProviderEditProfileComponents/editProfileSteps/Step";
import { FIRM_INFO } from "../../../utils/constants/constants";
import EditProfileSteps from "../../PremiumProviderEditProfileComponents/editProfileSteps/EditProfileSteps";

export const validFileFormats = ["jpg", "jpeg", "png", "webp"];

const PersonalInfoNewScope = ({
  user,
  step,
  setStep,
  callApi,
  categories,
  isLoading,
  onSubmit,
}) => {
  const [practiceAreas, setPracticeAreas] = useState();
  const [languages, setLanguages] = useState([languageObject, languageObject]);
  const [socialURLs, setSocialUrls] = useState([socialObject, socialObject]);
  const [practiceAreaModal, setPracticeAreaModal] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const languageList = useSelector(
    (state) => state.categoriesReducer.languages
  );

  const dispatch = useDispatch();
  const fileRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0)
    if (user) {
      formik.setValues({
        firstName: user?.firstName ?? "",
        lastName: user?.lastName ?? "",
        phone: user?.phone ?? "",
        email: user?.email ?? "",
        practiceAreas: user?.practiceAreas ?? [],
        languages: user?.languages?.length ? user?.languages : languages,
        socialURLs: user?.socialURLs?.length ? user.socialURLs : socialURLs,
        represent: user?.represent?.length
          ? user.represent
          : ["INDIVIDUAL", "BUSINESS"],
        biography: user?.biography ?? "",
      });
      setPracticeAreas(user?.practiceAreas ?? []);
      user?.languages?.length && setLanguages(user.languages);
      user?.socialURLs?.length && setSocialUrls(user.socialURLs);
    }
  }, [user]);
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      practiceAreas: [],
      represent: ["INDIVIDUAL", "BUSINESS"],
      languages: languages,
      socialURLs: [],
      biography: "",
    },
    validationSchema: personalInfoValidationNewScope,
    onSubmit: async (values) => {
      values.email = values.email.toLowerCase();
      cleanUpValues(values);
      let duplicates = findDuplicateIndexes(values.languages);
      let imageUploadStatus = null;
      if (duplicates?.length) {
        duplicates.forEach((index) => {
          formik.setFieldError(
            `languages[${index}].language`,
            " Duplicate languages are not allowed!"
          );
        });
        return;
      }
      if (imageFile) {
        let formData = new FormData();
        formData.append("file", imageFile);
        formData.append("target", "USER_PROFILE_PICTURE");
        await callApi(routes.media.url, "POST", formData, {
          onSuccess: async () => {
            imageUploadStatus = true;
            setImageUrl(null);
            await callApi(
              routes.me.url,
              routes.me.method,
              {},
              {
                onSuccess: ({ data }) => {
                  dispatch(signInAction(data.data));
                },
                turnOffNotification: true,
              }
            );
          },
        });
      }
      if (imageFile && !imageUploadStatus) {
        return;
      }
      if (user?.stepsCompleted < 2 || !user?.stepsCompleted)
        values.stepsCompleted = 1;
      values.practiceAreas = practiceAreas;
      onSubmit(values);
    },
  });

  const { getFieldProps } = formik;

  const showInfoMessage = () => {
    const message = "Clients may search as an individual or as a business. If you wish to appear in searches for only one or the other, please only select the appropriate box";
    const type = "info";

    toast[type](message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileType = file.type.split("/")[0];
    const fileFormat = file.type.split("/")[1];
    if (fileType !== "image") {
      notify("Please upload valid image file", "error");
      return;
    }
    if (file?.size > 1024000) {
      notify("File size too large, max size 1mb", "error");
      return;
    }
    if (!validFileFormats.includes(fileFormat)) {
      notify(`Invalid File Format ${fileFormat}`, "error");
      return;
    }
    setImageFile(file);
    setImageUrl(URL.createObjectURL(file));
  };
  const handleRemoveClick = (id) => {
    const temp = [...practiceAreas].filter((categoryId) => categoryId !== id);
    setPracticeAreas(temp);
  };
  const updateSocialUrls = ({ url, kind, index }) => {
    if (url !== undefined) {
      formik.setFieldTouched(`socialURLs[${index}].url`);
      formik.setFieldValue(`socialURLs[${index}].url`, url);
    }
    if (kind !== undefined) {
      formik.setFieldTouched(`socialURLs[${index}].kind`);
      formik.setFieldValue(`socialURLs[${index}].kind`, kind);
    }
  };
  const addSocialField = () => {
    setSocialUrls((pre) => [...pre, socialObject]);
    formik.setFieldValue(`socialURLs`, [
      ...formik.values.socialURLs,
      socialObject,
    ]);
  };
  const removeSocialField = (index) => {
    let _temp = [...formik.values.socialURLs];
    _temp.splice(index, 1);
    formik.setFieldValue("socialURLs", _temp);
    setSocialUrls(_temp);
  };

  const validateAndSubmit = async (values) => {
    values.email = values.email.toLowerCase();
    cleanUpValues(values);
    let duplicates = findDuplicateIndexes(values.languages);
    let imageUploadStatus = null;
    if (duplicates?.length) {
      duplicates.forEach((index) => {
        formik.setFieldError(
          `languages[${index}].language`,
          " Duplicate languages are not allowed!"
        );
      });
      return;
    }
    if (imageFile) {
      let formData = new FormData();
      formData.append("file", imageFile);
      formData.append("target", "USER_PROFILE_PICTURE");
      await callApi(routes.media.url, "POST", formData, {
        onSuccess: async () => {
          imageUploadStatus = true;
          setImageUrl(null);
          await callApi(
            routes.me.url,
            routes.me.method,
            {},
            {
              onSuccess: ({ data }) => {
                dispatch(signInAction(data.data));
              },
              turnOffNotification: true,
            }
          );
        },
      });
    }
    if (imageFile && !imageUploadStatus) {
      return;
    }
    if (user?.stepsCompleted < 2 || !user?.stepsCompleted)
      values.stepsCompleted = 1;
    values.practiceAreas = practiceAreas;
    onSubmit(values, true);
  };
  const saveStep = () => {
    validateAndSubmit(formik.values);
  };
  return (
    <div className="">
      <EditProfileSteps
        step={step}
        stepsCompleted={user?.stepsCompleted}
        setStep={setStep}
        stepData={stepDataNewScope}
        saveStep={saveStep}
      />
      <div className="profile-upload flex justify-between w-[100%] py-10 bg-[#76786800] flex-wrap">
        <div className="flex ">
          <div className=" mb-6">
            <img
              className="h-[181px] w-[180px] rounded-md object-contain"
              src={imageUrl ?? user?.profilePicture ?? client}
              alt=""
            />
          </div>
          <div className="pl-5">
            <h1 className="text-[16px] pt-12 font-semibold">
              Change Display Picture
            </h1>
            <input
              type="file"
              className="hidden"
              ref={fileRef}
              onChange={handleFileChange}
            />
            <button
              className="bg-primary  py-2 px-6 mt-5 rounded-xs text-white hover:bg-hoverColor duration-300"
              onClick={() => fileRef.current.click()}
            >
              Browse
            </button>
          </div>
        </div>
      </div>
      <div className="popup-modal">
        <div className="flex-wrap ">
          <div className=" py-6">
            <StepLabel label="Personal Information" />
            {/* Form */}
            <form
              className="space-y-6 profile-form"
              onSubmit={formik.handleSubmit}
            >
              <div className="flex items-start input-wrap justify-between gap-3 sm:gap-0">
                <div className="w-[100%]">
                  <label
                    htmlFor="firstName"
                    className="block mb-2 text-[14px] font-[500] text-textColor"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none
"
                    placeholder="Jane"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <Error formik={formik} errorField="firstName" />
                </div>
                <div className="w-[100%] sm:pl-3 ">
                  <label
                    htmlFor="lastName"
                    className="block mb-2 text-[14px] font-[500] text-textColor"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                    placeholder="Lawyer"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <Error formik={formik} errorField="lastName" />
                </div>
              </div>

              <div className="flex items-start input-wrap gap-3 ">
                <div className="w-[100%]">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-[14px] font-[500] text-textColor"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                    placeholder="janelawyer@hello.com"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled
                  />
                  <Error formik={formik} errorField="email" />
                </div>
                <div className="w-[100%]">
                  <div className="flex">
                    <label
                      htmlFor="businessPhone"
                      className="block mb-2 text-sm font-medium text-textColor"
                    >
                      Phone Number
                    </label>
                    <label
                      htmlFor="businessPhone"
                      className="mt-[2px] ml-1 text-[12px] font-medium text-[#979797]"
                    >
                      (Optional)
                    </label>
                  </div>

                  <PhoneInput
                    type="text"
                    id="phone"
                    className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                    placeholder="(208) 555-0112"
                    value={formik.values.phone}
                    onChange={(value) => {
                      formik.setFieldTouched("phone", true);
                      formik.setFieldValue("phone", value);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <Error formik={formik} errorField="phone" />
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex w-[100%]">
                  <label className="block mb-2 text-[14px] font-[500] text-textColor">
                    Practice Areas
                  </label>
                  <div className="relative">
                    <label
                      onClick={() => setPracticeAreaModal(!practiceAreaModal)}
                      className="mt-[-2px] ml-2 text-[16px] font-medium text-primary cursor-pointer"
                    >
                      Click to Select
                    </label>
                  </div>
                </div>
                <div className="flex items-start input-wrap gap-3">
                  <div className="flex flex-col w-[100%]">
                    <PracticeAreaField
                      practiceAreas={practiceAreas}
                      handleRemoveClick={handleRemoveClick}
                    />
                  </div>
                  <div className="padding-input flex flex-wrap justify-evenly w-[100%] padding-input-t">
                    <div className="form-control self-center">
                      <label className="label cursor-pointer">
                        <span className="label-text font-medium text-textColor">
                          I represent: 
                        </span>
                      </label>
                    </div>
                    <div className="form-control font-medium text-textColor self-center">
                      <label className="label cursor-pointer flex items-center">
                        <Checkbox
                          type="checkbox"
                          name="represent"
                          className="rounded-xs"
                          value="INDIVIDUAL"
                          checked={formik.values.represent?.includes(
                            "INDIVIDUAL"
                          )}
                          onChange={formik.handleChange}
                        />
                        <span className="label-text pl-2">Individuals</span>
                      </label>
                    </div>
                    <div className="form-control font-medium text-textColor self-center">
                      <label className="label cursor-pointer flex items-center">
                        <Checkbox
                          type="checkbox"
                          name="represent"
                          className="rounded-xs"
                          value="BUSINESS"
                          checked={formik.values.represent?.includes(
                            "BUSINESS"
                          )}
                          onChange={formik.handleChange}
                        />
                        <span className="label-text pl-2">Businesses</span>
                      </label>
                    </div>
                    <InfoOutlinedIcon onClick={showInfoMessage} />
                  </div>
                </div>
              </div>
              {/* Biography */}
              <div className="flex flex-col w-[100%]">
                <label className="block mb-2 -mt-4 text-[14px] font-[500] text-textColor">
                  Biography
                </label>
                <textarea
                  type="text"
                  name="biography"
                  placeholder="Enter text here..."
                  className=" rounded-sm py-1 w-full border border-borderColor bg-white pl-5 min-h-[100px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                  {...getFieldProps(`biography`)}
                />
                <Error formik={formik} errorField="biography" />
              </div>
              {/* languages */}
              <div className="profile-form flex justify-start flex-col gap-4">
                {languages.map((_, index) => {
                  return (
                    <Fragment key={index}>
                      {index !== 1 && (
                        <DynamicFieldsHeader
                          title={
                            index < 2 ? "Languages" : "Additional Language"
                          }
                          index={index > 1 ? index - 1 : index}
                          callBack={() =>
                            removeFields(
                              setLanguages,
                              index,
                              formik,
                              "languages",
                              true
                            )
                          }
                          length={languages?.length}
                        />
                      )}
                      <div
                        key={index}
                        className="flex input-wrap justify-between mb-[21px]"
                      >
                        <div className="w-[100%]">
                          <label className="block mb-2 text-[14px] font-[500] text-textColor">
                            {index === 0 ? "Primary" : "Secondary  (optional)"}
                          </label>
                          <LanguagesAutoComplete
                            name={`languages[${index}].language`}
                            options={filterByTopItems({
                              data: languageList,
                              topItems: topLanguages,
                              divider: true,
                            })}
                            languageId={formik.values.languages[index].language}
                            onChange={(value) => {
                              formik.setFieldTouched(
                                `languages[${index}].language`,
                                true
                              );
                              formik.setFieldValue(
                                `languages[${index}].language`,
                                value
                              );
                            }}
                          />
                          <Error
                            formik={formik}
                            errorField="language"
                            property="languages"
                            index={index}
                          />
                        </div>
                        <div className="w-[100%] pl-3 padding-input padding-input-t ">
                          <div className="flex w-full items-start gap-4">
                            <div className="flex flex-col w-[100%]">
                              <label className="block mb-2 text-[14px] font-[500] text-textColor">
                                Oral Proficiency
                              </label>
                              <select
                                className="pl-5 h-[55px] text-base font-medium outline-none focus:border-primary"
                                id="oralProficiency"
                                {...getFieldProps(
                                  `languages[${index}].oralProficiency`
                                )}
                              >
                                <option value="">Select</option>
                                {oralProficiencies.map((proficiency) => (
                                  <option key={proficiency} value={proficiency}>
                                    {capitalizeFirstLetter(proficiency)}
                                  </option>
                                ))}
                              </select>
                              <Error
                                formik={formik}
                                errorField="oralProficiency"
                                property="languages"
                                index={index}
                              />
                            </div>
                            <div className="flex flex-col w-[100%]">
                              <label className="block mb-2 text-sm text-[14px] font-[500] text-textColor">
                                Written Proficiency
                              </label>
                              <select
                                className="pl-5 h-[55px] text-base font-medium outline-none focus:border-primary"
                                id="writtenProficiency"
                                {...getFieldProps(
                                  `languages[${index}].writtenProficiency`
                                )}
                              >
                                <option value="">Select</option>
                                {oralProficiencies.map((proficiency) => (
                                  <option key={proficiency} value={proficiency}>
                                    {capitalizeFirstLetter(proficiency)}
                                  </option>
                                ))}
                              </select>
                              <Error
                                formik={formik}
                                errorField="writtenProficiency"
                                property="languages"
                                index={index}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  );
                })}

                <AddFieldButton
                  buttonText="Add Language"
                  onClick={() =>
                    addFields(
                      setLanguages,
                      languageObject,
                      formik,
                      "languages",
                      true
                    )
                  }
                />
              </div>
              {/* Social urls */}
              {/* <>
                <div className="flex flex-col">
                  <h2 className="font-[600] text-[20px] text-secondary mb-4">
                    Social Media
                  </h2>
                  {socialURLs.map((_, index) => {
                    return (
                      <div key={index} className="mb-6">
                        <SocialField
                          index={index}
                          kind={formik.values.socialURLs[index]?.kind}
                          url={formik.values.socialURLs[index]?.url}
                          setKind={(kind) => updateSocialUrls({ index, kind })}
                          setUrl={(url) => updateSocialUrls({ index, url })}
                          handleRemoveFieldClick={() =>
                            removeSocialField(index)
                          }
                        />
                        <Error
                          formik={formik}
                          errorField="url"
                          property="socialURLs"
                          index={index}
                          errorLabel=""
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="w-fit text-primary hover:text-hoverColor font-semibold cursor-pointer"
                    onClick={addSocialField}
                  >
                    Add Social Platform
                  </button>
                </div>
              </> */}
            </form>
          </div>
        </div>
      </div>
      <PracticeAreasModal />
      <EditProfileFooter
        // className="mb-[150px]"
        step={step}
        next={() => {
          formik.handleSubmit();
        }}
      />
      {practiceAreaModal && (
        <PracticeArea
          setPracticeAreas={setPracticeAreas}
          practiceAreas={practiceAreas}
          categories={categories}
          isLoading={isLoading}
          email={user?.email}
          onClose={() => setPracticeAreaModal(!practiceAreaModal)}
        />
      )}
    </div>
  );
};

export default PersonalInfoNewScope;

export const PracticeAreaField = ({ practiceAreas }) => {
  return (
    <div
      className="rounded-sm w-full border border-borderColor bg-white pl-5 min-h-[55px] h-auto text-base font-medium outline-none focus:border-primary text-secondary bg-none mb-6 pb-2
"
    >
      <div className="flex flex-wrap mt-2 gap-2 ">
        {practiceAreas?.map((category) => {
          return (
            <div
              key={category.category_id}
              className="w-auto px-2 mt-[5px] h-[30px] bg-[#DAF8FF] rounded-sm flex justify-center items-center"
            >
              <p className="text-primary rounded-md cursor-default flex gap-2 relative">
                <Tooltip label={category?.name}>
                  {cutLabels(category?.name)}
                </Tooltip>
                {!!category?.subCatagories?.length && (
                  <span className="absolute text-center -right-4 -top-3 w-5 h-5 text-sm font-semibold text-white bg-blue-600 rounded-[100%]">
                    {category?.subCatagories?.length}
                  </span>
                )}
              </p>
              {/* <i
                onClick={() => handleRemoveClick(categoryId)}
                className="fa-solid fa-xmark text-borderColor pl-2 mt-[5px] cursor-pointer"
              ></i> */}
            </div>
          );
        })}
        {!practiceAreas?.length && (
          <div className="text-borderColor mt-2">
            Please select practice area!
          </div>
        )}
      </div>
    </div>
  );
};

const style = {
  position: "absolute",
  top: "0px",
  left: "50%",
  // transform: "translate(-50%, -50%)",
  width: 400,
  height: 400,
  bgcolor: "orange",
  boxShadow: 24,
  p: 4,
};

export const PracticeAreasModal = ({ open }) => {
  return (
    <Modal
      open={!!open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ position: "relative" }}
    >
      <Box sx={style}>
        <h3>Modal</h3>
      </Box>
    </Modal>
  );
};