// BlogListingPage.jsx
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import subscribeIcon from "../assets/subscribe.svg"; // Make sure the path is correct
import BlogCard from "../components/BlogCard";
import NewsletterSubscriptionPopup from "../components/NewsletterSubscriptionPopup";
import { getAllBlogPosts } from "../sanity/lib/sanityClient";

const BlogListingPage = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  useEffect(() => {
    // Fetch all blog posts on mount
    getAllBlogPosts()
      .then((data) => {
        setBlogPosts(data);
        setFilteredPosts(data); // Display all posts initially
      })
      .catch(console.error);
  }, []);

  // Filter posts based on search term and selected tag
  const filterPosts = (term, tag) => {
    let filtered = blogPosts;

    if (term) {
      filtered = filtered.filter((post) =>
        post.title.toLowerCase().includes(term.toLowerCase())
      );
    }

    if (tag) {
      filtered = filtered.filter((post) => post.tags?.includes(tag));
    }

    setFilteredPosts(filtered);
  };

  // Handle search input change
  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    filterPosts(term, selectedTag);
  };

  // Handle tag filter change
  const handleTagFilter = (tag) => {
    setSelectedTag(tag);
    filterPosts(searchTerm, tag);
  };

  // Extract unique tags from blog posts for filtering
  const uniqueTags = [...new Set(blogPosts.flatMap((post) => post.tags || []))];

  return (
    <div className="max-w-6xl px-6 py-10 mx-auto blog-listing-page text-secondary">
      <Helmet>
        <title>The Legal Lowdown | Listing</title>
        <meta
          name="description"
          content="Explore a wide array of articles, blogs, news, and guides to stay updated on the latest legal insights."
        />
        <meta
          name="keywords"
          content="legal blogs, legal news, legal guides, legal articles"
        />
        <meta
          property="og:title"
          content="The Legal Lowdown - Stay Informed on Legal Matters"
        />
        <meta
          property="og:description"
          content="A comprehensive listing of legal blogs, news, and guides to keep you informed."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="path_to_your_image.png" />{" "}
        {/* Replace with actual image path */}
      </Helmet>

      {/* Title and Subscribe Icon */}
      <div className="flex items-center mt-20 space-x-2">
        <h1 className="mb-2 text-5xl font-bold text-primary">
          The Legal Lowdown
        </h1>
        <img
          src={subscribeIcon}
          alt="Subscribe"
          className="w-10 h-10 cursor-pointer"
          onClick={() => setShowPopup(true)}
        />
      </div>
      <h2 className="mb-20 text-2xl text-left text-gray-600">
        Articles, Blogs, News, and Guides You Need to Stay in the Know.
      </h2>

      {/* Newsletter Subscription Popup */}
      {showPopup && (
        <NewsletterSubscriptionPopup
          showPopup={showPopup}
          setShowPopup={setShowPopup}
        />
      )}

      {/* Search and Filter section */}
      <div className="flex flex-col items-center justify-between mb-6 md:flex-row">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search blog posts..."
          className="w-full p-2 mb-4 border border-gray-300 rounded-md md:w-1/3 md:mb-0"
        />

        <div className="flex flex-wrap justify-center mt-4 space-x-2 md:mt-0">
          <button
            onClick={() => handleTagFilter("")}
            className={`px-4 py-1 border rounded-md ${
              selectedTag === ""
                ? "bg-primary text-white"
                : "bg-white text-primary"
            }`}
          >
            All
          </button>
          {uniqueTags.map((tag) => (
            <button
              key={tag}
              onClick={() => handleTagFilter(tag)}
              className={`px-4 py-1 border rounded-md ${
                selectedTag === tag
                  ? "bg-primary text-white"
                  : "bg-white text-primary"
              }`}
            >
              {tag}
            </button>
          ))}
        </div>
      </div>

      {/* Blog Grid */}
      <div className="blog-grid-container">
        {filteredPosts.length > 0 ? (
          filteredPosts.map((post) => (
            <BlogCard
              key={post._id}
              title={post.title}
              excerpt={post.excerpt}
              mainImage={post.mainImage}
              slug={post.slug.current}
            />
          ))
        ) : (
          <p className="text-center text-gray-500 col-span-full">
            No posts found.
          </p>
        )}
      </div>
    </div>
  );
};

export default BlogListingPage;
