import { routes } from "utils/appConfig";
import { serializeParams } from "utils/helperFunctions/helper";

export const fetchAdminPanelUsers = (callApi, params, cb) => {
  const { url, method } = routes.admin.getUsers;
  try {
    callApi(
      url + serializeParams(params),
      method,
      {},
      {
        onSuccess: ({ data }) => {
          cb(data.data);
        },
        onError: (error) => {
          cb(null, error);
        },
        turnOffNotification: true,
      }
    );
  } catch (e) {
    throw new Error(e);
  }
};
