import React, { useEffect, useState } from "react";
import ProfileImg from "../assets/user.svg";
import { useNavigate, useParams } from "react-router-dom";
import { fetchMatterById } from "services/fetchMatterById";
import { useAxios } from "utils/hooks/useAxios";
import Loader from "utils/loader/Loader";
import { Box, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { formatDate, formatPhone } from "utils/helperFunctions/helper";
import NoDataAvailable from "components/noDataAvailable/NoDataAvailable";
import { fetchInquiryById } from "services/fetchInquiryById";
import arrow from "components/PremiumProviderEditProfileComponents/providermui/imgs/arow.svg";

const ConsumerMatterDetails = ({ isInquiryPage }) => {
  const [matter, setMatter] = useState({});
  const [inquiry, setInquiry] = useState({});
  const userType = useSelector((state) => state.user?.userType);
  const params = useParams();
  const { isLoading, callApi } = useAxios();
  const navigate = useNavigate();
  useEffect(() => {
    if (params?.id && !isInquiryPage) {
      fetchMatterById(callApi, params.id, (data, error) => {
        if (data) {
          setMatter(data);
        } else if (error) {
          console.log(error);
        }
      });
    } else if (params?.id) {
      fetchInquiryById(callApi, params.id, (data, error) => {
        if (data) {
          setInquiry(data?.data);
        } else if (error) {
          console.log(error);
        }
      });
    }
  }, [params?.id]);
  return (
    <Box sx={{ pt: "50px", width: "94%", margin: "0 auto" }}>
      <button
        className="bg-transparent hover:bg-blue-gray-100 sm:h-[40px] sm:w-[65px] rounded-xs text-blue-gray-900 duration-300"
        onClick={() => navigate("/dashboard")}
      >
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="center"
          padding="5px"
          spacing="2px"
        >
          <img
            className="w-[15px] h-[15px] rounded-[2px] rotate-180"
            src={arrow}
            alt=""
          />
          <p>Back</p>
        </Stack>
      </button>
      <section className="matter-details p-5 w-[90%] mx-auto flex flex-col-reverse lg:flex-row gap-10">
        {isLoading ? (
          <Stack justifyContent="center" minHeight="540px" minWidth="100%">
            <Loader width="50px" height="50px" />
          </Stack>
        ) : matter?._id ?? inquiry?._id ? (
          <div className="flex gap-6 min-h-[51.7vh] w-[100%]">
            <div className="main-content w-[100%] flex-grow lg:w-[70%]">
              <Details
                data={isInquiryPage ? inquiry : matter}
                isInquiryPage={isInquiryPage}
              />
              {!isInquiryPage && (
                <div className="meeting-schedule">
                  <h5 className="text-[#11142D] text-lg font-bold my-8">
                    Meeting Schedule
                  </h5>
                  <MeetingSchedule
                    profile={
                      userType === "PROVIDER" ? matter?.user : matter?.provider
                    }
                    meetingCreated={matter?.createdAt}
                  />
                </div>
              )}
            </div>
            <div className="profile w-[100%] h-[100%] lg:w-[30%]">
              <h5 className="text-[#11142D] text-lg font-bold">
                {userType === "PROVIDER" ? "Consumer" : "Lawyer"} Profile
              </h5>
              <Profile
                profile={
                  userType === "PROVIDER"
                    ? matter?.user ?? inquiry?.user
                    : matter?.provider ?? inquiry?.provider
                }
              />
            </div>
          </div>
        ) : (
          <div className="w-[100%] h-[25rem] flex justify-center items-center">
            <NoDataAvailable />
          </div>
        )}
      </section>
    </Box>
  );
};
const Details = ({ data, isInquiryPage }) => (
  <div>
    <div className="main-content w-full lg:w-[70%]">
      <div className="flex justify-between">
        <p className="text-[#64B4D0] text-lg font-bold">
          Inquiry Details
        </p>
        <p className="text-[#3CAA91] text-md font-semibold">{data?.status}</p>
      </div>
      <div className="my-7">
        <p className="text-[#11142D] text-md font-bold">
          {data?.title ??
            `Text Return It is a long established fact that a reader will be
          distracted by the readable content of a page when looking at its
          layout.`}
        </p>
        <p className="text-opacityColor text-sm my-3">
          {formatDate(data.updatedAt)}
        </p>
        <p className="mb-6 text-textColor text-lg ">
          {data?.description ??
            `It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout. The point of
          using Lorem Ipsum is that it has a more-or-less normal distribution of
          letters, as opposed to using 'Content here, content here', making it
          look like readable English. Many desktop publishing packages and web
          page editors now use Lorem Ipsum as their default model text.`}
        </p>
      </div>
    </div>
  </div>
);
const Profile = ({ profile }) => {
  // const categories = useSelector(
  //   (state) => state.categoriesReducer?.categories
  // );
  // const practiceAreas = profile?.practiceAreas.map(
  //   (category) => fetchCategoryName(categories, category)?.name
  // );
  return (
    <div className="flex flex-wrap xl:flex-nowrap mt-7 gap-6 py-7 px-8  border-[1px] border-[#D9D9D9] rounded-md ">
      <div className="w-36 rounded-md overflow-hidden">
        <img
          src={profile?.profilePicture ?? ProfileImg}
          alt="profile picture"
        />
      </div>
      <div>
        <h5 className="text-secondary text-lg font-bold my-1">
          {profile?.firstName + " " + profile?.lastName}
        </h5>
        {/* {!!profile?.practiceAreas?.length && (
          <p className="text-textColor font-medium">
            <Tooltip label={practiceAreas.filter((item) => !!item).join(", ")}>
              {practiceAreas?.[0]}...
            </Tooltip>
          </p>
        )} */}
        <p className="block text-textColor font-medium mt-1">
          {profile?.email}
        </p>
        <p className="block text-textColor font-medium mt-1 ">
          {formatPhone(profile?.phone)}
        </p>
        {/* {!!profile?.addresses?.length &&
          profile.addresses.map((address, index) => {
            return (
              <div key={index} className="flex mt-1">
                <div className="w-[40px] h-[40px]">
                  <img src={LocationIcon} alt="location" />
                </div>
                <p className="text-[#C6C7C4] text-lg font-medium">
                  {address?.city}, {address?.street01} {address?.postalCode}
                </p>
              </div>
            );
          })} */}
      </div>
    </div>
  );
};

const MeetingSchedule = ({ profile, meetingCreated, booking = false }) => {
  return (
    <div className="flex flex-col xs:flex-row items-center gap-3 w-full mb-4">
      <div className="img xs:w-16 rounded-sm overflow-hidden ">
        <img src={profile?.profilePicture ?? ProfileImg} alt="profile" />
      </div>
      <div className="xs:flex-1 p-6 bg-[#F9F9F9] rounded-sm">
        <div className="flex justify-between flex-wrap gap-1">
          <p className="text-[#11142D] text-sm font-semibold">
            {profile?.firstName} {profile?.lastName}
          </p>
          {booking && (
            <a className="text-[#64B4D0] text-sm font-bold cursor-pointer">
              Manage Booking
            </a>
          )}
        </div>
        <div className="flex mt-1  flex-wrap gap-1">
          <p className="text-[#181C32] text-sm italic mr-4">
            Meeting Booked on {formatDate(meetingCreated)}
          </p>
          <p className="text-[#181C32] text-sm italic">
            {formatDate(meetingCreated, "hh:mm A")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConsumerMatterDetails;
