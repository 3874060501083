import React, { useEffect, useState } from "react";
import ProfileImg from "../assets/user.svg";
import { useNavigate, useParams } from "react-router-dom";
import { fetchMatterById } from "services/fetchMatterById";
import { useAxios } from "utils/hooks/useAxios";
import Loader from "utils/loader/Loader";
import { Box, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { formatDate, formatPhone } from "utils/helperFunctions/helper";
import NoDataAvailable from "components/noDataAvailable/NoDataAvailable";
import arrow from "components/PremiumProviderEditProfileComponents/providermui/imgs/arow.svg";
import BookingManagerMain from "pages/BookingManagerMain";
import { RenderPracticeAreas } from "components/SearchResultProfileComponents/RenderTag";

const BookingDetails = () => {
  const [matter, setMatter] = useState({});
  const userType = useSelector((state) => state.user?.userType);
  const params = useParams();
  const { isLoading, callApi } = useAxios();
  const navigate = useNavigate();
  useEffect(() => {
    if (params?.id) {
      fetchMatterById(callApi, params.id, (data, error) => {
        if (data) {
          setMatter(data);
        } else if (error) {
          console.log(error);
        }
      });
    }
  }, [params?.id]);
  return (
    <Box sx={{ pt: "50px", width: "94%", margin: "0 auto" }}>
      <button
        className="bg-transparent hover:bg-blue-gray-100 sm:h-[40px] sm:w-[65px] rounded-xs text-blue-gray-900 duration-300"
        onClick={() => navigate(`/dashboard?value=2`)}
      >
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="center"
          padding="5px"
          spacing="2px"
        >
          <img
            className="w-[15px] h-[15px] rounded-[2px] rotate-180"
            src={arrow}
            alt=""
          />
          <p>Back</p>
        </Stack>
      </button>
      <section className="matter-details p-5 w-[90%] mx-auto flex flex-col-reverse lg:flex-row gap-10 ">
        {isLoading ? (
          <Stack justifyContent="center" minHeight="540px" minWidth="100%">
            <Loader width="50px" height="50px" />
          </Stack>
        ) : matter?._id ? (
          <div className="flex gap-6 min-h-[51.7vh] w-[100%]">
            <div className="main-content w-[100%] h-[100%] lg:w-[60%]">
              <Details
                data={matter}
              />
            </div>
            <div className="profile w-[100%] h-[100%] lg:w-[40%]">
                {!!matter?.search?.text && (
                    <div>
                        <h5 className="text-[#0d2959] text-lg font-bold">
                            Search Text
                        </h5>
                        <p className="block text-textColor font-medium mt-3 my-5">
                            {matter.search.text}
                        </p>
                    </div>   
                )}
              <h5 className="text-[#0d2959] text-lg font-bold">
                Lawyer Profile
              </h5>
              <Profile
                profile={
                  userType === "PROVIDER" ? matter?.user : matter?.provider
                }
              />
            </div>
          </div>
        ) : (
          <div className="w-[100%] h-[25rem] flex justify-center items-center">
            <NoDataAvailable />
          </div>
        )}
      </section>
    </Box>
  );
};
const Details = ({ data }) => (
    <div className="main-content w-full lg:w-[90%]">
        <div className="flex justify-between">
            <p className="text-[#0d2959] text-lg font-bold">
                Appointment Details
            </p>
        </div>
        <BookingManagerMain
            calAtomsBookingId={data.bookafyId}
            calAtomsEventSlug={data.eventSlug}
            calAtomsUserName={data.providerCalAtomsUserName}
        />
    </div>
);
const Profile = ({ profile }) => {
  return (
    <div className="mt-5 gap-6 px-5  border-[1px] border-[#D9D9D9] rounded-md">
        <div className="flex flex-wrap xl:flex-nowrap my-3 gap-6">
        <div className="w-36 rounded-md overflow-hidden">
            <img
                src={profile?.profilePicture ?? ProfileImg}
                alt="profile picture"
                style={{backgroundColor: !!profile?.profilePicture ? "white" : "#F6F6F6"}}
            />
        </div>
        <div>
            <h5 className="text-secondary text-lg font-bold my-1">
                {profile?.firstName + " " + profile?.lastName}
            </h5>
            <p className="block text-textColor font-medium mt-1">
                {profile?.email}
            </p>
            <p className="block text-textColor font-medium mt-1 ">
                {formatPhone(profile?.phone)}
            </p>
        </div>
        </div>
        <div className="my-5">
            {!!profile?.practiceAreas.length && (
                <RenderPracticeAreas practiceAreas={profile.practiceAreas} gap={1} hideBubbles={false} /> 
            )}
        </div>
    </div>
  ); 
};

export default BookingDetails;
