import React, { useLayoutEffect, useState } from "react";
import Box from "@mui/material/Box";
import ProviderDetailHeader from "components/PremiumProviderEditProfileComponents/providermui/ProviderDetailHeader";
import { Container, Stack } from "@mui/material";
import Languages from "components/PremiumProviderEditProfileComponents/providermui/Languages";
import Education from "components/PremiumProviderEditProfileComponents/providermui/Education";
import WorkInfo from "components/PremiumProviderEditProfileComponents/providermui/WorkInfo";
import Publication from "components/PremiumProviderEditProfileComponents/providermui/Publication";
import Awards from "components/PremiumProviderEditProfileComponents/providermui/Awards";
import SpeakingEngagement from "components/PremiumProviderEditProfileComponents/providermui/SpeakingEngagements";
import Associations from "components/PremiumProviderEditProfileComponents/providermui/Associations";
import ExperienceMain from "components/PremiumProviderEditProfileComponents/providermui/ExperienceMain";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { fetchUserInfoById, fetchUserInfoByName } from "services/fetchUserInfo";
import { useAxios } from "utils/hooks/useAxios";
import Loader from "utils/loader/Loader";
import Biography from "components/PremiumProviderEditProfileComponents/providermui/Biography";
import arrow from "../PremiumProviderEditProfileComponents/providermui/imgs/arow.svg";

const ProviderDetailsNewScope = ({
  user,
  editAble = false,
  isMyProfilePage = false,
}) => {
  const [provider, setProvider] = useState(user ?? {});
  const { isLoading, callApi } = useAxios();
  const params = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  useLayoutEffect(() => {
    // SEO - Meta Description tag
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", provider?.biography);
    } else {
      const meta = document.createElement('meta');
      meta.name = "description";
      meta.content = provider?.biography;
      document.head.appendChild(meta);
    }

    // SEO - Title tag
    document.title = `JuriCon.ai | ${provider?.firstName} ${provider?.lastName}`;

    // SEO - Rel Canonical link tag
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    const absoluteUrl = window.location.href;
    if (canonicalLink) {
      canonicalLink.setAttribute("href", absoluteUrl);
    } else {
      const link = document.createElement('link');
      link.rel = "canonical";
      link.href = absoluteUrl;
      document.head.appendChild(link);
    }

  }, [provider?.biography]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
    if (state) {
      setProvider(state)
    } else if (params?.id) { // check to see which param was provided in the URI, id or name

      fetchUserInfoById(callApi, params.id, (data) => {
        setProvider(data.data);
      });
    } else if (params?.name) {
      // remove .html from the name
      let lawyerName = params.name.replace(/\.html/g, '');

      // fetch user info by name
      fetchUserInfoByName(callApi, lawyerName, (data) => {
        if (data === null || data?.data === null) {
          console.log(`lawyer '${lawyerName}' could not be found.`);
          ///TODO: show 404 page?
        }
        else {
          setProvider(data.data);
        }
      });
    }
  }, []);
  
  return (
    <Container sx={{ width: "100%", pb: 6, maxWidth: { md: "95%" }, pt: "50px" }}>
      <button
        className="bg-transparent hover:bg-blue-gray-100 sm:h-[40px] rounded-xs text-blue-gray-900 duration-300"
        onClick={() => editAble? navigate("/") : navigate("/searchresult")}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          padding="5px"
          spacing="2px"
          mr="3px"
        >
          <img
            className="w-[15px] h-[15px] rounded-[2px] rotate-180"
            src={arrow}
            alt=""
          />
          <p>{editAble ? "Back" : "Back to Search Results"}</p>
        </Stack>
      </button>
      {isLoading ? (
        <Stack justifyContent="center" alignItems="center" minHeight="500px">
          <Loader width={50} height={50} />
        </Stack>
      ) : (
        <div className=" my-7">
          {!!Object.keys(provider)?.length && (
            <ProviderDetailHeader
              provider={provider}
              isMyProfilePage={isMyProfilePage}
              editAble={editAble}
              isNewScope={true}
            />
          )}
          {!!provider?.relevantRepresentations?.length && (
            <ExperienceMain
              editable={editAble}
              user={provider}
              isNewScope={true}
              showRelevantRepresentations={true}
            />
          )}
          {provider?.biography && (
            <Biography editAble={editAble} text={provider?.biography} />
          )}
          {!!provider?.languages?.length && (
            <Languages user={provider} editAble={editAble} />
          )}
          {!!provider?.educations?.length && (
            <Education
              editAble={editAble}
              educations={provider.educations ?? []}
            />
          )}
          {!!provider?.workInfo?.length && (
            <WorkInfo editAble={editAble} workInfo={provider?.workInfo ?? []} />
          )}
          {!!provider?.publications?.length && (
            <Box>
              <Publication
                editAble={editAble}
                publications={provider?.publications}
              />
            </Box>
          )}
          {!!provider?.speakingengagements?.length && (
            <Box>
              <SpeakingEngagement editAble={editAble} speakingengagements={provider?.speakingengagements} />
            </Box>
          )}
          {!!provider?.awards?.length && (
            <Box>
              <Awards editAble={editAble} awards={provider?.awards} />
            </Box>
          )}
          {!!provider?.associations?.length && (
            <Box>
              <Associations
                editAble={editAble}
                associations={provider?.associations}
              />
            </Box>
          )}
          {!!provider?.representations?.length && (
            <ExperienceMain
              editable={editAble}
              user={provider}
              isNewScope={true}
            />
          )}
          {/* BELOW ARE HIDDEN BECAUSE NO RATINGS IMPLEMENTED YET */}
          {/* <Box sx={{ display: "flex" }}>
          <Typography
            sx={{ color: "#002A5C", fontSize: 22, fontWeight: 700, my: 8 }}
            gutterBottom
          >
            Feedback and Ratings
          </Typography>
          <img className="pl-2" src={Exclamation} alt="" />
        </Box> */}
          {/* <CardsMain /> */}
        </div>
      )}
    </Container>
  );
};
export default ProviderDetailsNewScope;
