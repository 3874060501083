import React from "react";

const RemoveIcon = ({ onClick }) => {
  return (
    <svg
      style={{ width: "30px", height: "30px", cursor: "pointer" }}
      viewBox="0 0 24 24"
      onClick={onClick}
    >
      <path
        fill="#C6C7C4"
        d="M17,13H7V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
      />
    </svg>
  );
};

export default RemoveIcon;
