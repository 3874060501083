import {
  ADD_CATEGORIES,
  ADD_STATES,
  CHANGE_MEMBERSHIP_TO,
  RESET_FILTERS,
  RESET_SEARCH_REDUCER,
  SEARCH_QUERY,
  UPDATE_BUSINESS_LIST,
  UPDATE_DATE_FILTER,
  UPDATE_FILTERS,
  UPDATE_FILTER_ARRAYS,
  UPDATE_PAYMENT_CARDS,
} from "utils/constants/constants";
import { formatDate } from "utils/helperFunctions/helper";

export const addCategories = (payload) => {
  return {
    type: ADD_CATEGORIES,
    payload,
  };
};
export const addStates = (payload) => {
  return {
    type: ADD_STATES,
    payload,
  };
};
export const updateCategoriesReducer = (type, payload) => {
  return {
    type,
    payload,
  };
};
export const updateSearchQuery = (payload) => {
  return {
    type: SEARCH_QUERY,
    payload,
  };
};
export const resetSearchReducer = () => ({
  type: RESET_SEARCH_REDUCER,
});
export const updateDateFilter = (payload) => {
  return {
    type: UPDATE_DATE_FILTER,
    payload,
  };
};
export const initializeDateFilter = () => {
  const currentDate = new Date();
  return {
    type: UPDATE_DATE_FILTER,
    payload: {
      startDate: "01/01/2023",
      endDate: formatDate(currentDate, "MM/DD/YYYY"),
    },
  };
};
export const resetDateFilter = () => {
  return {
    type: UPDATE_DATE_FILTER,
    payload: {
      startDate: null,
      endDate: null,
    },
  };
};
export const updateBusinessList = (payload) => {
  return {
    type: UPDATE_BUSINESS_LIST,
    payload,
  };
};
export const updateCreditCards = (payload) => {
  return {
    type: UPDATE_PAYMENT_CARDS,
    payload,
  };
};
export const updateMemberShipTo = (payload) => {
  return {
    type: CHANGE_MEMBERSHIP_TO,
    payload,
  };
};
export const updateFilters = (filters) => {
  return {
    type: UPDATE_FILTERS,
    payload: filters,
  };
};
export const updateFilterArrays = (filters) => {
  return {
    type: UPDATE_FILTER_ARRAYS,
    payload: filters,
  };
};
export const resetFilters = () => {
  return {
    type: RESET_FILTERS,
  };
};
