import { CloseOutlined } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import calendarImage from "assets/book-appt-button.png";
import sendInquiryImage from "assets/send-inquiry-button.png";
import client from "assets/user.svg";
import BookmarkButton from "components/bookmark/BookmarkButton";
import Error from "components/error/Error";
import { billingText } from "components/PremiumProviderEditProfileComponents/providermui/ProviderDetailHeader";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { sendInquiry } from "services/sendInquiry";
import { openModal } from "store/actions/modalActions";
import { isCalAtomsUserIDValid } from "utils/config";
import { OPEN_BOOKING_APPOINTMENT_MODAL, OPEN_SIGN_IN_MODAL, PREMIUM, PREMIUM_PACKAGE } from "utils/constants/constants";
import { useAxios } from "utils/hooks/useAxios";
import Loader from "utils/loader/Loader";
import * as Yup from "yup";
import { RenderPracticeAreas } from "./RenderTag";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  maxWidth: 700,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const SearchResultCard = ({
  name,
  clientImage,
  subscription,
  providerId,
  searchId,
  provider,
  user,
  states,
}) => {
  const [showInquiryModal, setShowInquiryModal] = useState(false);
  const dispatch = useDispatch();
  const {
    matchPercentage,
    ratePerHourMin,
    onContingency,
    practiceAreas,
    profilePicture,
    billingStructure,
    relevantPracticeAreas,
    relevantRepresentations,
    yearStartedPracticingLaw,
  } = provider;
  const navigate = useNavigate();
  const { isLoading, callApi } = useAxios();
  const borderColor =
    subscription?.includes(PREMIUM_PACKAGE) || subscription?.includes(PREMIUM)
      ? "#E9BA16"
      : "#C6C7C4";
  let isBookingAppointmentClicked = false

  useEffect(() => {
    const checkElement = document.getElementById("bookafy-fancy-box");
    if (checkElement) {
      checkElement.parentNode.removeChild(checkElement);
    }

    const script = document.createElement("script");
    script.id = "bookafy_script";
    script.type = "text/javascript";
    script.src = "https://app.bookafy.com/bookafy-popup-code.js";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (isBookingAppointmentClicked && isCalAtomsUserIDValid(provider?.calAtomsUserId)) {
      handleBookingAppointment()
    }
  }, [user]);

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required().min(3).label(" Title"),
      description: Yup.string().required().min(10).label(" Description"),
    }),
    onSubmit: (values) => {
      values.provider = providerId;
      values.providerEmail = provider?.email;
      values.providerPhone = provider?.phone;
      values.providerName = provider?.firstName + " " + provider?.lastName;

      // send inquiry submit form string to google analytics
      ReactGA.event({
        category: "submit form",
        action: "Juricon_Send-Inquiry-Submit",
        label: "provider: " + values.providerName,
      });

      sendInquiry(callApi, values, (res) => {
        if (res.success) {
          formik.resetForm();
          setShowInquiryModal(false);
        }
      });
    },
  });
  const handleCardClick = (e) => {
    e.stopPropagation();
    navigate(`/profile/${provider?._id}?searchId=${searchId}`, {state: provider});
  };

  const matchPercentageCappedUpperBound = function (rawValue) {
    if (rawValue < 100) {
      return rawValue
    } else {
      return 99
    }
  };

  const showToast = (message) => {
    toast["info"](message, {
      position: "top-right",
      autoClose: 8000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }

  const showInfoMessage = () => {
    if (!!ratePerHourMin) {
      showToast("The rate disclosed for this lawyer is for one hour. Most lawyers bill in 6 minute increments. Please contact the lawyer for more details.")
    } else {
      showToast("No hourly rate was disclosed for this lawyer. Please contact the lawyer for specific rates.")
    }
    if (billingStructure?.includes("FIXED_FEE")) {
      showToast("This lawyer is willing to accept certain projects for a fixed fee.")
    }
    if (!!onContingency) {
      showToast("If Contingency is listed, the lawyer is willing to take on certain projects where their fee is contingent on the outcome of the project.")
    }
    if (billingStructure?.includes("RETAINER")) {
      showToast("Some lawyers require a retainer before beginning a representation. Contact the lawyer for more details.")
    }
  }

  const getLawFirmName = () => {
    let name = null
    const sortedWorkInfo = provider?.workInfo.sort((a, b) => (b.startDate - a.startDate))
    Object.keys(sortedWorkInfo)
        .filter(key => {
          if (provider.workInfo[key].endDate === '') { 
            name = provider.workInfo[key].companyName  
          }
        })
    return name
  }

  const getLicensedStates = () => { 
    return (states || []).reduce((acc, item) => {
      if (provider?.licenses?.some(x => x.state === item._id)) { 
        acc.push(item);
      }
      return acc;
    }, []);
  }
  

  const getRelevantPriorRepreentationsString = () => {
    return "Prior " + (relevantRepresentations?.length > 1 ? "representations " :  "representation ") + "handling similar cases"
  }

  const getYearsPracticingLaw = () => {
    const currentYear = new Date().getFullYear()
    let yearStartedPracticing = yearStartedPracticingLaw || currentYear
    return currentYear - yearStartedPracticing
  }

  const getYearsPracticingLawString = () => {
    return (getYearsPracticingLaw() > 1 ? "Years " : "Year ") + "practicing law"
  }

  const handleBookingAppointment = () => {
    isBookingAppointmentClicked = true
    if (user === null) {
      dispatch(openModal(OPEN_SIGN_IN_MODAL));
    } else {
      // send book appointment start to google analytics
      ReactGA.event({
        category: "start book-appointment",
        action: "Juricon_Book-Appointment-Start",
      });
      isBookingAppointmentClicked = false
      const values = {
        provider: provider,
        searchId: searchId
      }
      dispatch(openModal(OPEN_BOOKING_APPOINTMENT_MODAL, values));
    }
  }

  const handleSendInquiry = () => {
    if (user === null) {
      dispatch(openModal(OPEN_SIGN_IN_MODAL));
    } else {
      // send inquiry start string to google analytics
      ReactGA.event({
        category: "start send-inquiry",
        action: "Juricon_Send-Inquiry-Start",
      });
      setShowInquiryModal(true);
    }
  }

  return (
    <div
      className={` pt-[1.375rem] pb-[0.6rem] px-[1.1rem] rounded-[8px] cursor-pointer flex-1 max-w-[445px]`}
      style={{ border: `1px solid ${borderColor}` }}
      onClick={handleCardClick}
    >
      <div className="relative min-h-[100px] flex flex-col gap-2 pb-5 h-[100%]">
        <div className="flex flex-row w-[100%]">
          <div className="w-[8rem] h-[7.125rem] flex overflow-hidden rounded-sm">
            <img
              className="object-contain "
              src={profilePicture || clientImage || client}
              alt=""
            />
          </div>
          {matchPercentage && (
            <div className="top-[-2.305rem] bg-[#DAF8FF] absolute px-2 rounded-xs">
              <span className="font-semibold text-secondary">Match </span>
              <span className="font-bold text-secondary">
                {matchPercentageCappedUpperBound(matchPercentage)}%
              </span>
            </div>
          )}
            <Stack className="top-[-2.305rem] right-[-1rem] absolute px-2"
              direction="row"
              spacing={0.8}
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isCalAtomsUserIDValid(provider?.calAtomsUserId) && (
                <button>
                  <img
                    src={calendarImage} alt="calendarImage" 
                    style={{ height: 30, width: 30}}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleBookingAppointment()
                    }}/>
                </button>
              )}
              <button>
                <img
                  src={sendInquiryImage} alt="sendInquiryImage" 
                  style={{ height: 30, width: 30}}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleSendInquiry()
                  }}/>
              </button>
              <BookmarkButton
                searchId={searchId}
                providerId={providerId}
                isSearchResultCard={true}
              />
            </Stack>
          <div className="w-[100%]">
            <div className="ml-5">
              <h1 className="text-[#002A5C] text-[25px] leading-[20px] font-[700] mb-3 ">
                {name}
              </h1>
              <Stack direction="row"
               spacing={2}
               alignItems="center"
               justifyContent="flex-start"
              >
                <Stack direction="column"
                  spacing={2}
                  alignItems="left"
                  justifyContent="space-around"
                  width="60%"
                >
                  {getLawFirmName() && (
                    <h2 className=" text-[#090909] text-[18px] font-[500] leading-[25px]">
                      {getLawFirmName()}
                    </h2>
                  )}
                  {getLicensedStates() && ( 
                    <Stack direction="column"
                      alignItems="left"
                      justifyContent="flex-start"
                    >
                      <h2 className=" text-[#494949] text-[10px] font-[500] leading-[25px]">
                        LICENSED TO PRACTICE
                      </h2>
                      <h2 className=" text-[#090909] text-[12px] font-[500] leading-[25px]">
                        {""+getLicensedStates().map(i => " "+i.name)}
                      </h2>
                    </Stack> 
                  )}
                </Stack>
                <Stack direction="column"
                  alignItems="left"
                  justifyContent="flex-start"
                >
                  <h2 className=" text-[#494949] text-[10px] font-[500] leading-[20px] mb-2">
                   {"RATE "}
                    <InfoOutlinedIcon style={{ height: 20, width: 20, marginBottom: 3}}
                      onClick={(e) => {
                        e.stopPropagation()
                        showInfoMessage()
                      }}>
                    </InfoOutlinedIcon>
                  </h2>
                  <h2 className=" text-[#090909] text-[12px] font-[400] leading-[20px]">
                   {ratePerHourMin ? ("$" + billingText(ratePerHourMin)) : "No Rate Given"}
                  </h2>
                  {billingStructure?.includes("FIXED_FEE") && (
                      <h2 className=" text-[#090909] text-[12px] font-[400] leading-[20px]">
                        Fixed Fee
                      </h2>
                  )}
                  {!!onContingency && (
                    <h2 className=" text-[#090909] text-[12px] font-[400] leading-[20px]">
                      {onContingency}% Contingency
                    </h2>
                  )}
                  {billingStructure?.includes("RETAINER") && (
                    <h2 className=" text-[#090909] text-[12px] font-[400] leading-[20px]">
                      Retainer Required
                    </h2>
                  )}
                </Stack> 
              </Stack>
            </div>
          </div>
        </div>
        <Stack direction="column"
               spacing={1}
               alignItems="left"
               justifyContent="center"
               width="390px"
          >
            {(!!relevantRepresentations?.length || getYearsPracticingLaw() > 0) && (
              <h2
                className="text-[#494949] text-left text-[10px] font-[500] leading-[25px]">
                EXPERIENCE
              </h2>              
            )}
            <Stack direction="row"
               spacing={2}
               alignItems="left"
               justifyContent="flex-start"
            >
            {!!relevantRepresentations?.length && (
              <Stack direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="flex-start"
              width={getYearsPracticingLaw() > 0 ? "50%" : "100%"}
              >
                <h2
                  className="text-[#64B4D0] text-left text-[25px] font-[500] leading-[25px]">
                  {relevantRepresentations?.length}
                </h2>
                <h2
                  className="text-[#494949] text-left text-[14px] font-[300] leading-[25px]">
                  {getRelevantPriorRepreentationsString()}
                </h2>
              </Stack>
            )}
            {getYearsPracticingLaw() > 0 && (
              <Stack direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="flex-start"
              >
                <h2
                  className="text-[#64B4D0] text-left text-[25px] font-[500] leading-[25px]">
                  {getYearsPracticingLaw()}
                </h2>
                <h2
                  className="text-[#494949] text-left text-[14px] font-[300] leading-[25px]">
                  {getYearsPracticingLawString()}
                </h2>
              </Stack>
            )}
            </Stack> 
            {!!relevantPracticeAreas?.length && (
              <h2
                className="text-[#494949] text-left text-[10px] font-[500] leading-[25px]">
                EXPERTISE
              </h2>              
            )}
            {!!relevantPracticeAreas?.length && (
              <RenderPracticeAreas practiceAreas={relevantPracticeAreas} gap={1} hideBubbles={true} /> 
            )}
        </Stack> 
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showInquiryModal}
        onClose={(e) => {
          e.stopPropagation();
          formik.resetForm();
          setShowInquiryModal(false);
        }}
      >
        <Box sx={style} onClick={(e) => e.stopPropagation()}>
          <Stack gap={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography
                variant="h5"
                fontWeight={600}
                className="text-secondary"
              >
                Let's Discuss Your Legal Matters
              </Typography>
              <IconButton
                onClick={() => setShowInquiryModal(false)}
                size="large"
              >
                <CloseOutlined />
              </IconButton>
            </Stack>
            <form>
              <Stack gap={2}>
                <Typography
                  variant="label"
                  className="text-[#737373] font-[600]"
                >
                  Title
                </Typography>
                <Stack>
                  <TextField
                    size="small"
                    name="title"
                    {...formik.getFieldProps("title")}
                  />
                  <Error formik={formik} errorField="title" />
                </Stack>

                <Typography
                  variant="label"
                  className="text-[#737373] font-[600]"
                >
                  Description
                </Typography>
                <Stack>
                  <textarea
                    className="border-borderColor border rounded-xs min-h-[35vh] focus:outline-primary p-3"
                    name="description"
                    {...formik.getFieldProps("description")}
                    placeholder="Please describe your legal issue using as much detail as possible (but omitting any party names or confidential information)."
                  />
                  <Error formik={formik} errorField="description" />
                </Stack>
                <Stack direction="row" justifyContent="end">
                  <Button
                    variant="contained"
                    sx={{
                      color: "White",
                      "&:hover": {
                        backgroundColor: "rgba(0, 164, 218, 0.9)",
                      },
                      minWidth: "138px",
                    }}
                    type="submit"
                    onClick={formik.handleSubmit}
                    disabled={isLoading}
                  >
                    {isLoading ? <Loader /> : "Send Inquiry"}
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default SearchResultCard;
