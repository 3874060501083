import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AutoComplete from "components/autocomplete/AutoComplete";
import { Paper } from "@mui/material";

export default function RenderStates({
  stateId,
  onChange,
  variant,
  borderRadius,
}) {
  const [fetchedState, setFetchedState] = useState({});

  const states = useSelector((state) => state.categoriesReducer?.states);
  const handleChange = (value) => {
    onChange(value?._id);
  };
  useEffect(() => {
    if (stateId) {
      const fetchedState = states?.find((state) => state?._id === stateId);
      setFetchedState(fetchedState);
    }
  }, [stateId]);
  return (
    <AutoComplete
      options={states}
      defaultValue={fetchedState}
      onChange={handleChange}
      placeholder="Select Location"
      variant={variant}
      borderRadius={borderRadius}
    />
  );
}

// sx={{
//   width: 340,
//   "& + .MuiAutocomplete-popper .MuiAutocomplete-option":
//   {
//     backgroundColor: "#363636",
//   },
//   "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
//   {
//     backgroundColor: "#4396e6",
//   },
//   "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused":
//   {
//     backgroundColor: "#3878b4",
//   },
// }}
