import {
  EMAIL_VERIFIED_SUCCESSFULLY,
  SIGNOUT_REQUEST,
  SIGN_IN_ACTION,
} from "utils/constants/constants";
import { notify } from "utils/toast/toast";

export const signInAction = (values) => ({
  type: SIGN_IN_ACTION,
  payload: values,
});

export const emailVerifiedSuccessfully = (accessToken) => ({
  type: EMAIL_VERIFIED_SUCCESSFULLY,
  payload: { accessToken, isEmailVerified: true },
});

export const signOut = () => {
  notify("You are logged out successfully!", "success");
  return { type: SIGNOUT_REQUEST };
};
