import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import masterCard from "assets/master-card-logo.png";
import visaCard from "assets/visa-card-logo.png";
import { useAxios } from "utils/hooks/useAxios";
import { deleteCardById } from "services/deletePaymentCard";
import Loader from "utils/loader/Loader";
import { fetchPaymentCards } from "services/fetchPaymentCards";
import { updateCreditCards } from "store/actions/actions";
import { useDispatch } from "react-redux";
import { markAsDefault } from "services/markAsDefault";
import { openModal } from "store/actions/modalActions";
import { DELETE_CARD_SUCCESS_MODAL } from "utils/constants/constants";

const PaymentCard = ({
  type,
  defaultCard,
  holderName,
  lastFourDigit,
  cardExpiry,
  cardId,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { isLoading, callApi } = useAxios();
  const dispatch = useDispatch();
  const { callApi: paymentCardsApi } = useAxios();
  const { isLoading: markDefaultLoading, callApi: markDefaultApi } = useAxios();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDeleteCard = (cardId) => {
    deleteCardById(callApi, cardId, () => {
      fetchPaymentCards(paymentCardsApi, (data) => {
        if (data) {
          dispatch(updateCreditCards(data.data.data));
          handleClose();
          dispatch(openModal(DELETE_CARD_SUCCESS_MODAL));
        }
      });
    });
  };
  const handleMarkAsDefault = (cardId) => {
    markAsDefault(
      markDefaultApi,
      {
        paymentMethodId: cardId,
        isMarkAsDefault: true,
      },
      (data) => {
        if (data) {
          fetchPaymentCards(paymentCardsApi, (data) => {
            if (data) {
              dispatch(updateCreditCards(data.data.data));
              handleClose();
            }
          });
        }
      }
    );
  };
  const src = type === "visa" ? visaCard : masterCard;

  return (
    <div
      className={`payment-card rounded-md ${
        type !== "visa" ? "bg-[#FFF4CB]" : "bg-[#BAEEFF]"
      } min-w-[340px]`}
    >
      <div className="top flex justify-between">
        <div className="logo mt-2">
          <img src={src} alt="card-logo" />
        </div>
        <div className="flex relative">
          {defaultCard && (
            <button className="bg-textColor px-4 py-1 text-white rounded-xs mr-2">
              Default
            </button>
          )}

          <IconButton
            aria-label="more"
            id="card-button"
            aria-controls={open ? "card-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            size="large"
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="card-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "more",
            }}
          >
            <MenuItem
              sx={{ color: "#11142D", fontSize: "14px", minWidth: "102px" }}
              onClick={() => handleMarkAsDefault(cardId)}
            >
              {markDefaultLoading ? <Loader /> : "Set Default"}
            </MenuItem>
            <MenuItem
              sx={{ color: "#11142D", fontSize: "14px" }}
              onClick={() => handleDeleteCard(cardId)}
            >
              {isLoading ? <Loader /> : "Delete"}
            </MenuItem>
          </Menu>
        </div>
      </div>

      <h4 className="card-no mt-8 mb-6 text-textColor text-xl font-medium">
        XXXX XXXX XXXX <span className="last-digits">{lastFourDigit}</span>
      </h4>
      <div className="flex">
        <div>
          <label className="mb-2 text-[#979797] text-[10px]">Card Holder</label>
          <p className="text-textColor text-base font-semibold user-name">
            {holderName}
          </p>
        </div>
        <div className="ml-24">
          <label className="mb-2 text-[#979797] text-[10px]">Valid Thru</label>
          <p className="text-textColor text-base font-semibold due-date">
            {cardExpiry}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentCard;
