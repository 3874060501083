import { routes } from "utils/appConfig";

export const markAsDefault = (callApi, data, cb) => {
  const { url, method } = routes.addCard;
  try {
    callApi(url, method, data, {
      onSuccess: ({ data }) => {
        cb(data);
      },
      onError: (error) => {
        cb(null, error);
      },
    });
  } catch (e) {
    console.log("🚀 ~ e:", e);
    throw new Error();
  }
};
