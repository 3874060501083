import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchUserInfoById } from "services/fetchUserInfo";
import { signInAction } from "store/actions/authActions";
import { useAxios } from "utils/hooks/useAxios";
import calendarIcon from "../../assets/calendar-white.svg";
import starsIcon from "../../assets/stars.svg";

const WhatsNewCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user || {});
  const { isLoading, callApi } = useAxios();

  const handleNavigateToManageCalendar = () => {
    // Fetch latest user info from the DB to ensure cal access token is updated to latest after refresh by the cal atoms SDK
    fetchUserInfoById(callApi, user._id, (data) => {
      dispatch(signInAction(data.data));
      navigate("/managecalendar", { state: data.data ?? user });
    });
  };
  return (
    <div className="w-[560px] card_mb p-6 md:p-6 flex flex-col bg-secondary rounded-[10px] text-white  shadow-[#29292a1a] shadow-lg">
      <div className="flex justify-center items-center p-2">
        <img className="object-contain" src={starsIcon} alt="Icon" />
        <h1 className="text-center font-[500] md:font-[600] text-[28px] md:text-[28px] ml-3 leading-[40px] md:leading-[40px] pt-2 tracking-[0.1px]">
          What's New
        </h1>
      </div>
      <div className="flex items-start text-left">
        <img
          className="mt-2 w-[27px] h-[28px] ml-3 object-contain"
          src={calendarIcon}
          alt="Icon"
        />
        <h2 className="font-[200] md:font-[200] text-[20px] md:text-[20px] ml-3 leading-[40px] md:leading-[40px] tracking-[0.1px]">
          Introducing Direct Booking
        </h2>
      </div>
      <p className="font-[200] text-[10px] md:text-[14px] leading-[20px] pt-3 pb-1.5 tracking-[0.1px] ml-12 text-left">
        Now, individuals can easily schedule appointments with providers right
        from their search results.
      </p>
      <p className="font-[200] text-[10px] md:text-[14px] leading-[20px] pt-3 pb-1.5 tracking-[0.1px] ml-12 text-left">
        Set your availability, and you're ready to receive and accept meeting
        requests from new clients. To get started, click below.
      </p>
      <div className="flex justify-center mt-4">
        <button
          className="bg-white text-primary text-[20px] py-2 px-4 rounded-md hover:bg-gray-200"
          onClick={handleNavigateToManageCalendar}
        >
          Manage Calendar
        </button>
      </div>
    </div>
  );
};

export default WhatsNewCard;
