import React, { useEffect } from "react";
import DatePicker from "components/datePicker/DatePicker";
import { PERSONAL_INFO } from "components/PremiumProviderEditProfileComponents/constants/editProfileConstants";
import EditProfileFooter from "components/PremiumProviderEditProfileComponents/editProfilFooter/EditProfileFooter";
import RemoveIcon from "components/removeIcon/RemoveIcon";
import { useFormik } from "formik";
import moment from "moment";
import AddFieldButton from "../addFieldButton/AddFieldButton";
import {
  educationObject,
  educationsValidationNewScope,
  licenseObjectNewScope, stepDataNewScope
} from "../helper";
import StepLabel from "../stepLabel/StepLabel";
import MultipleValues from "./MultipleValus/MultipleValues";
import { cleanUpValues, findDuplicateIndexes, sortData } from "utils/helperFunctions/helper";
import Error from "components/error/Error";
import SchoolsAutocomplete from "components/schools/SchoolsAutoComplete";
import RenderStates from "components/States/StatesAutoComplete";
import { useDispatch, useSelector } from "react-redux";
import { addSchool } from "services/addSchool";
import { useAxios } from "utils/hooks/useAxios";
import { notify } from "utils/toast/toast";
import { fetchSchoolsList } from "services/fetchSchoolsList";
import { updateCategoriesReducer } from "store/actions/actions";
import { ADD_SCHOOLS_DATA } from "utils/constants/constants";
import EditProfileSteps from "../../PremiumProviderEditProfileComponents/editProfileSteps/EditProfileSteps";
import { routes } from "../../../utils/appConfig";
import { signInAction } from "../../../store/actions/authActions";

export const addItemNewScope = (getKeyBy, formik, obj) => {
  formik.setValues({
    ...formik.values,
    [getKeyBy]: [...formik.values[getKeyBy], obj],
  });
};

export const validateLicenseNumber = (value, index) => {
  console.log("value", value);
};

export const removeItemNewScope = (index, getByKey, formik, itemKeys) => {
  if (itemKeys) {
    setFieldsUntouched(formik, index, getByKey, itemKeys);
  }
  let _temp = [...formik.values[getByKey]];
  _temp.splice(index, 1);
  formik.setFieldValue(getByKey, _temp);
};

export const setFieldsUntouched = (formik, index, category, keys) => {
  // keys option added to apply logic to any specified fields to use in other objects and make it reusable
  Object.keys(keys ?? educationObject).forEach((key) => {
    formik.setFieldTouched(`${category}[${index}].[${key}]`, false);
  });
};

const EducationNewScope = ({
  user,
  step,
  setStep,
  states,
  onSubmit,
}) => {
  const schoolsData = useSelector((state) => state.categoriesReducer.schools);
  const { callApi, isLoading } = useAxios();
  const dispatch = useDispatch();
  const stepsCompleted = user?.stepsCompleted;
  const educations=  user?.educations ?? [];
  const licenses= user?.licenses ?? [];

  useEffect(() => {
    fetchSchoolsList(callApi, (data) => {
      if (data) {
        dispatch(
          updateCategoriesReducer(
            ADD_SCHOOLS_DATA,
            sortData(data, "asc", "name")
          )
        );
      }
    });
  }, []);
  useEffect(() => {
    if (educations?.length) {
      formik.setFieldValue("educations", educations);
    }
    if (licenses?.length) {
      formik.setFieldValue("licenses", licenses);
    }
  }, [educations, licenses]);
  const formik = useFormik({
    initialValues: {
      educations: [educationObject],
      licenses: [licenseObjectNewScope],
    },
    validationSchema: educationsValidationNewScope,
    onSubmit: async (values) => {
      const newSchools = values.educations.filter((education) => {
        const isExistingSchool = schoolsData.find(
          (school) => school.name === education.university
        );
        return !isExistingSchool;
      });
      if (newSchools.length) {
        const schoolsPromises = newSchools.map(
          async (school) =>
            await addSchool(callApi, { name: school.university })
        );
        const promisesResult = await Promise.all(schoolsPromises);
        //console.log("$$$$$ schools api result", promisesResult);
        fetchSchoolsList(callApi, (data) => {
          if (data) {
            dispatch(
              updateCategoriesReducer(
                ADD_SCHOOLS_DATA,
                sortData(data, "asc", "name")
              )
            );
          }
        });
        const _cleanedValues = values.educations.map(cleanUpValues);
        if (stepsCompleted === 1) values.stepsCompleted = 2;
        onSubmit({ ...values, educations: _cleanedValues });
      } else {
        const _cleanedValues = values.educations.map(cleanUpValues);
        if (stepsCompleted === 1) values.stepsCompleted = 2;
        onSubmit({ ...values, educations: _cleanedValues });
      }
    },
  });

  const updateCompletedDate = (value, index) => {
    formik.setFieldTouched(`educations[${index}].completedAt`);
    if (!value) {
      formik.setFieldValue(`educations[${index}].completedAt`, "");
    } else {
      formik.setFieldValue(
        `educations[${index}].completedAt`,
        moment(value).format("MM/DD/YYYY")
      );
    }
  };

  const updateSchoolValue = (schoolName, index) => {
    formik.setFieldTouched(`educations[${index}].university`);
    formik.setFieldValue(`educations[${index}].university`, schoolName);
  };

  const isStateWithOutBarNumber = (state) => {
    let statesWithoutBarNumber = ["Iowa", "Maryland", "American Samoa", "New York", "Guam", "Virgin Islands"];
    return statesWithoutBarNumber.includes(state);
  };

  const { getFieldProps } = formik;
  const validateAndSubmit = async (values) => {
    const newSchools = values.educations.filter((education) => {
      const isExistingSchool = schoolsData.find(
        (school) => school.name === education.university
      );
      return !isExistingSchool;
    });
    if (newSchools.length) {
      const schoolsPromises = newSchools.map(
        async (school) =>
          await addSchool(callApi, { name: school.university })
      );
      const promisesResult = await Promise.all(schoolsPromises);
      //console.log("$$$$$ schools api result", promisesResult);
      fetchSchoolsList(callApi, (data) => {
        if (data) {
          dispatch(
            updateCategoriesReducer(
              ADD_SCHOOLS_DATA,
              sortData(data, "asc", "name")
            )
          );
        }
      });
      const _cleanedValues = values.educations.map(cleanUpValues);
      if (stepsCompleted === 1) values.stepsCompleted = 2;
      onSubmit({ ...values, educations: _cleanedValues }, true);
    } else {
      const _cleanedValues = values.educations.map(cleanUpValues);
      if (stepsCompleted === 1) values.stepsCompleted = 2;
      onSubmit({ ...values, educations: _cleanedValues }, true);
    }
  };
  const saveStep = () => {
    validateAndSubmit(formik.values);
  };
  return (
    <div className="mb-12">
      <EditProfileSteps
        step={step}
        stepsCompleted={user?.stepsCompleted}
        setStep={setStep}
        stepData={stepDataNewScope}
        saveStep={saveStep}
      />
      <div className="popup-modal">
        <div className="flex-wrap ">
          <div className=" py-6">
            <StepLabel label="Education" />
            {/* Form */}
            <form onSubmit={formik.handleSubmit}>
              {formik.values.educations.map((education, index) => {
                return (
                  <div key={index} className="space-y-6 profile-form mb-8">
                    <div className="flex items-start input-wrap justify-between gap-3 sm:gap-0">
                      <div className="w-[100%]">
                        <div className="flex justify-between">
                          <label
                            htmlFor="firstName"
                            className="block mb-2 text-[14px] font-[500] text-textColor"
                          >
                            School
                          </label>
                          {formik.values.educations.length > 1 && (
                            <RemoveIcon
                              onClick={() =>
                                removeItemNewScope(
                                  index,
                                  "educations",
                                  formik,
                                  educationObject
                                )
                              }
                            />
                          )}
                        </div>
                        <SchoolsAutocomplete
                          options={schoolsData || []}
                          value={formik.values.educations[index].university}
                          setValue={(schoolName) =>
                            updateSchoolValue(schoolName, index)
                          }
                          isLoading={isLoading}
                        />
                        <Error
                          formik={formik}
                          errorField="university"
                          property="educations"
                          index={index}
                        />
                      </div>
                    </div>
                    <div className="flex items-start input-wrap justify-between gap-3 sm:gap-0">
                      <div className="w-[100%]">
                        <label
                          htmlFor="firstName"
                          className="block mb-2 text-[14px] font-[500] text-textColor"
                        >
                          Degree
                        </label>
                        <input
                          type="text"
                          name="degree"
                          id="degree"
                          className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                          placeholder="Degree"
                          {...getFieldProps(`educations[${index}.degree]`)}
                        />
                        <Error
                          formik={formik}
                          errorField="degree"
                          property="educations"
                          index={index}
                        />
                      </div>
                      <div className="w-[100%] sm:pl-3 ">
                        <label
                          htmlFor="lastName"
                          className="block mb-2 text-[14px] font-[500] text-textColor"
                        >
                          Completed
                        </label>
                        <DatePicker
                          name="completedAt"
                          id="completedAt"
                          size="large"
                          views={["year"]}
                          openTo="year"
                          format="yyyy"
                          placeholder="YYYY"
                          value={education.completedAt}
                          onChange={(value) =>
                            updateCompletedDate(value, index)
                          }
                        />
                        <div className="sm:mt-[1.35rem]">
                          <Error
                            formik={formik}
                            errorField="completedAt"
                            property="educations"
                            index={index}
                          />
                        </div>
                      </div>
                    </div>
                    <MultipleValues
                      label="Activities & Honors"
                      onChange={(value) => {
                        formik.setFieldTouched(
                          `educations[${index}].certificates`,
                          true
                        );
                        formik.setFieldValue(
                          `educations[${index}].certificates`,
                          value
                        );
                      }}
                      value={education.certificates}
                      options={education.certificates}
                    />
                    <Error
                      formik={formik}
                      errorField="certificates"
                      property="educations"
                      index={index}
                    />
                  </div>
                );
              })}
              <AddFieldButton
                buttonText="Add Education"
                onClick={() =>
                  addItemNewScope("educations", formik, educationObject)
                }
              />
              <StepLabel label="Licenses" />
              {formik.values.licenses.map((_, i) => (
                <div className="space-y-6 profile-form mb-8 relative" key={i}>
                  {formik.values.licenses.length > 1 && (
                    <div className="absolute top-0 right-0">
                      <RemoveIcon
                        onClick={() =>
                          removeItemNewScope(i, "licenses", formik)
                        }
                      />
                    </div>
                  )}
                  <div className="flex input-wrap justify-between">
                    <div className="w-[100%] ">
                      <label
                        htmlFor="state"
                        className="block mb-2 text-[16px] font-[500] text-textColor"
                      >
                        State/Territory
                      </label>
                      <RenderStates
                        variant="outlined"
                        borderRadius="6px"
                        stateId={formik.values.licenses?.[`${i}`]?.state}
                        onChange={(value) => {
                          const state = states.find((state) => state._id === value);
                          const isReadOnly = isStateWithOutBarNumber(state?.name);
                          formik.setFieldValue(`licenses[${i}].barNumberReadOnly`, isReadOnly);
                          formik.setFieldTouched(`licenses[${i}].state`, value);
                          formik.setFieldValue(`licenses[${i}].state`, value);
                        }}
                      />
                      {/* </div> */}
                      <Error
                        formik={formik}
                        errorField="state"
                        property="licenses"
                        index={i}
                      />
                    </div>
                    <div className="w-[100%] pl-3 padding-input padding-input-t">
                      <label className="block mb-2 text-[16px] font-[500] text-textColor">
                        Bar/License Number
                      </label>

                      <input
                        type="text"
                        name="licenseNumber"
                        id="licenseNumber"
                        className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[57px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                        placeholder={formik.values.licenses?.[`${i}`]?.barNumberReadOnly? "State/Territory does not use bar number": "Bar/License Number"}
                        readOnly={formik.values.licenses?.[`${i}`]?.barNumberReadOnly}
                        {...getFieldProps(`licenses[${i}].licenseNumber`)}
                      />
                      <Error
                        formik={formik}
                        errorField="licenseNumber"
                        property="licenses"
                        index={i}
                      />
                    </div>
                  </div>
                </div>
              ))}
              <AddFieldButton
                buttonText="Add License"
                onClick={() =>
                  addItemNewScope("licenses", formik, licenseObjectNewScope)
                }
              />
            </form>
          </div>
        </div>
      </div>
      <EditProfileFooter
        pre={() => setStep(PERSONAL_INFO)}
        step={step}
        next={() => {
          formik.handleSubmit();
        }}
      />
    </div>
  );
};

export default EducationNewScope;
