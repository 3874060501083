import React from "react";

import client1 from "assets/user.svg";

const ConsumerProfile = () => {
  return (
    <div>
      <div className="w-[100%] pt-5 flex justify-evenly flex-row border border-borderColor rounded-md">
        <div className="w-[17%] pb-[26px]">
          <img className="rounded-md" src={client1} alt="Client" />
        </div>
        <div className="w-[70%]">
          <div className="flex">
            <label
              htmlFor="heading"
              className="block mb-2 text-xl  font-bold text-secondary"
            >
              Herry Mason
            </label>
            <a href="#">
              <label
                htmlFor="Consumer"
                className="block cursor-pointer mt-1 pl-3 text-sm font-semibold text-primary hover:text-[#00a4dabe]"
              >
                Edit
              </label>
            </a>
          </div>

          <div className="flex">
            <i className="text-[#979797] mt-1 fa-solid pr-2 fa-location-dot"></i>
            <label
              htmlFor="heading"
              className="block mb-2 text-[16px] font-medium text-[#979797]"
            >
              3401 110th st Inglewood, CA 90303-2225, USA
            </label>
          </div>
          <div className="flex justify-between w-80">
            <div className="">
              <label
                htmlFor="heading"
                className="block mb-2 text-[16px]  font-semibold text-secondary"
              >
                Phone Number
              </label>
              <label
                htmlFor="heading"
                className="block mb-2 text-[16px]  font-medium text-[#5A5A5A]"
              >
                (208) 555-0112
              </label>
            </div>
            <div className="">
              <label
                htmlFor="heading"
                className="block mb-2 text-[16px]  font-semibold text-secondary"
              >
                Phone Number
              </label>
              <label
                htmlFor="heading"
                className="block mb-2 text-[16px]  font-medium text-[#5A5A5A]"
              >
                (208) 555-0112
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsumerProfile;
