import React from "react";
import RemoveIcon from "components/removeIcon/RemoveIcon";
import { removeDynamicFields } from "utils/config";

const DynamicFieldsHeader = ({ title, index, callBack, length }) => {
  return (
    <div className="flex justify-between items-center">
      <h3 className="mb-4 text-[20px] font-[600] flex-wrap text-secondary ">
        {title}
      </h3>
      {removeDynamicFields && length > 1 && (
        <RemoveIcon onClick={() => callBack(index)} />
      )}
    </div>
  );
};

export default DynamicFieldsHeader;
