import React, { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Close, Search as SearchIcon } from "@mui/icons-material";

export default function CollapsibleSearchInput({ searchText, setSearchText }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchInput, setSearchInput] = useState(searchText);

  const handleSearch = () => {
    setSearchText(searchInput);
  };

  function handleExpandClick() {
    setIsExpanded(!isExpanded);
    setSearchText("");
    setSearchInput("");
  }

  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return !isExpanded ? (
    <IconButton onClick={handleExpandClick} size="large">
      <SearchIcon />
    </IconButton>
  ) : (
    <TextField
      placeholder="Search"
      size="small"
      value={searchInput}
      onChange={handleInputChange}
      onKeyDown={handleKeyPress}
      variant="outlined"
      focused={isExpanded}
      sx={{
        width: isExpanded ? "35vw" : "40px",
        transition: "width 0.3s ease",
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={handleSearch} size="large">
              <SearchIcon />
            </IconButton>
            <IconButton onClick={handleExpandClick} size="large">
              <Close />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
