import React, { useEffect } from "react";
import { Button, Stack, TextField } from '@mui/material';
import { useFormik } from "formik";
import { useAxios } from "utils/hooks/useAxios";
import { useDispatch } from "react-redux";
import { sendNewPracticeAreaEmail } from "services/sendNewPracticeArea";
import * as Yup from "yup";

const NewPracticeAreaDialog = ({
    email,
    onClose,
}) => {
    const { callApi } = useAxios();
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (!email) {
            console.warn("No user email provided");
        }
      }, [email]);

    const newPracticeAreaForm = useFormik({
        initialValues: {
            practiceArea: "",
        },
        validationSchema: Yup.object().shape({
            practiceArea: Yup.string().required().label("New Practice Area"),
        }),
        onSubmit: async (values) => {
            sendNewPracticeAreaEmail(callApi, values, (res) => {
                if (res.success) {
                    setShowSuccessMessage(true);
                }
                else {
                    console.error(res.error);
                }
                handleClose();
            });
        },
    });

    const handleClose = () => {
        if (onClose) {
            onClose();
        } else {
            dispatch(closeCurrentModal());
        }
    };

    return (
        <div className="fixed bg-[#a8a0a059] top-0 right-0 left-0  w-[100%] h-[100%] py-2 z-50">
            <div className="w-[100%] h-[100%] flex justify-center items-center overflow-auto">
                <div>
                    <div className="relative bg-[#fff] w-[1026px] md:w-[600px] overflow-auto rounded-[20px] shadow dark:bg-gray-700 p-[1rem]">
                        <form onSubmit={newPracticeAreaForm.handleSubmit}>
                            <div className="px-6 lg:px-8">
                                <h3 className="mb-4 text-[20px] font-[600] text-secondary ">
                                    New Practice Area
                                </h3>
                                <div>
                                <label
                                    htmlFor="practiceArea"
                                    className="block mb-2 text-[14px] font-[500] text-textColor"
                                >
                                    Suggest a new practice area and our staff will get back to you promptly.
                                </label>
                                </div>
                                <div className="w-[100%] py-2">
                                    <TextField
                                        autoFocus
                                        required
                                        id="practiceArea"
                                        name="practiceArea"
                                        label="New Practice Area"
                                        type="text"
                                        fullWidth
                                        value={newPracticeAreaForm.values.practiceArea}
                                        onChange={newPracticeAreaForm.handleChange}
                                        onBlur={newPracticeAreaForm.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="w-[100%] py-2">
                            <Stack direction="row" justifyContent="end" gap={1}>
                                <Button onClick={handleClose}>Cancel</Button>
                                <Button onClick={newPracticeAreaForm.handleSubmit}>Save</Button>
                            </Stack>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewPracticeAreaDialog;