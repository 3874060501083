import React, { useLayoutEffect } from "react";
import BreadCrumb from "components/Dashboard/Bredcrums/BreadCrum";
import { Box, Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { notify } from "utils/toast/toast";
import ProviderDetailsNewScope from "components/providerDetailNewScope/ProviderDetailsNewScope";
import ConsumerDetails from "components/Individualconsumer/ConsumerDetails";
import { OPEN_SUBSCRIPTION_MODAL } from "../utils/constants/constants";
import { openModal } from "../store/actions/modalActions";

export const myProfileBreadCrumbsNewScope = {
  PROVIDER: {
    url: "/profile",
    label: "My Profile",
  },
  INDIVIDUAL: {
    url: "/profile",
    label: "Consumer",
    userType: "INDIVIDUAL",
  },
  BUSINESS: {
    url: "/profile",
    label: "Business Consumer",
    userType: "BUSINESS",
  },
};

const ProfileNewScope = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    if (user?.userType === "PROVIDER") {
      const userHaveNotSubscription = !user?.subscription && user?.userType === "PROVIDER";
      if (user.stepsCompleted < 6 || !user.stepsCompleted) {
        navigate("/profile/edit");
        notify("please complete your profile", "warning");
      } else if (userHaveNotSubscription) { // open subscription modal once user has completed their profile
        notify("please subscribe", "warning");
        dispatch(openModal(OPEN_SUBSCRIPTION_MODAL));
      }
    }
  }, [user]);
  return (
    <Box width="100%">
      <Container sx={{ pt: "50px", maxWidth: { md: "95%" } }}>
        <BreadCrumb
          path={myProfileBreadCrumbsNewScope[user.userType].url}
          label={myProfileBreadCrumbsNewScope[user.userType].label}
        />
      </Container>
      {user.userType === "PROVIDER" ? (
        <ProviderDetailsNewScope user={user} editAble={true} isMyProfilePage />
      ) : (
        <div className="flex w-[92%] mx-auto h-[70vh]">
          <div className="w-full">
            <label
              htmlFor="Contact Person"
              className="block py-2 text-xl font-bold text-secondary"
            >
              Contact Person
            </label>
            <ConsumerDetails />
          </div>
        </div>
      )}
    </Box>
  );
};

export default ProfileNewScope;
