import React from "react";

const ExternalLink = ({ children, ...props }) => {
  return (
    <a className="text-blue-500 hover:underline" {...props}>
      {children}
    </a>
  );
};

export default ExternalLink;
