import { useGetBooking, useCancelBooking } from "@calcom/atoms";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useAxios } from "utils/hooks/useAxios";
import { deleteAppointment } from "services/deleteAppointment";
import { notify } from "utils/toast/toast";
import { Link } from 'react-router-dom'

const BookingManager = ({
    bookingId,
    eventSlug,
    calUsername
  }) => {
  const { isLoading, data: booking, refetch } = useGetBooking(bookingId ?? "");
  const { isLoading: deleteAppointmentLoading, callApi: deleteAppointmentApi } = useAxios()
  const navigate = useNavigate();
  const startTime = dayjs(booking?.startTime).format(12 === 12 ? "h:mma" : "HH:mm");
  const endTime = dayjs(booking?.endTime).format(12 === 12 ? "h:mma" : "HH:mm");
  const date = dayjs(booking?.startTime).toDate();
  const dateToday = dayjs(booking?.startTime).date();
  const year = dayjs(booking?.startTime).year();
  const day = dayjs(date).format("dddd");
  const month = dayjs(date).format("MMMM");
  const { mutate: cancelBooking } = useCancelBooking({
    onSuccess: () => {
      refetch();
    },
  });

  const deleteBooking = () => {
    cancelBooking({
      id: booking.id,
      uid: booking.uid,
      cancellationReason: "User request",
      allRemainingBookings: true,
    });
    deleteAppointment(deleteAppointmentApi, bookingId, (data, error) => {
      if (data) {
        notify("Appointment successfully cancelled", "success");
      } else if (error) {
        console.log("Appointment cancel error = ", error);
      }
    });
  };

  return (
    <main
      className={`flex min-h-screen flex-col main text-default flex min-h-full w-full flex-col items-center overflow-visible`}>
      {isLoading && <p>Loading...</p>}
      {!isLoading && booking && (
        <div
          key={booking.id}
          className="my-5 w-100% overflow-hidden rounded-md border-[1px] border-[#D9D9D9] px-10 py-5">
          {booking.status === "ACCEPTED" ? (
            <div className="mx-2 my-4 flex flex-col items-center justify-center text-center">
              <h1 className="text-[#0d2959] text-xl font-bold">This meeting is scheduled</h1>
              <p>We sent an email with a calendar invitation with the details to everyone.</p>
            </div>
          ) : (
            <div className="mx-2 my-4 flex flex-col items-center justify-center text-center">
              <h4 className="text-2xl font-bold">This event is cancelled</h4>
            </div>
          )}
          <hr className="mx-2 bg-black text-black" />
          <div className="mx-2 my-7 flex flex-col gap-y-3">
            <div className="flex gap-[73px]">
              <div>
                <h4 className="font-bold">What</h4>
              </div>
              <div>
                <p>{booking.title}</p>
              </div>
            </div>
            <div className="flex gap-[70px]">
              <div>
                <h4 className="font-bold">When</h4>
              </div>
              <div>
                <div>
                  <p
                    style={{
                      textDecoration: booking.status === "ACCEPTED" ? "normal" : "line-through",
                    }}>
                    {`${day}, ${month} ${dateToday}, ${year}`}
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      textDecoration: booking.status === "ACCEPTED" ? "normal" : "line-through",
                    }}>
                    {`${startTime}`} - {`${endTime}`}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex gap-[80px]">
              <div className="font-bold">Who</div>
              <div>
                <div>
                  <h4>
                    {booking.user?.name}{" "}
                    <span className="rounded-md bg-blue-800 px-2 text-sm text-white">Host</span>
                  </h4>
                  {booking.attendees.map((attendee, i) => {
                    return (
                      <div key={`${i}-${attendee.name}`}>
                        <div>
                          <h4>{`${attendee.name}`}</h4>
                        </div>
                      </div>
                    );
                  })}
                </div>
                
              </div>
            </div>
            {!!booking.metadata.videoCallUrl && (
              <div className="flex gap-[65px]">
                <div className="font-bold">Where</div>
                <Link className="text-[#64B4D0]" to={booking.metadata.videoCallUrl}>Cal Video</Link>
              </div>
            )}
            {/* {!!booking.location && booking.location.startsWith("http") && (
              <div className="flex gap-[70px]">
                <div>
                  <h4>Where</h4>
                </div>
                <div>
                  <p>{booking.location}</p>
                </div>
              </div>
            )} */}
            {booking.responses.notes && (
              <div className="flex gap-[70px]">
                <div className="w-[40px]">
                  <h4>Additional notes</h4>
                </div>
                <div>
                  <p>{`${booking.responses.notes}`}</p>
                </div>
              </div>
            )}
          </div>

          {booking.status === "ACCEPTED" && (
            <>
              <hr className="mx-3" />
              <div className="mx-2 my-3 text-center">
                <p>
                  Need to make a change?{" "}
                  <button
                    className="underline"
                    onClick={() => {
                        navigate(`/bookings/reschedule/${booking?.uid}?slug=${eventSlug}&username=${calUsername}&reschedule=true`)
                    }}>
                    Reschedule
                  </button>{" "}
                  or{" "}
                  <button
                    className="underline"
                    onClick={deleteBooking}>
                    Cancel
                  </button>
                </p>
              </div>
            </>
          )}
        </div>
      )}
    </main>
  );
};

export default BookingManager;
