export const searchByList = [
  {
    name: "Practice Area",
    _id: "PRACTICE_AREA",
  },
  {
    name: "Name",
    _id: "NAME",
  },
  {
    name: "School",
    _id: "SCHOOL",
  },
  {
    name: "Association",
    _id: "ASSOCIATION",
  },
];
