import { routes } from "utils/appConfig";

export const deleteAppointment = async (callApi, appointmentId, callBack) => {
  const { url, method } = routes.calAtoms.deleteAppointment;
  try {
    callApi(
      url(appointmentId),
      method,
      {},
      {
        onSuccess: ({ data }) => callBack(data),
        onError: (res) => callBack(null, res),
        successMessage: "Appointment Deleted successfully!",
      }
    );
  } catch (e) {
    throw new Error(e.response);
  }
};