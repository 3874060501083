import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import defaultImg from "assets/user.svg";
import { Link } from "react-router-dom";
import { PERSONAL_INFO } from "components/PremiumProviderEditProfileComponents/constants/editProfileConstants";
import { formatPhone } from "utils/helperFunctions/helper";

export default function ComplexGrid({ user }) {
  return (
    <Paper
      sx={{
        p: "24px",
        pr: "24px",
        pt: 4,
        pb: 7,
        marginTop: "40px",
        margin: "auto",
        height: "auto",
        maxWidth: "100%",
        borderRadius: "20px",
        boxShadow: "none",
        border: "1px solid #C6C7C4",
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      }}
    >
      <Grid container gap={2}>
        <Grid item xs={2}>
          <Box sx={{ maxWidth: "200px" }}>
            <img
              src={user?.profilePicture ?? defaultImg}
              className="rounded-[10px] object-contain mx-auto"
              alt=""
            />
          </Box>
        </Grid>
        <Grid item xs={9} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <div className="flex">
                <Typography
                  sx={{
                    color: "#002A5C",
                    fontSize: 22,
                    fontWeight: 700,
                  }}
                  gutterBottom
                >
                  {user?.firstName} {user?.lastName}
                </Typography>
                {/* <Rating /> */}
                <Typography
                  sx={{
                    color: "#00A4DA",
                    fontSize: 16,
                    fontWeight: 600,
                    textAlign: "center",
                    pl: 1,
                    mt: { md: 1 },
                  }}
                  gutterBottom
                >
                  <Link to="/profile/edit" state={{ goToStep: PERSONAL_INFO }}>
                    Edit
                  </Link>
                </Typography>
              </div>
              <Typography
                sx={{
                  color: "#5A5A5A",
                  fontSize: 16,
                  fontWeight: 500,
                  marginBottom: "16px",
                }}
                gutterBottom
              >
                Manager
              </Typography>
              <div className="flex gap-2 sm:gap-8 flex-wrap">
                <div className="">
                  <Typography
                    sx={{
                      color: "#002A5C",
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                    gutterBottom
                  >
                    Phone Number
                  </Typography>
                  <Typography
                    sx={{ color: "#5A5A5A", fontSize: 16, fontWeight: 500 }}
                    gutterBottom
                  >
                    {formatPhone(user?.phone)}
                  </Typography>
                </div>
                <div className="">
                  <Typography
                    sx={{
                      color: "#002A5C",
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                    gutterBottom
                  >
                    Email
                  </Typography>
                  <Typography
                    sx={{ color: "#5A5A5A", fontSize: 16, fontWeight: 500 }}
                    gutterBottom
                  >
                    {user?.email}
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
