import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Loader from "utils/loader/Loader";

const AdminActionMenu = ({ options, onMenuClick, disabled, isLoading }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option) => {
    onMenuClick(option);
    setAnchorEl(null);
  };

  return (
    <div className="relative">
      <IconButton onClick={handleClick} size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ marginLeft: "-30px" }}
      >
        {!disabled ? (
          options.map(({ value, label }) => (
            <MenuItem
              key={value}
              onClick={() => handleClose(value)}
              sx={{
                width: "170px",
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              {label}
            </MenuItem>
          ))
        ) : (
          <MenuItem
            onClick={() => handleClose("enableUser")}
            // disabled={disabled}
            sx={{
              width: "170px",
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {isLoading ? <Loader /> : "Enable User"}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default AdminActionMenu;
