import { routes } from "utils/appConfig";

export const addSchool = (callApi, data, cb) => {
  const { url, method } = routes.createSchool;
  try {
    return callApi(url, method, data, {
      onSuccess: ({ data }) => {
        cb && cb(data);
        return data;
      },
      successMessage: "School added successfully!",
      onError: (error) => {
        cb && cb(null, error);
        return error;
      },
    });
  } catch (e) {
    console.log("🚀~ addSchool ~ e:", e);
    throw new Error();
  }
};
