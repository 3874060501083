import React from "react";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-center bg-secondary items-center bg-slate-700 h-screen font-bold">
      <h1 className="text-8xl text-white">404</h1>
      <button
        className="mt-8 bg-[#00A4DA] py-2 px-8 text-white"
        onClick={() => navigate("/")}
      >
        HOME
      </button>
    </div>
  );
};

export default ErrorPage;
