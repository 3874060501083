import React from "react";
import Button from "../../button/Button";
import { useFormik } from "formik";
import Error from "../../error/Error";
import { checkValidationError, otpValidation } from "../helper";
import { closeCurrentModal } from "store/actions/modalActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const OTPModal = ({ onClose, onSubmit, loader }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: { verificationToken: "" },
    validationSchema: otpValidation,
    onSubmit: (values) => {
      onSubmit(values.verificationToken);
    },
  });
  const handleClose = () => {
    if (onClose) {
      onClose();
      return;
    }
    dispatch(closeCurrentModal());
  };
  return (
    <div className="relative w-[326px] md:w-[544px] bg-[#fff] rounded-lg shadow dark:bg-gray-700 rounded-[20px] space-y-4 px-[2rem]">
      <button
        type="button"
        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex  dark:hover:text-primary text-textColor"
        data-modal-toggle="authentication-modal"
      >
        <svg
          onClick={handleClose}
          aria-hidden="true"
          className="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
        <span className="sr-only">Close modal</span>
      </button>
      <div className="px-6 py-16 lg:px-8 ">
        <h3 className="mb-1 text-secondary text-[22px] font-[700] text-center">
          Enter Verification Code (OTP)
        </h3>
        <label className="block mb-6 text-[16px] font-[400] text-gray-900 text-textColor  text-center">
          Please enter OTP you have received in your email.
        </label>
        <form className="space-y-6" onSubmit={formik.handleSubmit}>
          <div>
            <input
              type="text"
              name="verificationToken"
              id="verificationToken"
              className="rounded-sm w-full border  border-borderColor bg-white px-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none text-center"
              placeholder="00  -  00  -  00"
              onChange={formik.handleChange}
              value={formik.values.verificationToken}
              onBlur={formik.handleBlur}
            />
            <Error formik={formik} errorField="verificationToken" />
          </div>

          <Button
            className="h-[50px] text-[16px] font-[600]"
            type="submit"
            loader={loader}
            disabled={loader || checkValidationError(formik)}
          >
            Verify
          </Button>
        </form>
      </div>
    </div>
  );
};

export default OTPModal;
