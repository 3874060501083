import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import { Stack, TextField } from "@mui/material";
import { useAxios } from "utils/hooks/useAxios";
import { adminPanelUserUpdate } from "services/adminPanelUserUpdate";
import { notify } from "utils/toast/toast";
import Loader from "utils/loader/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

export default function AdminUserInfoUpdateModal({
  user,
  open,
  setOpen,
  setSelectedUser,
  setUsers,
}) {
  const { isLoading, callApi } = useAxios();

  const handleClose = () => {
    setSelectedUser(null);
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      bookafyId: "",
      bookafySlug: "",
    },
    onSubmit: (values) => {
      values.email = values.email.toLowerCase();
      adminPanelUserUpdate(callApi, user._id, values, (res, error) => {
        if (res.success) {
          setUsers((pre) => {
            let index;
            const updatedUser = pre.find((provider, i) => {
              if (provider._id === user._id) {
                index = i;
                return provider;
              }
            });
            updatedUser.email = values.email;
            pre.splice(index, 1, updatedUser);
            return pre;
          });
          setSelectedUser(null);
          setOpen(false);
        } else {
          notify("Something went wrong!", "error");
          console.log("AdminUserInfoUpdateModal ~ error~: ", error);
        }
      });
    },
  });

  useEffect(() => {
    if (user) {
      formik.setValues({
        email: user?.email ?? "",
        bookafyId: user?.bookafyId ?? "",
        bookafySlug: user?.bookafySlug ?? "",
      });
    }
  }, [user]);

  return (
    <div className="relative">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={formik.handleSubmit}>
            <Stack gap={2}>
              <TextField
                id="email"
                name="email"
                label="Email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              {user?.userType === "PROVIDER" && (
                <TextField
                  id="bookafyId"
                  name="bookafyId"
                  label="Bookafy ID"
                  value={formik.values.bookafyId}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.bookafyId && Boolean(formik.errors.bookafyId)
                  }
                  helperText={
                    formik.touched.bookafyId && formik.errors.bookafyId
                  }
                />
              )}
              {user?.userType === "PROVIDER" && (
                <TextField
                  id="bookafySlug"
                  name="bookafySlug"
                  label="Bookafy Slug"
                  value={formik.values.bookafySlug}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.bookafySlug &&
                    Boolean(formik.errors.bookafySlug)
                  }
                  helperText={
                    formik.touched.bookafySlug && formik.errors.bookafySlug
                  }
                />
              )}
              <Stack direction="row" width="100%" justifyContent="end">
                <Box>
                  <Button onClick={handleClose}>cancel</Button>
                  <Button type="submit">
                    {isLoading ? <Loader /> : "Update"}
                  </Button>
                </Box>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
