import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  checkValidationError,
  PasswordVisibility,
  signUpValidationSchema,
} from "components/modals/helper";
import Error from "components/error/Error";
import Button from "components/button/Button";
import { closeCurrentModal, openSignInModal } from "store/actions/modalActions";
import { removeExtraSpaces } from "utils/helperFunctions/helper";
import { ClickAwayListener, Stack } from "@mui/material";
import PhoneInput from "components/phoneInput/PhoneInput";
import ReactGA from "react-ga4";

const SignUpModal = ({ onSubmit, loader }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const currentTab = useSelector((state) => state.modal.currentTab);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
      userType: currentTab ? "PROVIDER" : "INDIVIDUAL",
      postalCode: "",
    },
    validationSchema: signUpValidationSchema,
    onSubmit: (values) => {
      // send sign up submit form event to google analytics
      ReactGA.event({
        category: "submit form",
        action: "Juricon_Sign_Up_Submit",
        label: currentTab ? "PROVIDER" : "INDIVIDUAL",
      });

      values.firstName = removeExtraSpaces(values.firstName);
      values.lastName = removeExtraSpaces(values.lastName);
      values.email = values.email.toLowerCase();
      onSubmit(values);
    },
  });
  const handlePhoneChange = (value) => {
    formik.setFieldTouched("phone", true);
    formik.setFieldValue("phone", value);
  };
  return (
    <ClickAwayListener onClickAway={() => dispatch(closeCurrentModal())}>
      <div className="relative bg-[#fff] w-[852px] h-auto m-7 rounded-[20px] shadow overflow-hidden">
        <div className="flex w-[100%] justify-center items-center mb-3 ">
          <div
            className={`w-[100%] min-h-[64px] flex justify-center items-center cursor-pointer text-center ${
              formik.values.userType !== "PROVIDER"
                ? "bg-primary text-white "
                : "bg-[#F3F3F3] text-[#C6C7C4]"
            }`}
            onClick={() => formik.setFieldValue("userType", "INDIVIDUAL")}
          >
            Looking for Legal Services
          </div>
          <div
            className={`w-[100%] min-h-[64px] flex justify-center items-center cursor-pointer ${
              formik.values.userType === "PROVIDER"
                ? "bg-primary text-white"
                : "bg-[#F3F3F3] text-[#C6C7C4]"
            }`}
            onClick={() => formik.setFieldValue("userType", "PROVIDER")}
          >
            I am Provider
          </div>
        </div>
        <div className="md:px-[2rem]">
          <div className="flex justify-between items-center p-1.5 ">
            <label className="block mb-2 ml-[1.5rem] text-[16px] font-[400] text-textColor">
              Welcome!
            </label>
          </div>
          <div className="px-6 pb-6 lg:px-8 ">
            <h3 className="mb-4 text-[22px] font-[700] text-secondary">
              {formik.values.userType !== "PROVIDER"
                ? "Signing Up as"
                : "Sign Up "}
            </h3>
            <form className="space-y-[0.6rem]" onSubmit={formik.handleSubmit}>
              {formik.values.userType !== "PROVIDER" && (
                <div className="flex w-[100%]">
                  <button
                    type="button"
                    className={`h-12 w-[100%] rounded-l-xs ${
                      formik.values.userType === "INDIVIDUAL"
                        ? "bg-[#DAF8FF] text-primary"
                        : "bg-[#F3F3F3] text-[#C6C7C4]"
                    }`}
                    onClick={() =>
                      formik.setFieldValue("userType", "INDIVIDUAL")
                    }
                  >
                    Individual
                  </button>
                  <button
                    type="button"
                    className={`h-12 w-[100%] rounded-l-xs ${
                      formik.values.userType === "BUSINESS"
                        ? "bg-[#DAF8FF] text-primary"
                        : "bg-[#F3F3F3] text-[#C6C7C4]"
                    }`}
                    onClick={() => formik.setFieldValue("userType", "BUSINESS")}
                  >
                    Business
                  </button>
                </div>
              )}
              <div className="flex flex-col md:flex-row w-full gap-4">
                <div className="md:w-[50%]">
                  <label
                    htmlFor="firstName"
                    className="block mb-2 text-[14px] font-[500] text-textColor"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    className="  rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                    placeholder="Jane"
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                    onBlur={formik.handleBlur}
                  />
                  <Error formik={formik} errorField="firstName" />
                </div>
                <div className="md:w-[50%] ">
                  <label
                    htmlFor="lastName"
                    className="block mb-2 text-[14px] font-[500] text-textColor"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    className="  rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                    placeholder="Lawyer"
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                    onBlur={formik.handleBlur}
                  />
                  <Error formik={formik} errorField="lastName" />
                </div>
              </div>

              <div className="flex flex-col md:flex-row gap-4 -pt-2">
                <div className="md:w-[50%]">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-[14px] font-[500] text-textColor"
                  >
                    Email
                  </label>

                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="  rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                    placeholder="name@email.com"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                  />
                  <Error formik={formik} errorField="email" />
                </div>
                <div className="md:w-[50%]">
                  <label
                    htmlFor="number"
                    className="block mb-2 text-[14px] font-[500] text-textColor"
                  >
                    Phone
                  </label>
                  <PhoneInput
                    defaultCountry="US"
                    name="phone"
                    id="phone"
                    className="block w-full text-lg py-5 bg-transparent outline-none transition duration-300 ease-in-out hover:border-slate-500 focus-within:border-slate-500 "
                    value={formik.values.phone}
                    onChange={handlePhoneChange}
                    onBlur={formik.handleBlur}
                    placeholder="Phone Number"
                  />
                  <Error formik={formik} errorField="phone" />
                </div>
              </div>
              <div className="md:w-[100%]">
                <label
                  htmlFor="number"
                  className="block mb-2 text-[14px] font-[500] text-textColor"
                >
                  Zip Code
                </label>
                <input
                  type="text"
                  name="postalCode"
                  id="postalCode"
                  className="  rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                  placeholder="12345"
                  onChange={formik.handleChange}
                  value={formik.values.postalCode}
                  onBlur={formik.handleBlur}
                />
                <Error formik={formik} errorField="postalCode" />
              </div>

              <div className="flex flex-col md:flex-row gap-4 justify-between mb-[24px]">
                <div className="md:w-[50%]">
                  <label
                    htmlFor="password"
                    className="block mb-2 text-[14px] font-[500] text-textColor "
                  >
                    Password
                  </label>
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      placeholder="••••••••"
                      className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      onBlur={formik.handleBlur}
                    />
                    <PasswordVisibility
                      showPassword={showPassword}
                      setShowPassword={setShowPassword}
                      className="top-4 right-3"
                    />
                  </div>
                  <Error formik={formik} errorField="password" />
                </div>
                <div className="md:w-[50%]">
                  <label
                    htmlFor="confirmPassword"
                    className="block mb-2 text-[14px] font-[500] text-textColor "
                  >
                    Confirm Password
                  </label>
                  <div className="relative">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder="••••••••"
                      className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                      onChange={formik.handleChange}
                      value={formik.values.confirmPassword}
                      onBlur={formik.handleBlur}
                    />
                    <PasswordVisibility
                      showPassword={showConfirmPassword}
                      setShowPassword={setShowConfirmPassword}
                      className="top-4 right-3"
                    />
                  </div>
                  <Error formik={formik} errorField="confirmPassword" />
                </div>
              </div>
              <Stack direction="row" gap={2}>
                <Button
                  type="button"
                  onClick={() => dispatch(closeCurrentModal())}
                  className="bg-[#F3F3F3] text-[#C6C7C4] hover:bg-hoverColor hover:text-white h-[50px] text-[16px] font-[600]"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  loader={loader}
                  disabled={loader || checkValidationError(formik)}
                  className="bg-primary hover:bg-hoverColor h-[50px] text-[16px] font-[600]"
                >
                  Sign Up
                </Button>
              </Stack>
              <div className="flex items-center text-[14px] font-[400] text-gray-500 dark:text-gray-300 justify-center text-textColor text-center">
                Already have an account?
                <p
                  onClick={() => dispatch(openSignInModal())}
                  className=" hover:underline dark:text-blue-500 w-fit text-center text-primary hover:text-hoverColor cursor-pointer pl-2"
                >
                  Sign In
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default SignUpModal;
