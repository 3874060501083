import React from "react";
import Card1 from "./Card1";
import Card2 from "./Card2";
import Card3 from "./Card3";
import Box from "@mui/material/Box";

const CardsMain = () => {
  return (
    <div className="">
      {/* <div className="flex justify-between"> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { lg: "row", md: "row", sm: "column", xs: "column" },
          justifyContent: "space-between",
          width: "100%",
          // maxWidth: 500,
          gap: 2,
        }}
      >
        <Card1 />
        <Card2 />
        <Card3 />
      </Box>
      {/* </div> */}
    </div>
  );
};

export default CardsMain;
