import * as Yup from "yup";
import {
  alphaNumericValidation,
  emailValidation,
  fullNameValidation,
  phoneValidation,
} from "components/modals/helper";

export const personalInfoValidationSchema = Yup.object().shape({
  firstName: fullNameValidation("First Name"),
  lastName: fullNameValidation("Last Name"),
  phone: phoneValidation(" Phone Number"),
  email: emailValidation,
  firmName: fullNameValidation("Firm Name"),
  designation: Yup.string().required().label("Designation"),
  represent: Yup.array().test({
    name: "represent",
    exclusive: true,
    message: "Please select one represent field",
    test: (value) => value?.length > 0,
  }),
});

export const addressValidationSchema = Yup.object().shape({
  addresses: Yup.array().of(
    Yup.object().shape({
      officeType: Yup.string().required().label(" Office Type"),
      country: Yup.string().label(" Country"),
      state: Yup.string().required().label(" State"),
      city: fullNameValidation(" City"),
      postalCode: Yup.string()
        .required()
        .label(" Postal Code")
        .matches(
          /^(?:\d{5}|\d{1,5}-\d{4})$/,
          " Please enter the valid postal code in 5 or 9 digits, 9 digits format 12345-6789"
        ),
      street01: Yup.string().trim(),
      street02: Yup.string().trim(),
    })
  ),
});
const alphaNumericRegex = "^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$";
export const licenseValidationSchema = Yup.object().shape({
  licenses: Yup.array().of(
    Yup.object().shape({
      state: Yup.string().required().label(" State"),
      licenseNumber: Yup.string()
        .required()
        .trim()
        .matches(alphaNumericRegex, " License Number must be alpha numeric")
        .label("License Number"),
    })
  ),
});

export const billingValidation = Yup.object().shape({
  onContingency: Yup.string()
    .max(8)
    .trim()
    .matches(
      /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,2})?)$/,
      " Only allowed from 0.00-100.0"
    )
    .label("On Contingency"),
  ratePerHourMin: Yup.string()
    .max(8)
    .trim()
    .matches(
      /^\d+(\.\d+)?$/,
      " Only positive numbers with decimals are allowed"
    )
    .label("Rate Per Hour"),
  retainer: Yup.string()
    .max(8)
    .trim()
    .matches(
      /^\d+(\.\d+)?$/,
      " Only positive numbers with decimals are allowed"
    )
    .label("Retainer"),
  billingStructure: Yup.array().test({
    name: "billing_structure",
    exclusive: true,
    message: "Please select one Billing Structure",
    test: (value) => value?.length > 0,
  }),
});

export const languageValidation = (
  language,
  oralProficiency,
  writtenProficiency
) =>
  Yup.object().shape({
    languages: Yup.array().of(
      Yup.object().shape({
        language: Yup.string().required().label(language),
        oralProficiency: Yup.string().required().label(oralProficiency),
        writtenProficiency: Yup.string().required().label(writtenProficiency),
      })
    ),
  });

export const experiencesValidation = (title, description, court) =>
  Yup.object().shape({
    experiences: Yup.array().of(
      Yup.object().shape({
        title: fullNameValidation(title),
        description: Yup.string().min(20).required().label(description),
        court: alphaNumericValidation(court),
      })
    ),
  });
