import React, { useState, useEffect } from "react";
import { Box, Pagination, Stack } from "@mui/material";

import MyTable from "components/Dashboard/table/Table";
import { invoicesColumns } from "components/invoices/helper";
import { useAxios } from "utils/hooks/useAxios";
import { fetchInvoices } from "services/fetchInvoices";
import Loader from "utils/loader/Loader";

const Invoices = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [invoices, setInvoices] = useState([]);
  const { isLoading, callApi } = useAxios();

  useEffect(() => {
    fetchInvoices(callApi, ({ data }, error) => {
      if (data) {
        setInvoices(data.invoices);
      } else if (error) {
        console.log(
          "$$$ ~ file: Invoices.jsx:26 ~ fetchInvoices ~ error:",
          error
        );
      }
    });
  }, [currentPage]);

  const totalPages = () => {
    return 2;
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <section className="p-5 w-[94%] mx-auto">
      {isLoading ? (
        <div className="w-full h-[57vh] flex justify-center items-center">
          <Loader width="50px" height="50px" />
        </div>
      ) : (
        <div className="w-[100%] h-[100%]">
          <h2 className="font-bold text-3xl text-secondary">Invoices</h2>
          <div className="min-h-[23.43rem]">
            <MyTable columns={invoicesColumns} rows={invoices} />
          </div>
          {/* <Stack direction="row" my={4} justifyContent="space-between">
            <Box />
            {totalPages() > 1 && (
              <Pagination
                page={currentPage}
                count={totalPages()}
                onChange={(_, pageNumber) => handlePageChange(pageNumber)}
              />
            )}
          </Stack> */}
        </div>
      )}
    </section>
  );
};

export default Invoices;
