export const topLanguages = [
  "English",
  "Spanish; Castilian",
  "Chinese",
  "Tagalog",
  "Vietnamese",
  "French",
  "Arabic",
  "Korean",
  "Russian",
  "German",
];
