import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { IconButton, MenuItem, Stack, SvgIcon } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

ArrowUpwardIcon;
export default function SortPopover({ state, setState }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSortTypeClick = (value) => {
    setState(value);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton
        aria-describedby={id}
        variant="text"
        onClick={handleClick}
        sx={{
          fontSize: "30px",
        }}
        size="large"
      >
        {state.includes("-") ? <AscendingIcon /> : <DescendingIcon />}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack sx={{ minWidth: "177px" }}>
          <Typography p={1} variant="h6">
            Sort By:
          </Typography>
          <MenuItem
            className="w-[100%]"
            selected={state === "createdAt"}
            onClick={() => handleSortTypeClick("createdAt")}
          >
            <SortItem label="Created At" icon={<ArrowDownwardIcon />} />
          </MenuItem>
          <MenuItem
            selected={state === "-createdAt"}
            onClick={() => handleSortTypeClick("-createdAt")}
          >
            <SortItem label="Created At" icon={<ArrowUpwardIcon />} />
          </MenuItem>
          <MenuItem
            selected={state === "bookafyId"}
            onClick={() => handleSortTypeClick("bookafyId")}
          >
            <SortItem label="ID" icon={<ArrowDownwardIcon />} />
          </MenuItem>
          <MenuItem
            selected={state === "-bookafyId"}
            onClick={() => handleSortTypeClick("-bookafyId")}
          >
            <SortItem label="ID" icon={<ArrowUpwardIcon />} />
          </MenuItem>
        </Stack>
      </Popover>
    </div>
  );
}

export const AscendingIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 256"
        id="sort-ascending"
      >
        <rect width="256" height="256" fill="none"></rect>
        <polyline
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
          points="144 167.994 184 207.994 223.999 168"
        ></polyline>
        <line
          x1="184"
          x2="184"
          y1="111.993"
          y2="207.993"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        ></line>
        <line
          x1="48"
          x2="119.999"
          y1="127.994"
          y2="127.994"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        ></line>
        <line
          x1="48"
          x2="183.999"
          y1="63.994"
          y2="63.994"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        ></line>
        <line
          x1="48"
          x2="104"
          y1="191.994"
          y2="191.994"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        ></line>
      </svg>
    </SvgIcon>
  );
};
export const DescendingIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 256"
        id="sort-descending"
      >
        <rect width="256" height="256" fill="none"></rect>
        <polyline
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
          points="144 87.994 184 47.994 223.999 87.987"
        ></polyline>
        <line
          x1="184"
          x2="184"
          y1="143.994"
          y2="47.994"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        ></line>
        <line
          x1="48"
          x2="119.999"
          y1="127.994"
          y2="127.994"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        ></line>
        <line
          x1="48"
          x2="103.999"
          y1="63.994"
          y2="63.994"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        ></line>
        <line
          x1="48"
          x2="183.999"
          y1="191.994"
          y2="191.994"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        ></line>
      </svg>
    </SvgIcon>
  );
};
export const SortItem = ({ label, icon }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <p>{label}</p>
      <span>{icon}</span>
    </Stack>
  );
};
