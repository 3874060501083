import React, { useState } from "react";
import Dialog from "components/dialog/Dialog";
import { useAxios } from "utils/hooks/useAxios";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { signInAction } from "store/actions/authActions";
import { Button, DialogTitle, Stack, TextField } from "@mui/material";
import Loader from "utils/loader/Loader";
import { verifyEmailCode } from "services/verifyEmailCode";
import { BL_RF_TOKEN } from "utils/constants/constants";
import useCookies from "utils/hooks/useCookie";
import SendEmailForm from "./SendEmailForm";

const NewEmailForm = ({ open, setOpen }) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { isLoading: verifyCodeLoading, callApi: verifyCodeApi } = useAxios();
  const dispatch = useDispatch();
  const { setCookie } = useCookies();
  const userEmail = useSelector((state) => state.user?.email);

  const verifyEmailForm = useFormik({
    initialValues: {
      verificationCode: "",
    },
    validationSchema: Yup.object().shape({
      verificationCode: Yup.string().required().label("Verification Code"),
    }),
    onSubmit: async (values) => {
      if (showSuccessMessage) {
        verifyEmailCode(verifyCodeApi, values, (res) => {
          if (res?.data?.tokens?.accessToken) {
            setCookie(BL_RF_TOKEN, res.data.tokens.refreshToken);
            dispatch(
              signInAction({
                accessToken: res.data.tokens.accessToken,
                ...res.data.user,
              })
            );
            setShowSuccessMessage(false);
            verifyEmailForm.resetForm();
            setOpen(null);
          }
        });
      }
    },
  });

  return (
    <Dialog
      title="Change Email"
      open={open}
      onClose={() => {
        verifyEmailForm.resetForm();
        setShowSuccessMessage(false);
        setOpen(null);
      }}
      hideCloseButton
      renderDialogActions={() => {
        return (
          <Stack direction="row" justifyContent="end" gap={1}>
            <Button
              size="small"
              onClick={() => {
                verifyEmailForm.resetForm();
                setShowSuccessMessage(false);
                setOpen(null);
              }}
            >
              Cancel
            </Button>
            <Button
              size="small"
              disabled={verifyCodeLoading}
              onClick={verifyEmailForm.handleSubmit}
            >
              {verifyCodeLoading ? <Loader /> : " Verify"}
            </Button>
          </Stack>
        );
      }}
    >
      <SendEmailForm
        userEmail={userEmail}
        setShowSuccessMessage={setShowSuccessMessage}
        showSuccessMessage={showSuccessMessage}
      />
      <DialogTitle ml={-3} color="Black">
        Verify Email
      </DialogTitle>
      <form onSubmit={verifyEmailForm.handleSubmit}>
        <TextField
          id="verificationCode"
          name="verificationCode"
          label="Verification Code"
          size="small"
          type="number"
          fullWidth
          {...verifyEmailForm.getFieldProps("verificationCode")}
          error={
            verifyEmailForm.touched.verificationCode &&
            Boolean(verifyEmailForm.errors.verificationCode)
          }
          helperText={
            verifyEmailForm.touched.verificationCode &&
            verifyEmailForm.errors.verificationCode
          }
        />
      </form>
    </Dialog>
  );
};

export default NewEmailForm;
