import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeCurrentModal, openSignInModal } from "store/actions/modalActions";
import Button from "components/button/Button";
import { useFormik } from "formik";
import Error from "components/error/Error";
import { forgotPasswordValidation } from "components/modals/helper";
import { OPEN_VERIFY_EMAIL_MODAL } from "utils/constants/constants";
import { useNavigate } from "react-router-dom";

const ForgotPasswordModal = ({ onSubmit, onClose, loader }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentModal } = useSelector((state) => state.modal);
  const user = useSelector((state) => state.user);
  const isForgotPasswordModal = currentModal === "forgotPasswordModal";
  const formik = useFormik({
    initialValues: { email: user?.email ?? "" },
    validationSchema: forgotPasswordValidation,
    onSubmit: ({ email }) => {
      onSubmit(email.toLowerCase());
    },
  });
  const isError = Object.keys(formik.errors)?.length;
  const handleClose = () => {
    if (onClose) {
      onClose();
      return;
    }
    dispatch(closeCurrentModal());
    navigate("/");
  };
  return (
    <div className="relative w-[326px] md:w-[544px] bg-[#fff] rounded-lg shadow dark:bg-gray-700 rounded-[20px] p-[2rem]">
      {isForgotPasswordModal && (
        <button
          type="button"
          className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex  dark:hover:text-primary text-textColor"
          data-modal-toggle="authentication-modal"
        >
          <svg
            onClick={handleClose}
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
      )}
      <div className="px-6 py-6 lg:px-8">
        <h3 className="text-secondary text-[22px] font-[700]">
          {isForgotPasswordModal ? "Reset Password" : "Verify Your Email"}
        </h3>
        {isForgotPasswordModal && (
          <label className="block mb-6 text-[16px] font-[400] text-gray-900 text-textColor">
            Please enter your email to reset password.
          </label>
        )}
        <form
          className={`space-y-6 ${!isForgotPasswordModal && "mt-4"}`}
          onSubmit={formik.handleSubmit}
        >
          <div>
            <label
              htmlFor="password"
              className="block mb-2 text-[14px] font-[500] text-gray-900 text-textColor"
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
              placeholder="name@email.com"
              onChange={formik.handleChange}
              value={formik.values.email}
              onBlur={formik.handleBlur}
              disabled={currentModal === OPEN_VERIFY_EMAIL_MODAL}
            />
            <Error formik={formik} errorField="email" />
          </div>

          <Button
            className="h-[50px] text-[16px] font-[600]"
            type="submit"
            loader={loader}
            disabled={loader || isError}
          >
            Send OTP
          </Button>
          {isForgotPasswordModal && (
            <>
              <label className="block mb-6 text-[13px] font-[300] text-gray-900 text-textColor">
                We will send instructions on your email to change your password
              </label>
              <div className="text-sm font-medium text-gray-500 dark:text-gray-300 text-textColor text-center">
                <p
                  onClick={() => dispatch(openSignInModal())}
                  className="hover:underline dark:text-blue-500 text-center text-primary text-[14px] font-[400] cursor-pointer"
                >
                  Back to Sign in
                </p>
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
