import React from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import TextWithSeeMore from "components/textWithSeeMore/TextWithSeeMore";
import { PERSONAL_INFO } from "components/PremiumProviderEditProfileComponents/constants/editProfileConstants";

const Biography = (props) => {
  const navigate = useNavigate();
  const hanldleBiographyClick = () => {
    navigate("/profile/edit", { state: { goToStep: PERSONAL_INFO } });
  };
  return (
    <div className=" w-full h-[auto] rounded-[8px] border border-borderColor p-6 mt-4">
      <div className="flex">
        <Typography
          sx={{ color: "#002A5C", fontSize: 20, fontWeight: 600 }}
          gutterBottom
        >
          Biography
        </Typography>
        {props?.editAble && (
          <Typography
            onClick={hanldleBiographyClick}
            sx={{
              color: "#00A4DA",
              fontSize: 16,
              fontWeight: 600,
              textAlign: "center",
              pl: 1,
              mt: "5px",
              cursor: "pointer",
            }}
            gutterBottom
          >
            Edit
          </Typography>
        )}
      </div>
      <TextWithSeeMore text={props?.text} maxLength={550} />
    </div>
  );
};

export default Biography;
