import React, { useLayoutEffect } from "react";
import { useFormik } from "formik";
import Error from "components/error/Error";
import { individualValidationSchema } from "components/Individualconsumer/helper";
import EditProfileFooter from "components/PremiumProviderEditProfileComponents/editProfilFooter/EditProfileFooter";
import client from "assets/user.svg";
import {
  capitalizeFirstLetter,
  designations,
} from "components/PremiumProviderEditProfileComponents/helper";
import { useRef } from "react";
import { useState } from "react";
import { routes } from "utils/appConfig";
import { signInAction } from "store/actions/authActions";
import { useDispatch } from "react-redux";
import { cleanUpValues } from "utils/helperFunctions/helper";
import PhoneInput from "components/phoneInput/PhoneInput";
import { notify } from "utils/toast/toast";
import { validFileFormats } from "components/editProviderNewScope/personalInfoNewScope/PersonalInfoNewScope";

const IndividualPersonalInfo = ({ onSubmit, user, callApi }) => {
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const fileRef = useRef(null);
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    if (user) {
      const { firstName, lastName, phone, email, postalCode = "" } = user;
      const fetchedUserValues = {
        firstName,
        lastName,
        phone,
        email,
        postalCode,
      };
      formik.setValues({ ...fetchedUserValues });
    }
  }, [user]);
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      postalCode: "",
    },
    validationSchema: individualValidationSchema,
    onSubmit: async (values) => {
      values.email = values.email.toLowerCase();
      cleanUpValues(values);
      let imageUploadStatus = null;
      try {
        if (imageFile) {
          let formData = new FormData();
          formData.append("file", imageFile);
          formData.append("target", "USER_PROFILE_PICTURE");
          await callApi(routes.media.url, "POST", formData, {
            onSuccess: () => {
              imageUploadStatus = true;
              setImageUrl(null);
              callApi(
                routes.me.url,
                routes.me.method,
                {},
                {
                  onSuccess: ({ data }) => {
                    dispatch(signInAction(data.data));
                  },
                  turnOffNotification: true,
                }
              );
            },
          });
        }
        if (imageFile && !imageUploadStatus) {
          return;
        }
        onSubmit(values);
      } catch (e) {
        console.log(e);
      }
    },
  });
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileType = file.type.split("/")[0];
    const fileFormat = file.type.split("/")[1];
    if (fileType !== "image") {
      notify("Please upload valid image file", "error");
      return;
    }
    if (file.size > 1024000) {
      notify("File size too large, max size 1mb", "error");
      return;
    }
    if (!validFileFormats.includes(fileFormat)) {
      notify(`Invalid File Format ${fileFormat}`, "error");
      return;
    }
    setImageFile(file);
    setImageUrl(URL.createObjectURL(file));
  };
  return (
    <>
      <div className="Component-1">
        <div className="flex  py-16">
          <div className="h-[100%]">
            <img
              className="h-[181px] w-[180px] rounded-md object-contain"
              src={imageUrl ?? user?.profilePicture ?? client}
              alt=""
            />
          </div>
          <div className="pl-5">
            <h1 className="text-[16px] pt-12 font-semibold">
              Change Display Picture
            </h1>
            <input
              type="file"
              className="hidden"
              ref={fileRef}
              onChange={handleFileChange}
            />
            <button
              onClick={() => fileRef.current.click()}
              className="bg-primary  py-2 px-6 mt-5 rounded-xs text-white hover:bg-[#fff] hover:text-[white] hover:bg-[#00a4dab7] duration-300	 "
            >
              Browse
            </button>
          </div>
        </div>
        <h3 className="mb-4 text-xl flex-wrap text-secondary font-semibold">
          Personal Info
        </h3>
        <div className="flex input-wrap flex-wrap gap-y-6  justify-between mb-6">
          <div className="basis-[100%] sm:basis-[48%] order-1">
            <label
              htmlFor="firstName"
              className="block mb-2 text-sm font-medium text-textColor"
            >
              First Name
            </label>
            <input
              type="text"
              name="firstName"
              id="firstName"
              className=" border border-borderColor outline-primary border-primary0 rounded-sm text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 text-[gray]"
              placeholder="Jane"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Error formik={formik} errorField="firstName" />
          </div>
          <div className="basis-[100%] sm:basis-[48%] order-2 padding-input padding-input-t">
            <label
              htmlFor="lastName"
              className="block mb-2 text-sm font-medium text-textColor"
            >
              Last Name
            </label>
            <input
              type="text"
              name="lastName"
              id="lastName"
              className=" border border-borderColor outline-primary border-primary0 rounded-sm text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 text-[gray]"
              placeholder="Lawyer"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Error formik={formik} errorField="lastName" />
          </div>
          <div className="basis-[100%] sm:basis-[48%] order-4">
            <div className="flex">
              <label
                htmlFor="phone"
                className="block mb-2 text-sm font-medium text-textColor"
              >
                Phone
              </label>
              <label
                htmlFor="phone"
                className="mt-[2px] ml-1 text-[12px] font-medium text-[#979797]"
              >
                (Optional)
              </label>
            </div>

            <PhoneInput
              name="phone"
              id="phone"
              className=" border border-borderColor  rounded-sm text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 text-[gray] h-[42px]"
              placeholder="(255) 555-0112"
              value={formik.values.phone}
              onChange={(value) => {
                formik.setFieldTouched("phone", true);
                formik.setFieldValue("phone", value);
              }}
              onBlur={formik.handleBlur}
            />
            <Error formik={formik} errorField="phone" />
          </div>
          <div className="basis-[100%] sm:basis-[48%] order-5 padding-input padding-input-t input-wrap">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-textColor"
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className=" border border-borderColor outline-primary border-primary0 rounded-sm text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 text-[gray]"
              placeholder="janelawyer@hello.com"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled
            />
            <Error formik={formik} errorField="email" />
          </div>
          <div className="basis-[100%] sm:basis-[48%] order-5 padding-input padding-input-t input-wrap flex-grow">
            <label className="block mb-2 text-sm font-medium text-textColor">
              Zip Code
            </label>
            <input
              type="text"
              name="postalCode"
              id="postalCode"
              className="border border-borderColor outline-primary border-primary0 rounded-sm text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 text-[gray]"
              placeholder="janelawyer@hello.com"
              value={formik.values.postalCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Error formik={formik} errorField="postalCode" />
          </div>
          {/* {isBusinessUser && (
            <div className="basis-[100%] sm:basis-[48%] order-3">
              <div className="flex">
                <label
                  htmlFor="designation"
                  className="block mb-2 text-sm font-medium text-textColor"
                >
                  Designation
                </label>
              </div>
              <select
                name="designation"
                id="designation"
                className="w-full"
                value={formik.values?.designation?.toUpperCase()}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">select</option>
                {designations.map((designation) => (
                  <option key={designation} value={designation}>
                    {capitalizeFirstLetter(designation)}
                  </option>
                ))}
              </select>
              <Error formik={formik} errorField="designation" />
            </div>
          )} */}
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            onClick={formik.handleSubmit}
            className="bg-primary  py-2 px-6 mt-5 rounded-xs text-white hover:bg-[#fff] hover:text-[white] hover:bg-[#00a4dab7] duration-300	 "
          >
            Update
          </button>
        </div>
      </div>
    </>
  );
};

export default IndividualPersonalInfo;
