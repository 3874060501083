import React from "react";
import AutoComplete from "components/autocomplete/AutoComplete";

export default function CategoriesAutoComplete({
  options,
  defaultValue,
  onChange,
  showSubCatagories = false,
}) {
  // const handleChange = (value) => {
  //   onChange(value?._id);
  // };

  return (
    <AutoComplete
      options={options}
      defaultValue={defaultValue}
      onChange={onChange}
      placeholder={showSubCatagories ? "Subcategory" : "Category"}
      sx={{
        width: { sm: "100%", md: 340 },
        "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
          backgroundColor: "#363636",
        },
        "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
          {
            backgroundColor: "#4396e6",
          },
        "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused":
          {
            backgroundColor: "#3878b4",
          },
      }}
    />
  );
}
