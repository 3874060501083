import Button from "components/button/Button";
import {
  formatDate,
  formattedCurrency,
  getSubscriptionDate,
} from "utils/helperFunctions/helper";

export const invoicesColumns = [
  {
    id: 1,
    label: "Period",
    // width: "5%",
    render: ({ period_start, period_end }) => {
      return (
        <div>
          {formatDate(period_start)} to {formatDate(period_end)}
        </div>
      );
    },
  },
  {
    id: 2,
    label: "Paid on",
    // width: "45%",
    render: ({ status_transitions }) => {
      return <div>{formatDate(status_transitions?.paid_at)}</div>;
    },
  },
  {
    id: 3,
    field: "price",
    label: "Amount",
    // width: "15%",
    align: "center",
    render: (invoice) => {
      return <div>{formattedCurrency(invoice.amount_paid / 100, 2)}</div>;
    },
  },
  // {
  //   id: 4,
  //   field: "updatedAt",
  //   label: "Subscription Date",
  //   width: "15%",
  //   align: "center",
  //   render: (invoice) => {
  //     return (
  //       <div>{getSubscriptionDate(invoice?.status_transitions?.paid_at)}</div>
  //     );
  //   },
  // },
  {
    id: 5,
    field: "updatedAt",
    label: "Details",
    // width: "15%",
    align: "center",
    render: (invoice) => {
      return (
        <Button className="focus:ring-0 w-fit h-8">
          <a
            href={invoice?.hosted_invoice_url}
            target="_blank"
            rel="noreferrer"
          >
            Details
          </a>
        </Button>
      );
    },
  },
];
