import React from "react";
import {
  Dialog as MUIDialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import Loader from "utils/loader/Loader";

const Dialog = ({
  open,
  onClose,
  title,
  children,
  agree,
  loading,
  agreeText,
  color,
  hideCloseButton,
  renderDialogActions,
}) => {
  return (
    <MUIDialog
      open={open}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle color="Black">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          sx={{ width: 300, py: 2 }}
        >
          {children}
        </DialogContentText>
      </DialogContent>
      {renderDialogActions ? (
        <DialogActions>{renderDialogActions()}</DialogActions>
      ) : (
        <DialogActions>
          {!hideCloseButton && <Button onClick={onClose}>cancel</Button>}
          {agreeText && (
            <Button onClick={agree} disabled={loading} color={color ?? "info"}>
              {loading ? <Loader /> : agreeText}
            </Button>
          )}
        </DialogActions>
      )}
    </MUIDialog>
  );
};

export default Dialog;
