import { Typography } from "@mui/material";
import { useState } from "react";

export default function TextWithSeeMore({
  text,
  maxLength,
  showSeeMore = true,
}) {
  const [showFullText, setShowFullText] = useState(text?.length < maxLength);

  const shortenedText = showFullText
    ? text
    : text?.slice(0, maxLength) + " . . . ";

  return (
    <div>
      <span>{shortenedText}</span>
      {!showFullText && showSeeMore && (
        <Typography
          onClick={() => setShowFullText(!showFullText)}
          sx={{
            color: "#00A4DA",
            fontSize: 16,
            fontWeight: 600,
            cursor: "pointer",
            display: "inline-flex",
          }}
        >
          {showFullText ? "Show Less" : "See More"}
        </Typography>
      )}
    </div>
  );
}
