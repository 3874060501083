import React from "react";

import client1 from "assets/user.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatPhone } from "utils/helperFunctions/helper";

const ConsumerDetails = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  return (
    <div>
      <div className="w-[100%] pt-5 flex justify-evenly flex-row border border-borderColor rounded-md">
        <div className="w-[17%] pb-[26px]">
          <img
            className="w-[180px] rounded-md object-contain"
            src={user?.profilePicture ?? client1}
            alt="Client"
          />
        </div>
        <div className="w-[70%] space-y-3">
          <label
            htmlFor="heading"
            className="inline mb-2 text-xl  font-bold text-secondary"
          >
            {user.firstName + " " + user.lastName}
          </label>
          <label
            onClick={() => navigate("/profile/edit")}
            className="inline cursor-pointer mt-1 pl-3 text-sm font-semibold text-primary hover:text-[#00a4dabe]"
          >
            Edit
          </label>
          {/* <div className="flex mt-3 ">
            <i className="text-[#979797] mt-1 fa-solid pr-2 fa-location-dot"></i>
            <label
              htmlFor="heading"
              className="block mb-2 text-[16px] font-medium text-[#979797 w-[100%]"
            >
              {user.addresses?.[0]?.street01}
            </label>
          </div> */}
          <div className="bg-[#DAF8FF] text-primary w-fit py-[7px] px-[16px] rounded-[4px] text-xs capitalize">
            {user.userType}
          </div>
          <div className="flex flex-wrap w-100 sm:gap-8">
            {user?.phone && (
              <div className="">
                <label
                  htmlFor="heading"
                  className="block mb-2 text-[16px]  font-semibold text-secondary"
                >
                  Phone Number
                </label>
                <label
                  htmlFor="heading"
                  className="block mb-2 text-[16px]  font-medium text-[#5A5A5A] w-[100%]"
                >
                  {formatPhone(user?.phone)}
                </label>
              </div>
            )}
            {user?.email && (
              <div className="">
                <label
                  htmlFor="heading"
                  className="block mb-2 text-[16px]  font-semibold text-secondary"
                >
                  Email
                </label>
                <label
                  htmlFor="heading"
                  className="block mb-2 text-[16px]  font-medium text-[#5A5A5A] w-[100%]"
                >
                  {user?.email}
                </label>
              </div>
            )}
            {user?.postalCode && (
              <div className="">
                <label
                  htmlFor="heading"
                  className="block mb-2 text-[16px]  font-semibold text-secondary"
                >
                  Zip Code
                </label>
                <label
                  htmlFor="heading"
                  className="block mb-2 text-[16px] font-medium text-[#5A5A5A] w-[100%]"
                >
                  {user?.postalCode}
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsumerDetails;
