import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClickAwayListener, TextField, Typography } from "@mui/material";
import masterCard from "assets/master-card-logo.png";
import visaCard from "assets/visa-card-logo.png";
import Button from "components/button/Button";
import {
  ADD_CREDIT_CARD_MODAL,
  PAYMENT_SUCCESS_MODAL,
} from "utils/constants/constants";
import { closeCurrentModal, openModal } from "store/actions/modalActions";
import { useAxios } from "utils/hooks/useAxios";
import { paySubscription } from "services/paySubscription";
import { fetchLoggedInUserInfo } from "services/fetchUserInfo";
import { signInAction } from "store/actions/authActions";
import { markAsDefault } from "services/markAsDefault";
import { updateCreditCards } from "store/actions/actions";
import { fetchPaymentCards } from "services/fetchPaymentCards";

const SelectCardModal = ({ setSubscriptionDetail }) => {
  const [selectedCard, setSelectedCard] = useState({});
  const [markDefault, setMarkDefault] = useState(false);
  const dispatch = useDispatch();
  const { isLoading, callApi } = useAxios();
  const { callApi: fetchUserApi } = useAxios();
  const paymentCards = useSelector(
    (state) => state?.membershipReducer?.paymentCards
  );
  const plan = useSelector(
    (state) => state.membershipReducer?.changeMembershipTo
  );
  useEffect(() => {
    if (paymentCards.length) {
      paymentCards.forEach((card) => {
        if (card?.isDefaultPaymentMethod) {
          setSelectedCard(card);
        }
      });
    }
    if (plan?.price) {
      setSubscriptionDetail({
        productPrice: plan.price,
        productName: plan.type,
      });
    }
  }, [paymentCards, plan]);
  const handlePayNowClick = () => {
    paySubscription(
      callApi,
      {
        paymentMethodId: selectedCard.id,
        productId: plan?.productId,
      },
      (data) => {
        if (data) {
          if (markDefault) {
            markAsDefault(
              fetchUserApi,
              {
                paymentMethodId: selectedCard.id,
                isMarkAsDefault: true,
              },
              (data) => {
                if (data) {
                  fetchPaymentCards(fetchUserApi, (data) => {
                    if (data) {
                      dispatch(updateCreditCards(data.data.data));
                    }
                  });
                }
              }
            );
          }
          dispatch(openModal(PAYMENT_SUCCESS_MODAL));
          fetchLoggedInUserInfo(fetchUserApi, (data) => {
            if (data) {
              dispatch(signInAction(data.data));
            }
          });
        }
      }
    );
  };
  const handleClose = () => {
    dispatch(closeCurrentModal());
  };
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className="relative bg-[#fff] w-[326px] sm:w-[544px] md:h-[580px] rounded-lg shadow dark:bg-gray-700 rounded-[20px] flex flex-col  py-10 px-11">
        <div>
          <Typography fontWeight={700} fontSize={22} sx={{ color: "#002A5C" }}>
            Select Card
          </Typography>
          <Typography
            sx={{ color: "#5A5A5A", marginTop: "9px", marginBottom: "10px" }}
          >
            Join now to help people!
          </Typography>
        </div>
        <div className="max-h-[300px] overflow-scroll flex-grow">
          {!!paymentCards?.length &&
            paymentCards.map((card, index) => {
              const defaultCard = card?.isDefaultPaymentMethod;

              const showMarkDefault =
                index === paymentCards.length - 1 &&
                selectedCard.id !== card.id;

              return (
                <>
                  <div
                    key={card.id}
                    className={`bg-[${
                      selectedCard.id === card.id ? "#00a4da08" : "#F6F6F6"
                    }] min-h-[61px] border border-[${
                      selectedCard.id === card.id ? "#00A4DA" : "#C6C7C4"
                    }] rounded-[8px] flex justify-between px-2 items-center cursor-pointer mt-4`}
                    onClick={() => setSelectedCard(card)}
                  >
                    <div className="flex gap-2">
                      <img src={masterCard} alt="logo" className=" h-[25px]" />
                      <div className="flex justify-center text-textColor gap-2">
                        <span className="text-[16px] ">
                          &#x2022;&#x2022;&#x2022; &#x2022;&#x2022;&#x2022;
                          &#x2022;&#x2022;&#x2022;
                        </span>
                        <span className="last-digits">{card?.card?.last4}</span>
                        <span>( {card?.card?.brand} )</span>
                      </div>
                    </div>
                    {!!defaultCard && (
                      <button className="bg-textColor px-4 py-1 text-white rounded-xs mr-2">
                        Default
                      </button>
                    )}
                  </div>
                  {showMarkDefault && (
                    <div className="flex gap-3 mt-3">
                      <input
                        type="checkbox"
                        checked={markDefault}
                        onChange={(e) => setMarkDefault(e.target.checked)}
                      />
                      <Typography className="text-textColor">
                        Mark as Default
                      </Typography>
                    </div>
                  )}
                </>
              );
            })}
        </div>
        <div>
          <Button
            loader={isLoading}
            className="py-4 mt-6"
            onClick={handlePayNowClick}
          >
            Pay Now
          </Button>
          <button
            type="submit"
            className="bg-borderColor w-full px-3 mt-3 py-2 rounded-xs text-white hover:bg-hoverColor duration-300"
            onClick={handleClose}
          >
            Cancel
          </button>
          <div className="flex justify-center mt-3">
            <button
              className="hover:text-primary font-bold text-[#002A5C]"
              onClick={() => dispatch(openModal(ADD_CREDIT_CARD_MODAL))}
            >
              Add New Credit Card
            </button>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default SelectCardModal;
