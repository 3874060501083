import moment from "moment";
import { formatPhoneNumber } from "react-phone-number-input";
import { PREMIUM, PREMIUM_PACKAGE } from "utils/constants/constants";

export const getCookie = (name) => {
  const cookieString = document.cookie;
  const cookieArray = cookieString.split("; ");

  let cookieValue = null;

  cookieArray.forEach((cookie) => {
    const [cookieName, value] = cookie.split("=");
    if (cookieName === name) {
      cookieValue = value;
    }
  });

  return cookieValue;
};

export const makeId = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters?.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const removeExtraSpaces = (str) => {
  try {
    return str.trim().replace(/\s+/g, " ");
  } catch (e) {
    console.log(e);
    return str;
  }
};
export const cleanUpValues = (values) => {
  //this function removes extra spaces
  try {
    Object.entries(values).forEach(([key, value]) => {
      if (typeof value === "string") {
        values[key] = value?.includes(" ") ? removeExtraSpaces(value) : value;
      }
    });
    return values;
  } catch (e) {
    alert("error");
    console.log(e);
    return values;
  }
};

export const serializeParams = (obj) => {
  try {
    const params = new URLSearchParams(obj).toString();
    return `?${params}`;
  } catch (e) {
    return obj;
  }
};
export const cutLabels = (text, maxSize = 22, isTreeDots = true) => {
  if (text && text?.length > maxSize) {
    const newText = text.slice(0, maxSize - 3);
    return isTreeDots ? `${newText}...` : newText;
  }
  return text;
};
export function extractYear(dateStr) {
  try {
    const date = new Date(dateStr);
    return date.getUTCFullYear();
  } catch (error) {
    console.error(`Error extracting year from "${dateStr}":`, error);
    return dateStr;
  }
}

export const formatDate = (date, format = "MMM DD, YYYY") => {
  if (typeof date === "number") {
    const milliseconds = date * 1000;
    return moment(milliseconds).zone("-0400").format(format);
  }
  return moment(date).format(format);
};
export const fetchCategoryName = (categories, id) => {
  try {
    const fetchedCategory = categories.find(
      (category) => (category?._id ?? category?.category_id) === id
    );
    return fetchedCategory;
  } catch (e) {
    console.log("🚀~ fetchCategoryName ~ e:", e);
    return id;
  }
};

export const camelToTitle = (camelCaseStr) => {
  try {
    const spacedStr = camelCaseStr.replace(/([A-Z])/g, " $1");
    const capitalizedStr =
      spacedStr.charAt(0).toUpperCase() + spacedStr.slice(1);
    return capitalizedStr;
  } catch (e) {
    console.log("🚀 ~ e:", e);
    return camelCaseStr;
  }
};

export const findOne = (arr, key, value) => {
  if (!arr?.length || !arr) return null;
  return arr.find((item) => item[key] === value);
};
export function isValidURL(url) {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}

export const isPremiumSubscription = (subscription) =>
  !!subscription?.includes(PREMIUM_PACKAGE) ||
  !!subscription?.includes(PREMIUM);

export const findDuplicateIndexes = (arr) => {
  const duplicates = [];
  const map = {};

  for (let i = 0; i < arr.length; i++) {
    const language = arr[i].language;
    if (language in map) {
      duplicates.push(i);
    } else {
      map[language] = i;
    }
  }

  return duplicates;
};

export const findItemIndex = (arr, key, value) => {
  return arr.findIndex((item) => item[key] === value);
};

export const sortData = (arr, order = "asc", key = null) => {
  if (!Array.isArray(arr)) {
    console.error("The 'arr' argument must be an array.");
    return arr;
  }

  const validOrders = ["asc", "desc"];
  if (!validOrders.includes(order)) {
    console.error(`Invalid order. Must be one of: ${validOrders.join(", ")}`);
    return arr;
  }

  let sortKey = (item) => item; // Default sort key

  if (key) {
    if (typeof key === "string") {
      sortKey = (item) => item[key];
      if (!sortKey) {
        console.error("invalid key provided");
      }
    } else {
      console.error("The 'key' argument must be a string.");
      return arr;
    }
  }

  arr.sort((a, b) => {
    const keyA = sortKey(a);
    const keyB = sortKey(b);

    if (order === "asc") {
      return keyA < keyB ? -1 : keyA > keyB ? 1 : 0;
    } else {
      return keyA > keyB ? -1 : keyA < keyB ? 1 : 0;
    }
  });

  return arr;
};

export const formatUrl = (url) => {
  if (!url) return url;

  try {
    if (!url.startsWith('https://') && !url.startsWith('http://')) {
      url = `https://${url}`
    }

    const normalizedUrl = new URL(url);
  }
  catch (e) {
    console.error('Invalid url provided', e);
  }
  return url;
}

export const formatPhone = (phone) => {
  if (!phone) return phone;
  try {
    const formattedPhone = formatPhoneNumber(phone);
    if (!formattedPhone) {
      var cleaned = phone.replace(/\D/g, "");

      let normValue = `(${cleaned.substring(0, 3)})${
        cleaned.length > 3 ? " " : ""
      }${cleaned.substring(3, 6)}${
        cleaned.length > 6 ? "-" : ""
      }${cleaned.substring(6, 11)}`;
      return normValue ? normValue : phone;
    }
    return formattedPhone;
  } catch (e) {
    return phone;
  }
};

export const sortArrayByOrder = (arr1, arr2) => {
  // Create a map to store the index of each name in arr2
  const nameIndexMap = new Map();

  // Populate the map with the indices of names in arr2
  arr2.forEach((name, index) => {
    nameIndexMap.set(name, index);
  });

  // Sort arr1 based on the order defined in arr2
  arr1.sort((a, b) => {
    const indexA = nameIndexMap.get(a.name);
    const indexB = nameIndexMap.get(b.name);

    // Use the index values for comparison
    return indexA - indexB;
  });

  return arr1;
};

export const filterByTopItems = ({
  data = [],
  topItems = [],
  filterByKey = "name",
  divider = false,
}) => {
  if (!data.length || !topItems?.length) return data;
  let _topListItems = [];
  let remainingItems = [];
  data.forEach((option) => {
    if (topItems?.includes(option[filterByKey])) {
      _topListItems.push(option);
    } else {
      remainingItems.push(option);
    }
  });
  const sortedByTopItems = sortArrayByOrder(_topListItems, topItems);
  const filteredByTopItems = [].concat(sortedByTopItems);
  if (divider) {
    filteredByTopItems.push({ name: "divider", _id: null });
  }
  filteredByTopItems.push(...remainingItems);
  return filteredByTopItems;
};

export const formattedCurrency = (number, maximumFractionDigits = 0) => {
  try {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits,
    }).format(number);
  } catch (e) {
    console.log("$$$ ~ file: helper.js:262 ~ formattedCurrency ~ e:", e);
    return number;
  }
};

export const calculateDiscountedPrice = (
  originalPrice,
  discountPercentage,
  formatInUSD
) => {
  if (
    !discountPercentage ||
    discountPercentage < 0 ||
    discountPercentage > 100
  ) {
    if (formatInUSD) {
      return formattedCurrency(Number(originalPrice));
    }
    return originalPrice;
  }
  const discountAmount =
    (Number(discountPercentage) / 100) * Number(originalPrice);
  let discountedPrice = originalPrice - discountAmount;
  if (formatInUSD) {
    discountedPrice = formattedCurrency(discountedPrice);
  }
  return discountedPrice;
};

export const sortedPracticeAreas = (practiceAreas) => {
  practiceAreas.map((item) => {
    return sortAlphabetically(item.subCatagories)
  })
  return sortAlphabetically(practiceAreas)
}
    
const sortAlphabetically = (practiceAreas) => {
    return practiceAreas.sort(function(a, b) {
      if(a.name.toLowerCase() < b.name.toLowerCase()) return -1
      if(a.name.toLowerCase() > b.name.toLowerCase()) return 1
    return 0;
  })
}

export const getSubscriptionDate = (ms, format = "MMM DD, YYYY") =>
  moment()
    .subtract(ms / 1000, "seconds")
    .format(format);
