import { routes } from "utils/appConfig";

const { POST, DELETE } = routes.bookmark;
export const bookmarkPostApi = (callApi, searchId, providerId, cb) => {
  try {
    callApi(
      POST.url(searchId),
      POST.method,
      { providerId },
      {
        onSuccess: ({ data }) => {
          cb(data);
        },
      }
    );
  } catch (e) {
    console.log("🚀 ~ e:", e);
    throw new Error();
  }
};

export const bookmarkDeleteApi = (callApi, searchId, providerId, cb) => {
  try {
    callApi(
      DELETE.url(searchId),
      DELETE.method,
      { providerId },
      {
        onSuccess: ({ data }) => {
          cb(data);
        },
      }
    );
  } catch (e) {
    console.log("🚀 ~ e:", e);
    throw new Error();
  }
};
