import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useAxios } from "utils/hooks/useAxios";
import Loader from "utils/loader/Loader";
import { stepDataNewScope } from "components/editProviderNewScope/helper";
import {
  ACHIEVEMENTS,
  BILLING,
  EDUCATION,
  PERSONAL_INFO,
  REPRESENTATION,
  WORK_INFO,
} from "components/PremiumProviderEditProfileComponents/constants/editProfileConstants";
import EditProfileSteps from "components/PremiumProviderEditProfileComponents/editProfileSteps/EditProfileSteps";
import { routes } from "utils/appConfig";
import { signInAction } from "store/actions/authActions";
import PersonalInfoNewScope from "components/editProviderNewScope/personalInfoNewScope/PersonalInfoNewScope";
import EducationNewScope from "components/editProviderNewScope/educationNewScope/EducationNewScope";
import WorkInfoNewScope from "components/editProviderNewScope/WorkInfoNewScope/WorkInfoNewScope";
import AchievementsNewScope from "components/editProviderNewScope/Achievements/AchievementsNewScope";
import {
  EXPERIENCE_MODAL,
  PREMIUM,
  PREMIUM_PACKAGE,
} from "utils/constants/constants";
import { openModal } from "store/actions/modalActions";
import BillingNewScope from "components/editProviderNewScope/billingNewScope/BillingNewScope";
import RepresentationNewScope from "./representationNewScope/RepresentationNewScope";
import BreadCrumb from "components/PremiumProviderEditProfileComponents/breadCrumb/BreadCrumb";
import Alert from "@mui/material/Alert";
import { isCalAtomsUserIDValid } from "utils/config";

const EditProfileNewScope = ({ user, adminPanelUser = false }) => {
  const { categories, states } = useSelector(
    (state) => state.categoriesReducer
  );
  const [step, setStep] = useState(PERSONAL_INFO);
  const { isLoading, callApi } = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.goToStep) {
      setStep(location?.state?.goToStep);
    }
  }, [location?.state?.goToStep]);

  const updateUserInfo = async (values, jumpStep = false) => {
    if (!adminPanelUser) {
      const { url, method } = routes.user;
      await callApi(url, method, values, {
        onSuccess: ({ data }) => {
          dispatch(signInAction(data.data));
          if (!jumpStep) goToNextStep();
        },
      });
    }
  };

  const goToNextStep = () => {
    step === PERSONAL_INFO && setStep(EDUCATION);
    step === EDUCATION && setStep(WORK_INFO);
    step === WORK_INFO && setStep(BILLING);
    step === BILLING && setStep(ACHIEVEMENTS);
    if (step === ACHIEVEMENTS) {
      setStep(REPRESENTATION);
    }
    if (step === REPRESENTATION) {
      navigate("/profile");
    }
  };
  return (
    <>
      <BreadCrumb
        path={adminPanelUser ? "/admin" : "/profile"}
        moveTo={adminPanelUser ? "Back to admin panel" : "Back to profile"}
        currentPath="Edit profile"
      />

      <div className=" w-[85%] m-auto">
        {!isCalAtomsUserIDValid(user?.calAtomsUserId) && (
          <Alert severity="info">Your profile is under review!</Alert>
        )}
        {isLoading ? (
          <div className="w-full h-[30vh] flex justify-center items-center">
            <Loader width="50px" height="50px" />
          </div>
        ) : (
          <div>
            {step === PERSONAL_INFO && (
              <PersonalInfoNewScope
                user={user}
                step={step}
                setStep={setStep}
                callApi={callApi}
                categories={categories}
                isLoading={isLoading}
                onSubmit={updateUserInfo}
              />
            )}
            {step === EDUCATION && (
              <EducationNewScope
                user={user}
                step={step}
                setStep={setStep}
                states={states}
                onSubmit={updateUserInfo}
              />
            )}
            {step === WORK_INFO && (
              <WorkInfoNewScope
                user={user}
                step={step}
                setStep={setStep}
                onSubmit={updateUserInfo}
              />
            )}
            {step === BILLING && (
              <BillingNewScope
                pre={() => setStep(ACHIEVEMENTS)}
                user={user}
                step={step}
                setStep={setStep}
                onSubmit={updateUserInfo}
              />
            )}
            {step === ACHIEVEMENTS && (
              <AchievementsNewScope
                user={user}
                step={step}
                setStep={setStep}
                onSubmit={updateUserInfo}
              />
            )}
            {step === REPRESENTATION && (
              <RepresentationNewScope
                pre={() => setStep(ACHIEVEMENTS)}
                user={user}
                step={step}
                setStep={setStep}
                categories={categories}
                isLoading={isLoading}
                onSubmit={updateUserInfo}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default EditProfileNewScope;
