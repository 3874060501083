import { UPDATE_DATE_FILTER } from "utils/constants/constants";
const initialState = {
  startDate: null,
  endDate: null,
};

export const dateReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DATE_FILTER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
