import {
  EMAIL_VERIFIED_SUCCESSFULLY,
  SIGN_IN_ACTION,
} from "../../utils/constants/constants";

const initialState = null;

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN_ACTION:
      return { ...state, ...action.payload };
    case EMAIL_VERIFIED_SUCCESSFULLY:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
