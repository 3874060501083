import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { signOut } from "store/actions/authActions";
import { notify } from "utils/toast/toast";
import moment from "moment";
import { getCookie } from "utils/helperFunctions/helper";
import { BL_RF_TOKEN } from "utils/constants/constants";

function ProtectedRoute() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = !!user?.accessToken;

  useEffect(() => {
    // check if user token is expired, log him out and clear token
    const token = getCookie(BL_RF_TOKEN);
    try {
      if (token) {
        const decoded = JSON.parse(window?.atob(token?.split(".")[1]));
        const checkTokenExpiry = setInterval(() => {
          try {
            if (decoded?.exp * 1000 < moment().valueOf()) {
              clearInterval(checkTokenExpiry);
              notify("token expired", "error");
              dispatch(signOut());
              navigate("/");
            }
          } catch (e) {
            console.log("~ e", e);
          }
        }, 1000);
      }
    } catch (e) {
      console.log("error while validating token", e);
    }
  }, []);

  if (!isAuthenticated) {
    notify("Please Login First", "warn");
    let previousUrl = location.pathname;
    let uri = `/?from=${previousUrl}`;

    return <Navigate to={uri} />;
  }
  return <Outlet />;
}

export default ProtectedRoute;
