import GeneralSearch from "components/search/GeneralSearch";
import { useSelector } from "react-redux";
import { ADVANCED_SEARCH } from "utils/constants/constants";
import NewTopComponent from "./NewTopComponent";

const SearchLayout = ({ children, searchType, setSearchType }) => {
  const userType = useSelector((state) => state.user?.userType);

  const vimeoEmbedUrl = "https://player.vimeo.com/video/1027990739";

  return (
    <div className="flex flex-col min-h-screen">
      {/* Hero section */}
      <div className="mb-2">
        <NewTopComponent />
      </div>

      {/* Main content container */}
      <div className="flex-grow">
        <div className="max-w-5xl px-4 mx-auto">
          {/* Search Section */}
          <div className="w-full mb-16">
            {/* Search Input */}
            <div className="max-w-2xl mx-auto">
              <GeneralSearch className="w-full search-input" />

              {/* Search Type Toggle */}
              {userType !== "PROVIDER" && (
                <div className="mt-2 text-center text-secondary font-[600]">
                  {searchType === ADVANCED_SEARCH
                    ? "Want to describe your legal issue?"
                    : "Know what type of lawyer you're looking for?"}
                  <button
                    onClick={setSearchType}
                    className="pl-2 font-[700] text-primary hover:text-[#0099CB] hover:underline"
                  >
                    Click Here
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* Video Section */}
          <div className="mb-4">
            <div className="max-w-3xl mx-auto">
              <div className="relative overflow-hidden bg-gray-100 rounded-lg aspect-video">
                <iframe
                  className="absolute inset-0 w-full h-full"
                  src={vimeoEmbedUrl}
                  title="Service Overview"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchLayout;
