import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { closeCurrentModal } from "store/actions/modalActions";
import Loader from "utils/loader/Loader";
import TreeViewComponent from "components/treeView/TreeView";
import { Stack, Typography } from "@mui/material";
import  NewPracticeAreaDialog from "components/modals/practiceArea/NewPracticeAreaDialog";
import { sortedPracticeAreas } from "utils/helperFunctions/helper";

const PracticeArea = ({
  categories,
  onClose,
  isLoading,
  setPracticeAreas,
  practiceAreas,
  label,
  email,
}) => {
  let timeoutId;
  const [inputValue, setInputValue] = useState("");
  const [results, setResults] = useState([]);
  const [inheritedResults, setInheritedResults] = useState(sortedPracticeAreas(practiceAreas));
  const dispatch = useDispatch();
  const [newPracticeAreaDialog, setNewPracticeAreaDialog] = useState(false);
  useEffect(() => setInheritedResults(sortedPracticeAreas(results)), [results]);
  useEffect(() => setInheritedResults(sortedPracticeAreas(practiceAreas)), []);
  useEffect(() => {
    if (!email) {
      console.warn("No user email provided");
    }
  }, [email]);

  const handleSearch = (e) => {
    const input = e.target.value;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => setInputValue(input.toLowerCase()), 500);
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      dispatch(closeCurrentModal());
    }
  };
  return (
    <div className="fixed bg-[#a8a0a059] top-0 right-0 left-0  w-[100%] h-[100%] py-2 z-50">
      <div className="w-[100%] h-[100%] flex justify-center items-center overflow-auto">
        <div>
          <div className="relative bg-[#fff] w-[1026px] md:w-[900px] h-[750px] overflow-auto rounded-[20px] shadow dark:bg-gray-700 p-[1rem]">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex  dark:hover:text-primary text-textColor"
              data-modal-toggle="authentication-modal"
            >
              <svg
                onClick={handleClose}
                aria-hidden="true"
                className="w-8 h-8 fill-[0.7]"
                fill="currentColor"
                viewBox="0 0 20 20"
                // xmlns={close}
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="px-6 lg:px-8">
              <h3 className="mb-4 text-[20px] font-[600] text-secondary ">
                {label ?? "Select Practice Area"}
              </h3>
              <div className="flex items-center border-2 border-[#c6c7c481] w-[100%] md:w-[] h-[43px] rounded-sm">
                <input
                  type="text"
                  onChange={handleSearch}
                  className=" h-[2rem] w-[100%] outline-none px-6  text-lg font-semibold text-textColor placeholder:text-[#c6c7c4c4]  border-[#c6c7c481]"
                  placeholder={`Search`}
                />
              </div>

              {isLoading ? (
                <div className="flex h-full items-center mt-24">
                  <Loader width="50px" height="50px" />
                </div>
              ) : (
                <Stack>
                  <div className="flex w-full gap-4 pt-4">
                    {!!categories && (
                      <div className="border-2 border-borderColor w-[100%] rounded-md">
                        <CategoriesList label="Practice Areas List">
                          <TreeViewComponent
                            data={sortedPracticeAreas(categories)}
                            selectedCategories={setResults}
                            enableSelection={true}
                            inputValue={inputValue}
                            practiceAreas={inheritedResults}
                          />
                        </CategoriesList>
                      </div>
                    )}
                    <div className="flex flex-col justify-between border-2 border-borderColor w-[100%] rounded-md">
                      <CategoriesList
                        label="Practice Areas Selected"
                        maxHeight={370}
                      >
                        <TreeViewComponent
                          data={inheritedResults}
                          expandAll={true}
                        />
                      </CategoriesList>
                    </div>
                  </div>
                  <div className="flex justify-between gap-5 px-1 pt-4">
                    <label className="block mb-2 text-[14px] font-[500] text-textColor">
                      <p>
                        Don't see your niche practice area?<label onClick={() => setNewPracticeAreaDialog(!newPracticeAreaDialog)} className="mt-[-2px] ml-2 text-[14px] font-[500] text-primary cursor-pointer">Please click here</label>  to send a suggested practice area and our staff will get back to you promptly.
                      </p>
                    </label>
                  </div>
                  <div className="flex justify-between gap-5 px-1 pt-4">
                    <button
                      type="submit"
                      className="bg-borderColor w-full px-3 py-2 rounded-xs text-white hover:bg-hoverColor duration-300"
                      onClick={() => {
                        setPracticeAreas(inheritedResults);
                        handleClose();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-primary w-full px-3 py-2 rounded-xs text-white hover:bg-hoverColor duration-300"
                      onClick={() => {
                        setPracticeAreas(inheritedResults);
                        handleClose();
                      }}
                    >
                      Complete
                    </button>
                  </div>
                </Stack>
              )}
            </div>
          </div>
        </div>
      </div>
      {newPracticeAreaDialog && (
        <NewPracticeAreaDialog 
          email={email}
          onClose={() => setNewPracticeAreaDialog(!newPracticeAreaDialog)}
       />
      )}
    </div>
  );
};

export default PracticeArea;

export const CategoriesList = ({ label, children }) => {
  // const categories = useSelector((state) => state.categoriesReducer.categories);
  return (
    <div className="flex flex-col gap-4">
      <Typography align="center" variant="h5" className="py-2 ">
        {label}
      </Typography>
      <div className={`overflow-auto h-[395px] thin-scrollbar`}>{children}</div>
    </div>
  );
};

export const findCheckedCategories = (categories) => {
  let _temp = [...sortedPracticeAreas(categories)];
  let results = [];
  let currentIndex = 0;
  _temp.forEach((category) => {
    if (category?.checked) {
      results[currentIndex] = category;
      currentIndex++;
      return;
    } else if (category?.subCatagories?.length) {
      // check if any subCategory of the main category is checked then add it to selected
      let subCatagories = [];
      category?.subCatagories.forEach((subCategory) => {
        if (subCategory.checked) {
          subCatagories.push(subCategory);
        }
      });
      if (subCatagories.length) {
        results[currentIndex] = { ...category, subCatagories };
        currentIndex++;
      }
    }
  });
  return results;
};
