import EditProfileNewScope from "components/editProviderNewScope/EditProfileNewScope";
import BreadCrumb from "components/PremiumProviderEditProfileComponents/breadCrumb/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import IndividualPersonalInfo from "components/Individuals/IndividualPersonalInfo";
import { useAxios } from "utils/hooks/useAxios";
import { Link } from "react-router-dom";
import { routes } from "utils/appConfig";
import { signInAction } from "store/actions/authActions";
import Loader from "utils/loader/Loader";
import { adminPanelUserUpdate } from "services/adminPanelUserUpdate";

const EditProfile = () => {
  const user = useSelector((state) => state.user);

  return (
    <>
      {user && user.userType !== "PROVIDER" && <BreadCrumb />}
      {user && user.userType === "PROVIDER" ? (
        <EditProfileNewScope user={user} />
      ) : (
        <EditConsumerProfile user={user} />
      )}
    </>
  );
};

export default EditProfile;

export const EditConsumerProfile = ({ user, adminPanelUser = false }) => {
  const { isLoading, callApi } = useAxios();
  const dispatch = useDispatch();

  const updateUserInfo = (values) => {
    if (!adminPanelUser) {
      const { url, method } = routes.user;
      callApi(url, method, values, {
        onSuccess: ({ data }) => {
          dispatch(signInAction(data.data));
        },
      });
    } else if (adminPanelUser) {
      adminPanelUserUpdate(callApi, user._id, values, (res, error) => {
        if (res?.success) {
          dispatch(signInAction(res.data));
        } else {
          console.log("Error", error);
        }
      });
    }
  };
  return (
    <div className="flex flex-col w-[92%] mx-auto min-h-[70vh] overflow-auto mb-8">
      <h4 className="cursor-pointer text-primary font-bold ">
        {!adminPanelUser ? (
          <Link to="/profile">Back to profile</Link>
        ) : (
          <Link to="/admin">Back to admin panel</Link>
        )}
      </h4>
      <div className="w-full">
        <label
          htmlFor="Contact Person"
          className="block py-2 text-xl font-bold text-secondary"
        >
          Contact Person
        </label>
        {isLoading ? (
          <div className="w-full h-[60vh] flex justify-center items-center">
            <Loader width="50px" height="50px" />
          </div>
        ) : (
          <IndividualPersonalInfo
            onSubmit={updateUserInfo}
            callApi={callApi}
            user={user}
          />
        )}
      </div>
    </div>
  );
};
