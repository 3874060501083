import React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { Stack } from "@mui/material";
import {
  RenderNAICCodes,
  RenderPracticeAreas,
} from "components/SearchResultProfileComponents/RenderTag";
import TextWithSeeMore from "components/textWithSeeMore/TextWithSeeMore";

export default function MultiActionAreaCard({ experience }) {
  return (
    <Card
      sx={{
        maxWidth: "100%",
        p: 3,
        border: "1px solid #C6C7C4",
        borderRadius: "10px",
        boxShadow: "none",
        height: "100%",
      }}
    >
      <Box sx={{ display: "flex", gap: 2 }}>
        <Box sx={{ mt: 2 }}>
          <Typography
            sx={{ fontWeight: 700, textSize: 16, color: "#002A5C" }}
            gutterBottom
          >
            {experience?.materialIssues}
          </Typography>
        </Box>
      </Box>
      <TextWithSeeMore text={experience?.description} maxLength={100} />
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        mt={2}
        width="100%"
        flexWrap="wrap"
      >
        {!!experience?.practiceAreas?.length && (
          <Box>
            <Typography
              sx={{ fontWeight: 500, textSize: 14, color: "#002A5C" }}
              gutterBottom
              component="div"
            >
              Practice Areas
            </Typography>
            <RenderPracticeAreas
              practiceAreas={experience?.practiceAreas}
              limit={3}
              gap={1}
            />
          </Box>
        )}
        {/* {!!experience?.naicCodes?.length && (
          <Box>
            <Typography
              sx={{ fontWeight: 500, textSize: 14, color: "#002A5C" }}
              gutterBottom
              component="div"
            >
              NAIC Codes
            </Typography>
            <RenderNAICCodes
              NAICCodes={experience?.naicCodes}
              limit={3}
              gap={1}
            />
          </Box>
        )} */}
      </Stack>
    </Card>
  );
}
