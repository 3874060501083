import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import React from "react";

const DashboardCard = ({ label, count, percentageChange }) => {
  return (
    <Card
      variant="outlined"
      sx={{ width: { xs: "350px", sm: "276px" }, borderRadius: "8px" }}
    >
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={4}
        >
          <Typography fontWeight={700} fontSize={16}>
            {label}
          </Typography>
          {percentageChange >= 0 && <ArrowUpward sx={{ color: "#42BDA1" }} />}
          {percentageChange < 0 && <ArrowDownward sx={{ color: "#F04461" }} />}
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography fontWeight={700} fontSize={16}>
            {count}
          </Typography>
          <Typography
            sx={{ color: percentageChange >= 0 ? "#42BDA1" : "#F04461" }}
          >
            {percentageChange > 0 ? "+" : ""}
            {percentageChange}%
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default DashboardCard;
