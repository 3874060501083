import { Button, Container, Stack } from "@mui/material";
import React from "react";
import app_crash from "../assets/app_crash.png";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }
  render() {
    if (this.state.errorInfo) {
      console.log("🚀 ~ errorInfo:", this.state.errorInfo);
      // Error path
      return (
        <Stack>
          <Container justifyContent="center" display="flex">
            <img src={app_crash} />
            <a href="/">
              <Button
                variant="contained"
                sx={{
                  mt: 4,
                  color: "White",
                  "&:hover": { backgroundColor: "green" },
                }}
              >
                Go to Home
              </Button>
            </a>
          </Container>
          <details
            style={{ whiteSpace: "pre-wrap", fontSize: "20px", margin: "4rem" }}
          >
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </Stack>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
export default ErrorBoundary;
