import { useEffect, useState } from "react";
import { getPracticeAreas } from "../../sanity/lib/sanityClient";
import TypingEffect from "../TypingEffect";

const NewTopComponent = () => {
  const [practiceAreaTitles, setPracticeAreaTitles] = useState([
    "Family",
    "Business",
    "Criminal",
    "Immigration",
  ]);

  useEffect(() => {
    const fetchPracticeAreas = async () => {
      try {
        const areas = await getPracticeAreas();
        const titles = areas.map((area) => area.title);
        if (titles.length > 0) {
          setPracticeAreaTitles(titles);
        }
      } catch (error) {
        console.error("Error fetching practice areas:", error);
      }
    };

    fetchPracticeAreas();
  }, []);

  return (
    <div className="w-full max-w-3xl px-4 pt-10 pb-8 mx-auto text-center hero text-secondary">
      {/* Added a container with fixed height for the main heading */}
      <div className="min-h-[240px] mb-8"> {/* Adjust min-h-[160px] value based on your needs */}
        <h2 className="text-6xl font-bold">
          Are you looking for the ideal{" "}
          <TypingEffect words={practiceAreaTitles} /> lawyer?
        </h2>
      </div>
      <h2 className="text-3xl text-gray-500">
        Enter your legal problem in your own words and let us match you with the
        ideal lawyer.
      </h2>
    </div>
  );
};

export default NewTopComponent;
