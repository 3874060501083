import { UPDATE_BUSINESS_LIST } from "utils/constants/constants";

const initialState = { businesses: null };

export const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BUSINESS_LIST:
      return { ...state, businesses: action.payload };
    default:
      return state;
  }
};
