import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeCurrentModal,
  openForgotPasswordModal,
  openModal,
} from "../../../store/actions/modalActions";
import Button from "../../button/Button";
import {
  checkValidationError,
  PasswordVisibility,
  signInValidation,
} from "../helper";
import Error from "../../error/Error";
import { useLocation, useNavigate } from "react-router-dom";
import { OPEN_SIGN_UP_MODAL } from "utils/constants/constants";
import ReactGA from "react-ga4";

const SignInModal = ({ onClose, onSubmit, loader }) => {
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: signInValidation,
    onSubmit: (values) => {
      values.email = values.email.toLowerCase();
      onSubmit(values);
    },
  });
  // w-[326px] md:w-[544px]
  return (
    <div className="relative bg-[#fff] mx-4 rounded-[20px] shadow dark:bg-gray-700 flex py-[3rem] gap-4 max-w-[1155px]">
      <button
        type="button"
        className="absolute top-4 right-4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex  dark:hover:text-primary text-textColor z-10"
        data-modal-toggle="authentication-modal"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => {
            if (onClose) {
              onClose();
            } else {
              dispatch(closeCurrentModal());
            }
          }}
          aria-hidden="true"
        >
          <path d="M1 1L19 19" stroke="#5A5A5A" strokeWidth="2" />
          <path d="M1 19L19 1" stroke="#5A5A5A" strokeWidth="2" />
        </svg>

        <span className="sr-only">Close modal</span>
      </button>
      <div className="flex-1 px-4 md:px-7 lg:px-12 ">
        <label className="block mb-2 text-[16px] font-[400] text-textColor">
          Welcome!
        </label>

        <h3 className="mb-4 text-[22px] font-[700] text-secondary ">
          Sign In to JuriCon.AI
        </h3>
        <form className="space-y-6" onSubmit={formik.handleSubmit}>
          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-[14px] font-[500] text-textColor"
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
              placeholder="name@email.com"
              onChange={formik.handleChange}
              value={formik.values.email}
              onBlur={formik.handleBlur}
            />
            <Error formik={formik} errorField="email" />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block mb-2 -mt-2 text-[14px] font-[500] text-gray-900 text-textColor"
            >
              Password
            </label>
            <div className="relative">
              <input
                type={!showPassword ? "password" : "text"}
                name="password"
                id="password"
                placeholder="••••••••"
                className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-textColor"
                onChange={formik.handleChange}
                value={formik.values.password}
                onBlur={formik.handleBlur}
              />
              <PasswordVisibility
                showPassword={showPassword}
                setShowPassword={setShowPassword}
                className="top-4 right-3"
              />
            </div>
            <Error formik={formik} errorField="password" />
          </div>
          <div className="flex justify-between">
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="remember"
                  type="checkbox"
                  value=""
                  className="w-4 h-4 border bg-none clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%) text-primary border-textColor rounded-sm"
                />
              </div>
              <label
                htmlFor="remember"
                className="ml-2 text-[14px] font-[500] text-gray-900 dark:text-gray-300 text-textColor"
              >
                Remember me
              </label>
            </div>
            <p
              onClick={() => dispatch(openForgotPasswordModal())}
              className=" hover:underline dark:text-blue-500 text-textColor cursor-pointer text-[14px] font-[500]"
            >
              Forget Password?
            </p>
          </div>

          <Button
            type="submit"
            loader={loader}
            disabled={loader || checkValidationError(formik)}
            className="bg-primary hover:bg-hoverColor h-[50px] text-[16px] font-[600] "
          >
            Sign In
          </Button>
          <div className="lg:hidden flex items-center text-[14px] font-[400] text-gray-500 dark:text-gray-300 justify-center text-textColor text-center">
            Don't have an account?
            <p
              onClick={() => {
                // send sign up start event to google analytics
                ReactGA.event({
                  category: "start sign up",
                  action: "Juricon_Sign_Up_Start",
                });

                dispatch(openModal(OPEN_SIGN_UP_MODAL));
              }}
              className=" hover:underline dark:text-blue-500 w-fit text-center text-primary hover:text-hoverColor cursor-pointer pl-2"
            >
              Sign Up
            </p>
          </div>
        </form>
      </div>
      <div className="signIn-aside hidden flex-1 px-12 rounded-[20px] lg:flex flex-col justify-between ">
        <div>
          <h3 className="text-[22px] font-[700] text-secondary ">
            Don’t have an account?
          </h3>
          <h3 className="mb-5 text-[22px] font-[700] text-secondary ">
            Sign up here for FREE!
          </h3>
        </div>
        <div>
          <p className="mb-4 text-textColor text-[20px] font-[500] ">
            JuriCon.ai leverages the power of artificial intelligence to find
            and book you the right lawyer!
          </p>
          <ul className="text-secondary text-[16px] font-[600] px-5">
            <li className="my-2">FREE to sign up</li>
            <li className="my-2">FREE to use</li>
            <li className="my-2">Check your previous searches</li>
            <li className="my-2">Book Appointment</li>
          </ul>
        </div>
        <Button
          className="mt-3 bg-primary hover:bg-hoverColor h-[50px] text-[16px] font-[600] "
          onClick={() => {
            // send sign up start event to google analytics
            ReactGA.event({
              category: "start sign up",
              action: "Juricon_Sign_Up_Start",
            });

            dispatch(openModal(OPEN_SIGN_UP_MODAL));
          }}
        >
          Sign Up
        </Button>
      </div>
    </div>
  );
};

export default SignInModal;
