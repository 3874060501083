import React from "react";
import { ADVANCED_SEARCH, GENERAL_SEARCH } from "utils/constants/constants";
import { fetchCategoryName } from "utils/helperFunctions/helper";

const SearchText = ({ searchRecord, categories }) => {
  const searchType = searchRecord?.category ? ADVANCED_SEARCH : GENERAL_SEARCH;
  if (searchType === ADVANCED_SEARCH) {
    const fetchedCategory = fetchCategoryName(
      categories,
      searchRecord?.category
    );

    return <p>{fetchedCategory?.name ?? searchRecord?.school}</p>;
  }
  return (
    <p>
      {searchRecord?.text ??
        searchRecord?.name ??
        searchRecord?.school ??
        searchRecord?.association}
    </p>
  );
};

export default SearchText;
