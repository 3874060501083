import { routes } from "utils/appConfig";

export const fetchSubscriptionPlans = (callApi, cb) => {
  const { url, method } = routes.subscriptionPlans;

  try {
    callApi(
      url,
      method,
      {},
      {
        onSuccess: ({ data }) => {
          cb(data);
        },
        onError: (error) => {
          cb(null, error);
        },
        turnOffNotification: true,
      }
    );
  } catch (e) {
    console.error("🚀 ~ e:", e);
    throw new Error();
  }
};
