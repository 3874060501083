import { useState } from "react";
import { subscribeToNewsletter } from "services/subscribeToNewsletter";
import { useAxios } from "utils/hooks/useAxios";

const NewsletterSubscriptionPopup = ({ setShowPopup }) => {
  const { callApi } = useAxios(); // Access the callApi function from the custom hook
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [message, setMessage] = useState("");

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle the subscription logic
  const handleSubscribe = async () => {
    if (!agreeToTerms) {
      setMessage("You must agree to receive communications from JuriCon.ai.");
      return;
    }

    try {
      await subscribeToNewsletter(callApi, formData, (response, error) => {
        if (response) {
          setMessage("Thank you for subscribing to our newsletter!");
          setFormData({ firstName: "", lastName: "", email: "" });
          setAgreeToTerms(false);
          setTimeout(() => setShowPopup(false), 2000);
        } else {
          console.error("Subscription error:", error);
          setMessage("An error occurred. Please try again later.");
        }
      });
    } catch (error) {
      console.error("Subscription error:", error);
      setMessage("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="p-6 bg-white rounded-lg shadow-lg w-80">
        <h3 className="mb-4 text-xl font-semibold text-center">
          Subscribe to Our Newsletter
        </h3>
        <input
          type="text"
          name="firstName"
          placeholder="First Name"
          value={formData.firstName}
          onChange={handleInputChange}
          className="w-full p-2 mb-2 border border-gray-300 rounded-md"
        />
        <input
          type="text"
          name="lastName"
          placeholder="Last Name"
          value={formData.lastName}
          onChange={handleInputChange}
          className="w-full p-2 mb-2 border border-gray-300 rounded-md"
        />
        <input
          type="email"
          name="email"
          placeholder="Email Address"
          value={formData.email}
          onChange={handleInputChange}
          className="w-full p-2 mb-4 border border-gray-300 rounded-md"
        />

        {/* Agree to Terms Checkbox */}
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            id="agreeToTerms"
            checked={agreeToTerms}
            onChange={(e) => setAgreeToTerms(e.target.checked)}
            className="mr-2"
          />
          <label htmlFor="agreeToTerms" className="text-sm text-gray-600">
            I agree to receive newsletter and communications from JuriCon.ai.
          </label>
        </div>

        {/* Display message */}
        {message && (
          <p
            className={`mb-4 text-sm ${
              message.includes("Thank") ? "text-green-600" : "text-red-600"
            }`}
          >
            {message}
          </p>
        )}

        {/* Subscribe Button */}
        <button
          onClick={handleSubscribe}
          className="w-full px-4 py-2 text-white rounded-md bg-primary hover:bg-secondary"
        >
          Subscribe
        </button>

        {/* Cancel Button */}
        <button
          onClick={() => setShowPopup(false)}
          className="w-full px-4 py-2 mt-2 text-gray-500 bg-gray-100 rounded-md hover:bg-gray-200"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default NewsletterSubscriptionPopup;
