import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  ListItemButton,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MenuIcon from "@mui/icons-material/Menu";
import Logout from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useNavigate } from "react-router-dom";

import logo from "assets/logo_small.png";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "store/actions/authActions";
import NavDropDown from "components/navbar/NavDropDown";

const drawerWidth = 240;

const MainAppBar = styled(AppBar)(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "white",
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const MainDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  ...(theme.breakpoints.down("md") && {
    "& .MuiDrawer-paper": {
      width: "100%",
    },
  }),
}));

const AdminNav = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const businessList = useSelector((state) => state.user?.myBusinesses);
  const user = useSelector((state) => state.user);
  const { profilePicture, firstName } = user;
  const [open, setOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <MainAppBar position="fixed" open={open}>
        <Toolbar>
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Stack direction="row" alignItems="center">
              <IconButton
                edge="start"
                onClick={() => setOpen(!open)}
                sx={{ marginRight: 2 }}
                color="inherit"
                aria-label="open drawer"
                size="large"
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                noWrap
                sx={{
                  flexGrow: 1,
                }}
              >
                Dashboard
              </Typography>
            </Stack>
            <>
              <div
                className="flex items-center hover:cursor-pointer"
                onClick={() => setShowMenu(!showMenu)}
              >
                {profilePicture ? (
                  <img
                    src={profilePicture}
                    className="rounded-md w-[40px] h-[40px] object-contain"
                    alt={firstName}
                  />
                ) : (
                  <i className="fa-solid fa-user text-borderColor fa-xl"></i>
                )}
                <i className="fa-solid fa-chevron-down text-[#5A5A5A] text-lg pl-1"></i>
              </div>
              {showMenu && (
                <NavDropDown
                  name={firstName}
                  setShowMenu={setShowMenu}
                  businessList={businessList}
                />
              )}
            </>
          </Stack>
        </Toolbar>
      </MainAppBar>

      <MainDrawer
        variant="persistent"
        anchor="left"
        open={open}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: (theme) => theme.spacing(5, 1),
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        >
          <img className="object-contain" src={logo} alt="logo" />
        </Box>
        <Divider />
        <List>
          <ListItemButton selected>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
          {/* <ListItemButton>
            <ListItemIcon>
              <ManageAccountsIcon />
            </ListItemIcon>
            <ListItemText primary="User Management" />
          </ListItemButton> */}
        </List>
        <List sx={{ marginTop: "auto" }}>
          <Divider />
          <ListItemButton
            onClick={() => {
              dispatch(signOut());
              navigate("/");
            }}
            sx={{ marginTop: "auto" }}
          >
            <ListItemIcon>
              {" "}
              <Logout />{" "}
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </List>
      </MainDrawer>
    </Box>
  );
};
export default AdminNav;
