import { createClient } from "@sanity/client";
import { apiVersion, dataset, projectId, useCdn } from "../config";

export const client = createClient({
  apiVersion,
  dataset,
  projectId,
  useCdn,
});

//fetch blog posty by slug
export const getBlogPostBySlug = async (slug) => {
  const query = `*[_type == "blogPost" && slug.current == $slug][0]{
  title,
  excerpt,
  body,
  mainImage {
    asset-> {
      _id,
      url
    }
  },
  publishedAt,
  "author": author->name,
  seoTitle,
  seoDescription,
  ogTitle,
  ogDescription,
  "ogImage": ogImage.asset->url,
  tags
}`;
  return await client.fetch(query, { slug });
};
//fetch pinned blog posts
export async function getPinnedBlogPosts() {
  const query = `*[_type == "blogPost" && pinned == true] | order(publishedAt desc) {
    title,
    slug,
    mainImage,
    excerpt,
    publishedAt
  }`;
  return await client.fetch(query);
}
//fetch latest blog posts
export const getLatestBlogPosts = async () => {
  const query = `*[_type == "blogPost"] | order(publishedAt desc) {
    _id,
    title,
    slug,
    excerpt,
    mainImage,
    publishedAt
  }`;
  return await client.fetch(query);
};
//fetch SEO settings for a landing page
export async function getSeoSettingsForPage(pageName) {
  const query = `*[_type == "seoSettings" && page == $pageName][0]{
    metaTitle,
    metaDescription,
    keywords,
    ogTitle,
    ogDescription,
    "ogImage": ogImage.asset->url,
    twitterTitle,
    twitterDescription,
    "twitterImage": twitterImage.asset->url
  }`;
  return await client.fetch(query, { pageName });
}

// fetch all blog posts
export async function getAllBlogPosts() {
  const query = `*[_type == "blogPost"] | order(publishedAt desc) {
    title,
    slug,
    mainImage,
    excerpt,
    publishedAt,
    pinned,
    tags,
    seoTitle,
    seoDescription,
    ogImage,
    ogTitle,
    ogDescription
  }`;
  return await client.fetch(query);
}
//fetch service page (pracing plans)
export const getServicesPage = async () => {
  const query = `*[_type == "servicesPage"][0]{
    title,
    description,
    videoUrl,
    plans,
    outro,
    seoTitle,
    seoDescription,
    "ogImage": ogImage.asset->url
  }`;
  return await client.fetch(query);
};

//fetch all practice areas
export const getPracticeAreas = async () => {
  const query = `*[_type == "practiceArea"] {
    title
  }`;
  try {
    const areas = await client.fetch(query);
    return areas;
  } catch (error) {
    console.error("Error fetching practice areas:", error);
    throw error;
  }
};

export default client;
