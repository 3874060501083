import React, { useEffect } from "react";
import AddFieldButton from "components/editProviderNewScope/addFieldButton/AddFieldButton";
import EditProfileFooter from "components/PremiumProviderEditProfileComponents/editProfilFooter/EditProfileFooter";
import { BILLING } from "components/PremiumProviderEditProfileComponents/constants/editProfileConstants";
import StepLabel from "components/editProviderNewScope/stepLabel/StepLabel";
import { useFormik } from "formik";
import {
  achievementsValidationNewScope,
  awardsAndAssociationsObject,
  publicationsObject,
  speakingengagementsObject, stepDataNewScope
} from "components/editProviderNewScope/helper";
import {
  addItemNewScope,
  removeItemNewScope,
} from "components/editProviderNewScope/educationNewScope/EducationNewScope";
import RemoveIcon from "components/removeIcon/RemoveIcon";
import Error from "components/error/Error";
import DatePicker from "components/datePicker/DatePicker";
import moment from "moment";
import { cleanUpValues, formatUrl } from "utils/helperFunctions/helper";
import EditProfileSteps from "../../PremiumProviderEditProfileComponents/editProfileSteps/EditProfileSteps";
import { removeEmptyValuesFromObject } from "../../PremiumProviderEditProfileComponents/helper";

const AchievementsNewScope = ({
  user,
  step,
  setStep,
  onSubmit,
}) => {
  const stepsCompleted = user?.stepsCompleted;
  const publications= user?.publications ?? [];
  const awards= user?.awards ?? [];
  const associations= user?.associations ?? [];
  const speakingengagements= user?.speakingengagements ?? [];

  const formik = useFormik({
    initialValues: {
      awards: [awardsAndAssociationsObject],
      associations: [awardsAndAssociationsObject],
      publications: [publicationsObject],
      speakingengagements: [speakingengagementsObject],
    },
    validationSchema: achievementsValidationNewScope,
    onSubmit: (values) => {
      const _cleanedPublicationValues = [...values.publications].map(
        cleanUpValues
      );
      const _cleanedAwardValues = [...values.awards].map(cleanUpValues);
      const _cleanedAssociationValues = [...values.associations].map(
        cleanUpValues
      );
      const _cleanedSpeakingEngagementValues = [...values.speakingengagements].map(
        cleanUpValues
      );
      if (stepsCompleted === 4) values.stepsCompleted = 5;
      const combineValues = {
        ...values,
        awards: _cleanedAwardValues,
        associations: _cleanedAssociationValues,
        publications: _cleanedPublicationValues,
        speakingengagements: _cleanedSpeakingEngagementValues,
      };
      onSubmit(combineValues);
    },
  });

  useEffect(() => {
    if (publications?.length) {
      formik.setFieldValue("publications", publications);
    }
    if (associations?.length) {
      formik.setFieldValue("associations", associations);
    }
    if (awards?.length) {
      formik.setFieldValue("awards", awards);
    }
    if (speakingengagements?.length) {
      formik.setFieldValue("speakingengagements", speakingengagements);
    }
  }, [publications, associations, awards, speakingengagements]);

  const { getFieldProps } = formik;

  const updateDate = (value, index, array, key) => {
    formik.setFieldTouched(`${array}[${index}].${key}`, true);
    if (!value) {
      formik.setFieldValue(`${array}[${index}].${key}`, "");
    } else {
      formik.setFieldValue(
        `${array}[${index}].${key}`,
        moment(value).format("MM/DD/YYYY")
      );
    }
  };

  const updateUrl = (value, index, array, key) => {
    formik.setFieldTouched(`${array}[${index}].${key}`, true);
    if (!value) {
      formik.setFieldValue(`${array}[${index}].${key}`, "");
    } else {
      formik.setFieldValue(
        `${array}[${index}].${key}`, formatUrl(value)
      );
    }
  };
  const validateAndSubmit = async (values) => {
    const _cleanedPublicationValues = [...values.publications].map(
      cleanUpValues
    );
    const _cleanedAwardValues = [...values.awards].map(cleanUpValues);
    const _cleanedAssociationValues = [...values.associations].map(
      cleanUpValues
    );
    const _cleanedSpeakingEngagementValues = [...values.speakingengagements].map(
      cleanUpValues
    );
    if (stepsCompleted === 4) values.stepsCompleted = 5;
    const combineValues = {
      ...values,
      awards: _cleanedAwardValues,
      associations: _cleanedAssociationValues,
      publications: _cleanedPublicationValues,
      speakingengagements: _cleanedSpeakingEngagementValues,
    };
    onSubmit(combineValues, true);
  };
  const saveStep = () => {
    validateAndSubmit(formik.values);
  };
  return (
    <div className="mb-12">
      <EditProfileSteps
        step={step}
        stepsCompleted={user?.stepsCompleted}
        setStep={setStep}
        stepData={stepDataNewScope}
        saveStep={saveStep}
      />
      <div className="popup-modal">
        <div className="flex-wrap ">
          <div className=" py-6">
            <form onSubmit={formik.handleSubmit}>
              <>
                <StepLabel label="Publications" />
                {formik.values.publications.map((publication, index) => {
                  return (
                    <div key={index} className="space-y-6 profile-form mb-8">
                      <div className="flex items-start input-wrap justify-between gap-3 sm:gap-0 relative">
                        {formik.values.publications.length > 1 && (
                          <div className="absolute top-0 right-0">
                            <RemoveIcon
                              onClick={() =>
                                removeItemNewScope(
                                  index,
                                  "publications",
                                  formik
                                )
                              }
                            />
                          </div>
                        )}
                        <div className="w-[100%]">
                          <label
                            htmlFor="firstName"
                            className="block mb-2 text-[14px] font-[500] text-textColor"
                          >
                            Article, Blog or Book Title
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                            placeholder="Article, Blog or Book Title"
                            {...getFieldProps(`publications[${index}.name]`)}
                          />
                          <Error
                            formik={formik}
                            errorField="name"
                            property="publications"
                            index={index}
                          />
                        </div>
                        <div className="w-[100%] sm:pl-3 ">
                          <label className="block mb-2 text-[14px] font-[500] text-textColor">
                            Published Date
                          </label>
                          <DatePicker
                            name="publishedAt"
                            id="publishedAt"
                            format="MM/DD/YYYY"
                            placeholder="MM/DD/YYYY"
                            size="large"
                            onBlur={formik.handleBlur}
                            value={publication.publishedAt}
                            onChange={(value) =>
                              updateDate(
                                value,
                                index,
                                "publications",
                                "publishedAt"
                              )
                            }
                          />
                          <div className="sm:mt-[1.35rem]">
                            <Error
                              formik={formik}
                              errorField="publishedAt"
                              property="publications"
                              index={index}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-start input-wrap justify-between gap-3 sm:gap-0">
                        <div className="w-[100%]">
                          <label className="block mb-2 text-[14px] font-[500] text-textColor">
                            Article URL (optional)
                          </label>
                          <input
                            type="text"
                            name="url"
                            id="url"
                            onChange={(value) => 
                              updateUrl(
                                value, 
                                index, 
                                "publications", 
                                "url"
                              )
                            }
                            className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                            placeholder="URL where the article, blog or book title can be found – if applicable"
                            {...getFieldProps(`publications[${index}.url]`)}
                          />
                          <Error
                            formik={formik}
                            errorField="url"
                            property="publications"
                            index={index}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
                <AddFieldButton
                  buttonText="Add Publication"
                  onClick={() =>
                    addItemNewScope("publications", formik, publicationsObject)
                  }
                />
              </>
              <>
                <StepLabel label="Speaking Engagements" />
                {formik.values.speakingengagements.map((speakingengagement, index) => {
                  return (
                    <div key={index} className="space-y-6 profile-form mb-8">
                      <div className="flex items-start input-wrap justify-between gap-3 sm:gap-0 relative">
                        {formik.values.speakingengagements.length > 1 && (
                          <div className="absolute top-0 right-0">
                            <RemoveIcon
                              onClick={() =>
                                removeItemNewScope(
                                  index,
                                  "speakingengagements",
                                  formik
                                )
                              }
                            />
                          </div>
                        )}
                        <div className="w-[100%]">
                          <label
                            htmlFor="firstName"
                            className="block mb-2 text-[14px] font-[500] text-textColor"
                          >
                            Title of Presentation
                          </label>
                          <input
                            type="text"
                            name="presentationname"
                            id="presentationname"
                            className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                            placeholder="Title of Presentation"
                            {...getFieldProps(`speakingengagements[${index}.name]`)}
                          />
                          <Error
                            formik={formik}
                            errorField="name"
                            property="speakingengagements"
                            index={index}
                          />
                        </div>
                        <div className="w-[100%] sm:pl-3 ">
                          <label className="block mb-2 text-[14px] font-[500] text-textColor">
                            Presentation Date
                          </label>
                          <DatePicker
                            name="presentedAt"
                            id="presentedAt"
                            format="MM/DD/YYYY"
                            placeholder="MM/DD/YYYY"
                            size="large"
                            onBlur={formik.handleBlur}
                            value={speakingengagement.presentedAt}
                            onChange={(value) =>
                              updateDate(
                                value,
                                index,
                                "speakingengagements",
                                "presentedAt"
                              )
                            }
                          />
                          <div className="sm:mt-[1.35rem]">
                            <Error
                              formik={formik}
                              errorField="presentedAt"
                              property="speakingengagements"
                              index={index}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-start input-wrap justify-between gap-3 sm:gap-0">
                        <div className="w-[100%]">
                          <label className="block mb-2 text-[14px] font-[500] text-textColor">
                            Entity or Organization Presented To
                          </label>
                          <input
                            type="text"
                            name="entityname"
                            id="entityname"
                            className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                            placeholder="Name of the Entity or Organization Presented To"
                            {...getFieldProps(`speakingengagements[${index}.entityname]`)}
                          />
                          <Error
                            formik={formik}
                            errorField="entityname"
                            property="speakingengagements"
                            index={index}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
                <AddFieldButton
                  buttonText="Add Speaking Engagement"
                  onClick={() =>
                    addItemNewScope("speakingengagements", formik, speakingengagementsObject)
                  }
                />
              </>
              {/* AWARDS */}
              <>
                <StepLabel label="Awards and Recognitions" />
                {formik.values.awards.map((award, i) => (
                  <div className="space-y-6 profile-form mb-8 relative" key={i}>
                    {formik.values.awards.length > 1 && (
                      <div className="absolute top-0 right-0">
                        <RemoveIcon
                          onClick={() =>
                            removeItemNewScope(i, "awards", formik)
                          }
                        />
                      </div>
                    )}
                    <div className="flex input-wrap justify-between">
                      <div className="w-[100%] ">
                        <label className="block mb-2 text-[16px] font-[500] text-textColor">
                          Name
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none
                      "
                          placeholder="Name of Award"
                          {...getFieldProps(`awards[${i}].title`)}
                        />
                        <Error
                          formik={formik}
                          errorField="title"
                          property="awards"
                          index={i}
                        />
                      </div>
                      <div className="w-[100%] pl-3 padding-input padding-input-t">
                        <label className="block mb-2 text-[16px] font-[500] text-textColor">
                          Date
                        </label>
                        <DatePicker
                          name="date"
                          id="date"
                          placeholder="YYYY"
                          size="large"
                          views={["year"]}
                          value={award.date}
                          onChange={(value) =>
                            updateDate(value, i, "awards", "date")
                          }
                        />
                        <div className="sm:mt-[1.35rem]">
                          <Error
                            formik={formik}
                            errorField="date"
                            property="awards"
                            index={i}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <AddFieldButton
                  buttonText="Add Award"
                  onClick={() =>
                    addItemNewScope(
                      "awards",
                      formik,
                      awardsAndAssociationsObject
                    )
                  }
                />
              </>
              {/* ASSOCIATIONS */}
              <>
                <StepLabel label="Associations" />
                {formik.values.associations.map((association, i) => (
                  <div className="space-y-6 profile-form mb-8 relative" key={i}>
                    {formik.values.associations.length > 1 && (
                      <div className="absolute top-0 right-0">
                        <RemoveIcon
                          onClick={() =>
                            removeItemNewScope(i, "associations", formik)
                          }
                        />
                      </div>
                    )}
                    <div className="flex input-wrap justify-between">
                      <div className="w-[100%] ">
                        <label className="block mb-2 text-[16px] font-[500] text-textColor">
                          Name
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none
                      "
                          placeholder="Name of Association"
                          {...getFieldProps(`associations[${i}].title`)}
                        />
                        <Error
                          formik={formik}
                          errorField="title"
                          property="associations"
                          index={i}
                        />
                      </div>
                      <div className="w-[100%] pl-3 padding-input padding-input-t">
                        <label className="block mb-2 text-[16px] font-[500] text-textColor">
                          Member Since
                        </label>
                        <DatePicker
                          name="date"
                          id="date"
                          size="large"
                          views={["year"]}
                          placeholder="YYYY"
                          value={association.date}
                          onChange={(value) =>
                            updateDate(value, i, "associations", "date")
                          }
                        />
                        <div className="sm:mt-[1.35rem]">
                          <Error
                            formik={formik}
                            errorField="date"
                            property="associations"
                            index={i}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <AddFieldButton
                  buttonText="Add Association"
                  onClick={() =>
                    addItemNewScope(
                      "associations",
                      formik,
                      awardsAndAssociationsObject
                    )
                  }
                />
              </>
            </form>
          </div>
        </div>
      </div>
      <EditProfileFooter
        pre={() => setStep(BILLING)}
        step={step}
        next={() => {
          formik.handleSubmit();
        }}
      />
    </div>
  );
};

export default AchievementsNewScope;
