import { routes } from "utils/appConfig";

export const fetchUserInfoById = (callApi, userId, cb) => {
  const { url, method } = routes.fetchUser;
  try {
    callApi(
      url(userId),
      method,
      {},
      {
        onSuccess: ({ data }) => {
          cb(data);
        },
        onError: (res) => cb(null, res),
        errorMessage: "Something went wrong!",
        turnOffNotification: true,
      }
    );
  } catch (e) {
    console.log("fetchUserInfoById", e);
  }
};

export const fetchUserInfoByName = (callApi, name, cb) => {
  const { url, method } = routes.fetchUserByName;
  try {
    callApi(
      url(name),
      method,
      {},
      {
        onSuccess: ({ data }) => {
          cb(data);
        },
        onError: (res) => cb(null, res),
        errorMessage: "Something went wrong!",
        turnOffNotification: true,
      }
    );
  } catch (e) {
    console.log("fetchUserInfoByName", e);
  }
};

export const fetchLoggedInUserInfo = (callApi, cb) => {
  const { url, method } = routes.me;
  try {
    callApi(
      url,
      method,
      {},
      {
        onSuccess: ({ data }) => {
          cb(data);
        },
        errorMessage: "Something went wrong!",
        turnOffNotification: true,
      }
    );
  } catch (e) {
    console.log("fetchLoggedInUserInfo", e);
  }
};
