import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Grid, Stack, TextField, Typography } from "@mui/material";
import Button from "components/button/Button";

const DateRangeSelector = ({ onDateSelected, startDate, endDate }) => {
  const formik = useFormik({
    initialValues: {
      startDate: null,
      endDate: null,
    },
    validationSchema: yup.object({
      startDate: yup
        .date()
        .nullable()
        .required("Start date is required")
        .test(
          "isValidDateRange",
          "Start date must be before end date",
          function (value) {
            return (
              !value || !formik.values.endDate || value <= formik.values.endDate
            );
          }
        ),
      endDate: yup
        .date()
        .nullable()
        .required("End date is required")
        .test(
          "isValidDateRange",
          "End date must be after start date",
          function (value) {
            return (
              !value ||
              !formik.values.startDate ||
              value >= formik.values.startDate
            );
          }
        ),
    }),
    onSubmit: (values) => {
      onDateSelected({ start: values.startDate, end: values.endDate });
    },
  });
  useEffect(() => {
    formik.setValues({
      startDate: moment(startDate),
      endDate: moment(endDate),
    });
  }, []);
  const handleStartDateChange = (date) => {
    formik.setFieldValue("startDate", date);
  };

  const handleEndDateChange = (date) => {
    formik.setFieldValue("endDate", date);
  };

  const handleLast7Days = () => {
    const endDate = moment();
    const startDate = moment().subtract(7, "days");
    formik.setValues({ startDate, endDate });
  };

  const handleLastMonth = () => {
    const endDate = moment();
    const startDate = moment().subtract(1, "month");
    formik.setValues({ startDate, endDate });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} locale="en">
      <Stack gap={2} justifyContent="space-around" height="100%">
        <Typography fontSize={18} fontWeight={600}>
          Select Date Range
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <DesktopDatePicker
                label="Start Date"
                maxDate={new Date()}
                value={formik.values.startDate}
                onChange={handleStartDateChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    error={
                      formik.touched.startDate &&
                      Boolean(formik.errors.startDate)
                    }
                    helperText={
                      formik.touched.startDate && formik.errors.startDate
                    }
                  />
                )}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DesktopDatePicker
                label="End Date"
                maxDate={new Date()}
                value={formik.values.endDate}
                onChange={handleEndDateChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    error={
                      formik.touched.endDate && Boolean(formik.errors.endDate)
                    }
                    helperText={formik.touched.endDate && formik.errors.endDate}
                  />
                )}
                required
              />
            </Grid>
            {/* <Grid item>
            <Button variant="outlined" onClick={handleLast7Days}>
              Last 7 Days
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleLastMonth}>
              Last Month
            </Button>
          </Grid> */}
            {/* <Grid item xs={12}></Grid> */}
          </Grid>
        </form>
        <Button
          type="submit"
          className="h-12"
          onClick={formik.handleSubmit}
          variant="contained"
          sx={{ color: "#ffff" }}
        >
          Update
        </Button>
      </Stack>
    </LocalizationProvider>
  );
};

export default DateRangeSelector;
