import React from "react";
import { PERSONAL_INFO } from "components/PremiumProviderEditProfileComponents/constants/editProfileConstants";
import Loader from "utils/loader/Loader";

const EditProfileFooter = ({ step, pre, next, nextButtonTitle, isLoading }) => {
  return (
    <div className="flex justify-between flex-wrap h-[20%] w-100 py-4 mb-24 mt-12">
      {step !== PERSONAL_INFO && (
        <button
          className="bg-primary hover:bg-hoverColor  w-[125px] h-[45px] sm:h-[56px] sm:w-[177px] rounded-xs text-white duration-300"
          onClick={pre}
        >
          Previous
        </button>
      )}
      {/*  EMPTY DIV TO SET THE NEXT BUTTON AT END WHILE FIRST STEP  */}
      <div></div>

      <button
        type="submit"
        className="bg-primary w-[125px] h-[45px] sm:h-[56px] sm:w-[177px] rounded-xs text-white hover:bg-hoverColor duration-300"
        onClick={next}
      >
        {isLoading ? <Loader /> : nextButtonTitle ?? "Next"}
      </button>
    </div>
  );
};

export default EditProfileFooter;
