import { routes } from "utils/appConfig";

export const adminPanelUserUpdate = async (callApi, userId, data, callBack) => {
  const { url, method } = routes.admin.updateUser;
  try {
    callApi(url(userId), method, data, {
      onSuccess: ({ data }) => callBack(data),
      onError: (res) => callBack(null, res),
      successMessage: "User updated successfully!",
    });
  } catch (e) {
    throw new Error(e.response);
  }
};
