import React, { useEffect, useState } from "react";
import MembershipPlans from "components/membership/MembershipPlans";
import SubscribedPlan from "components/membership/SubscribedPlan";
import PaymentCard from "components/membership/PaymentCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchPaymentCards } from "services/fetchPaymentCards";
import { useAxios } from "utils/hooks/useAxios";
import Loader from "utils/loader/Loader";
import { openModal } from "store/actions/modalActions";
import { ADD_CREDIT_CARD_MODAL } from "utils/constants/constants";
import { updateCreditCards } from "store/actions/actions";
import {
  formattedCurrency,
  isPremiumSubscription,
} from "utils/helperFunctions/helper";
import { fetchSubscriptionPlans } from "services/fetchSubscriptionPlans";
import Button from "components/button/Button";
import { Link } from "react-router-dom";
import moment from "moment";

const Membership = () => {
  const dispatch = useDispatch();
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const userType = useSelector((state) => state.user?.userType);
  const subscriptionId = useSelector((state) => state.user?.subscription);
  const nextBillingDate = useSelector((state) => state.user?.nextBillingDate);

  const paymentCards = useSelector(
    (state) => state.membershipReducer?.paymentCards
  );
  const { isLoading, callApi } = useAxios();
  const { isLoading: subscriptionPlanLoading, callApi: subscriptionPlanApi } =
    useAxios();
  useEffect(() => {
    if (userType === "PROVIDER" && !!subscriptionId) {
      fetchPaymentCards(callApi, ({ data }) => {
        if (data) {
          dispatch(updateCreditCards(data.data));
        }
      });
    }
  }, [userType]);
  useEffect(() => {
    fetchSubscriptionPlans(subscriptionPlanApi, (data) => {
      if (data) {
        setSubscriptions(data.data);
      }
    });
  }, []);
  useEffect(() => {
    if (subscriptions?.length) {
      setSubscriptionDetails(
        subscriptions.find((subs) => subs.productId === subscriptionId)
      );
    }
  }, [subscriptionId, subscriptions]);
  return (
    <>
      <section className="p-5 w-[90%] mx-auto">
        <div className="flex justify-between min-h-[3rem] items-center mb-5">
          <h2 className="text-secondary text-xl text-center font-bold">
            Cards
          </h2>
          <Link to="/invoices">
            <Button className="w-fit h-[2.5rem] focus:ring-0">Invoices</Button>
          </Link>
        </div>
        {isLoading ? (
          <Loader width={50} height={50} />
        ) : (
          <div className="cards flex gap-6 overflow-x-auto min-h-[202px] pb-2">
            <button
              onClick={() => dispatch(openModal(ADD_CREDIT_CARD_MODAL))}
              className="add-card text-primary font-bold text-3xl flex justify-center items-center bg-[#f3f3f3] hover:bg-[#00A4DA] hover:bg-opacity-30  hover:outline-[#00A4DA] transition ease-in-out duration-500 min-w-[100px] rounded-md "
            >
              +
            </button>
            {!!paymentCards?.length &&
              paymentCards.map((card) => {
                return (
                  <PaymentCard
                    key={card.id}
                    defaultCard={card?.isDefaultPaymentMethod}
                    cardExpiry={`${
                      card?.card?.exp_month > 9
                        ? card?.card?.exp_month
                        : `0${card?.card?.exp_month}`
                    }/${card?.card?.exp_year}`}
                    lastFourDigit={card?.card?.last4}
                    type={card?.card?.brand}
                    holderName={card?.billing_details?.name}
                    cardId={card.id}
                  />
                );
              })}
          </div>
        )}
        {!!subscriptionId && !!subscriptionDetails?.productName && (
          <>
            <h2 className="text-secondary text-xl font-bold mt-5 mb-9">
              Subscribed Plan
            </h2>
            <div className="flex gap-8 flex-wrap">
              <SubscribedPlan
                type={subscriptionDetails?.productName}
                price={subscriptionDetails?.productPrice}
                description={subscriptionDetails?.productDescription}
              />
              <div className="py-7 px-10 border border-borderColor rounded-sm ">
                <h5 className="text-secondary font-semibold text-base">
                  Next Payment
                </h5>
                <h3 className="text-xl font-bold text-secondary my-1 ">
                  {formattedCurrency(subscriptionDetails?.productPrice)}/y
                </h3>
                {nextBillingDate && (
                  <p className="text-[#979797] font-semibold text-base">
                    {moment(nextBillingDate).format("MMM DD, YYYY")}
                  </p>
                )}
              </div>
            </div>
          </>
        )}
        <h2 className="text-secondary text-xl font-bold mt-5 mb-9">
          Membership Plans
        </h2>
        <div className="flex gap-8 flex-col mb-10">
          {subscriptionPlanLoading ? (
            <Loader width={50} height={50} />
          ) : (
            !!subscriptions?.length &&
            subscriptions.map((plan) => {
              return (
                <MembershipPlans
                  key={plan?.productId}
                  productId={plan?.productId}
                  type={plan?.productName}
                  price={plan?.productPrice}
                  subscription={subscriptionId}
                  productDescription={plan?.productDescription}
                  active={subscriptionId === plan.productId}
                />
              );
            })
          )}
        </div>
      </section>
    </>
  );
};

export default Membership;
