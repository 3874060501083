import { routes } from "utils/appConfig";

export const deleteMyAccount = async (callApi, callBack) => {
  const { url, method } = routes.deleteAccount;
  try {
    callApi(
      url,
      method,
      {},
      {
        onSuccess: ({ data }) => callBack(data),
        onError: (res) => callBack(null, res),
        successMessage: "User Deleted successfully!",
      }
    );
  } catch (e) {
    throw new Error(e.response);
  }
};
