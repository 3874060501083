import React from "react";
import Dialog from "components/dialog/Dialog";
import { useAxios } from "utils/hooks/useAxios";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { phoneValidation } from "components/modals/helper";
import { signInAction } from "store/actions/authActions";
import { TextField } from "@mui/material";
import { updateUserData } from "services/updateUserData";
import { notify } from "utils/toast/toast";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "components/phoneInput/PhoneInput";
import Error from "components/error/Error";

const ChangePhoneForm = ({ open, setOpen }) => {
  const { isLoading, callApi } = useAxios();
  const dispatch = useDispatch();
  const userPhone = useSelector((state) => state.user?.phone);

  const formik = useFormik({
    initialValues: {
      phone: userPhone,
    },
    validationSchema: Yup.object().shape({
      phone: Yup.string()
        .required(" Phone is required")
        .test({
          name: "phone",
          exclusive: true,
          message: " Phone is invalid",
          test: (value) => {
            return isValidPhoneNumber(value ?? "");
          },
        }),
    }),
    onSubmit: async (values) => {
      if (userPhone !== values.phone) {
        updateUserData(callApi, values, (res) => {
          if (res) {
            dispatch(signInAction(res.data));
            notify("Phone updated successfully", "success");
            setOpen(null);
          }
        });
      }
    },
  });
  const handlePhoneChange = (value) => {
    formik.setFieldTouched("phone", true);
    formik.setFieldValue("phone", value);
  };
  return (
    <Dialog
      title="Change Phone"
      open={open}
      onClose={() => setOpen(null)}
      agree={formik.handleSubmit}
      agreeText="Update"
      loading={isLoading}
    >
      <form onSubmit={formik.handleSubmit}>
        <PhoneInput
          id="phone"
          name="phone"
          size="small"
          fullWidth
          value={formik.values.phone}
          onChange={handlePhoneChange}
        />
        <Error formik={formik} errorField="phone" />
      </form>
    </Dialog>
  );
};

export default ChangePhoneForm;
