import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu2 from "./Menu2";
import profileImage from "assets/user.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { extractYear } from "utils/helperFunctions/helper";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Modal, Paper, Stack, TextField } from "@mui/material";
import {
  BILLING,
  EDUCATION,
  PERSONAL_INFO,
} from "components/PremiumProviderEditProfileComponents/constants/editProfileConstants";
import { openModal } from "store/actions/modalActions";
import {
  OPEN_BOOKING_APPOINTMENT_MODAL,
  OPEN_SIGN_IN_MODAL,
  PREMIUM,
  PREMIUM_PACKAGE,
  PROVIDER_CONTACT_MODAL,
} from "utils/constants/constants";
import { isCalAtomsUserIDValid } from "utils/config";
import BookmarkButton from "components/bookmark/BookmarkButton";
import { useAxios } from "utils/hooks/useAxios";
import { getSearchById } from "services/searchById";
import { RenderPracticeAreas } from "components/SearchResultProfileComponents/RenderTag";
import Loader from "utils/loader/Loader";
import { sendInquiry } from "services/sendInquiry";
import { useFormik } from "formik";
import * as Yup from "yup";
import Error from "components/error/Error";
import { CloseOutlined } from "@mui/icons-material";
import ReactGA from "react-ga4";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  maxWidth: 700,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};
let isBookingAppointmentClicked = false

// USED IN NEW SCOPE
export const billingText = (ratePerHourMin) => {
  const minRate = ratePerHourMin ? ratePerHourMin + "/hr" : "";
  return minRate;
};
export default function ComplexGrid({
  provider,
  isMyProfilePage,
  editAble = false,
}) {
  const [bookmarkedLawyers, setIsBookmarkedLawyers] = useState([]);
  const [showInquiryModal, setShowInquiryModal] = useState(false);
  const user = useSelector((state) => state?.user);
  const { states } = useSelector((state) => state.categoriesReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, callApi } = useAxios();
  const location = useLocation();
  const searchId = new URLSearchParams(location.search).get("searchId");
  const isPremiumUser =
    provider?.subscription?.includes(PREMIUM_PACKAGE) ||
    provider?.subscription?.includes(PREMIUM);

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required().min(3).label(" Title"),
      description: Yup.string().required().min(10).label(" Description"),
    }),
    onSubmit: (values) => {
      values.provider = provider._id;
      values.providerEmail = provider?.email;
      values.providerPhone = provider?.phone;
      values.providerName = provider?.firstName + " " + provider?.lastName;

      // send inquiry submit form string to google analytics
      ReactGA.event({
        category: "submit form",
        action: "Juricon_Send-Inquiry-Submit",
        label: "provider: " + values.providerName,
      });
      sendInquiry(callApi, values, (res) => {
        if (res.success) {
          formik.resetForm();
          setShowInquiryModal(false);
        }
      });
    },
  });

  useEffect(() => {
    if (searchId) {
      getSearchById(callApi, searchId, (data) => {
        setIsBookmarkedLawyers(data?.data?.bookmarkedLawyers);
      });
    }
  }, [searchId]);

  useEffect(() => {
    if (isBookingAppointmentClicked && isCalAtomsUserIDValid(provider?.calAtomsUserId)) {
      handleBookingAppointment()
    }
  }, [user]);

  const handleContactButtonClick = () => {
    dispatch(openModal(PROVIDER_CONTACT_MODAL));
  };

  const handlePersonalInfoEditClick = () => {
    navigate("/profile/edit", { state: { goToStep: PERSONAL_INFO } });
  };

  const handleBookingAppointmentClick = (e) => {
    e.stopPropagation()
    handleBookingAppointment()
  };

  const handleBookingAppointment = () => {
    isBookingAppointmentClicked = true
    if (user === null) {
      dispatch(openModal(OPEN_SIGN_IN_MODAL));
    } else {
      // send book appointment start to google analytics
      ReactGA.event({
        category: "start book-appointment",
        action: "Juricon_Book-Appointment-Start",
      });
      isBookingAppointmentClicked = false
      dispatch(openModal(OPEN_BOOKING_APPOINTMENT_MODAL));
    }
  }

  return (
    <Paper
      sx={{
        p: "24px",
        pr: "24px",
        pt: 4,
        marginTop: "40px",
        margin: "auto",
        minHeight: "20rem",
        maxWidth: "100%",
        borderRadius: "8px",
        boxShadow: "none",
        border: "1px solid #C6C7C4",
        position: "relative",
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      }}
    >
      <Grid container gap={2}>
        <Grid item sm={3} md={2}>
          <Box className="max-w-[200px]">
            <img
              src={provider?.profilePicture ?? profileImage}
              className={`rounded-[10px] w-[200px] h-[200px] border-[#E9BA16] object-contain ${
                isPremiumUser ? "border-4" : ""
              }`}
              alt=""
            />
            {/* TODO : SHOW THEM WHEN MATCHES DATA IS PASSED IN USER */}
            {/* <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                width: "100%",
                marginTop: "10px"
              }}
            >
              <Box className="">
                <Typography
                  sx={{ color: "#3CAA91", fontSize: 26, fontWeight: 700 }}
                >
                  200+
                </Typography>
                <Typography
                  sx={{ color: "#3CAA91", fontSize: 14, fontWeight: 500 }}
                  gutterBottom
                >
                  Matches
                </Typography>
              </Box>
              <Box className="">
                <Typography
                  sx={{ color: "#64B4D0", fontSize: 26, fontWeight: 700 }}
                >
                  90+
                </Typography>
                <Typography
                  sx={{ color: "#64B4D0", fontSize: 14, fontWeight: 500 }}
                  gutterBottom
                >
                  Matches
                </Typography>
              </Box>
            </Box> */}
          </Box>
        </Grid>
        <Grid item sm={8} md={9} container>
          <Stack>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                maxWidth: 500,
              }}
            >
              <Typography
                sx={{
                  color: "#002A5C",
                  fontSize: 22,
                  fontWeight: 700,
                }}
              >
                {provider?.firstName} {provider?.lastName}
              </Typography>
              {/* <Rating /> */}
              {!!editAble && (
                <Typography
                  onClick={handlePersonalInfoEditClick}
                  sx={{
                    color: "#00A4DA",
                    fontSize: 16,
                    fontWeight: 600,
                    textAlign: "center",
                    pl: 1,
                    cursor: "pointer",
                  }}
                >
                  Edit
                </Typography>
              )}
            </Box>
            <div className="flex items-center gap-2">
              {/* <Stack direction="row">
                <Typography
                  sx={{
                    color: "#002A5C",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Legal Rights .
                </Typography>
                <Typography
                  sx={{ color: "#5A5A5A", fontSize: 16, fontWeight: 500 }}
                >
                  Associate
                </Typography>
              </Stack> */}
              {editAble && (
                <Button
                  sx={{ fontWeight: 700, marginLeft: "-8px !important" }}
                  onClick={handleContactButtonClick}
                >
                  Contact
                </Button>
              )}
            </div>
            <div className="flex flex-col text-secondary md:flex-row md:items-center gap-2 mb-2">
              {provider?.billingStructure?.includes("PER_HOUR") && (
                <BillingText
                  text={billingText(
                    provider?.ratePerHourMin
                  )}
                />
              )}
              {provider?.billingStructure?.includes("ON_CONTINGENCY") && (
                <BillingText
                  text={`${provider?.onContingency}% Recovery on Contingency`}
                />
              )}
              {provider?.billingStructure?.includes("FIXED_FEE") && (
                <BillingText text="Fixed Fee" />
              )}
              {!!editAble && !!provider?.billingStructure?.length && (
                <span className="hidden md:inline">
                  <Link to="/profile/edit" state={{ goToStep: BILLING }}>
                    <Typography
                      sx={{
                        color: "#00A4DA",
                        fontSize: 16,
                        fontWeight: 600,
                        textAlign: "center",
                        pl: 1,
                        cursor: "pointer",
                      }}
                    >
                      Edit
                    </Typography>
                  </Link>
                </span>
              )}
            </div>
            <Stack
              direction={{ md: "row" }}
              gap={!!provider?.practiceAreas?.length && 2}
            >
              <Stack gap={1}>
                <div className="flex flex-wrap">
                  {!!provider?.practiceAreas?.length && (
                    <Typography
                      sx={{
                        color: "#002A5C",
                        fontSize: 16,
                        fontWeight: 500,
                      }}
                    >
                      Practice Areas
                    </Typography>
                  )}
                  {!!provider?.practicingLawSince && (
                    <Typography
                      sx={{
                        color: "#5A5A5A",
                        fontSize: 14,
                        fontWeight: 500,
                        marginTop: "2px",
                        paddingLeft: { sm: 1 },
                      }}
                    >
                      Since {extractYear(provider?.practicingLawSince)}
                    </Typography>
                  )}
                  {!!editAble && !!provider?.practiceAreas?.length && (
                    <Typography
                      onClick={handlePersonalInfoEditClick}
                      sx={{
                        color: "#00A4DA",
                        fontSize: 16,
                        fontWeight: 600,
                        textAlign: "center",
                        pl: 1,
                        cursor: "pointer",
                      }}
                    >
                      Edit
                    </Typography>
                  )}
                </div>
                <Stack direction="row" gap={2} sx={{ flexWrap: "wrap" }}>
                  {!!provider?.practiceAreas?.length && (
                    <RenderPracticeAreas
                      practiceAreas={provider.practiceAreas}
                    />
                  )}
                </Stack>
              </Stack>
              <Stack gap={1}>
                {!!provider?.represent?.length && (
                  <>
                    <Stack direction="row">
                      <Typography
                        sx={{
                          color: "#002A5C",
                          fontSize: 16,
                          fontWeight: 500,
                        }}
                      >
                        Represents
                      </Typography>
                      {!!editAble && (
                        <Typography
                          onClick={handlePersonalInfoEditClick}
                          sx={{
                            color: "#00A4DA",
                            fontSize: 16,
                            fontWeight: 600,
                            textAlign: "center",
                            pl: 1,
                            cursor: "pointer",
                          }}
                        >
                          Edit
                        </Typography>
                      )}
                    </Stack>
                    <Stack direction="row" gap={2} sx={{ flexWrap: "wrap" }}>
                      {provider?.represent?.map((label) => (
                        <Button
                          key={label}
                          variant="outlined"
                          sx={{
                            textTransform: "capitalize",
                            color: "#5A5A5A",
                            fontSize: 14,
                            fontWeight: 500,
                            backgroundColor: "#F6F6F6",
                            outline: "none",
                            border: "none",
                            "&:hover": {
                              border: "none",
                            },
                          }}
                        >
                          {label.toLowerCase()}
                        </Button>
                      ))}
                    </Stack>
                  </>
                )}
              </Stack>
            </Stack>
            <Stack>
              {!!provider?.licenses?.length && (
                <Grid item>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#002A5C",
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                    gutterBottom
                  >
                    Licenses Info
                    {!!editAble && (
                      <Link to="/profile/edit" state={{ goToStep: EDUCATION }}>
                        <Typography
                          sx={{
                            color: "#00A4DA",
                            fontSize: 16,
                            fontWeight: 600,
                            pl: 1,
                            display: "inline",
                          }}
                        >
                          Edit
                        </Typography>
                      </Link>
                    )}
                  </Typography>
                </Grid>
              )}

              <div className="flex gap-2 flex-wrap">
                {!!provider?.licenses?.length &&
                  provider?.licenses.map((license) => {
                    const state = states?.find(
                      (state) => state._id === license?.state
                    );
                    return (
                      <Menu2
                        key={license.licenseNumber}
                        label={
                          <span className="text-ellipsis whitespace-nowrap overflow-hidden w-[180px]">
                            {state?.name}
                          </span>
                        }
                        value={license?.licenseNumber}
                      />
                    );
                  })}
              </div>
              {/* <div className="absolute bottom-8 right-8 flex justify-center items-center w-[55px] h-[55px] sm:w-[85px] sm:h-[85px] mt-[-40px] text-textColor bg-[#C6C7C41A] rounded-[8px]">
                <div className="flex justify-center items-center w-[54px] h-[54px] text-textColor bg-[#EFEFEF] rounded-[50px]">
                  <p className=" text-textColor self-center">Logo</p>
                </div>
              </div> */}
            </Stack>
          </Stack>
        </Grid>
        {/* <Grid item xs={1}></Grid> */}
      </Grid>
      {!isMyProfilePage && (
        <Grid
          item
          position={{ md: "absolute" }}
          sx={{
            display: "flex",
            top: "2rem",
            right: "2rem",
            gap: 1,
            alignItems: "center",
          }}
        >
          <Button
            sx={{
              maxWidth: "182px",
              height: "46px",
              textTransform: "capitalize",
              color: "#fff",
              fontSize: 16,
              fontWeight: 600,
              lineHeight: "16px",
              backgroundColor: "#00A4DA",
              "&:hover": {
                backgroundColor: "#0099CB",
              },
            }}
            fullWidth
            variant="contained"
            onClick={handleBookingAppointmentClick}
            id="app-scheduling"
            disabled={!isCalAtomsUserIDValid(provider?.calAtomsUserId) || !searchId}
          >
            Book Appointment
          </Button>
          <Button
            variant="outlined"
            size="small"
            fullWidth
            sx={{
              py: { xs: 0, md: 1.2 },
              height: "50px",
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: 16,
              lineHeight: "16px",
            }}
            className="text-primary max-w-[195px]"
            onClick={() => {
              if (user === null) {
                dispatch(openModal(OPEN_SIGN_IN_MODAL));
              } else {
                // send inquiry start string to google analytics
                ReactGA.event({
                  category: "start send-inquiry",
                  action: "Juricon_Send-Inquiry-Start",
                });
                setShowInquiryModal(true);
              }
            }}
          >
            Send Inquiry
          </Button>
          {!isLoading && (
            <BookmarkButton
              searchId={searchId}
              providerId={provider._id}
              bookmarkedLawyers={bookmarkedLawyers}
              loading={isLoading}
              className="w-[50px]"
            />
          )}
        </Grid>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showInquiryModal}
        onClose={(e) => {
          e.stopPropagation();
          formik.resetForm();
          setShowInquiryModal(false);
        }}
      >
        <Box sx={style} onClick={(e) => e.stopPropagation()}>
          <Stack gap={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography
                variant="h5"
                fontWeight={600}
                className="text-secondary"
              >
                Let's Discuss Your Legal Matters
              </Typography>
              <IconButton
                onClick={() => setShowInquiryModal(false)}
                size="large"
              >
                <CloseOutlined />
              </IconButton>
            </Stack>
            <form>
              <Stack gap={2}>
                <Typography
                  variant="label"
                  className="text-[#737373] font-[600]"
                >
                  Title
                </Typography>
                <Stack>
                  <TextField
                    size="small"
                    name="title"
                    {...formik.getFieldProps("title")}
                  />
                  <Error formik={formik} errorField="title" />
                </Stack>

                <Typography
                  variant="label"
                  className="text-[#737373] font-[600]"
                >
                  Description
                </Typography>
                <Stack>
                  <textarea
                    className="border-borderColor border rounded-xs min-h-[35vh] focus:outline-primary p-3"
                    name="description"
                    {...formik.getFieldProps("description")}
                    placeholder="Please describe your legal issue using as much detail as possible (but omitting any party names or confidential information)."
                  />
                  <Error formik={formik} errorField="description" />
                </Stack>
                <Stack direction="row" justifyContent="end">
                  <Button
                    variant="contained"
                    sx={{
                      color: "White",
                      "&:hover": {
                        backgroundColor: "rgba(0, 164, 218, 0.9)",
                      },
                      minWidth: "138px",
                    }}
                    type="submit"
                    onClick={formik.handleSubmit}
                    disabled={isLoading}
                  >
                    {isLoading ? <Loader /> : "Send Inquiry"}
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Stack>
        </Box>
      </Modal>
    </Paper>
  );
}

export const BillingText = ({ text }) => {
  return (
    <Typography
      sx={{
        color: "#002A5C",
        fontSize: 16,
        fontWeight: 600,
      }}
    >
      {text}
    </Typography>
  );
};
