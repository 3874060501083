import React from "react";

const AddFieldButton = ({ buttonText, onClick, rest }) => {
  return (
    <div className="flex justify-end">
      <button
        type="button"
        className="w-fit text-primary hover:text-hoverColor font-semibold cursor-pointer"
        onClick={onClick}
        {...rest}
      >
        {buttonText}
      </button>
    </div>
  );
};

export default AddFieldButton;
