import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import EditProfileFooter from "components/PremiumProviderEditProfileComponents/editProfilFooter/EditProfileFooter";
import Error from "components/error/Error";
// import { experiencesValidation } from "components/PremiumProviderEditProfileComponents/editProfileValidation";
import DynamicFieldsHeader from "components/dynamicFeildsHeader/DynamicFieldsHeader";
import {
  addFields,
  removeFields,
} from "components/PremiumProviderEditProfileComponents/helper";
import { cleanUpValues } from "utils/helperFunctions/helper";
import PracticeArea from "components/modals/practiceArea/PracticeArea";
import {
  representationObject,
  representationValidationNewScope, stepDataNewScope
} from "components/editProviderNewScope/helper";
// import { useSelector } from "react-redux";
import { PracticeAreaField } from "../personalInfoNewScope/PersonalInfoNewScope";
import EditProfileSteps from "../../PremiumProviderEditProfileComponents/editProfileSteps/EditProfileSteps";

const RepresentationNewScope = ({
  pre,
  user,
  step,
  setStep,
  categories,
  isLoading,
  onSubmit,
}) => {
  const [representations, setRepresentations] = useState([
    representationObject,
  ]);
  const [practiceAreas, setPracticeAreas] = useState({});
  const [NAICMap, setNAICMap] = useState({});
  const [practiceAreaModal, setPracticeAreaModal] = useState(false);
  // const [showNAICModal, setShowNAICModal] = useState(false);
  const [currentPracticeAreaIndex, setCurrentPracticeAreaIndex] = useState(0);
  // const [currentNAICIndex, setCurrentNAICIndex] = useState(0);
  // const NAICList = useSelector((state) => state.categoriesReducer.naicCodes);

  const setPracticeAreaState = (arr, index, key) => {
    if (key === "practiceAreas") {
      setPracticeAreas((prePracticeAreas) => ({
        ...prePracticeAreas,
        [index]: arr,
      }));
    } else {
      setNAICMap((previousNAIC) => ({
        ...previousNAIC,
        [index]: arr,
      }));
    }
    const updateRepresentation = {
      ...formik.values.representations[index],
      [key]: arr,
    };
    const updateRepresentations = [
      ...formik.values.representations.slice(0, index),
      updateRepresentation,
      ...formik.values.representations.slice(index + 1),
    ];
    formik.setFieldValue("representations", updateRepresentations);
  };
  useEffect(() => {
    if (user?.representations?.length) {
      const tempUser = JSON.parse(JSON.stringify(user));
      tempUser?.representations.forEach((representation, index) => {
        setPracticeAreaState(
          representation?.practiceAreas || [],
          index,
          "practiceAreas"
        );
        // setPracticeAreaState(
        //   representation?.naicCodes || [],
        //   index,
        //   "naicCodes"
        // );
      });
      setRepresentations(user.representations);
      formik.setValues({
        representations: [...(user.representations || [])],
      });
    }
  }, [user?.representations]);
  const formik = useFormik({
    initialValues: {
      representations,
    },
    validationSchema: representationValidationNewScope(),
    onSubmit: (values) => {
      values.representations.forEach((item) => {
        cleanUpValues(item);
      });
      values.stepsCompleted = 6;

      onSubmit(values);
    },
  });

  const { getFieldProps } = formik;

  // const handleRemoveClick = (id, index, key) => {
  //   if (key !== "practiceAreas") {
  //     const temp = [...(NAICMap?.[index] || [])].filter(
  //       (categoryId) => categoryId !== id
  //     );
  //     setPracticeAreaState(temp, index, "naicCodes");
  //   }
  // };
  const validateAndSubmit = async (values) => {
    values.representations.forEach((item) => {
      cleanUpValues(item);
    });
    values.stepsCompleted = 6;
    onSubmit(values, true);
  };
  const saveStep = () => {
    validateAndSubmit(formik.values);
  };
  return (
    <div className="">
      <EditProfileSteps
        step={step}
        stepsCompleted={user?.stepsCompleted}
        setStep={setStep}
        stepData={stepDataNewScope}
        saveStep={saveStep}
      />
      <div className="popup-modal   ">
        <div className="flex-wrap pb-10">
          <div className=" py-6">
            {/* Form */}
            <form className=" profile-form" action="#">
              {representations.map((_, index) => (
                <div key={index} className={`mt-${index > 0 ? 10 : 0}`}>
                  <DynamicFieldsHeader
                    title="Prior Representation and Projects"
                    index={index}
                    callBack={() =>
                      removeFields(
                        setRepresentations,
                        index,
                        formik,
                        "representations"
                      )
                    }
                    length={representations?.length}
                  />
                  <div className="flex input-wrap justify-between">
                    <div className="w-[100%] ">
                      {/* TITLE */}
                      {/* <label
                        className="block mb-2 text-[14px] font-[500]  text-textColor"
                      >
                        Title
                      </label>
                      <div className="w-full">
                        <input
                          id="title"
                          className="rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none
                          "
                          placeholder="title"
                          {...getFieldProps(`representations[${index}].title`)}
                        />
                        <Error
                          formik={formik}
                          errorField="title"
                          property="representations"
                          index={index}
                        />
                      </div> */}
                      {/* MATERIAL ISSUES */}
                      <label className="block mb-2 pt-7 text-[14px] font-[500]  text-textColor">
                        Brief Title
                      </label>
                      <div className="w-full">
                        <input
                          id="materialIssues"
                          className="rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none
                          "
                          placeholder="Provide a brief description of any material issues unique to this representation"
                          {...getFieldProps(
                            `representations[${index}].materialIssues`
                          )}
                        />
                        <Error
                          formik={formik}
                          errorField="materialIssues"
                          property="representations"
                          index={index}
                        />
                      </div>
                      {/* DESCRIPTION */}
                      <div className="w-full ">
                        <label className="block mb-2 pt-7 text-[14px] font-[500]  text-textColor">
                          Description
                        </label>
                        <textarea
                          className=" rounded-sm w-full border border-borderColor bg-white pl-5 pt-2 h-[200px] text-base font-medium outline-none focus:border-primary text-secondary bg-none
                          "
                          placeholder="Provide a description of the representation including, to the extent permissible, any parties or agencies. Our AI will use this information to match you with clients seeking similar expertise. Please note – this information will be public. Please do not include any confidential or attorney-client protected information"
                          {...getFieldProps(
                            `representations[${index}].description`
                          )}
                        />
                        <Error
                          formik={formik}
                          errorField="description"
                          property="representations"
                          index={index}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-[100%] flex pt-7 flex-wrap">
                    <div className="w-full">
                      <div className="flex">
                        <label className="block mb-2 text-sm font-medium text-textColor">
                          Practice Areas
                        </label>
                        <label
                          onClick={() => {
                            setPracticeAreaModal(!practiceAreaModal);
                            setCurrentPracticeAreaIndex(index);
                          }}
                          className="mt-[-2px] ml-2 text-[16px] font-medium text-primary cursor-pointer"
                        >
                          Click to Select
                        </label>
                      </div>

                      <PracticeAreaField
                        placeholder="Please select practice area!"
                        practiceAreas={practiceAreas[index] || []}
                        categories={categories}
                        // handleRemoveClick={(id) =>
                        //   handleRemoveClick(id, index, "practiceAreas")
                        // }
                      />
                    </div>
                    {/* <div className="sm:w-[50%] sm:pl-3 padding-input">
                      <div className="flex">
                        <label
                          className="block mb-2 text-sm font-medium text-textColor"
                        >
                          NAIC Codes
                        </label>
                        <label
                          onClick={() => {
                            setShowNAICModal(!showNAICModal);
                            setCurrentNAICIndex(index);
                          }}
                          className="mt-[-2px] ml-2 text-[16px] font-medium text-primary cursor-pointer"
                        >
                          Click to Select
                        </label>
                      </div>
                      <RenderNAIC
                        placeholder="Please select NAIC codes!"
                        practiceAreas={NAICMap?.[index] || []}
                        categories={NAICList}
                        handleRemoveClick={(id) =>
                          handleRemoveClick(id, index, "naicCodes")
                        }
                      />
                      {showNAICModal && (
                        <NAICModal
                          setPracticeAreas={(arr) =>
                            setPracticeAreaState(
                              arr,
                              currentNAICIndex,
                              "naicCodes"
                            )
                          }
                          practiceAreas={NAICMap?.[currentNAICIndex] || []}
                          categories={NAICList}
                          isLoading={isLoading}
                          onClose={() => setShowNAICModal(!showNAICModal)}
                          label="NAIC Codes"
                        />
                      )}
                    </div> */}
                  </div>

                  <label className="block pt-7 pb-2 text-[14px] font-[500]  text-textColor">
                    Court/Regulatory Body
                    <span className="text-[#979797] ml-2">(If Applicable)</span>
                  </label>
                  <div className="w-full">
                    <input
                      type="text"
                      className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none
                      "
                      placeholder="List any court or regulatory body critical this this representation"
                      {...getFieldProps(`representations[${index}].court`)}
                    />
                    <Error
                      formik={formik}
                      errorField="court"
                      property="representations"
                      index={index}
                    />
                  </div>
                </div>
              ))}
              {practiceAreaModal && (
                <PracticeArea
                  setPracticeAreas={(values) => {
                    setPracticeAreas((pre) => ({
                      ...pre,
                      [currentPracticeAreaIndex]: values,
                    }));
                    formik.setFieldValue(
                      `representations.[${currentPracticeAreaIndex}].practiceAreas`,
                      values
                    );
                  }}
                  practiceAreas={practiceAreas[currentPracticeAreaIndex] || []}
                  categories={categories}
                  isLoading={isLoading}
                  onClose={() => setPracticeAreaModal(!practiceAreaModal)}
                />
              )}
              <div className="flex justify-end mt-6">
                <button
                  type="button"
                  className="w-fit text-primary font-semibold pt-3 cursor-pointer"
                  onClick={() =>
                    addFields(
                      setRepresentations,
                      representationObject,
                      formik,
                      "representations"
                    )
                  }
                >
                  Add Representation
                </button>
              </div>
            </form>
            <EditProfileFooter
              pre={pre}
              next={formik.handleSubmit}
              nextButtonTitle="Finish"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepresentationNewScope;
