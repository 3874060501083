import { routes } from "utils/appConfig";

export const fetchFilterOptions = (callApi, searchId, cb) => {
  const { url, method } = routes.filters;
  try {
    callApi(
      url(searchId),
      method,
      {},
      {
        onSuccess: ({ data }) => {
          cb(data.data);
        },
        onError: (error) => {
          cb(null, error);
        },
        turnOffNotification: true,
      }
    );
  } catch (e) {
    console.log("fetchMatters~catch", e);
    throw new Error();
  }
};
