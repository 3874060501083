export function generateArrayOfYears(numberOfYears) {
  const max = new Date().getFullYear();
  const years = [];
  const min = max - numberOfYears;

  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
}
export const capitalizeFirstLetter = (str) => {
  const lowercase = str.toLowerCase();
  const arr = lowercase.split(" ");
  for (var i = 0; i < arr?.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(" ");
};
export const designations = [
  "PARTNER",
  "OF_COUNSEL",
  "ASSOCIATE",
  "SOLO_PRACTITIONER",
  "OTHER",
];
export const officeType = ["VIRTUAL", "PHYSICAL"];
export const languageProficiency = [
  "NATIVE",
  "FLUENT",
  "INTERMEDIATE",
  "BEGINNER",
];
export const oralProficiencies = [
  "NATIVE",
  "FLUENT",
  "INTERMEDIATE",
  "BEGINNER",
];

export const addFields = (setState, fieldsObject, formik, key, isNewScope) => {
  if (setState) {
    setState((p) => [...p, fieldsObject]);
  }
  if (isNewScope) {
    formik.setValues({
      ...formik.values,
      [key]: [...formik.values[key], fieldsObject],
    });
  } else {
    formik.setValues({
      [key]: [...formik.values[key], fieldsObject],
    });
  }
};
export const removeFields = (setState, index, formik, key, isNewScope) => {
  const { [key]: tempArray } = formik.values;
  tempArray.splice(index, 1);
  if (setState) {
    setState(tempArray);
  }
  if (isNewScope) {
    formik.setValues({
      ...formik.values,
      [key]: tempArray,
    });
  } else {
    formik.setValues({
      [key]: tempArray,
    });
  }
};
export const removeEmptyValuesFromObject = (values) => {
  try {
    const temp = { ...values };
    for (const prop in temp) {
      if (temp[prop] === "") delete temp[prop];
    }
    return temp;
  } catch (e) {
    console.log(e);
    return values;
  }
};
export const addressObject = {
  officeType: "",
  country: "USA",
  state: "",
  city: "",
  postalCode: "",
  street01: "",
  street02: "",
};
export const licenseObj = {
  state: "",
  licenseNumber: "",
};
export const experienceObj = {
  title: "",
  description: "",
  industry: "",
  court: "",
  practiceAreas: [],
};
export const languageObject = {
  language: "",
  oralProficiency: "",
  writtenProficiency: "",
};
