import React from "react";
import Footer from "components/Footer";
import Navbar from "components/navbar/Navbar";
import CompleteProfile from "pages/others/CompleteProfile";
import {useLocation} from "react-router-dom";

const CompleteProfileMain = () => {
  const location = useLocation();
  return (
    <div>
      <div className="h-[100vh]">
        <Navbar />
        <div className="flex justify-center">
          <CompleteProfile
              isProfileComplete = {location.state?.isProfileComplete ?? false}
          />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default CompleteProfileMain;
