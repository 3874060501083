export const adminPanelFilters = [
  {
    label: "BUSINESS",
    value: "BUSINESS",
  },
  {
    label: "INDIVIDUAL",
    value: "INDIVIDUAL",
  },
  {
    label: "PROVIDER",
    value: "PROVIDER",
  },
];

export const adminActions = [
  // {
  //   label: "Make Admin",
  //   value: "makeAdmin",
  // },
  {
    label: "Edit User",
    value: "editUser",
  },
  {
    label: "Change Password",
    value: "changePassword",
  },
  {
    label: "Delete User",
    value: "deleteUser",
  },
];
