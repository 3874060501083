import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import Button from "components/button/Button";
import Error from "components/error/Error";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeCurrentModal, openModal } from "store/actions/modalActions";
import { notify } from "utils/toast/toast";
import vectorMaster from "components/modals/authModal/imgs/vector.png";
import {
  cardDetailModalSchema,
  checkValidationError,
} from "components/modals/helper";
import { stripePaymentMethodHandler } from "components/modals/subscriptionModal/helper";
import { removeExtraSpaces } from "utils/helperFunctions/helper";
import {
  ADD_CARD_SUCCESS_MODAL,
  OPEN_SUBSCRIPTION_MODAL,
} from "utils/constants/constants";
import { useAxios } from "utils/hooks/useAxios";
import { routes } from "utils/appConfig";
import { addCreditCard } from "services/addPaymentCard";
import { fetchPaymentCards } from "services/fetchPaymentCards";
import { updateCreditCards } from "store/actions/actions";

const CARD_ELEMENT_OPTIONS = {
  iconStyle: "solid",
  showIcon: true,
  style: {
    base: {
      lineHeight: "27px",
      color: "#212529",
      fontSize: "1.1rem",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const PaymentModal = ({
  onClose,
  onSubmit,
  selectedSubscription,
  addCard = false,
  promoCode,
  couponStatus,
}) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { callApi } = useAxios();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  useEffect(() => {
    if (!selectedSubscription && !addCard) {
      dispatch(openModal(OPEN_SUBSCRIPTION_MODAL));
    }
  }, []);
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: cardDetailModalSchema,
    onSubmit: async (values) => {
      values.name = removeExtraSpaces(values.name);
      if (!stripe || !elements) {
        return;
      }
      const paymentMethodObj = {
        type: "card",
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: values.name,
        },
      };
      try {
        setIsLoading(true);
        const paymentMethodResult = await stripe.createPaymentMethod(
          paymentMethodObj
        );
        if (addCard) {
          addCreditCard(
            callApi,
            {
              paymentMethodId: paymentMethodResult.paymentMethod.id,
              isMarkAsDefault: false,
            },
            (data) => {
              if (data) {
                fetchPaymentCards(callApi, (data) => {
                  if (data) {
                    setIsLoading(false);
                    dispatch(updateCreditCards(data.data.data));
                    dispatch(openModal(ADD_CARD_SUCCESS_MODAL));
                  }
                });
              }
            }
          );
        } else {
          let subscriptionData = {
            paymentMethodId: paymentMethodResult.paymentMethod.id,
            productId: selectedSubscription,
          };
          if (promoCode && couponStatus?.valid) {
            subscriptionData.promoCode = promoCode;
          }
          callApi(
            routes.subscription.url,
            routes.subscription.method,
            subscriptionData,
            {
              onSuccess: ({ data }) => {
                setIsLoading(false);
                if (data?.success) {
                  onSubmit();
                }
              },
              onError: () => {
                setIsLoading(false);
              },
            }
          );
        }
      } catch (e) {
        setIsLoading(false);
        console.log("stripe api error", e);
      }
    },
  });

  const handleChange = (e, id) => {
    const displayError = document.querySelector(`#${id}`);
    if (e.error) {
      setIsError(true);
      displayError.textContent = e.error.message;
    } else {
      setIsError(false);
      displayError.textContent = "";
    }
  };
  return (
    <div className="relative w-[326px] md:w-[544px] bg-[#fff] shadow dark:bg-gray-700 rounded-[20px] p-[2rem]">
      <button
        type="button"
        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex  dark:hover:text-primary text-textColor"
        data-modal-toggle="authentication-modal"
      >
        <svg
          onClick={() => (onClose ? onClose() : dispatch(closeCurrentModal()))}
          aria-hidden="true"
          className="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
        <span className="sr-only">Close modal</span>
      </button>
      <div className="px-6 py-6 lg:px-8">
        <h3 className="mb-1  text-[22px] font-[700] text-secondary">
          Credit Card
        </h3>
        <label className="block mb-5  text-[16px] font-[400] text-gray-900 text-textColor">
          Enter your card details
        </label>
        <form className="space-y-6" onSubmit={formik.handleSubmit}>
          <div>
            <label className="block mb-2  text-[14px] font-[500] text-textColor">
              Card Number
            </label>
            <div className="relative rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none">
              <CardNumberElement
                className="pt-4"
                options={CARD_ELEMENT_OPTIONS}
                onChange={(e) => handleChange(e, "cardNumberError")}
              />
              {/* <div className="absolute top-[.6rem] right-3">
                <img className="w-6 mt-2" src={vectorMaster} alt="" />
              </div> */}
            </div>
            <p id="cardNumberError" className="text-[red]"></p>
          </div>
          <div>
            <label
              htmlFor="name"
              className="block mb-2  text-[14px] font-[500] text-textColor"
            >
              Cardholder Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none "
              placeholder="Jane Lawyer"
              onChange={formik.handleChange}
              value={formik.values.name}
              onBlur={formik.handleBlur}
            />
            <Error formik={formik} errorField="name" />
          </div>

          <div className="flex flex-col md:flex-row w-full gap-3">
            <div className="md:w-[50%]">
              <label className="block mb-2  text-[14px] font-[500] text-textColor">
                Expiration Date
              </label>
              <div className="rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none">
                <CardExpiryElement
                  className="pt-4"
                  onChange={(e) => handleChange(e, "expiryError")}
                />
              </div>
              <p id="expiryError" className="text-[red]"></p>
            </div>
            <div className="md:w-[50%] ">
              <label className="block mb-2  text-[14px] font-[500] text-textColor">
                CVV/CVC
              </label>
              <div className="rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none">
                <CardCvcElement
                  className="pt-4"
                  onChange={(e) => handleChange(e, "cvcError")}
                />
              </div>
              <p id="cvcError" className="text-[red]"></p>
            </div>
          </div>
          {!addCard && (
            <div className="flex justify-between">
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="remember"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 border border-textColor rounded-sm"
                  />
                </div>
                <label
                  htmlFor="remember"
                  className="ml-2  text-[14px] font-[500] text-gray-900 dark:text-gray-300 text-textColor"
                >
                  Save Details for Later Payment Processing
                </label>
              </div>
            </div>
          )}
          <Button
            type="submit"
            className="hover:bg-hoverColor w-[100%] h-[50px] text-[16px] font-[600]"
            disabled={isError || checkValidationError(formik) || isLoading}
            loader={isLoading}
          >
            {addCard ? "Save" : "Proceed Payment"}
          </Button>
          {!addCard && (
            <div className="text-sm font-medium text-gray-500 dark:text-gray-300 text-textColor text-center flex justify-center">
              <p
                className="text-[14px] font-[400] hover:text-hoverColor hover:underline dark:text-blue-500 text-center text-primary cursor-pointer"
                onClick={() => dispatch(openModal("subscriptionModal"))}
              >
                Back to Subscription
              </p>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default PaymentModal;
