import Error from "components/error/Error";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateSearchQuery } from "store/actions/actions";
import { GENERAL_SEARCH } from "utils/constants/constants";

const GeneralSearch = ({ navSearch }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { searchText } = useSelector((state) => state.searchReducer);

  useEffect(() => {
    formik.setValues({
      searchText,
    });
  }, [searchText]);

  const formik = useFormik({
    initialValues: {
      searchText: "",
    },
    onSubmit: (value) => {
      if (value?.searchText) {
        dispatch(
          updateSearchQuery({
            searchText: value.searchText,
            searchType: GENERAL_SEARCH,
          })
        );
        navigate("/searchresult");
      }
    },
  });

  const { getFieldProps } = formik;

  return (
    <div
      className={`${
        !navSearch && "my-6"
      } w-full flex flex-col justify-center items-center`}
    >
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col w-[100%] max-w-4xl"
      >
        <textarea
          name="searchText"
          {...getFieldProps("searchText")}
          className="w-full h-[200px] p-4 text-lg font-semibold text-secondary placeholder-[#c6c7c4c4] border-2 border-[#c6c7c481] rounded-md resize-none"
          placeholder="Describe your legal issue"
          rows="5"
        />
        <div className="mt-2 text-center">
          <button type="submit" onClick={formik.handleSubmit}>
            <i className="fa-solid fa-magnifying-glass text-3xl text-[#5c5e5eb2] hover:text-primary transition-all cursor-pointer"></i>
          </button>
        </div>
      </form>
      <Error formik={formik} errorField="searchText" />
    </div>
  );
};

export default GeneralSearch;
