import { routes } from "utils/appConfig";

export const sendNewPracticeAreaEmail = async (callApi, data, callBack) => {
  const { url, method } = routes.suggestNewPracticeArea;
  try {
    callApi(url, method, data, {
      onSuccess: (res) => callBack(res),
      onError: (error) => callBack(null, error),
      successMessage: "Practice area suggestion successfuly sent to the Admins",
    });
  } catch (e) {
    throw new Error(e.response);
  }
};