export const sessionTime = "1hour";
export const removeDynamicFields = true;
export const pageLimit = 9;
export const hideIncompleteFeatures = true;
export const TRACKING_ID = "G-XV2K81N7D4";

export const isUserVerified = (user) => {
  if (!user?._id || !user?.isEmailVerified) {
    return { status: false, message: "Please login!" };
  }
  switch (user?.userType) {
    case "PROVIDER":
      {
        // const profileCompleted = isPremiumSubscriber(user?.subscription)
        //   ? user?.stepsCompleted === 6
        //   : user?.stepsCompleted === 5;
        const profileCompleted = user?.stepsCompleted === 6;
        const calAtomsUserIDExists = isCalAtomsUserIDValid(user?.calAtomsUserId);
        if (!profileCompleted) {
          console.error(
            "utils/config.js~isUserVerified:25,\n profile not completed, Please complete your profile"
          );
          return { status: false, message: "Please complete your profile" };
        }
        if (!calAtomsUserIDExists) {
          console.log(
            "utils/config.js~isUserVerified:25,\n bookafyId not found!"
          );
          return { status: false, message: "Your profile is under review" };
        }
      }
      return { status: true, message: "Welcome to dashboard" };
    default:
      return { status: true, message: "Welcome to dashboard" };
  }
};

// disabling bookafy until a future phase to replace with another provider (cal.com)
export const isCalAtomsUserIDValid = (id) => {
  return !!id && !id.includes("null") && !id.includes("undefined");
};
