import EditProfileNewScope from "components/editProviderNewScope/EditProfileNewScope";
import NoDataAvailable from "components/noDataAvailable/NoDataAvailable";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchUserInfoById } from "services/fetchUserInfo";
import { useAxios } from "utils/hooks/useAxios";
import Loader from "utils/loader/Loader";
import { EditConsumerProfile } from "./EditProfile";

const AdminUserUpdate = () => {
  const [user, setUser] = useState(null);
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const { callApi } = useAxios();

  useEffect(() => {
    if (params?.userId) {
      setIsLoading(true);
      fetchUserInfoById(callApi, params?.userId, (res, error) => {
        setIsLoading(false);
        if (res?.success) {
          setUser(res.data);
        } else if (error) {
          console.log("error while fetching user info~: ", error);
        }
      });
    }
  }, [params.userId]);

  return isLoading ? (
    <div className="w-full h-[80vh] flex justify-center items-center">
      <Loader width="50px" height="50px" />
    </div>
  ) : !user ? (
    <NoDataAvailable />
  ) : (
    <>
      {/* {user && user.userType !== "PROVIDER" && <BreadCrumb />} */}
      {user?.userType === "PROVIDER" ? (
        <EditProfileNewScope adminPanelUser user={user} />
      ) : (
        <EditConsumerProfile adminPanelUser user={user} />
      )}
    </>
  );
};

export default AdminUserUpdate;
