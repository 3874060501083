import React, { useEffect, useState } from "react";
import AutoComplete from "components/autocomplete/AutoComplete";
import { searchByList } from "./helper";

export default function SearchByAutoComplete({ searchBy, onChange }) {
  const [fetchedSearchBy, setFetchedSearchBy] = useState({});

  const handleChange = (value) => {
    onChange(value?._id);
  };
  useEffect(() => {
    if (searchBy) {
      const fetchedSearchBy = searchByList?.filter(
        (state) => state?._id === searchBy
      )[0];
      setFetchedSearchBy(fetchedSearchBy);
    }
  }, [searchBy]);
  return (
    <AutoComplete
      options={searchByList}
      defaultValue={fetchedSearchBy}
      onChange={handleChange}
      placeholder="Search by . . ."
      sx={{
        width: { sm: "100%", md: 340 },
        "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
          backgroundColor: "#363636",
        },
        "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
          {
            backgroundColor: "#4396e6",
          },
        "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused":
          {
            backgroundColor: "#3878b4",
          },
      }}
    />
  );
}
