import React from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import TextWithSeeMore from "components/textWithSeeMore/TextWithSeeMore";
import lawFirmLogo from "assets/juricon-logo-white-small.svg";
import { useSelector } from "react-redux";
import { findOne } from "utils/helperFunctions/helper";
import { isValidURL } from "utils/helperFunctions/helper";
import { WORK_INFO } from "components/PremiumProviderEditProfileComponents/constants/editProfileConstants";

const WorkInfo = ({ editAble = false, workInfo }) => {
  const businessList = useSelector((state) => state.businessReducer.businesses);
  return (
    <Box className=" w-full rounded-[8px] border border-borderColor p-6 mt-4">
      <Box sx={{ display: "flex", alignItems: "baseline" }}>
        <Typography
          sx={{
            color: "#002A5C",
            fontSize: 20,
            fontWeight: 600,
            pb: 1,
          }}
          gutterBottom
        >
          Work Info
        </Typography>
        <Typography
          sx={{
            color: "#00A4DA",
            fontSize: 16,
            fontWeight: 600,
            mt: "6px",
            pl: 1,
          }}
          gutterBottom
        >
          {!!editAble && (
            <Link
              to="/profile/edit"
              state={{ goToStep: WORK_INFO }}
              className="mt-0"
            >
              Edit
            </Link>
          )}
        </Typography>
      </Box>
      <Stack gap={3} direction="row" flexWrap="wrap">
        {workInfo.map((work, index) => {
          const img = findOne(
            businessList,
            "_id",
            work?.business
          )?.businessLogo;
          return (
            <Box
              key={index}
              sx={{ display: "flex", gap: "20px", maxWidth: "400px" }}
            >
              <Box sx={{ mt: 1 }}>
                {!!editAble && (isValidURL(img) ? (
                    <div className="w-10 h-10">
                      <img
                        src={img}
                        alt="work"
                        className="mr-1 w-[100%] h-[100%] object-fill"
                      />
                    </div>
                  ) : (
                    <div className="w-10 h-10">
                      <img
                        className="mr-1 w-[100%] h-[100%] object-fill"
                        src={lawFirmLogo}
                        alt="work"
                      />
                    </div>
                ))}
              </Box>
              <Box sx={{ maxWidth: 400 }}>
                <Typography
                  variant="h4"
                  sx={{ color: "#002A5C", fontSize: "18px", fontWeight: "600" }}
                >
                  {work?.companyName}
                </Typography>
                <Typography sx={{ color: "#5A5A5A", fontWeight: "500" }}>
                  {work?.jobTitle}
                </Typography>
                <Typography sx={{ color: "#5A5A5A80", fontWeight: "500" }}>
                  {work?.startDate} - {work?.endDate}
                </Typography>
                <Box>
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#002A5C",
                      fontSize: "18px",
                      marginY: "10px",
                      fontWeight: "600",
                    }}
                  >
                    Description
                  </Typography>
                  <Box sx={{ wordBreak: "break-word" }}>
                    <TextWithSeeMore text={work?.description} maxLength={100} />
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default WorkInfo;
