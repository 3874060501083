/* eslint-disable prettier/prettier */
import { PortableText } from "@portabletext/react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import BlogCard from "../components/BlogCard";
import {
  getBlogPostBySlug,
  getLatestBlogPosts,
} from "../sanity/lib/sanityClient";

// Custom components for PortableText
const components = {
  block: {
    // Normal paragraphs
    normal: ({children}) => (
      <p className="mb-6 leading-relaxed text-gray-700">{children}</p>
    ),
    
    // Headings
    h1: ({children}) => (
      <h1 className="mb-8 text-4xl font-bold text-gray-900">{children}</h1>
    ),
    h2: ({children}) => (
      <h2 className="mb-6 text-3xl font-bold text-gray-900">{children}</h2>
    ),
    h3: ({children}) => (
      <h3 className="mb-4 text-2xl font-bold text-gray-900">{children}</h3>
    ),
    h4: ({children}) => (
      <h4 className="mb-4 text-xl font-bold text-gray-900">{children}</h4>
    ),

    // Blockquotes
    blockquote: ({children}) => (
      <blockquote className="pl-6 my-8 italic text-gray-700 border-l-4 border-primary">
        {children}
      </blockquote>
    ),
  },

  // Lists
  list: {
    bullet: ({children}) => (
      <ul className="pl-6 mb-6 space-y-2 list-disc">{children}</ul>
    ),
    number: ({children}) => (
      <ol className="pl-6 mb-6 space-y-2 list-decimal">{children}</ol>
    ),
  },

  listItem: ({children}) => (
    <li className="text-gray-700">{children}</li>
  ),

  // Custom marks
  marks: {
    link: ({value, children}) => {
      const target = (value?.href || '').startsWith('http') ? '_blank' : undefined
      return (
        <a
          href={value?.href}
          target={target}
          rel={target === '_blank' ? 'noopener noreferrer' : undefined}
          className="text-primary hover:underline"
        >
          {children}
        </a>
      )
    },
    strong: ({children}) => (
      <strong className="font-bold">{children}</strong>
    ),
    em: ({children}) => (
      <em className="italic">{children}</em>
    ),
    code: ({children}) => (
      <code className="px-2 py-1 font-mono text-sm bg-gray-100 rounded">
        {children}
      </code>
    ),
  },

  // Custom types
  types: {
    image: ({value}) => {
      if (!value?.asset?.url) return null;
      return (
        <figure className="my-8">
          <img
            src={value.asset.url}
            alt={value.alt || ''}
            className="w-full h-auto rounded-lg shadow-md"
          />
          {value.caption && (
            <figcaption className="mt-2 text-sm text-center text-gray-600">
              {value.caption}
            </figcaption>
          )}
        </figure>
      );
    },
    code: ({value}) => (
      <pre className="p-4 mb-6 overflow-x-auto bg-gray-100 rounded-lg">
        <code className="font-mono text-sm text-gray-800">
          {value.code}
        </code>
      </pre>
    ),
    // Add more custom types as needed
  },
};

const BlogPostPage = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [latestPosts, setLatestPosts] = useState([]);

  useEffect(() => {
    getBlogPostBySlug(slug)
      .then((data) => setPost(data))
      .catch(console.error);
    window.scrollTo({ top: 0, behavior: "smooth" });
    getLatestBlogPosts()
      .then((data) => setLatestPosts(data))
      .catch(console.error);
  }, [slug]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-4xl px-4 py-10 mx-auto">
      {/* SEO Metadata */}
      <Helmet>
        <title>{post.seoTitle || post.title}</title>
        <meta
          name="description"
          content={post.seoDescription || post.excerpt}
        />
        <meta
          property="og:title"
          content={post.ogTitle || post.seoTitle || post.title}
        />
        <meta
          property="og:description"
          content={post.ogDescription || post.seoDescription || post.excerpt}
        />
        {post.ogImage && (
          <meta property="og:image" content={post.ogImage.asset.url} />
        )}
      </Helmet>

      {/* Back Button */}
      <button
  onClick={() => navigate("/blog")}
  className="fixed z-50 px-6 py-3 font-bold transition-all duration-300 bg-white border-2 rounded-full shadow-lg bottom-8 right-8 text-secondary hover:text-white hover:bg-secondary border-secondary group"
>
  <span className="flex items-center gap-2">
    <span className="transition-transform duration-300 transform group-hover:-translate-x-1">&larr;</span>
    Go Back
  </span>
  <span className="absolute right-0 invisible px-3 py-1 text-sm text-white transition-opacity duration-300 rounded opacity-0 group-hover:visible group-hover:opacity-100 -top-10 bg-secondary whitespace-nowrap">
    Return to Blog
  </span>
</button>

      {/* Blog Content */}
      <h1 className="mb-6 text-5xl font-bold text-primary">{post.title}</h1>
      <div className="mb-4 ttext-secondary">
        <span>
          Published on {new Date(post.publishedAt).toLocaleDateString()}
        </span>
        {post.author && <span> | by {post.author}</span>}
      </div>

      {post.mainImage && (
        <img
          src={post.mainImage.asset.url}
          alt={post.title}
          className="w-full h-auto mb-8 rounded-lg shadow-md"
        />
      )}

      {/* Render Tags */}
      {post.tags && (
        <div className="mb-6">
          {post.tags.map((tag) => (
            <span
              key={tag}
              className="inline-block px-3 py-1 mr-2 text-sm font-medium text-white rounded-full text-secondary bg-primary"
            >
              #{tag}
            </span>
          ))}
        </div>
      )}

      {/* Render Body with styled components */}
      <div className="prose prose-lg max-w-none text-secondary">
        <PortableText 
          value={post.body} 
          components={components}
        />
      </div>

      {/* Latest Articles Section */}
      <h2 className="mt-12 mb-6 text-2xl font-semibold">Latest Articles</h2>
      <div className="blog-grid-container">
        {latestPosts.slice(0, 4).map((latestPost) => (
          <BlogCard
            key={latestPost._id}
            title={latestPost.title}
            excerpt={latestPost.excerpt}
            mainImage={latestPost.mainImage}
            slug={latestPost.slug.current}
          />
        ))}
      </div>
    </div>
  );
};

export default BlogPostPage;