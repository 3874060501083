import React from "react";
import { useNavigate } from "react-router-dom";
import { WORK_INFO } from "components/PremiumProviderEditProfileComponents/constants/editProfileConstants";

const BreadCrumb = ({ path, moveTo, currentPath, className }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (path === "/profile/edit") {
      navigate(path, { state: { goToStep: WORK_INFO } });
    } else {
      navigate(path);
    }
  };
  return (
    <div className="flex justify-center">
      <div className={`py-4 w-[85%] ${className}`}>
        <button
          className="text-primary text-[14px] font-[700]"
          onClick={handleClick}
        >
          {moveTo}
        </button>
        <h1 className="text-secondary font-[700] text-[22px]">{currentPath}</h1>
      </div>
    </div>
  );
};

export default BreadCrumb;
