import React from "react";
import { useDispatch } from "react-redux";
import { closeCurrentModal, openModal } from "store/actions/modalActions";
import experienceModalImg from "assets/experienceModal.svg";
import Button from "components/button/Button";
import { OPEN_SUBSCRIPTION_MODAL } from "utils/constants/constants";
import { useNavigate } from "react-router-dom";

const ExperienceModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = () => {
    dispatch(closeCurrentModal());
    // navigate("/");
  };
  return (
    <div className="relative bg-[#fff] w-[326px] sm:w-[544px] lg:w-[671px] pb-7 md:h-[480px] rounded-lg shadow dark:bg-gray-700 rounded-[20px] flex flex-col space-y-[25px]">
      <div className="flex justify-center mt-[51px]">
        <img src={experienceModalImg} alt="premium" />
      </div>
      <div className="m-auto px-2 lg:px-[6.5rem] text-center">
        <h1 className="text-[#E9BA16] font-[700] text-[22px] leading-7 mb-[13px]">
          Appear in Top Search Results!
        </h1>
        <h5 className="">
          Go premium to add experiences and appear in top searches!
        </h5>
      </div>
      <div className="px-4 lg:px-[3.563rem]">
        <Button
          className="text-base font-[600] mb-[13px] py-2"
          onClick={() => dispatch(openModal(OPEN_SUBSCRIPTION_MODAL))}
        >
          Subscribe Now
        </Button>
        <div className="flex justify-center">
          <button
            className="text-primary font-[400] text-sm w-fit"
            onClick={handleClose}
          >
            Skip
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExperienceModal;
