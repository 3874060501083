export const OPEN_SIGN_IN_MODAL = "signInModal";
export const OPEN_SIGN_UP_MODAL = "signUpModal";
export const OPEN_FORGOT_PASSWORD_MODAL = "forgotPasswordModal";
export const OPEN_SIGN_UP_OTP_MODAL = "signUpOtpModal";
export const OPEN_FORGOT_PASSWORD_OTP_MODAL = "forgotPasswordOtpModal";
export const OPEN_RESET_PASSWORD_MODAL = "resetPasswordModal";
export const OPEN_RESET_PASSWORD_SUCCESSFUL_MODAL = "resetPasswordSuccessModal";
export const OPEN_VERIFY_EMAIL_MODAL = "verifyEmailModal";
export const OPEN_SUCCESS_MODAL = "successModal";
export const PAYMENT_SUCCESS_MODAL = "paymentSuccessModal";
export const CLOSE_CURRENT_MODAL = "closeCurrentModal";
export const SIGN_IN_ACTION = "signInACtion";
export const SIGNOUT_REQUEST = "signOutRequest";
export const EMAIL_VERIFIED_SUCCESSFULLY = "emailVerifiedSuccessfully";
export const OPEN_SUBSCRIPTION_MODAL = "subscriptionModal";
export const OPEN_PAYMENT_MODAL = "paymentModal";
export const OPEN_MODAL = "openModal";
export const EXPERIENCE_MODAL = "experienceModal";
export const ADVANCED_SEARCH = "advancedSearch";
export const GENERAL_SEARCH = "generalSearch";
export const ADD_CATEGORIES = "addCategories";
export const ADD_STATES = "addStates";
export const SEARCH_QUERY = "searchQuey";
export const STRIPE_MODAL = "stripeModal";
export const RESET_SEARCH_REDUCER = "resetSearchReducer";
export const ADD_LANGUAGES = "addLanguages";
export const PREMIUM_PACKAGE = "prod_NI3kog3OBzJVS9";
export const STANDARD_PACKAGE = "prod_NI3iDLd49XzVC7";
export const PREMIUM = "PREMIUM";
export const STANDARD = "STANDARD";
export const DATE_RANGE_MODAL = "dateRangeModal";
export const UPDATE_DATE_FILTER = "updateDateFilter";
export const PROVIDER_CONTACT_MODAL = "providerContactModal";
export const FIRM_INFO = "firmInfo";
export const PLANS_MODAL = "plansModal";
export const FILTER_MODAL = "filterModal";
export const ADD_NAIC_CODES = "addNaicCodes";
export const ADD_CREDIT_CARD_MODAL = "addCreditCard";
export const UPDATE_PAYMENT_CARDS = "updatePaymentCards";
export const DELETE_CARD_SUCCESS_MODAL = "deleteSuccessModal";
export const ADD_CARD_SUCCESS_MODAL = "addSuccessModal";
export const CHANGE_MEMBERSHIP_TO = "changeMembershipTo";
export const SELECT_CARD_MODAL = "selectCardModal";
export const UPDATE_FILTER_ARRAYS = "updateFilterArrays";
export const UPDATE_FILTERS = "updateFilters";
export const RESET_FILTERS = "resetFilters";
export const BL_RF_TOKEN = "bl-rf-token";
export const DELETE_DIALOG = "deleteDialog";
export const CHANGE_PHONE_DIALOG = "changePhoneDialog";
export const CHANGE_PASSWORD_DIALOG = "changePasswordDialog";
export const APPEAR_IN_SEARCHES_DIALOG = "appearInSearchesDialog";
export const CHANGE_STATUS = "changeStatus";
export const CHANGE_EMAIL_DIALOG = "changeEmailDialog";
export const COMPANY_ADVANCED_SEARCH_MODAL = "companyAdvancedSearchModal";
export const CURRENT_SIGNUP_TAB = "PROVIDER";
export const ADD_SCHOOLS_DATA = "addSchoolsData";

export const STRIPE_PUBLISHABLE_KEY =
  // eslint-disable-next-line no-undef
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

export const LAWYER_UP_USER_SESSION = "lawyerUpUserSession";
export const UPDATE_BUSINESS_LIST = "updateBusinessList";

// unauthenticated user object id in MongoDB
export const UNAUTHENTICATED_USER_ID = "ffff0000ffff0000ffff0000";

// eslint-disable-next-line no-undef
export const CAL_ATOMS_CLIENT_ID = process.env.REACT_APP_CAL_ATOMS_CLIENT_ID;
// eslint-disable-next-line no-undef
export const CAL_ATOMS_API_URL = process.env.REACT_APP_CAL_ATOMS_API_URL;
// eslint-disable-next-line no-undef
export const CAL_ATOMS_REFRESH_URL = process.env.REACT_APP_CAL_ATOMS_REFRESH_URL;
export const OPEN_BOOKING_APPOINTMENT_MODAL = "bookingAppointmentModal";

