import React, { useEffect } from "react";
import { EDUCATION } from "components/PremiumProviderEditProfileComponents/constants/editProfileConstants";
import EditProfileFooter from "components/PremiumProviderEditProfileComponents/editProfilFooter/EditProfileFooter";
import { cleanUpValues } from "utils/helperFunctions/helper";
import StepLabel from "components/editProviderNewScope/stepLabel/StepLabel";
import DatePicker from "components/datePicker/DatePicker";
import { useFormik } from "formik";
import {
  stepDataNewScope,
  workInfoObject,
  workInfoValidationSchema,
  yearStartedPracticingLawObject,
  toYear,
} from "components/editProviderNewScope/helper";
import moment from "moment";
import Error from "components/error/Error";
import AddFieldButton from "../addFieldButton/AddFieldButton";
import RemoveIcon from "components/removeIcon/RemoveIcon";
import {
  addItemNewScope,
  removeItemNewScope,
} from "../educationNewScope/EducationNewScope";
import { useAxios } from "utils/hooks/useAxios";
import EditProfileSteps from "../../PremiumProviderEditProfileComponents/editProfileSteps/EditProfileSteps";

const WorkInfoNewScope = ({
  user,
  step,
  setStep,
  onSubmit,
}) => {
  const { isLoading } = useAxios();
  const workInfo = user?.workInfo || [];
  const stepsCompleted = user?.stepsCompleted;
  const yearStartedPracticingLaw = user?.yearStartedPracticingLaw;

  useEffect(() => {
    if (workInfo.length) {
      formik.setValues({
        workInfo,
      });
    }
    formik.setFieldValue("yearStartedPracticingLaw", toYear(yearStartedPracticingLaw));
  }, [workInfo, yearStartedPracticingLaw]);
  const formik = useFormik({
    initialValues: {
      workInfo: [workInfoObject],
      yearStartedPracticingLaw: yearStartedPracticingLawObject,
    },
    validationSchema: workInfoValidationSchema,
    onSubmit: async (values) => {
      const _cleanedValues = values.workInfo.map((firm) => {
        return cleanUpValues(firm);
      });
      if (stepsCompleted === 2) values.stepsCompleted = 3;
      onSubmit({ ...values, workInfo: _cleanedValues });
    },
  });
  const { getFieldProps } = formik;

  const handleDateChange = (value, index, key) => {
    if (key === "yearStartedPracticingLaw") {
      formik.setFieldTouched(`${key}`, true);
      formik.setFieldValue(`${key}`, toYear(value));
    }
    else {
      formik.setFieldTouched(`workInfo[${index}].${key}`, true);
      formik.setFieldValue(
        `workInfo[${index}].${key}`,
        value === null ? "" : toYear(value)
      );
    }
  };
  const validateAndSubmit = async (values) => {
    const _cleanedValues = values.workInfo.map((firm) => {
      return cleanUpValues(firm);
    });
    if (stepsCompleted === 2) values.stepsCompleted = 3;
    onSubmit({ ...values, workInfo: _cleanedValues }, true);
  };
  const saveStep = () => {
    validateAndSubmit(formik.values);
  };
  return (
    <div className="mb-12">
      <EditProfileSteps
        step={step}
        stepsCompleted={user?.stepsCompleted}
        setStep={setStep}
        stepData={stepDataNewScope}
        saveStep={saveStep}
      />
      <div className=" py-6">
        <StepLabel label="Work Info" />
        <form>
          {formik.values?.workInfo?.map((work, index) => {
            return (
              <div key={index} className="space-y-6 profile-form mb-8 relative">
                {formik.values.workInfo.length > 1 && (
                  <div className="absolute top-0 right-0">
                    <RemoveIcon
                      onClick={() =>
                        removeItemNewScope(
                          index,
                          "workInfo",
                          formik,
                          workInfoObject
                        )
                      }
                    />
                  </div>
                )}
                <div className="flex items-start input-wrap justify-between gap-3 sm:gap-0">
                  <div className="w-[100%]">
                    <div className="flex justify-between items-start pr-2">
                      <label
                        htmlFor="firstName"
                        className="block mb-2 text-[14px] font-[500] text-textColor"
                      >
                        Company Name
                      </label>
                    </div>
                    <input
                      type="text"
                      name="companyName"
                      id="companyName"
                      className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                      placeholder="Enter a company name"
                      {...getFieldProps(`workInfo[${index}].companyName`)}
                    />
                    <Error
                      formik={formik}
                      errorField="companyName"
                      property="workInfo"
                      index={index}
                    />
                  </div>
                  <div className="w-[100%] sm:pl-3 ">
                    <label
                      htmlFor="lastName"
                      className="block mb-2 text-[14px] font-[500] text-textColor"
                    >
                      Job Title (Optional)
                    </label>
                    <input
                      type="text"
                      name="jobTitle"
                      id="jobTitle"
                      className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                      placeholder="Title"
                      {...getFieldProps(`workInfo[${index}].jobTitle`)}
                    />
                    <Error
                      formik={formik}
                      errorField="jobTitle"
                      property="workInfo"
                      index={index}
                    />
                  </div>
                </div>
                <div className="flex items-start input-wrap justify-between gap-3 sm:gap-0">
                  <div className="w-[100%] flex flex-col">
                    <label className="block mb-2 text-[14px] font-[500] text-textColor">
                      Start
                    </label>
                    <DatePicker
                      size="large"
                      id="startDate"
                      name="startDate"
                      views={["year"]}
                      openTo="year"
                      placeholder="YYYY"
                      dateFormat="yyyy"
                      showYearPicker
                      onBlur={formik.handleBlur}
                      value={toYear(work.startDate)}
                      onChange={(value) =>
                        handleDateChange(value, index, "startDate")
                      }
                    />
                    <div className="sm:mt-6">
                      <Error
                        formik={formik}
                        errorField="startDate"
                        property="workInfo"
                        index={index}
                      />
                    </div>
                  </div>
                  <div className="w-[100%] sm:pl-3 ">
                    <label
                      htmlFor="lastName"
                      className="block mb-2 text-[14px] font-[500] text-textColor"
                    >
                      End (optional)
                    </label>
                    <DatePicker
                      size="large"
                      views={["year"]}
                      openTo="year"
                      dateFormat="yyyy"
                      showYearPicker
                      placeholder="Leave blank if current position"
                      value={toYear(work.endDate)}
                      onChange={(value) =>
                        handleDateChange(value, index, "endDate")
                      }
                    />
                    <div className="sm:mt-6">
                      <Error
                        formik={formik}
                        errorField="endDate"
                        property="workInfo"
                        index={index}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex items-start input-wrap justify-between gap-3 sm:gap-0">
                  <div className="w-[100%] sm:mt-6">
                    <div className="flex justify-between">
                      <label
                        htmlFor="firstName"
                        className="block mb-2 text-[14px] font-[500] text-textColor"
                      >
                        Description
                      </label>
                    </div>
                    <textarea
                      type="text"
                      name="university"
                      id="university"
                      className="rounded-sm w-full border border-borderColor bg-white pl-2 pt-1 h-[105px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                      placeholder="Please enter a brief description of your role and responsibilities"
                      {...getFieldProps(`workInfo[${index}].description`)}
                    />
                    <Error
                      formik={formik}
                      errorField="description"
                      property="workInfo"
                      index={index}
                    />
                  </div>
                </div>
              </div>
            );
          })}
          <AddFieldButton
            buttonText="Add Work Info"
            onClick={() => addItemNewScope("workInfo", formik, workInfoObject)}
          />

          <div className="w-[100%] sm:pl-3 ">
            <label
              htmlFor="lastName"
              className="block mb-2 text-[14px] font-[500] text-textColor"
            >
              Year Started Practicing Law
            </label>
            <DatePicker
              name="yearStartedPracticingLaw"
              id="yearStartedPracticingLaw"
              size="large"
              views={["year"]}
              openTo="year"
              placeholder="YYYY"
              dateFormat="yyyy"
              showYearPicker
              value={formik.values.yearStartedPracticingLaw}
              onBlur={formik.handleBlur}
              onChange={(value) =>
                handleDateChange(value, 0, "yearStartedPracticingLaw")
              }
            />
            <div className="sm:mt-6">
              <Error
                formik={formik}
                errorField="yearStartedPracticingLaw"
                index={-1}
              />
            </div>
          </div>
        </form>
      </div>
      <EditProfileFooter
        pre={() => setStep(EDUCATION)}
        step={step}
        next={() => {
          formik.handleSubmit();
        }}
        isLoading={isLoading}
      />
    </div>
  );
};

export default WorkInfoNewScope;
