import { routes } from "utils/appConfig";

export const getSearchById = (callApi, search, successCb, errorCb) => {
  callSearchApi(callApi, "GET", search, successCb, errorCb);
};
export const deleteSearchById = (callApi, search, successCb, errorCb) => {
  callSearchApi(callApi, "DELETE", search, successCb, errorCb);
};

export const callSearchApi = (callApi, method, search, successCb, errorCb) => {
  if (search === 'undefined' || search === null) return
  const { url } = routes.searchById;
  try {
    callApi(
      url(search),
      method,
      {},
      {
        onSuccess: ({ data }) => {
          successCb(data);
        },
        onError: (e) => {
          errorCb(e);
        },
        successMessage: "Search deleted successfully",
        turnOffNotification: method !== "DELETE",
      }
    );
  } catch (e) {
    console.log(e);
  }
};
