import React from "react";
import { adaptV4Theme } from "@mui/material/styles";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.jsx";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import reduxStore from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  makeStyles,
} from "@mui/material/styles";

const root = ReactDOM.createRoot(document.getElementById("root"));
const { store, persistor } = reduxStore();
const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
const theme = createTheme({
  palette: {
    primary: createColor("#00A4DA"),
    secondary: createColor("##002A5C"),
    white: createColor("#fff"),
    gray: createColor("#F6F6F6"),
    textColor: createColor("#5A5A5A"),
    borderColor: createColor("#C6C7C4"),
    hoverColor: createColor("#0099CB"),
    opacityColor: createColor("#9596A0"),
  },
});
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <ThemeProvider theme={theme} /> */}
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </StyledEngineProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
