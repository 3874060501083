import React from "react";
import BookingManagerMain from "pages/BookingManagerMain";
import { useParams, useLocation } from "react-router-dom";

const BookingDetailsDirectLink = () => {
    const location = useLocation()
    const bookingId = useParams().id
    const eventSlug = new URLSearchParams(location.search).get('slug')
    const calUsername = new URLSearchParams(location.search).get('username') 
    return (
        <div className="py-20">
            <BookingManagerMain 
                calAtomsBookingId={bookingId}
                calAtomsEventSlug={eventSlug}
                calAtomsUserName={calUsername}
            />
        </div>
    );  
};

export default BookingDetailsDirectLink;