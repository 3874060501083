import { Button, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RenderActionsMenu from "components/Dashboard/table/RenderActionMenu";
import { updateSearchQuery } from "store/actions/actions";
import { ADVANCED_SEARCH, GENERAL_SEARCH } from "utils/constants/constants";
import MatchDate from "./MatchDate";
import SearchText from "./SearchText";
import AvatarGroup from "./AvatarGroup";

export const RenderStatus = (item) => {
  return (
    <Typography color={item.status === "CANCELED" ? "error" : "primary"}>
      {item.status}
    </Typography>
  );
};
export const recentColumns = [
  {
    id: 1,
    field: "id",
    label: "ID",
    width: "5%",
  },
  {
    id: 2,
    field: "text",
    label: "Search Text",
    width: "45%",
    render: (searchRecord, _setState, _extended, { categories }) => (
      <SearchText searchRecord={searchRecord} categories={categories} />
    ),
  },
  {
    id: 3,
    field: "foundLawyers",
    label: "Match found",
    width: "15%",
    align: "center",
    render: (searchRecord) => {
      const { foundLawyers } = searchRecord;
      return <p>{foundLawyers?.length}</p>;
    },
  },
  {
    id: 4,
    field: "updatedAt",
    label: "Match Date",
    width: "15%",
    align: "center",
    render: (searchRecord) => <MatchDate searchRecord={searchRecord} />,
  },
  {
    id: 5,
    field: "results",
    label: "Results",
    width: "15%",
    align: "center",
    render: (searchRecord, _setState, _extended, { navigate, dispatch }) => {
      const searchType =
        searchRecord?.category || searchRecord?.name
          ? ADVANCED_SEARCH
          : GENERAL_SEARCH;
      const handleButtonClick = () => {
        if (searchType === ADVANCED_SEARCH) {
          const searchBy = searchRecord?.category ? "PRACTICE_AREA" : "NAME";
          dispatch(
            updateSearchQuery({
              searchCategoryId: searchRecord?.category ?? null,
              searchType: ADVANCED_SEARCH,
              searchText: null,
              searchName: searchRecord?.name ?? null,
              searchBy,
            })
          );
        } else {
          dispatch(
            updateSearchQuery({
              searchText: searchRecord?.text,
              searchType: GENERAL_SEARCH,
              searchCategoryId: null,
            })
          );
        }
        navigate("/searchresult", { state: { searchId: searchRecord._id } });
      };
      return <Button onClick={handleButtonClick}>View</Button>;
    },
  },
  {
    id: 6,
    field: "actions",
    label: "Actions",
    render: (searchRecord, setLists) => {
      let temp = [];
      const callBack = () => {
        setLists((previousRows) => {
          previousRows.forEach((curr) => {
            if (curr._id !== searchRecord._id) {
              temp.push(curr);
            }
          });
          return temp;
        });
      };
      return (
        <div>
          <RenderActionsMenu
            buttonText="Delete"
            options={[{ label: "Delete", option: "delete" }]}
            onClick={callBack}
            searchRecord={searchRecord}
          >
            <MoreVertIcon cursor="pointer" />
          </RenderActionsMenu>
        </div>
      );
    },
    align: "center",
    width: "5%",
  },
];
export const bookmarkedColumns = [
  {
    id: 1,
    field: "id",
    label: "ID",
    width: "3%",
  },
  {
    id: 2,
    field: "bookmarked",
    label: "Bookmarked",
    width: "13%",
    align: "left",
    render: (searchRecord) => (
      <AvatarGroup avatars={searchRecord?.bookmarkedLawyers} />
    ),
  },
  {
    id: 3,
    field: "text",
    label: "Search Text",
    width: "45%",
    render: (searchRecord, _setState, _extended, { categories }) => (
      <SearchText searchRecord={searchRecord} categories={categories} />
    ),
  },
  {
    id: 4,
    field: "matchDate",
    label: "Match Date",
    width: "15%",
    align: "center",
    render: (searchRecord) => <MatchDate searchRecord={searchRecord} />,
  },
];
export const myMattersColumns = [
  {
    id: 1,
    field: "searchText",
    label: "Search Text",
    width: "45%",
    render: (searchRecord, _setState, _extended, { categories }) => (
      <SearchText searchRecord={searchRecord?.search} categories={categories} />
    ),
  },
  {
    id: 2,
    field: "provider1",
    label: "Provider",
    width: "15%",
    align: "center",
    render: (searchRecord) => <p>{searchRecord?.provider?.firstName}</p>,
  },
  {
    id: 3,
    field: "matchDate",
    label: "Match Date",
    width: "15%",
    align: "center",
    render: (searchRecord) => <MatchDate searchRecord={searchRecord} />,
  },
  {
    id: 4,
    field: "status",
    label: "Status",
    width: "15%",
    align: "center",
    render: (item) => RenderStatus(item),
  },
  {
    id: 5,
    field: "actions",
    label: "Actions",
    render: (row, setRows, _extended, { navigate }) => {
      let temp = [];
      const callBack = (option) => {
        if (option === "viewDetails") {
          console.log("🚀 ~ value:", option);
          navigate(`/matters/booking/${row._id}`);
        } else {
          setRows((previousRows) => {
            previousRows.forEach((curr) => {
              if (curr._id !== row._id) {
                temp.push(curr);
              }
            });
            return temp;
          });
        }
      };
      return (
        <div>
          <RenderActionsMenu
            options={[
              { label: "Manage Booking", option: "viewDetails" },
              // { label: "Delete", option: "delete" },
            ]}
            onClick={callBack}
            searchRecord={row}
            isMatterTab={true}
          >
            <MoreVertIcon cursor="pointer" />
          </RenderActionsMenu>
        </div>
      );
    },
    align: "center",
    width: "5%",
  },
];
export const myInquiriesColumns = [
  {
    id: 1,
    field: "title",
    label: "Inquiries Title",
    width: "45%",
    render: (searchRecord) => <p>{searchRecord?.title}</p>,
  },
  {
    id: 2,
    field: "provider",
    label: "Provider",
    width: "15%",
    align: "center",
    render: (searchRecord) => <p>{searchRecord?.providerName}</p>,
  },
  {
    id: 3,
    field: "matchDate",
    label: "Match Date",
    width: "15%",
    align: "center",
    render: (searchRecord) => <MatchDate searchRecord={searchRecord} />,
  },
  {
    id: 4,
    field: "actions",
    label: "Actions",
    render: (row, setRows, _extended, { navigate }) => {
      let temp = [];
      const callBack = (option) => {
        if (option === "viewDetails") {
          navigate(`/inquiries/${row._id}`);
        } else if (option === "delete") {
          setRows((previousRows) => {
            previousRows.forEach((curr) => {
              if (curr._id !== row._id) {
                temp.push(curr);
              }
            });
            return temp;
          });
        }
      };
      return (
        <div>
          <RenderActionsMenu
            options={[{ label: "View Details", option: "viewDetails" }]}
            onClick={callBack}
            searchRecord={row}
            isMatterTab={true}
          >
            <MoreVertIcon cursor="pointer" />
          </RenderActionsMenu>
        </div>
      );
    },
    align: "center",
    width: "5%",
  },
];
export const providerMatterColumns = [
  {
    id: 1,
    field: "id",
    label: "ID",
    width: "5%",
    render: (searchRecord) => <p>{searchRecord?.bookafyId}</p>,
  },
  {
    id: 2,
    field: "matterTitle",
    label: "Matter Title",
    width: "45%",
    render: (searchRecord, _setState, _extended, { categories }) => (
      <SearchText searchRecord={searchRecord?.search} categories={categories} />
    ),
  },
  {
    id: 3,
    field: "clientName",
    label: "Client Name ",
    width: "15%",
    align: "center",
    render: (searchRecord) => (
      <p>
        {searchRecord?.user.firstName + " " + searchRecord?.user?.lastName ??
          ""}
      </p>
    ),
  },
  {
    id: 4,
    field: "matchDate",
    label: "Match Date",
    width: "15%",
    align: "center",
    render: (searchRecord) => <MatchDate searchRecord={searchRecord} />,
  },
  {
    id: 5,
    field: "status",
    label: "Status",
    width: "15%",
    align: "center",
    render: (item) => RenderStatus(item),
  },
  {
    id: 6,
    field: "actions",
    label: "Actions",
    render: (row, setLists, _extended, { navigate }) => {
      let temp = [];
      const callBack = (option) => {
        if (option === "viewDetails") {
          console.log("🚀 ~ value:", option);
          navigate(`/matters/${row._id}`);
        } else {
          setLists((previousRows) => {
            previousRows.forEach((curr) => {
              if (curr._id !== row._id) {
                temp.push(curr);
              }
            });
            return temp;
          });
        }
      };
      return (
        <div>
          <RenderActionsMenu
            buttonText="Delete"
            options={[
              { label: "View Details", option: "viewDetails" },
              // { label: "Delete", option: "delete" },
            ]}
            onClick={callBack}
            searchRecord={row}
            isMatterTab={true}
          >
            <MoreVertIcon cursor="pointer" />
          </RenderActionsMenu>
        </div>
      );
    },
    align: "center",
    width: "5%",
  },
];

export const providerInquiryColumns = [
  {
    id: 1,
    field: "title",
    label: "Inquiry Title",
    width: "45%",
  },
  {
    id: 2,
    field: "userName",
    label: "Details",
    width: "20%",
    align: "center",
    render: (row, setRows, _extended, { navigate }) => {
      return (
        <button
          className="bg-transparent sm:h-[40px] sm:w-[65px] rounded-xs text-[#64B4D0] duration-300"
          onClick={() => navigate(`/inquiries/${row._id}`)}
        >
          View
        </button>
      );
    },
  },
  {
    id: 3,
    field: "userName",
    label: "Consumers",
    width: "15%",
    align: "center",
  },
  {
    id: 4,
    field: "matchDate",
    label: "Match Date",
    width: "15%",
    align: "center",
    render: (searchRecord) => <MatchDate searchRecord={searchRecord} />,
  },
  {
    id: 5,
    field: "actions",
    label: "Actions",
    render: (row, setLists, _extended, { navigate }) => {
      let temp = [];
      const callBack = (option) => {
        if (option === "viewDetails") {
          navigate(`/inquiries/${row._id}`);
        } else {
          setLists((previousRows) => {
            previousRows.forEach((curr) => {
              if (curr._id !== row._id) {
                temp.push(curr);
              }
            });
            return temp;
          });
        }
      };
      return (
        <div>
          <RenderActionsMenu
            buttonText="Delete"
            options={[
              { label: "View Details", option: "viewDetails" },
              // { label: "Delete", option: "delete" },
            ]}
            onClick={callBack}
            searchRecord={row}
            isMatterTab={true}
          >
            <MoreVertIcon cursor="pointer" />
          </RenderActionsMenu>
        </div>
      );
    },
    align: "center",
    width: "5%",
  },
];

export const consumerTabs = [
  "Recent Searches",
  "Bookmarked",
  "My Bookings",
  "My Inquiries",
];
export const providerTabs = ["My Inquiries", "Matters"];
