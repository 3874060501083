import { Box, Stack, Typography } from "@mui/material";
import {
  ADDRESS,
  PERSONAL_INFO,
} from "components/PremiumProviderEditProfileComponents/constants/editProfileConstants";
import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { closeCurrentModal } from "store/actions/modalActions";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LanguageIcon from "@mui/icons-material/Language";
import CloseIcon from "@mui/icons-material/Close";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { formatPhone } from "utils/helperFunctions/helper";

const ProviderContactModal = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleNavigationClick = (step) => {
    dispatch(closeCurrentModal());
    navigate("profile/edit", { state: { goToStep: step } });
  };
  const handleClose = () => {
    dispatch(closeCurrentModal());
  };
  return (
    <div className="relative bg-[#fff] w-[326px] sm:w-[484px]  rounded-lg shadow dark:bg-gray-700 rounded-[20px] flex flex-col py-8 px-11">
      <Box
        position="absolute"
        top={12}
        right={12}
        sx={{ cursor: "pointer" }}
        onClick={handleClose}
      >
        <CloseIcon sx={{ width: "26px", height: "26px" }} />
      </Box>
      <Stack gap={2}>
        {/* Contact info */}
        <Stack gap={1}>
          <Stack direction="row" alignItems="center">
            <Typography fontWeight={700} fontSize={22}>
              Contact Info
            </Typography>
            <Typography
              onClick={() => handleNavigationClick(PERSONAL_INFO)}
              sx={{
                color: "#00A4DA",
                fontSize: 16,
                fontWeight: 600,
                textAlign: "center",
                cursor: "pointer",
                pl: 1,
              }}
            >
              Edit
            </Typography>
          </Stack>
          {/* phone info */}
          {user?.phone && (
            <Stack gap={1}>
              <Stack
                direction="row"
                alignItems="center"
                gap={2}
                sx={{ color: "#5a5a5a99" }}
              >
                <LocalPhoneIcon sx={{ width: "16px", height: "16px" }} />
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  sx={{ color: "#002A5C" }}
                >
                  Phone Number
                </Typography>
              </Stack>
              <Typography ml={4} color="#5a5a5a99">
                {formatPhone(user?.phone)}
              </Typography>
            </Stack>
          )}
          {/* email info */}
          {user?.email && (
            <Stack gap={1}>
              <Stack
                direction="row"
                alignItems="center"
                gap={2}
                sx={{ color: "#5a5a5a99" }}
              >
                <MailOutlineIcon sx={{ width: "16px", height: "16px" }} />
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  sx={{ color: "#002A5C" }}
                >
                  Email
                </Typography>
              </Stack>
              <Typography ml={4} color="#5a5a5a99">
                {user?.email}
              </Typography>
            </Stack>
          )}
          {/* website info */}
          {user?.firmWebsite && (
            <Stack gap={1}>
              <Stack
                direction="row"
                alignItems="center"
                gap={2}
                sx={{ color: "#5a5a5a99" }}
              >
                <LanguageIcon sx={{ width: "16px", height: "16px" }} />
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  sx={{ color: "#002A5C" }}
                >
                  Website
                </Typography>
              </Stack>
              <Typography ml={4} color="#5a5a5a99">
                {user?.firmWebsite}
              </Typography>
            </Stack>
          )}
        </Stack>
        {/* Social Urls */}
        {/* {user?.socialURLs?.length && (
          <Stack gap={1}>
            <Stack direction="row" alignItems="center">
              <Typography fontWeight={700} fontSize={22}>
                Social URLs
              </Typography>
              <Typography
                onClick={() => handleNavigationClick(PERSONAL_INFO)}
                sx={{
                  color: "#00A4DA",
                  fontSize: 16,
                  fontWeight: 600,
                  textAlign: "center",
                  cursor: "pointer",
                  pl: 1,
                }}
              >
                Edit
              </Typography>
            </Stack>
            {user?.socialURLs.map((social, i) => {
              return (
                <Stack key={i} direction="row" alignItems="center" gap={2}>
                  {social.kind === "facebook" && (
                    <FacebookOutlinedIcon fontSize="small" />
                  )}
                  {social.kind === "instagram" && (
                    <InstagramIcon fontSize="small" />
                  )}
                  {social.kind === "linkedin" && (
                    <LinkedInIcon fontSize="small" />
                  )}
                  <Typography
                    sx={{ color: "#5a5a5a99", fontSize: 14, fontWeight: 500 }}
                  >
                    {social.url}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        )} */}
      </Stack>
    </div>
  );
};

export default ProviderContactModal;
