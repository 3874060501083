import { routes } from "utils/appConfig";

export const fetchMatterById = (callApi, id, cb) => {
  const { url, method } = routes.matterById;
  try {
    callApi(
      url(id),
      method,
      {},
      {
        onSuccess: ({ data }) => {
          cb(data);
        },
        onError: (error) => {
          cb(null, error);
        },
        turnOffNotification: true,
      }
    );
  } catch (e) {
    console.log("fetchMatterById~catch", e);
    throw new Error();
  }
};
