import { routes } from "utils/appConfig";
import { serializeParams } from "utils/helperFunctions/helper";

export const fetchMyInquiries = (callApi, params, cb) => {
  const { url, method } = routes.inquiry.myInquiries;
  try {
    callApi(
      url + serializeParams(params),
      method,
      {},
      {
        onSuccess: ({ data }) => {
          cb(data.data);
        },
        onError: (error) => {
          cb(null, error);
        },
        turnOffNotification: true,
      }
    );
  } catch (e) {
    console.log("fetchMyInquiries~catch", e);
    throw new Error();
  }
};
