import { routes } from "utils/appConfig";

export const fetchProviderStats = (callApi, filters, cb) => {
  const { url, method } = routes.getStats;
  try {
    callApi(
      url,
      method,
      {
        filters,
      },
      {
        onSuccess: ({ data }) => {
          cb(data);
        },
        errorMessage: "Something went wrong!",
        turnOffNotification: true,
      }
    );
  } catch (e) {
    console.log("fetchProviderStats", e);
  }
};
