import { routes } from "utils/appConfig";

export const uploadMedia = (callApi, mediaFile, cb) => {
  try {
    let formData = new FormData();
    formData.append("file", mediaFile);
    callApi(routes.media.url, "POST", formData, {
      onSuccess: ({ data }) => {
        cb(data);
      },
      onError: (error) => {
        cb(null, error);
      },
    });
  } catch (e) {
    console.log("🚀 ~ e:", e);
    throw new Error();
  }
};
