import React, { useEffect, useState } from "react";
import FirmInfo, { firmInitialValues } from "components/firm/FirmInfo";
import { stepDataFirm } from "components/firm/helper";
import { ADDRESS } from "components/PremiumProviderEditProfileComponents/constants/editProfileConstants";
import EditProfileSteps from "components/PremiumProviderEditProfileComponents/editProfileSteps/EditProfileSteps";
import { useDispatch, useSelector } from "react-redux";
import {
  addBusiness,
  fetchBusinessById,
  fetchBusinessList,
  updateBusinessById,
} from "services/business";
import { FIRM_INFO } from "utils/constants/constants";
import { useAxios } from "utils/hooks/useAxios";
import { Typography, Container, Stack } from "@mui/material";
import PremiumProviderEditProfileAddress from "components/PremiumProviderEditProfileComponents/PremiumProviderEditProfileAddress";
import Loader from "utils/loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchLoggedInUserInfo } from "services/fetchUserInfo";
import { signInAction } from "store/actions/authActions";
import { updateBusinessList } from "store/actions/actions";
import BreadCrumb from "components/PremiumProviderEditProfileComponents/breadCrumb/BreadCrumb";

const CreateFirm = () => {
  const userType = useSelector((state) => state.user?.userType);
  const states = useSelector((state) => state.categoriesReducer.states);
  const [businessData, setBusinessData] = useState({});
  const [step, setStep] = useState(FIRM_INFO);
  const [stepsCompleted, setStepsCompleted] = useState(0);
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { isLoading, callApi } = useAxios();
  const { callApi: fetchUserApi } = useAxios();
  const navigate = useNavigate();

  useEffect(() => {
    if (state?.goToStep) {
      fetchBusinessById(callApi, state.id, (data) => {
        const fetchedBusiness = data.data;
        setBusinessData({ ...firmInitialValues, ...fetchedBusiness });
        setStep(state.goToStep);
        setIsEditMode(true);
        if (fetchedBusiness?.addresses) {
          setStepsCompleted(2);
        }
      });
    }
  }, [state?.goToStep]);

  const updateBusinessData = (data) => {
    if (isEditMode) {
      updateBusinessById(
        callApi,
        state.id,
        { ...businessData, ...data },
        () => {
          setBusinessData({ ...businessData, ...data });
          setStep(ADDRESS);
          fetchLoggedInUserInfo(fetchUserApi, (res) => {
            dispatch(signInAction(res?.data));
          });
          if (step === ADDRESS) {
            navigate(`/firm/${state.id}`);
          }
        }
      );
    } else {
      if (step === FIRM_INFO) {
        setBusinessData({ ...data });
        setStep(ADDRESS);
        setStepsCompleted(2);
      } else {
        data.isPublic = true;
        setBusinessData((pre) => ({
          ...pre,
          ...data,
        }));
        const tempDataToCallApi = {
          ...businessData,
          ...data,
          isGeneralCounselor: true,
          businessType: "LLC",
          EIN: "EIN",
          isAnonymous: false,
        };
        addBusiness(callApi, tempDataToCallApi, (data) => {
          if (data) {
            setBusinessData(data?.data);
            fetchLoggedInUserInfo(fetchUserApi, (data) => {
              dispatch(signInAction(data?.data));
            });
            fetchBusinessList(fetchUserApi, (data) => {
              if (data) {
                dispatch(updateBusinessList(data?.data));
              }
            });
            navigate(`/firm/${data.data?._id}`);
          }
        });
      }
    }
  };

  return (
    <>
      <Container>
        <BreadCrumb
          className="-ml-[15%] -mb-3"
          path={!isEditMode ? "/profile/edit" : `/firm/${state?.id}`}
          moveTo={!isEditMode ? "Back to profile" : "Back to firm"}
        />
        <Stack gap={2}>
          <Typography fontWeight={700} fontSize={22}>
            {isEditMode ? "Edit" : "Create"} Firm
          </Typography>
          <Typography fontWeight={400} fontSize={16}>
            Please enter the required information to create firm.
          </Typography>
        </Stack>
        <EditProfileSteps
          stepData={stepDataFirm}
          step={step}
          setStep={setStep}
          stepsCompleted={stepsCompleted}
        />
        {isLoading ? (
          <Stack height="400px">
            <Loader height={50} width={50} />
          </Stack>
        ) : (
          <>
            {step === FIRM_INFO && (
              <FirmInfo
                onSubmit={updateBusinessData}
                loading={isLoading}
                businessData={businessData}
                isEditMode={isEditMode}
              />
            )}
            {step === ADDRESS && (
              <PremiumProviderEditProfileAddress
                pre={() => setStep(FIRM_INFO)}
                onSubmit={updateBusinessData}
                addressesList={businessData?.addresses ?? []}
                stepsCompleted={stepsCompleted}
                states={states}
                userType={userType}
                createFirm={true}
                isEditMode={isEditMode}
              />
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default CreateFirm;
