import React from "react";
import Profile from "./Profile";
import { Container } from "@mui/material";
import ProfileImg from "./imgs/profile.png";

const BusinessDetail = ({ user }) => {
  return (
    <Container maxWidth="false" sx={{ pb: 6, maxWidth: "95%" }}>
      <div className="mt-4">
        <Profile image={ProfileImg} user={user} />
        {/* {!!user?.businesses?.length && (
          <>
            <Typography
              sx={{
                color: "#002A5C",
                fontSize: 22,
                fontWeight: 700,
                py: 2,
              }}
            >
              Business Info
            </Typography>
            <BusinessInfo business={user?.businesses?.[0]} />
          </>
        )} */}
        {/* {!!user?.businesses?.[0]?.businessDescription && (
          <Description
            description={user?.businesses?.[0]?.businessDescription}
          />
        )}
        {!!user?.addresses?.length && (
          <Address isEdit={true} addresses={user?.addresses} />
        )} */}
      </div>
    </Container>
  );
};
export default BusinessDetail;
