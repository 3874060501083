import React, { useEffect } from "react";
import {
  addFields,
  addressObject,
  capitalizeFirstLetter,
  officeType,
  removeFields,
} from "components/PremiumProviderEditProfileComponents/helper";
import EditProfileFooter from "components/PremiumProviderEditProfileComponents/editProfilFooter/EditProfileFooter";
import { useFormik } from "formik";
import { addressValidationSchema } from "components/PremiumProviderEditProfileComponents/editProfileValidation";
import Error from "components/error/Error";
import { useState } from "react";
import DynamicFieldsHeader from "components/dynamicFeildsHeader/DynamicFieldsHeader";
import { cleanUpValues } from "utils/helperFunctions/helper";

const PremiumProviderEditProfileAddress = ({
  pre,
  onSubmit,
  addressesList,
  stepsCompleted,
  states,
  userType,
  createFirm,
  isEditMode,
}) => {
  const [addresses, setAddresses] = useState([addressObject]);
  useEffect(() => {
    if (addressesList?.length) {
      setAddresses(addressesList);
      formik.setValues({
        addresses: addressesList,
      });
    }
  }, [addressesList]);
  const formik = useFormik({
    initialValues: {
      addresses,
    },
    validationSchema: addressValidationSchema,
    onSubmit: (values) => {
      values.addresses.forEach((item) => {
        cleanUpValues(item);
      });
      if (stepsCompleted === 1) values.stepsCompleted = 2;
      if (userType === "BUSINESS") values.stepsCompleted = 3;
      onSubmit(values);
    },
  });
  const { getFieldProps } = formik;
  return (
    <div className="flex-wrap ">
      <button
        type="button"
        className=" top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex  dark:hover:text-primary text-textColor"
        data-modal-toggle="authentication-modal"
      ></button>
      <div className=" py-6">
        {/* Form */}
        <form className="space-y-6 profile-form" onSubmit={formik.handleSubmit}>
          {addresses.map((_, i) => (
            <div key={i} className="space-y-6 profile-form">
              <DynamicFieldsHeader
                title="Address"
                index={i}
                callBack={() =>
                  removeFields(setAddresses, i, formik, "addresses")
                }
                length={addresses?.length}
              />
              <div className="flex">
                <label
                  htmlFor="officeType"
                  className="block text-[16px] font-[500] text-textColor"
                >
                  Office Type
                </label>
              </div>
              <div>
                <div className="flex w-full h-12 rounded-sm mb-2">
                  <select
                    name="officeType"
                    id="officeType"
                    className=" pr-4 rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none
                  "
                    {...getFieldProps(`addresses[${i}].officeType`)}
                  >
                    <option value="">Select</option>
                    {officeType.map((type) => (
                      <option key={type} value={type}>
                        {capitalizeFirstLetter(type)}
                      </option>
                    ))}
                  </select>
                </div>
                <Error
                  formik={formik}
                  errorField="officeType"
                  property="addresses"
                  index={i}
                />
              </div>
              <div className="flex items-start input-wrap justify-between">
                <div className="w-[100%] ">
                  <label
                    htmlFor="country"
                    className="block mb-2 text-[16px] font-[500] text-textColor"
                  >
                    Country
                  </label>
                  <select
                    name="country"
                    id="country"
                    className="rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none
                    "
                    {...getFieldProps(`addresses[${i}].country`)}
                    disabled
                  >
                    <option value="USA">USA</option>
                  </select>
                  <Error
                    formik={formik}
                    errorField="country"
                    property="addresses"
                    index={i}
                  />
                </div>
                <div className="w-[100%] pl-3 padding-input padding-input-t">
                  <label
                    htmlFor="state"
                    className="block mb-2 text-[16px] font-[500] text-textColor"
                  >
                    State
                  </label>
                  <select
                    name="state"
                    id="state"
                    className="rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none
                    "
                    {...getFieldProps(`addresses[${i}].state`)}
                  >
                    <option value="">Select</option>
                    {states?.map((state) => (
                      <option key={state._id} value={state._id}>
                        {capitalizeFirstLetter(state.name)}
                      </option>
                    ))}
                  </select>
                  <Error
                    formik={formik}
                    errorField="state"
                    property="addresses"
                    index={i}
                  />
                </div>
              </div>

              <div className="flex input-wrap ">
                <div className="w-[100%]">
                  <div className="flex">
                    <label
                      htmlFor="designation"
                      className="block mb-2 text-[16px] font-[500] text-textColor"
                    >
                      City
                    </label>
                  </div>

                  <input
                    type="text"
                    name="city"
                    id="city"
                    className="rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                    placeholder="Ingliwood"
                    {...getFieldProps(`addresses[${i}].city`)}
                  />
                  <Error
                    formik={formik}
                    errorField="city"
                    property="addresses"
                    index={i}
                  />
                </div>
                <div className="w-[100%] pl-3 padding-input padding-input-t">
                  <div className="flex">
                    <label
                      htmlFor="postalCode"
                      className="block mb-2 text-[16px] font-[500] text-textColor"
                    >
                      Postal Code
                    </label>
                  </div>

                  <input
                    type="text"
                    name="postalCode"
                    id="postalCode"
                    className="rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                    placeholder="903032225"
                    {...getFieldProps(`addresses[${i}].postalCode`)}
                  />
                  <Error
                    formik={formik}
                    errorField="postalCode"
                    property="addresses"
                    index={i}
                  />
                </div>
              </div>
              <div className="flex input-wrap">
                <div className="w-[100%] padding-input-t padding-input">
                  <label
                    htmlFor="street01"
                    className="block mb-2 text-[16px] font-[500] text-textColor"
                  >
                    Street 01
                  </label>

                  <input
                    type="text"
                    name="street01"
                    id="street01"
                    className="rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                    placeholder="3401 110TH, ST"
                    {...getFieldProps(`addresses[${i}].street01`)}
                  />
                </div>
              </div>

              <div className="w-[100%] padding-input-t padding-input">
                <label
                  htmlFor="street02"
                  className="block mb-2 text-[16px] font-[500] text-textColor"
                >
                  Street 02
                </label>

                <input
                  type="text"
                  name="street02"
                  id="street02"
                  className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                  placeholder="Street 02"
                  {...getFieldProps(`addresses[${i}].street02`)}
                />
              </div>
            </div>
          ))}
          {userType !== "INDIVIDUAL" && (
            <div className="flex justify-end">
              <button
                type="button"
                className="w-fit text-[14px] font-[700] hover:text-hoverColor text-primary pt-3 cursor-pointer"
                onClick={() =>
                  addFields(setAddresses, addressObject, formik, "addresses")
                }
              >
                Add Address
              </button>
            </div>
          )}
        </form>
        <EditProfileFooter
          pre={pre}
          next={formik.handleSubmit}
          nextButtonTitle={
            userType === "PROVIDER" && !createFirm ? "Next" : "Finish"
          }
        />
      </div>
    </div>
  );
};

export default PremiumProviderEditProfileAddress;
