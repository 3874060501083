// import Nav from "components/navbar/Navbar";
import React from "react";
import Lottie from "lottie-react";
import Lottie1 from "./imgs/lottie2.json";
import { Link } from "react-router-dom";
// import MainImg from "./imgs/bell.svg"
const CompleteProfile = ({isProfileComplete = false}) => {
  const subtitle = isProfileComplete ? 'Your profile is under review, please check again later' : 'Please complete your profile to appear in searches';
  const ctaText = isProfileComplete ? 'View Profile': 'Continue';
  return (
    <div className="flex justify-center">
      {/* <Nav /> */}
      <div className="flex  text-center">
        <div className="h-[95vh] pt-12">
          <Lottie className="w-[330] h-[250px]" animationData={Lottie1} />
          <div className="h-[40vh]">
            <h1 className="text-secondary font-[700] text-[26px]">
              Welcome to JuriCon!
            </h1>
            <p className="text-[#5A5A5A] pb-4">
              {subtitle}
            </p>
            <Link to="/profile/edit">
              <button className="bg-primary hover:bg-[#0099CB] text-white w-[183px] h-[56px] rounded-sm">
                {ctaText}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompleteProfile;
