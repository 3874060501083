import { Facebook, Linkedin, Youtube } from "lucide-react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/logo_white.png";
import subscribeIcon from "../assets/subscribeW.svg";
import NewsletterSubscriptionPopup from "./NewsletterSubscriptionPopup"; // Import the popup component

const footerMenu = [
  { name: "About", url: "about", externalLink: false },
  { name: "News & Articles", url: "blog", externalLink: true },
  { name: "Contact", url: "mailto:info@juricon.ai", externalLink: false },
  {
    name: "Terms of Service",
    url: "https://app.termly.io/document/terms-of-service/2350bad3-30e8-43be-82df-bc35e0b06bd6",
    externalLink: true,
  },
  {
    name: "Privacy Policy",
    url: "https://app.termly.io/document/privacy-policy/79ea0a88-7cf7-4d24-939a-2351f98ce44f",
    externalLink: true,
  },
];

const SocialLink = ({ href, icon: Icon, label }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="mx-2 transition-colors duration-300 hover:text-primary"
    aria-label={label}
  >
    <Icon size={24} />
  </a>
);

const Footer = () => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <footer className="mt-auto">
      <div className="p-8 text-white bg-secondary">
        <div className="grid grid-cols-1 gap-8 mx-auto max-w-7xl md:grid-cols-3">
          {/* Left Column - Logo and Copyright */}
          <div className="flex flex-col items-center md:items-start">
            <img src={Logo} alt="Logo" className="h-16 mb-4" />
            <p className="text-sm text-gray-300">
              © {new Date().getFullYear()} JuriCon AI.
              <br />
              All rights reserved.
            </p>
          </div>

          {/* Middle Column - Subscribe Icon and Social Links */}
          <div className="flex flex-col items-center space-y-8 md:flex-row md:items-center md:space-x-8 md:space-y-0">
            {/* Subscribe Icon */}
            <img
              src={subscribeIcon}
              alt="Subscribe"
              className="w-24 h-24 cursor-pointer"
              onClick={() => setShowPopup(true)}
            />

            {/* Social Links */}
            <div className="text-center md:text-left">
              <h3 className="mb-4 text-lg font-semibold">Follow Us</h3>
              <div className="flex items-center justify-center md:justify-start">
                <SocialLink
                  href="https://www.linkedin.com/company/juriconai/"
                  icon={Linkedin}
                  label="LinkedIn"
                />
                <SocialLink
                  href="https://www.facebook.com/JuriConai"
                  icon={Facebook}
                  label="Facebook"
                />
                <SocialLink
                  href="https://www.youtube.com/@JuriCon-AI"
                  icon={Youtube}
                  label="YouTube"
                />
              </div>
            </div>
          </div>

          {/* Right Column - Menu Links */}
          <div className="flex flex-col items-center md:items-end">
            <nav>
              <ul className="flex flex-col items-center space-y-2 md:items-end">
                {footerMenu.map((item) => (
                  <li key={item.name}>
                    {item.externalLink ? (
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="transition-colors duration-300 hover:text-primary"
                      >
                        {item.name}
                      </a>
                    ) : (
                      <NavLink
                        to={item.url}
                        className="transition-colors duration-300 hover:text-primary"
                      >
                        {item.name}
                      </NavLink>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>

      {/* Newsletter Subscription Popup */}
      {showPopup && (
        <NewsletterSubscriptionPopup
          showPopup={showPopup}
          setShowPopup={setShowPopup}
        />
      )}
    </footer>
  );
};

export default Footer;
