import React from "react";
import Loader from "../../utils/loader/Loader";

const Button = ({ className, loaderProps, loader, children, ...rest }) => {
  return (
    <button
      className={`w-full bg-primary text-white  hover:bg-hoverColor rounded-sm focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-sm px-5 disabled:opacity-30 ${className}`}
      {...rest}
    >
      {loader ? <Loader {...loaderProps} /> : children}
    </button>
  );
};

export default Button;
