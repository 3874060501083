import React from "react";
import { useDispatch } from "react-redux";
import { closeCurrentModal } from "store/actions/modalActions";
import vector from "./imgs/Ellipse.svg";
import success from "./imgs/succesTick.svg";
import { useLocation, useNavigate } from "react-router-dom";

const SuccessModal = ({
  label,
  description,
  buttonText,
  onSubmit,
  onClose,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const handleClose = () => {
    if (onClose) {
      onClose();
      return;
    }
    dispatch(closeCurrentModal());
  };

  return (
    <div className="relative bg-[#fff] w-[326px] md:w-[544px] rounded-lg shadow dark:bg-gray-700 rounded-lg rounded-md space-y-4 ">
      <button
        type="button"
        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex  dark:hover:text-primary text-textColor"
        data-modal-toggle="authentication-modal"
      >
        <svg
          onClick={handleClose}
          aria-hidden="true"
          className="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
        <span className="sr-only">Close modal</span>
      </button>
      <div className="px-6 py-16 lg:px-8 ">
        <div className="">
          <div className="flex justify-center pb-14">
            <img className=" mt-10" src={success} alt="" />
            <img className="absolute" src={vector} alt="" />
          </div>
        </div>

        {!!label && (
          <h3 className="mb-1 text-xl  text-[#06890C] font-semibold text-center">
            {label}
          </h3>
        )}

        {!!description && (
          <label className="block mb-6 text-sm font-medium text-gray-900 text-textColor  text-center">
            {description}
          </label>
        )}
        {!!buttonText && (
          <button
            type="submit"
            className="w-full bg-primary text-white  hover:bg-blue-800 py-3 rounded-sm focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm "
            onClick={onSubmit}
          >
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default SuccessModal;
