import "@calcom/atoms/globals.min.css";
import { CalProvider } from '@calcom/atoms';
import { Booker } from "@calcom/atoms";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CAL_ATOMS_CLIENT_ID,
  CAL_ATOMS_API_URL,
  CAL_ATOMS_REFRESH_URL,
} from "utils/constants/constants";
import { notify } from "utils/toast/toast";
import { closeCurrentModal } from "store/actions/modalActions";
import { createAppointment } from "services/createAppointment";
import { useAxios } from "utils/hooks/useAxios";

const BookAppointmentModal = ({
    user,
    provider,
    searchId
  }) => {
    const dispatch = useDispatch()
    const eventSlug = "thirty-minutes-video"
    const [bookingTitle, setBookingTitle] = useState(null)
    const { isLoading: createAppointmentLoading, callApi: createAppointmentApi } = useAxios()

    const handleClose = () => {
        dispatch(closeCurrentModal());
    };
  
    return (
        <div className="relative bg-[#ffffff] rounded-lg shadow dark:bg-gray-700 rounded-[20px] px-[2rem] py-[1rem]">
            <CalProvider
                clientId={CAL_ATOMS_CLIENT_ID}
                options={{
                apiUrl: CAL_ATOMS_API_URL,
                refreshUrl: CAL_ATOMS_REFRESH_URL 
                }}
            >
                <button
                    type="button"
                    className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex  dark:hover:text-primary text-textColor"
                    data-modal-toggle="authentication-modal"
                >
                    <svg
                        onClick={handleClose}
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                    ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>                
                <div>
                    {(!!user && !!provider && !!searchId) ? (
                        <div>
                            <h1 className="my-4 text-[#002A5C] text-2xl font-semibold">{provider.firstName} {provider.lastName} Booking Page</h1>
                            {!bookingTitle && (
                                <Booker
                                    eventSlug={eventSlug}
                                    username={provider.calAtomsUserName ?? ""}
                                    defaultFormValues={{
                                        name: user.firstName + " " + user.lastName,
                                        email: user.email ?? ""
                                    }}
                                    customClassNames={{
                                        eventMetaCustomClassNames: {
                                          eventMetaTitle: "!text-[#002A5C]",
                                        },
                                        datePickerCustomClassNames: {
                                            datePickerTitle: "!text-[#002A5C]",
                                            datePickerDays: "!text-primary",
                                            datePickerDatesActive: "!bg-[#c6f1ff] !text-textColor"
                                        },
                                        availableTimeSlotsCustomClassNames: {
                                            availableTimeSlotsTitle: "!text-[#002A5C]",
                                            availableTimes: "!border-primary !text-primary"
                                        },
                                      }}
                                    onCreateBookingSuccess={(data) => {
                                        setBookingTitle(data.data.title);
                                        var postData = {
                                            status: "ACTIVE",
                                            appointmentId: data.data.uid,
                                            providerCalId: data.data.userId,
                                            searchId: searchId,
                                            userId: user._id,
                                            providerId: provider._id,
                                            calAtomsUserName: provider.calAtomsUserName,
                                            eventSlug: eventSlug
                                        };
                                        createAppointment(createAppointmentApi, postData, () => {
                                            notify("Appointment booked!", "success")
                                        })
                                    }}
                                    onCreateBookingError={(error) => {
                                        notify("Unable to book appointment. Please try again later", "error");
                                    }}
                                />
                            )}
                            {bookingTitle && <p> Booking successfully created: {bookingTitle}</p>}
                        </div>
                    ) : (
                        <div>
                            <h1 className="my-4 text-2xl font-semibold">Booking Page unavailable</h1>
                            <p>Lawyer booking page not available. Please send an inquiry!</p>
                        </div>
                    )}
                </div>
                
                
                
                
                
            </CalProvider>
        </div>
    );
  };
  
  export default BookAppointmentModal;