import React from "react";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { ADDRESS } from "components/PremiumProviderEditProfileComponents/constants/editProfileConstants";
import { useSelector } from "react-redux";
import { fetchCategoryName } from "utils/helperFunctions/helper";

const Address = (props) => {
  const { addresses, isEdit } = props;
  const states = useSelector((state) => state.categoriesReducer.states);
  return (
    <div className=" w-full h-[210px] rounded-[20px] border md:border-borderColor p-6 mt-7 lg:border-borderColor">
      <div className="flex">
        <Typography
          sx={{ color: "#002A5C", fontSize: 20, fontWeight: 600 }}
          gutterBottom
        >
          Address
        </Typography>
        {isEdit && (
          <Typography
            sx={{
              color: "#00A4DA",
              fontSize: 16,
              fontWeight: 600,
              textAlign: "center",
              pl: 1,
              mt: { md: "5px" },
            }}
            gutterBottom
          >
            <Link to="/profile/edit" state={{ goToStep: ADDRESS }}>
              Edit
            </Link>
          </Typography>
        )}
      </div>
      {!!addresses?.length &&
        addresses.map((address, i) => {
          const fetchStateName = fetchCategoryName(states, address?.state).name;
          return (
            <ShowAddress key={i} address={address} state={fetchStateName} />
          );
        })}
      {/* <Typography
        sx={{ color: "#979797", fontSize: 16, fontWeight: 400 }}
        gutterBottom
      >
        Business HQ
      </Typography> */}
      {/* <Typography
        sx={{ color: "#979797", fontSize: 16, fontWeight: 400 }}
        gutterBottom
      >
        Registered
      </Typography> */}
    </div>
  );
};

export default Address;
export const ShowAddress = ({ address, state }) => {
  return (
    <Typography
      sx={{ color: "#5A5A5A", fontSize: 16, fontWeight: 400 }}
      gutterBottom
    >
      {address?.street01} {address?.street02} {address?.city}, {state}{" "}
      {address?.postalCode}, {address?.country}
    </Typography>
  );
};
