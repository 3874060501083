import React from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import EducationIcon from "./imgs/education.svg";
import { EDUCATION } from "components/PremiumProviderEditProfileComponents/constants/editProfileConstants";

const Education = ({ editAble = false, educations }) => {
  return (
    <Box className=" w-full rounded-[8px] border border-borderColor p-6 mt-4">
      <Box sx={{ display: "flex", alignItems: "baseline" }}>
        <Typography
          sx={{ color: "#002A5C", fontSize: 20, fontWeight: 600, pb: 1 }}
          gutterBottom
        >
          Education
        </Typography>
        <Typography
          sx={{
            color: "#00A4DA",
            fontSize: 16,
            fontWeight: 600,
            mt: "6px",
            pl: 1,
          }}
          gutterBottom
        >
          {!!editAble && (
            <Link
              to="/profile/edit"
              state={{ goToStep: EDUCATION }}
              className="mt-0"
            >
              Edit
            </Link>
          )}
        </Typography>
      </Box>

      <Stack gap={3} direction="row" flexWrap="wrap">
        {educations?.map((education, index) => {
          return (
            <Box
              key={index}
              sx={{
                display: "flex",
                gap: "20px",
                minWidth: "238px",
                alignItems: "start",
              }}
            >
              <Box sx={{ mt: 1 }}>
                <img src={EducationIcon} alt="education" />
              </Box>
              <Box sx={{ maxWidth: 400 }}>
                <Typography
                  variant="h4"
                  sx={{ color: "#002A5C", fontSize: "18px", fontWeight: "600" }}
                >
                  {education?.university}
                </Typography>
                <Typography sx={{ color: "#5A5A5A", fontWeight: "500" }}>
                  {education?.degree}
                </Typography>
                <Typography sx={{ color: "#5A5A5A80", fontWeight: "500" }}>
                  {education?.completedAt}
                </Typography>
                {!!education?.certificates.length && (
                  <Box>
                    <Typography
                      variant="h4"
                      sx={{
                        color: "#002A5C",
                        fontSize: "18px",
                        marginY: "10px",
                        fontWeight: "500",
                      }}
                    >
                    Activities & Honors{" "}
                    </Typography>
                    <Stack direction="row" gap={1} flexWrap="wrap">
                      {education.certificates.map((certificate, i) => {
                        return (
                          <Box
                            key={i}
                            sx={{
                              display: "flex",
                              gap: "5px",
                              flexWrap: "wrap",
                            }}
                          >
                            <Typography
                              variant="outlined"
                              sx={{
                                textTransform: "capitalize",
                                color: "#5A5A5A",
                                fontSize: 14,
                                fontWeight: 500,
                                backgroundColor: "#F6F6F6",
                                borderRadius: "8px",
                                border: "none",
                                px: 2,
                                py: 1,
                              }}
                              disabled
                            >
                              {certificate}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Stack>
                  </Box>
                )}
              </Box>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default Education;
