import { routes } from "utils/appConfig";

export const applyCoupon = (callApi, couponId) => {
  const { url, method } = routes.applyCoupon;
  try {
    return callApi(
      url(couponId),
      method,
      { couponId },
      {
        onSuccess: (res) => {
          return res;
        },
        onError: (e) => {
          return e;
        },
        turnOffNotification: true,
      }
    );
  } catch (e) {
    console.log("$$$ ~ file: applyCoupon.js:9 ~ applyCoupon ~ e:", e);
    return e;
  }
};
