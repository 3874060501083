import React from "react";

import heroImg from "./others/imgs/juricon/hero-img.png";
import helpImg from "./others/imgs/juricon/Group 11.png";
import matchImg from "./others/imgs/juricon/40007.svg";
import timeImg from "./others/imgs/juricon/cost-effective-productive-svgrepo-com.svg";
import qualifiedImg from "./others/imgs/juricon/pngwing.svg";
import trustImg from "./others/imgs/juricon/trust-01.svg";
import contactImg from "./others/imgs/juricon/Law-five-career-options-1650x928_0.png";
import footerImg from "./others/imgs/juricon/JURICON white.png";
import facebookImg from "./others/imgs/juricon/facebook.png";
import instagramImg from "./others/imgs/juricon/instagram.png";
import twitterImg from "./others/imgs/juricon/twitter.png";
import dribbleImg from "./others/imgs/juricon/dribbble.png";
const Juricon = () => {
  return (
    <div className="juricon">
      {/* Hero Section */}
      <section className="hero-section min-h-screen text-center ">
        <nav className="navigation-section flex items-start justify-center 2xl:justify-end gap-1 md:gap-4 2xl:gap-10 relative md:-left-44">
          <div className="nav-item">
            <a
              className="nav-link border-t-4 border-transparent active text-lg inline-block pt-4 px-1 text-[#00005F] font-semibold"
              href=""
            >
              Home
            </a>
          </div>
          <div className="nav-item">
            <a
              className="nav-link border-t-4 border-transparent text-lg inline-block pt-4 px-1 text-[#00005F] font-semibold"
              href=""
            >
              ABOUT
            </a>
          </div>
          <div className="nav-item">
            <a
              className="nav-link border-t-4 border-transparent text-lg inline-block pt-4 px-1 text-[#00005F] font-semibold"
              href=""
            >
              SERVICES
            </a>
          </div>
          <div className="nav-item">
            <a
              className="nav-link border-t-4 border-transparent text-lg inline-block pt-4 px-1 text-[#00005F] font-semibold"
              href=""
            >
              BLOG
            </a>
          </div>
          <div className="nav-item">
            <a
              className="nav-link border-t-4 border-transparent text-lg inline-block pt-4 px-1 text-[#00005F] font-semibold"
              href=""
            >
              CONTACT
            </a>
          </div>
        </nav>
        <div className="hero-img mx-auto flex justify-center mt-8">
          <img src={heroImg} alt="hero img" />
        </div>
        <div className="flex flex-wrap gap-6 justify-center items-center">
          <span className="text-[#0D61A5] text-[2.75rem]  ">I am</span>
          <button className="bg-[#0D61A5] rounded-[1.8rem] 2xl:rounded-[2.1875rem] px-4 2xl:px-8 py-2 2xl:py-4 text-white text-[1.75rem] border-2 border-[#0D61A5] shadow-[0rem_4 px_1.25rem_#00000029]">
            Looking For Attorney
          </button>
          <button className="bg-transparent rounded-[1.8rem] 2xl:rounded-[2.1875rem] px-4 2xl:px-8 py-2 2xl:py-4 text-[#0D61A5] text-[1.75rem] border-2 border-[#0D61A5] ">
            Attorney
          </button>
        </div>
      </section>

      {/* Tagline Section */}
      <section className="tagline-section text-center py-20 2xl:py-56 px-4">
        <h2 className="text-[#0D61A5] text-2xl 2xl:text-[2.8125rem] 2xl:leading-normal font-bold mb-7">
          NO ONE WANTS TO HAVE TO FIND <br /> AN ATTORNEY TO HANDLE LEGAL
          PROBLEMS.
        </h2>
        <p className="text-[#00005F] text-base 2xl:text-[1.25rem]">
          It's time consuming, sometimes expensive, and it means that we have a
          stressful legal situation that needs to be handled.
        </p>
      </section>

      {/* Help Section */}
      <section className="help-section bg-[#00005F] py-10 2xl:py-20 px-4 2xl:pl-[12.5rem] 2xl:pr-[6.25rem] md:flex items-center justify-between">
        <div className="help-section-text md:w-[70%] 2xl:w-[58%]">
          <h3 className="text-4xl 2xl:text-[4.375rem] text-white font-bold mb-6 2xl:mb-12">
            HOW CAN JURICON HELP?
          </h3>
          <p className="2xl:text-2xl text-white mb-4">
            Our AI driven platform allows clients to come to the site and search
            for the kind of law that you practice. They can type in the legal
            problem they are experiencing and our Artificial Intelligence will
            match them with attorneys - like you - in their area.
          </p>
          <p className="2xl:text-2xl text-white">
            You can set your rates up front, so there is no hassle or
            negotiations with a potential client Without having to spend more
            time and money marketing, you can focus on what you do best
            -Practice Law.
          </p>
        </div>
        <div className="help-section-img w-1/2 mx-auto md:w-[20%] 2xl:w-[25%]">
          <img src={helpImg} alt="help image" />
        </div>
      </section>

      {/* Features Section */}
      <section className="features-section py-10 2xl:pt-44 2xl:pb-32 px-4 2xl:px-[6.25rem] flex justify-center 2xl:justify-between gap-4 2xl:gap-6 flex-wrap">
        <div className="feature-card bg-white text-center rounded-[1.75rem] px-6 flex flex-col gap-10 justify-center items-center max-w-[18rem] 2xl:max-w-[23.125rem] min-h-[24rem] 2xl:min-h-[27.5rem] shadow-[.1875rem_.1875rem_1.25rem_#0000001A]">
          <img src={matchImg} alt="match making" />
          <h4 className="text-[1.75rem] text-[#0D61A5] font-bold">
            EFFICIENT LEGAL MATCHMAKING
          </h4>
        </div>

        <div className="feature-card bg-white text-center rounded-[1.75rem] px-6  flex flex-col gap-10 justify-center items-center max-w-[18rem] 2xl:max-w-[23.125rem] min-h-[24rem] 2xl:min-h-[27.5rem] shadow-[.1875rem_.1875rem_1.25rem_#0000001A]">
          <img src={timeImg} alt="Time saving" />
          <h4 className="text-[1.75rem] text-[#0D61A5] font-bold">
            TIME AND COST SAVINGS
          </h4>
        </div>

        <div className="feature-card bg-white text-center rounded-[1.75rem] px-6  flex flex-col gap-10 justify-center items-center max-w-[18rem] 2xl:max-w-[23.125rem] min-h-[24rem] 2xl:min-h-[27.5rem] shadow-[.1875rem_.1875rem_1.25rem_#0000001A]">
          <img src={qualifiedImg} alt="Qualified" />
          <h4 className="text-[1.75rem] text-[#0D61A5] font-bold">
            ACCESS TO QUALIFIED ATTORNEYS
          </h4>
        </div>

        <div className="feature-card bg-white text-center rounded-[1.75rem] px-6  flex flex-col gap-10 justify-center items-center max-w-[18rem] 2xl:max-w-[23.125rem] min-h-[24rem] 2xl:min-h-[27.5rem] shadow-[.1875rem_.1875rem_1.25rem_#0000001A]">
          <img src={trustImg} alt="Trust" />
          <h4 className="text-[1.75rem] text-[#0D61A5] font-bold">
            TRUST AND TRANSPARENCY EASY
          </h4>
        </div>
      </section>

      {/* Contact Us Section */}
      <section className="contact-section md:flex">
        <div className="contact-img">
          <img
            src={contactImg}
            alt="Contact Img"
            className="h-full object-cover"
          />
        </div>
        <div className="contact-details bg-[#00005F] py-10 2xl:pt-20 2xl:pb-14 px-10 2xl:pl-36 2xl:pr-80 text-white">
          <h5 className="text-3xl 2xl:text-[45px] mb-4">FREE CONSULTATION</h5>
          <p className="2xl:text-lg ">
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout.
          </p>
          <form className="contact-form w-11/12">
            <input
              className="block w-full text-2xl py-5 bg-transparent outline-none text-white placeholder-white border-b-2 border-white "
              type="text"
              placeholder="Full Name"
            />
            <input
              className="block w-full text-2xl py-5 bg-transparent outline-none text-white placeholder-white border-b-2 border-white "
              type="tel"
              placeholder="Phone Number"
            />
            <input
              className="block w-full text-2xl py-5 bg-transparent outline-none text-white placeholder-white border-b-2 border-white "
              type="email"
              placeholder="Email"
            />
            <input
              className="block w-full text-2xl py-5 bg-transparent outline-none text-white placeholder-white border-b-2 border-white "
              type="text"
              placeholder="Practice Area"
            />
            <input
              className="block w-full text-2xl py-5 bg-transparent outline-none text-white placeholder-white border-b-2 border-white "
              type="text"
              placeholder="Practice Area"
            />
            <button
              type="submit"
              className="bg-[#0D61A5] rounded-[35px] mt-11 block mx-auto py-[15px] px-[38px] shadow-[0px_4px_20px_#00000029]"
            >
              Send Request
            </button>
          </form>
        </div>
      </section>

      {/* Tagline Section */}
      <section className="tagline-section text-center py-20 2xl:py-56 px-4">
        <h2 className="text-[#0D61A5] text-2xl 2xl:text-[2.8125rem] 2xl:leading-normal font-bold mb-8">
          MARKETING YOUR LEGAL PRACTICE SHOULDN'T <br /> BE HARD.
        </h2>
        <p className="text-[#00005F] text-base 2xl:text-[1.25rem]">
          Costly advertising is hit or miss, especially if you practice a niche
          area of law.
        </p>
      </section>

      {/* Footer */}
      <footer className="bg-[#00005F] px-4 md:px-10 2xl:px-64 text-[#EDEDED]">
        <div className="pb-10 2xl:pb-24">
          <div className="footer-img">
            <img src={footerImg} alt="footer image" className="block mx-auto" />
          </div>
          <div className="footer-links flex flex-wrap justify-center gap-9">
            <a href="#" className="footer-link text-[#EDEDED]">
              Home
            </a>
            <a href="#" className="footer-link text-[#EDEDED]">
              About
            </a>
            <a href="#" className="footer-link text-[#EDEDED]">
              Services
            </a>
            <a href="#" className="footer-link text-[#EDEDED]">
              Terms & Conditions
            </a>
            <a href="#" className="footer-link text-[#EDEDED]">
              Privacy Policy
            </a>
            <a href="#" className="footer-link text-[#EDEDED]">
              Contact Us
            </a>
          </div>
        </div>
        <div className="flex flex-wrap justify-center md:justify-between gap-4 py-9 border-t border-[#F0F0F0]">
          <div className="copy-rights">
            <p>© 2022 Juricon. All Rights Reserved</p>
          </div>
          <div className="social-links flex items-center gap-4">
            <a href="" className="social-link">
              <img src={facebookImg} alt="facebook" />
            </a>
            <a href="" className="social-link">
              <img src={instagramImg} alt="instagram" />
            </a>
            <a href="" className="social-link">
              <img src={twitterImg} alt="twitter" />
            </a>
            <a href="" className="social-link">
              <img src={dribbleImg} alt="dribble" />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Juricon;
