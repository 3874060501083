import AdvancedSearch from "components/search/AdvancedSearch";
import { useFormik } from "formik";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { resetSearchReducer, updateSearchQuery } from "store/actions/actions";
import { hideIncompleteFeatures } from "utils/config";
import { ADVANCED_SEARCH, GENERAL_SEARCH } from "utils/constants/constants";

export const handleWhatYouAreLookingClick = (dispatch, searchType) => {
  dispatch(resetSearchReducer());
  dispatch(
    updateSearchQuery({
      searchType:
        searchType === ADVANCED_SEARCH ? GENERAL_SEARCH : ADVANCED_SEARCH,
    })
  );
};

const NavSearch = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { searchType } = useSelector((state) => state.searchReducer);
  const textAreaRef = useRef(null);

  // Formik setup for handling the search submission
  const formik = useFormik({
    initialValues: { searchText: "" },
    onSubmit: (values) => {
      if (values.searchText.trim()) {
        dispatch(
          updateSearchQuery({
            searchText: values.searchText,
            searchType: GENERAL_SEARCH,
          })
        );
        navigate("/searchresult");
      }
    },
  });

  const adjustHeight = () => {
    textAreaRef.current.style.height = "auto"; // Reset height
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`; // Set height based on scroll height
  };

  return (
    <div className="flex-grow">
      <div className="relative flex items-center justify-center w-full pr-10">
        {searchType === ADVANCED_SEARCH ? (
          <AdvancedSearch navSearch />
        ) : (
          // Modified General Search with Expandable Text Area and Search Icon
          <div className="relative w-full max-w-[500px] flex items-center">
            <form onSubmit={formik.handleSubmit} className="w-full">
              <textarea
                ref={textAreaRef}
                name="searchText"
                placeholder="Describe your legal issue..."
                rows="1"
                onInput={adjustHeight}
                {...formik.getFieldProps("searchText")}
                className="w-full max-h-[calc(36px*5)] overflow-y-auto p-2 border border-gray-300 rounded-md resize-none"
                style={{
                  fontSize: "16px",
                  minHeight: "36px",
                  lineHeight: "1.5",
                }}
              />
              {/* Search Icon Button */}
              <button
                type="submit"
                className="absolute right-3 bottom-3 text-primary hover:text-secondary"
                aria-label="Search"
              >
                <i className="text-lg fa-solid fa-magnifying-glass"></i>
              </button>
            </form>
          </div>
        )}
      </div>

      {/* Prompt Section */}
      <div className="flex flex-col justify-center w-full mt-1 lg:flex-row lg:justify-evenly">
        <div className="flex justify-center">
          <label className="block mb-4 text-xs sm:text-[14px] font-medium mt-[2px] text-secondary text-center w-[80%] md:w-[100%]">
            {searchType === ADVANCED_SEARCH
              ? "Want to describe your legal issue?"
              : "Know what type of lawyer you're looking for?"}
          </label>
          <div className="text-xs sm:text-sm font-medium text-secondary min-w-fit w-[50%] md:w-auto">
            <span
              onClick={() => handleWhatYouAreLookingClick(dispatch, searchType)}
              className="ml-2 cursor-pointer hover:underline text-primary"
            >
              Click Here
            </span>
          </div>
        </div>

        {/* Additional Search Improvement Prompt */}
        {!hideIncompleteFeatures &&
          location.pathname === "/searchresult" &&
          searchType === ADVANCED_SEARCH && (
            <div className="flex justify-center">
              <label className="block mb-6 text-xs sm:text-[14px] font-medium text-textColor">
                Help us to Improve Search Results!
              </label>
              <div className="text-xs font-medium sm:text-sm text-textColor">
                <button className="pl-2 hover:underline text-primary">
                  Click Here
                </button>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default NavSearch;