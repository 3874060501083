import { combineReducers } from "redux";
import { SIGNOUT_REQUEST } from "utils/constants/constants";
import { modalReducer } from "store/reducers/modalReducer";
import { userReducer } from "store/reducers/userReducer";
import { categoriesReducer } from "store/reducers/categoriesReducer";
import { searchReducer } from "store/reducers/searchReducer";
import { dateReducer } from "store/reducers/dateReducer";
import { businessReducer } from "store/reducers/businessReducer";
import { membershipReducer } from "store/reducers/membershipReducer";
import { filtersReducer } from "store/reducers/filtersReducer";

const appReducer = combineReducers({
  user: userReducer,
  modal: modalReducer,
  categoriesReducer,
  searchReducer,
  dateReducer,
  businessReducer,
  membershipReducer,
  filtersReducer,
});

const rootReducer = (state, action) => {
  if (action.type === SIGNOUT_REQUEST) {
    localStorage.removeItem("persist:root");
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
export default rootReducer;
