import React from "react";
import { TextField } from "@mui/material";

const PasswordChangeForm = ({ formik }) => {
  return (
    <div className="py-4 w-[100%]">
      <form onSubmit={formik.handleSubmit}>
        <TextField
          id="password"
          name="password"
          label="New Password"
          size="small"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
      </form>
    </div>
  );
};

export default PasswordChangeForm;
