import React from "react";

const ModalProvider = ({ children }) => {
  const modelMounted = children?.some((item) => item);
  return (
    modelMounted && (
      <div className="fixed top-0 left-0 z-50 w-[100vw] h-[100vh]  bg-[#a8a0a0e6]">
        <div className="fixed  top-0 left-0  w-[100%] h-[100%]  z-50 overflow-y-auto ">
          <div className="min-h-[100%] flex justify-center items-center overflow-auto">
            {children}
          </div>
        </div>
      </div>
    )
  );
};

export default ModalProvider;
