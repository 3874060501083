import { Stack } from "@mui/material";
import { createFirmValidation } from "components/firm/helper";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import client from "assets/user.svg";
import DatePicker from "components/datePicker/DatePicker";
import EditProfileFooter from "components/PremiumProviderEditProfileComponents/editProfilFooter/EditProfileFooter";
import { PERSONAL_INFO } from "components/PremiumProviderEditProfileComponents/constants/editProfileConstants";
import { useFormik } from "formik";
import moment from "moment";
import Error from "components/error/Error";
import { cleanUpValues } from "utils/helperFunctions/helper";
import { useAxios } from "utils/hooks/useAxios";
import { uploadMedia } from "services/uploadMedia";
import Loader from "utils/loader/Loader";
import LogoPlaceholder from "components/logoPlaceholder/LogoPlaceholder";
import PhoneInput from "components/phoneInput/PhoneInput";
import { notify } from "utils/toast/toast";
import { validFileFormats } from "components/editProviderNewScope/personalInfoNewScope/PersonalInfoNewScope";

export const firmInitialValues = {
  name: "",
  registeredDate: "",
  businessPhone: "",
  website: "",
  businessDescription: "",
};

const FirmInfo = ({ onSubmit, loading, businessData }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const fileRef = useRef(null);
  const user = useSelector((state) => state.user);
  const { isLoading, callApi } = useAxios();

  useEffect(() => {
    if (Object.keys(businessData).length) {
      if (businessData?.registeredDate) {
        businessData.registeredDate = moment(
          businessData.registeredDate
        ).format("MM/DD/YYYY");
      }
      formik.setValues({
        ...firmInitialValues,
        ...businessData,
      });
      if (businessData?.businessLogo) {
        setImageUrl(businessData?.businessLogo);
      }
    }
  }, [businessData]);

  const formik = useFormik({
    initialValues: firmInitialValues,
    validationSchema: createFirmValidation(),
    onSubmit: (values) => {
      const temp = cleanUpValues({ ...values });
      if (imageUrl) {
        temp.businessLogo = imageUrl;
      }
      onSubmit(temp);
    },
  });

  const { getFieldProps } = formik;

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileType = file.type.split("/")[0];
    const fileFormat = file.type.split("/")[1];
    if (fileType !== "image") {
      notify("Please upload valid image file", "error");
      return;
    }
    if (file?.size > 1024000) {
      notify("File size too large, max size 1mb", "error");
      return;
    }
    if (!validFileFormats.includes(fileFormat)) {
      notify(`Invalid File Format ${fileFormat}`, "error");
      return;
    }
    uploadMedia(callApi, file, (data) => {
      if (data) {
        setImageUrl(data.data.url);
      }
    });
  };
  return (
    <>
      <Stack gap={1.25}>
        <div className="flex  py-16 items-center">
          <div className="h-[100%]">
            {imageUrl ? (
              <img
                className="h-[181px] w-[180px] rounded-md object-contain"
                src={imageUrl ?? user?.profilePicture ?? client}
                alt=""
              />
            ) : (
              <LogoPlaceholder />
            )}
          </div>
          <div className="pl-5">
            <h1 className="text-[16px] font-semibold">
              {!imageUrl ? "Upload Display Picture" : "Change Display Picture"}
            </h1>
            <input
              type="file"
              className="hidden"
              ref={fileRef}
              onChange={handleFileChange}
            />
            <button
              onClick={() => fileRef.current.click()}
              className="bg-primary min-w-[104px]  py-2 px-6 mt-5 rounded-xs text-white hover:bg-[#fff] hover:text-[white] hover:bg-[#00a4dab7] duration-300	 "
            >
              {isLoading ? <Loader /> : "Browse"}
            </button>
          </div>
        </div>
        {!loading ? (
          <Stack>
            <h3 className="mb-4 text-xl flex-wrap text-secondary font-semibold">
              Firm Info
            </h3>
            <div className="flex input-wrap flex-wrap gap-y-6  justify-between mb-6">
              <div className="basis-[100%] sm:basis-[48%] order-1">
                <label
                  htmlFor="firstName"
                  className="block mb-2 text-sm font-medium text-textColor"
                >
                  Firm Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className=" border border-borderColor outline-primary border-primary0 rounded-sm text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 text-[gray]"
                  placeholder="Toney"
                  {...getFieldProps("name")}
                />
                <Error formik={formik} errorField="name" />
              </div>
              <div className="basis-[100%] sm:basis-[48%] order-2 padding-input">
                <label
                  htmlFor="lastName"
                  className="block mb-2 text-sm font-medium text-textColor"
                >
                  Formed Date (Optional)
                </label>
                <DatePicker
                  name="registeredDate"
                  format="MM/DD/YYYY"
                  placeholder="MM/DD/YYYY"
                  value={formik.values.registeredDate}
                  onChange={(value) => {
                    const date = moment(value).format("MM/DD/YYYY");
                    formik.setFieldTouched("registeredDate");
                    formik.setFieldValue(`registeredDate`, date);
                  }}
                />
                <Error formik={formik} errorField="registeredDate" />
              </div>
              <div className="basis-[100%] sm:basis-[48%] order-4">
                <div className="flex">
                  <label
                    htmlFor="phone"
                    className="block mb-2 text-sm font-medium text-textColor"
                  >
                    Phone Number
                  </label>
                </div>

                <PhoneInput
                  name="businessPhone"
                  id="businessPhone"
                  className=" border border-borderColor  rounded-sm text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 text-[gray] h-[42px]"
                  placeholder="(255) 555-0112"
                  value={formik.values.businessPhone}
                  onChange={(value) => {
                    formik.setFieldTouched("businessPhone", true);
                    formik.setFieldValue("businessPhone", value);
                  }}
                  onBlur={formik.handleBlur}
                />
                <Error formik={formik} errorField="businessPhone" />
              </div>
              <div className="basis-[100%] sm:basis-[48%] order-5 padding-input padding-input-t input-wrap">
                <label className="block mb-2 text-sm font-medium text-textColor">
                  Website
                </label>
                <input
                  type="text"
                  name="website"
                  id="website"
                  className=" border border-borderColor outline-primary border-primary0 rounded-sm text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 text-[gray]"
                  placeholder="https://janelawyer.com/"
                  {...getFieldProps("website")}
                />
                <Error formik={formik} errorField="website" />
              </div>
              <div className="basis-[100%] order-5 padding-input padding-input-t input-wrap">
                <label className="block mb-2 text-sm font-medium text-textColor">
                  Description
                </label>
                <textarea
                  type="text"
                  name="businessDescription"
                  id="businessDescription"
                  className=" border border-borderColor outline-primary border-primary0 rounded-sm text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 text-[gray] min-h-[140px]"
                  placeholder="description..."
                  {...getFieldProps("businessDescription")}
                />
                <Error formik={formik} errorField="businessDescription" />
              </div>
            </div>
          </Stack>
        ) : (
          <Stack justifyContent="center" alignItems="center" minHeight="400px">
            <Loader width={50} height={50} />
          </Stack>
        )}
      </Stack>
      <EditProfileFooter
        step={PERSONAL_INFO}
        next={formik.handleSubmit}
        nextButtonTitle={"Next"}
      />
    </>
  );
};

export default FirmInfo;
