import { RESET_SEARCH_REDUCER, SEARCH_QUERY } from "utils/constants/constants";

const initialState = {
  searchCategoryId: null,
  searchStateId: null,
  searchText: "",
  searchType: "",
  searchBy: "",
  searchName: "",
};

export const searchReducer = (state = initialState, action) => {
  const newState = { ...state };
  if (action?.payload) {
    Object.entries(action.payload).forEach(([key, value]) => {
      newState[key] = value;
    });
  }
  switch (action.type) {
    case SEARCH_QUERY:
      return newState;
    case RESET_SEARCH_REDUCER:
      return initialState;
    default:
      return state;
  }
};
