// components/TypingEffect.jsx
"use client"; // Use this if you are using a server-rendered setup like Next.js, otherwise remove

import { useEffect, useState } from 'react';

const TypingEffect = ({ words }) => {
  const [displayText, setDisplayText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(150);

  useEffect(() => {
    if (words.length === 0) return;

    const handleTyping = () => {
      const currentWord = words[loopNum % words.length];
      setDisplayText(
        isDeleting
          ? currentWord.substring(0, displayText.length - 1)
          : currentWord.substring(0, displayText.length + 1)
      );

      if (!isDeleting && displayText === currentWord) {
        setTimeout(() => setIsDeleting(true), 1000);
      } else if (isDeleting && displayText === '') {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
      }
    };

    const timer = setTimeout(handleTyping, typingSpeed);
    return () => clearTimeout(timer);
  }, [displayText, isDeleting, loopNum, words, typingSpeed]);

  return (
    <span className="highlight text-[#00a4da] font-bold min-w-[120px] inline-block">
      {displayText}
      <span className="animate-blink">|</span>
    </span>
  );
};

export default TypingEffect;