import React from "react";
import BookingManagerMain from "pages/BookingManagerMain";
import { useParams, useLocation } from "react-router-dom";

const BookingCancel = () => {
    const location = useLocation()
    const bookingId = useParams().id
    const eventSlug = new URLSearchParams(location.search).get('slug')
    const calUsername = new URLSearchParams(location.search).get('username')   
    return (
        <div className="py-20">
            <BookingManagerMain 
                calAtomsBookingId={bookingId}
                calAtomsEventSlug={eventSlug}
                calAtomsUserName={calUsername}
                isCancelAppointment={true}
            />
        </div>
    ); 
};

export default BookingCancel;