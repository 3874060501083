import { routes } from "utils/appConfig";

export const changePasswordByAdmin = async (
  callApi,
  userId,
  data,
  callBack
) => {
  const { url, method } = routes.admin.changePassword;
  try {
    callApi(url(userId), method, data, {
      onSuccess: ({ data }) => callBack(data),
      onError: (res) => callBack(null, res),
      successMessage: "Password updated successfully!",
    });
  } catch (e) {
    throw new Error(e.response);
  }
};
