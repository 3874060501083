import { useState, useEffect } from "react";

const useCookies = () => {
  const [cookies, setCookies] = useState({});

  useEffect(() => {
    const cookieString = document.cookie;
    const cookieArray = cookieString.split("; ");
    const cookieObj = {};
    cookieArray.forEach((cookie) => {
      const [name, value] = cookie.split("=");
      cookieObj[name] = value;
    });

    setCookies(cookieObj);
  }, []);

  const setCookie = (name, value, expirationDays = 1) => {
    const date = new Date();
    date.setDate(date.getDate() + expirationDays);

    const cookieValue =
      encodeURIComponent(value) +
      "; expires=" +
      date.toUTCString() +
      "; path=/";
    document.cookie = name + "=" + cookieValue;

    setCookies((prevCookies) => ({
      ...prevCookies,
      [name]: value,
    }));
  };

  const deleteCookie = (name) => {
    document.cookie =
      name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    setCookies((prevCookies) => {
      const { [name]: deletedCookie, ...restCookies } = prevCookies;
      console.log("🚀 ~ deletedCookie:", deletedCookie);
      return restCookies;
    });
  };

  return { cookies, setCookie, deleteCookie };
};

export default useCookies;
