import { useFormik } from "formik";
import React, { useState } from "react";
import Error from "../../error/Error";
import {
  PasswordVisibility,
  checkValidationError,
  resetPasswordValidation,
} from "../helper";
import Button from "components/button/Button";

const ResetPasswordModal = ({ onClose, onSubmit, loader }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: resetPasswordValidation,
    onSubmit: ({ password }) => {
      onSubmit(password);
    },
  });
  return (
    <div className="relative bg-[#fff] w-[326px] md:w-[544px] rounded-lg shadow dark:bg-gray-700 rounded-[20px] px-[2rem]">
      <button
        type="button"
        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex  dark:hover:text-primary text-textColor"
        data-modal-toggle="authentication-modal"
      >
        <svg
          onClick={onClose}
          aria-hidden="true"
          className="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
        <span className="sr-only">Close modal</span>
      </button>
      <div className="px-6 py-6 lg:px-8">
        <h3 className="text-[22px] font-[700] text-secondary">
          Reset Password
        </h3>
        <label className="block mb-6 text-[16px] font-[400] text-gray-900 text-textColor">
          Please enter your new password to reset
        </label>
        <form className="space-y-6" onSubmit={formik.handleSubmit}>
          <div>
            <label
              htmlFor="password"
              className="block mb-2  text-[14px] font-[500] text-gray-900 text-textColor"
            >
              Enter New Password
            </label>
            <div className="relative">
              <input
                type={!showPassword ? "password" : "text"}
                name="password"
                id="password"
                placeholder="••••••••"
                className="rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <PasswordVisibility
                showPassword={showPassword}
                setShowPassword={setShowPassword}
                className="top-2 right-3"
              />
            </div>

            <Error formik={formik} errorField="password" />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block mb-2  text-[14px] font-[500] text-gray-900 text-textColor"
            >
              Confirm Password
            </label>
            <div className="relative">
              <input
                type={!showConfirmPassword ? "password" : "text"}
                name="confirmPassword"
                id="confirmPassword"
                placeholder="••••••••"
                className="rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <PasswordVisibility
                showPassword={showConfirmPassword}
                setShowPassword={setShowConfirmPassword}
                className="top-2 right-3"
              />
            </div>
            <Error formik={formik} errorField="confirmPassword" />
          </div>

          <Button
            className="h-[50px] text-[16px] font-[600]"
            type="submit"
            loader={loader}
            disabled={loader || checkValidationError(formik)}
          >
            Reset Password
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordModal;
