import {
  RESET_FILTERS,
  UPDATE_FILTERS,
  UPDATE_FILTER_ARRAYS,
} from "utils/constants/constants";

const initialState = {
  schools: [],
  associations: [],
  locations: [],
  languages: [],
  filters: {
    selectedSchools: [],
    selectedAssociations: [],
    selectedLocations: [],
    selectedLanguages: [],
  },
};

export const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FILTER_ARRAYS:
      return { ...state, ...action.payload };
    case UPDATE_FILTERS:
      return { ...state, filters: { ...action.payload } };
    case RESET_FILTERS:
      return { ...state, filters: { ...initialState.filters } };
    default:
      return state;
  }
};
