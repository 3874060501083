import React from "react";

const StepLabel = ({ label }) => {
  return (
    <h3 className="mb-4 text-xl flex-wrap text-secondary font-semibold">
      {label}
    </h3>
  );
};

export default StepLabel;
