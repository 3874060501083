import {
  ADD_CATEGORIES,
  ADD_LANGUAGES,
  ADD_NAIC_CODES,
  ADD_SCHOOLS_DATA,
  ADD_STATES,
} from "utils/constants/constants";

const initialState = {
  categories: null,
  states: null,
  languages: null,
  naicCodes: null,
  schools: null,
};

export const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CATEGORIES:
      return { ...state, categories: action.payload };
    case ADD_STATES:
      return { ...state, states: action.payload };
    case ADD_LANGUAGES:
      return { ...state, languages: action.payload };
    case ADD_NAIC_CODES:
      return { ...state, naicCodes: action.payload };
    case ADD_SCHOOLS_DATA:
      return { ...state, schools: action.payload };
    default:
      return state;
  }
};
