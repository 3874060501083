import React from "react";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { BUSINESS } from "components/PremiumProviderEditProfileComponents/constants/editProfileConstants";

const Description = ({
  description,
  url = "/profile/edit",
  step = BUSINESS,
  rounded = 20,
  id,
}) => {
  return (
    <div
      className={`w-full h-[auto] rounded-[${rounded}px] border border-borderColor p-6 mt-7`}
    >
      <div className="flex">
        <Typography
          sx={{ color: "#002A5C", fontSize: 20, fontWeight: 600 }}
          gutterBottom
        >
          Description
        </Typography>
        <Typography
          sx={{
            color: "#00A4DA",
            fontSize: 16,
            fontWeight: 600,
            textAlign: "center",
            pl: 1,
            mt: { md: "5px" },
          }}
          gutterBottom
        >
          <Link to={url} state={{ goToStep: step, id }}>
            Edit
          </Link>
        </Typography>
      </div>
      <Typography
        sx={{ color: "#5A5A5A", fontSize: 16, fontWeight: 400 }}
        gutterBottom
      >
        {description}
      </Typography>
    </div>
  );
};

export default Description;
