import { Avatar, Box, Collapse, TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import { bookmarkDeleteApi } from "services/bookmark";
import { useAxios } from "utils/hooks/useAxios";
import Loader from "utils/loader/Loader";
import { isCalAtomsUserIDValid } from "utils/config";
import { openModal } from "store/actions/modalActions";
import { OPEN_BOOKING_APPOINTMENT_MODAL } from "utils/constants/constants";
import {useDispatch} from "react-redux";
// import { useSelector } from "react-redux";
// import { bookafyConfig } from "utils/bookafyConfig";
// import { isBookafyIdValid } from "utils/config";

const RenderExtended = ({ props, open, handleBookmarkClick }) => {
  const [bookmarkLoading, setBookmarkLoading] = useState({});
  // const loggedInUser = useSelector((state) => state.user);
  const { callApi } = useAxios();

  const handleBookmarkLoading = (id) => {
    setBookmarkLoading((pre) => ({
      ...pre,
      [id]: !pre?.id,
    }));
  };
  const handleClick = (searchId, providerId) => {
    handleBookmarkLoading(providerId);
    bookmarkDeleteApi(callApi, searchId, providerId, () => {
      handleBookmarkClick(searchId, providerId);
    });
  };
  const dispatch = useDispatch();

  return (
    <TableRow>
      <TableCell colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ ml: 5, pr: 6, maxWidth: "90%" }}>
            {props?.bookmarkedLawyers?.map((user, i) => {
              return (
                <div key={i} className="flex w-[100%] justify-between py-3">
                  <div className="flex">
                    <Avatar src={user?.profilePicture} />
                    <label
                      htmlFor=""
                      className="pt-3 pl-2 text-[14px] font-[400] text-secondary"
                    >
                      {user?.firstName + " " + user?.lastName}
                    </label>
                  </div>
                  <div className=" flex ">
                    <div className="flex justify-between items-center gap-4">
                      <div className="w-10 h-10 bg-gray rounded-[6px] justify-center flex">
                        {bookmarkLoading[user?._id] ? (
                          <Loader />
                        ) : (
                          <i
                            onClick={() => handleClick(props?._id, user?._id)}
                            className="fa fa-bookmark mt-1 text-primary text-lg cursor-pointer"
                          />
                        )}
                      </div>
                      <button
                        className="bg-[#F6F6F6] px-20 h-10 hover:bg-[#00A4DA] hover:text-[#F6F6F6] text-[#00A4DA] font-[600] text-[14px] leading-[16.8px] order border-gray rounded-[6px] shadow flex-grow disabled:bg-borderColor disabled:text-[#a6a6a6]"

                        onClick={(e) => {
                          e.stopPropagation()
                          const values = {
                            provider: user,
                            searchId: user?._id
                          }
                          dispatch(openModal(OPEN_BOOKING_APPOINTMENT_MODAL, values));
                        }}
                        id="app-scheduling"
                        disabled={
                          !isCalAtomsUserIDValid(user?.calAtomsUserName)
                        }
                      >
                        Book Appointment
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default RenderExtended;
