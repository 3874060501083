import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

export default function CustomBreadCrumbs({ label }) {
  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<Typography sx={{ fontSize: "18px" }}>&gt;</Typography>}
        aria-label="breadcrumb"
      >
        <Link
          underline="hover"
          color="inherit"
          to="/"
          className="hover:text-[#00A4DA]"
        >
          Home
        </Link>
        <Typography
          color="inherit"
          sx={{ color: "#00A4DA", cursor: "default" }}
        >
          {label}
        </Typography>
      </Breadcrumbs>
    </Stack>
  );
}
