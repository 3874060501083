import React, { useEffect, useState } from "react";
import { IconButton, Stack, TextField, Typography } from "@mui/material";
import Button from "components/button/Button";
import { DateRange } from "@mui/icons-material";
import DateRangeSelector from "components/datePicker/DateRangeSelector";
import { formatDate } from "utils/helperFunctions/helper";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { closeCurrentModal } from "store/actions/modalActions";
import { useDispatch, useSelector } from "react-redux";
import { updateDateFilter, resetDateFilter } from "store/actions/actions";
import CloseIcon from "@mui/icons-material/Close";

const DateRangeModal = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const date = useSelector((state) => state.dateReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!date.startDate && !!date.endDate) {
      setStartDate(date.startDate);
      setEndDate(date.endDate);
    }
  }, [date]);

  const handleCalendarClick = () => {
    setShowCalendar(!showCalendar);
  };
  const handleDateSelection = (values) => {
    setShowCalendar(false);
    setStartDate(formatDate(values.start, "MM/DD/YYYY"));
    setEndDate(formatDate(values.end, "MM/DD/YYYY"));
  };
  const handleCloseClick = () => {
    if (showCalendar) {
      setShowCalendar(false);
    } else {
      dispatch(closeCurrentModal());
    }
  };
  const applyDateFilter = () => {
    dispatch(updateDateFilter({ startDate, endDate }));
    dispatch(closeCurrentModal());
  };
  const handleClearDateFilter = () => {
    setStartDate("");
    setEndDate("");
    dispatch(resetDateFilter());
  };
  return (
    <div className="relative bg-[#fff] w-[326px] sm:w-[544px] lg:w-[671px] sm:h-[358px] rounded-lg shadow dark:bg-gray-700 rounded-[20px] flex flex-col py-8">
      <CloseIcon
        sx={{
          width: "fit-content",
          position: "absolute",
          top: "2rem",
          right: "2rem",
          cursor: "pointer",
        }}
        onClick={handleCloseClick}
      />
      {!showCalendar ? (
        <Stack
          px={{ xs: "1rem", md: "3.75rem" }}
          justifyContent="center"
          height="100%"
          gap={4}
        >
          <Typography fontSize={22} fontWeight={700}>
            Filters
          </Typography>
          <Stack gap={2}>
            <Typography fontSize={14} fontWeight={500}>
              Date Range
            </Typography>
            <TextField
              fullWidth
              placeholder="02/14/2023 - 03/12/2023"
              value={startDate && endDate ? `${startDate} - ${endDate}` : ""}
              onFocus={() => setShowCalendar(true)}
              onBlur={() => setShowCalendar(false)}
              InputProps={{
                endAdornment: (
                  <DateRange
                    sx={{ cursor: "pointer" }}
                    onClick={handleCalendarClick}
                  />
                ),
              }}
            />
            <Typography
              onClick={handleClearDateFilter}
              alignSelf="self-end"
              sx={{
                cursor: "pointer",
                "&:hover": {
                  color: "#00A4DA",
                },
              }}
            >
              Clear Filter
            </Typography>
          </Stack>
          {!!startDate && !!endDate ? (
            <Button
              className="text-[16px] font-semibold h-12"
              onClick={applyDateFilter}
            >
              Apply
            </Button>
          ) : (
            <Button
              className="text-[16px] font-semibold h-12"
              onClick={handleCloseClick}
            >
              close
            </Button>
          )}
        </Stack>
      ) : (
        <Stack alignItems="center" justifyContent="center" height="100%" px={4}>
          <DateRangeSelector
            onDateSelected={handleDateSelection}
            startDate={startDate}
            endDate={endDate}
          />
        </Stack>
      )}
    </div>
  );
};

export default DateRangeModal;
