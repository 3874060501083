import React, { useState } from "react";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import CustomBreadCrumbs from "components/Dashboard/Bredcrums/BreadCrum";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "components/dialog/Dialog";
import {
  APPEAR_IN_SEARCHES_DIALOG,
  CHANGE_EMAIL_DIALOG,
  CHANGE_PASSWORD_DIALOG,
  CHANGE_PHONE_DIALOG,
  CHANGE_STATUS,
  DELETE_DIALOG,
} from "utils/constants/constants";
import { useAxios } from "utils/hooks/useAxios";
import { signInAction, signOut } from "store/actions/authActions";
import { deleteMyAccount } from "services/deleteMyAccount";
import { useNavigate } from "react-router-dom";
import ChangePhoneForm from "components/settings/ChangePhoneForm";
import ChangePasswordForm from "components/settings/ChangePasswordForm";
import { updateUserData } from "services/updateUserData";
import { notify } from "utils/toast/toast";
import NewEmailForm from "components/settings/NewEmailForm";
import { formatPhone } from "utils/helperFunctions/helper";

const Settings = () => {
  const [openDialog, setOpenDialog] = useState(null);
  const user = useSelector((state) => state.user);
  const { isLoading, callApi } = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteUser = () => {
    deleteMyAccount(callApi, (res) => {
      if (res?.success) {
        setOpenDialog(false);
        // timer added to avoid please login first notification that shown after signing out from protected route
        setTimeout(() => {
          dispatch(signOut());
        }, 0);
        navigate("/");
      }
    });
  };

  const handleSettingActionClick = (action) => {
    setOpenDialog(action);
  };

  const toggleAppearInSearches = () => {
    updateUserData(
      callApi,
      { isAppearInSearches: !user?.isAppearInSearches },
      (res) => {
        if (res) {
          dispatch(signInAction(res.data));
          notify("User updated successfully", "success");
          setOpenDialog(null);
        }
      }
    );
  };

  const toggleStatus = () => {
    const status = user.status === "ACTIVE" ? "DISABLED" : "ACTIVE";
    updateUserData(callApi, { status }, (res) => {
      if (res) {
        dispatch(signInAction(res.data));
        notify("User status updated successfully", "success");
        setOpenDialog(null);
      }
    });
  };
  return (
    <Container sx={{ pt: "50px", maxWidth: { md: "95%" } }}>
      <CustomBreadCrumbs label="Settings" />
      <label
        htmlFor="Contact Person"
        className="block py-2 text-xl font-bold text-secondary"
      >
        Manage Account
      </label>
      <Stack gap={1} py={4} style={{ minHeight: "calc(100vh - 447px)" }}>
        {/* Email */}
        <Stack
          direction="row"
          alignItems="center"
          className="border-b border-borderColor mb-2"
        >
          <Typography className="text-secondary basis-[30%]">Email</Typography>
          <Typography className="text-borderColor flex-1 basis-[50%]">
            {user?.email}
          </Typography>
          <Box className="basis-[20%]">
            <Button
              variant="text"
              className="w-fit cursor-default"
              onClick={() => handleSettingActionClick(CHANGE_EMAIL_DIALOG)}
            >
              Change Email
            </Button>
          </Box>
        </Stack>
        {/* OPEN CHANGE EMAIL DIALOG */}
        <NewEmailForm
          open={openDialog === CHANGE_EMAIL_DIALOG}
          setOpen={setOpenDialog}
        />

        {/* PHone number */}
        <Stack
          direction="row"
          alignItems="center"
          className="border-b border-borderColor mb-2"
        >
          <Typography className="text-secondary basis-[30%]">Phone</Typography>
          <Typography className="text-borderColor flex-1 basis-[50%]">
            {formatPhone(user?.phone)}
          </Typography>
          <Box className="basis-[20%]">
            <Button
              variant="text"
              className="w-fit"
              onClick={() => handleSettingActionClick(CHANGE_PHONE_DIALOG)}
            >
              Change Phone
            </Button>
          </Box>

          {/* OPEN CHANGE PHONE DIALOG */}
          <ChangePhoneForm
            open={openDialog === CHANGE_PHONE_DIALOG}
            setOpen={setOpenDialog}
          />
        </Stack>

        {/* Password */}
        <Stack
          direction="row"
          alignItems="center"
          className="border-b border-borderColor mb-2"
        >
          <Typography className="text-secondary basis-[30%]">
            Password
          </Typography>
          <Typography className="text-borderColor flex-1 basis-[50%]">
            ********
          </Typography>
          <Box className="basis-[20%]">
            <Button
              variant="text"
              className="w-fit"
              onClick={() => handleSettingActionClick(CHANGE_PASSWORD_DIALOG)}
            >
              Change Password
            </Button>
          </Box>
        </Stack>

        {/* OPEN CHANGE PASSWORD DIALOG */}
        <ChangePasswordForm
          open={openDialog === CHANGE_PASSWORD_DIALOG}
          setOpen={setOpenDialog}
        />
        {/* Billing */}
        {user.userType === "PROVIDER" && (
          <Stack
            direction="row"
            alignItems="center"
            className="border-b border-borderColor mb-2"
          >
            <Typography className="text-secondary basis-[30%]">
              Billing
            </Typography>
            <Typography className="text-borderColor flex-1 basis-[50%]">
              XXXX XXXX XXXX 2343
            </Typography>
            <Box className="basis-[20%]">
              <Button
                variant="text"
                className="w-fit"
                onClick={() => navigate("/billing")}
              >
                Manage Billing
              </Button>
            </Box>
          </Stack>
        )}

        {/* Account Status */}
        {user.userType === "PROVIDER" && (
          <Stack
            direction="row"
            alignItems="center"
            className="border-b border-borderColor mb-2"
          >
            <Typography className="text-secondary basis-[30%]">
              Account Status
            </Typography>
            <Typography className="text-borderColor flex-1 basis-[50%]">
              {user?.status === "ACTIVE" ? "Active" : "Inactive"}
            </Typography>
            <Box className="basis-[20%]">
              <Button
                variant="text"
                color={user?.status === "ACTIVE" ? "error" : "info"}
                className="w-fit"
                onClick={() => handleSettingActionClick(CHANGE_STATUS)}
              >
                {user?.status === "ACTIVE" ? "Deactivate" : "Activate"}
              </Button>
            </Box>
          </Stack>
        )}

        {/* Appear in Searches */}
        {user.userType === "PROVIDER" && (
          <Stack
            direction="row"
            alignItems="center"
            className="border-b border-borderColor mb-2"
          >
            <Typography className="text-secondary basis-[30%]">
              Appear in Searches
            </Typography>
            <Typography className="text-borderColor flex-1 basis-[50%]">
              {user?.isAppearInSearches ? "Enable" : "Disable"}
            </Typography>
            <Box className="basis-[20%]">
              <Button
                variant="text"
                color={user?.isAppearInSearches ? "error" : "info"}
                className="w-fit"
                onClick={() =>
                  handleSettingActionClick(APPEAR_IN_SEARCHES_DIALOG)
                }
              >
                {!user?.isAppearInSearches ? "Enable" : "Disable"}
              </Button>
            </Box>
          </Stack>
        )}

        {/* Download Data */}
        {/* <Stack
          direction="row"
          alignItems="center"
          className="border-b border-borderColor mb-2"
        >
          <Typography className="text-secondary basis-[30%]">
            Download Data
          </Typography>
          <Typography className="text-borderColor flex-1 basis-[50%]">
            csv
          </Typography>
          <Button variant="text" className="basis-[20%]" disabled>
            Download
          </Button>
        </Stack> */}

        {/* Delete Account */}
        <Stack
          direction="row"
          alignItems="center"
          className="border-b border-borderColor mb-2"
        >
          <Typography className="text-secondary basis-[30%]">
            Delete account
          </Typography>
          <Typography className="text-borderColor flex-1 basis-[50%]">
            Data associated with this account will be lost upon deletion.
          </Typography>
          <Box className="basis-[20%]">
            <Button
              variant="text"
              color="error"
              className="w-fit"
              onClick={() => handleSettingActionClick(DELETE_DIALOG)}
            >
              Delete Account
            </Button>
          </Box>
        </Stack>
      </Stack>

      {/* CHANGE STATUS DIALOG */}
      <Dialog
        title="Account status"
        open={openDialog === CHANGE_STATUS}
        onClose={() => setOpenDialog("")}
        agree={toggleStatus}
        agreeText={user?.status === "ACTIVE" ? "Deactivate" : "Activate"}
        loading={isLoading}
        color={user?.status === "ACTIVE" ? "error" : "info"}
      >
        {`Are you sure, You want to ${
          user?.status === "ACTIVE" ? "deactivate" : "activate"
        } your account`}
      </Dialog>

      {/* APPEAR IN SEARCHES DIALOG */}
      <Dialog
        title="Appear in Searches"
        open={openDialog === APPEAR_IN_SEARCHES_DIALOG}
        onClose={() => setOpenDialog("")}
        agree={toggleAppearInSearches}
        agreeText={user?.isAppearInSearches ? "Disable" : "Enable"}
        loading={isLoading}
        color={user?.isAppearInSearches ? "error" : "info"}
      >
        {user?.isAppearInSearches
          ? "By disabling this option you will not appear in searches anymore. Are you sure, you want to disable."
          : "By enabling this option you will be appeared in searches. Are you sure, you want to enable."}
      </Dialog>

      {/* DELETE MY ACCOUNT DIALOG */}
      <Dialog
        title="Delete My Account"
        open={openDialog === DELETE_DIALOG}
        onClose={() => setOpenDialog("")}
        agree={deleteUser}
        agreeText="Delete"
        loading={isLoading}
        color="error"
      >
        Are you sure you want to delete your account, This action will delete
        your account and logout your current session.
      </Dialog>
    </Container>
  );
};

export default Settings;
