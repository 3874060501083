import React from "react";
import Input from "react-phone-number-input/input";

const PhoneInput = ({
  name = "phone",
  value,
  onChange,
  className,
  ...rest
}) => {
  return (
    <Input
      defaultCountry="US"
      value={value}
      name={name}
      onChange={onChange}
      className={` rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none ${className}`}
      {...rest}
    />
  );
};

export default PhoneInput;
