import { routes } from "utils/appConfig";

export const deleteCardById = (callApi, id, cb) => {
  const { url, method } = routes.deleteCard;
  try {
    callApi(
      url(id),
      method,
      {},
      {
        onSuccess: ({ data }) => {
          cb(data);
        },
        onError: (e) => {
          cb(null, e);
        },
        turnOffNotification: true,
      }
    );
  } catch (e) {
    console.log(e);
  }
};
