import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addCategories,
  updateFilterArrays,
  updateSearchQuery,
} from "store/actions/actions";
import { routes } from "utils/appConfig";
import { useAxios } from "utils/hooks/useAxios";
import Loader from "utils/loader/Loader";
import { addStates } from "store/actions/actions";
import { useNavigate } from "react-router-dom";
import { ADVANCED_SEARCH } from "utils/constants/constants";
import RenderCategories from "components/categories/CategoriesAutoComplete";
import SearchByAutoComplete from "components/practiceArea/PracticeAreaAutoComplete";

import RenderStates from "components/States/StatesAutoComplete";
import { fetchCategoryName, sortData } from "utils/helperFunctions/helper";
import { TextField } from "@mui/material";

const AdvancedSearch = ({ searchType, navSearch }) => {
  const [category, setCategory] = useState({});
  const [subCategory, setSubCategory] = useState({});
  const [stateId, setStateId] = useState(null);
  const [searchBy, setSearchBy] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isError, setIsError] = useState(null);
  const { isLoading, callApi } = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { categories, states } = useSelector(
    (state) => state.categoriesReducer
  );
  const {
    searchCategoryId,
    searchSubCategoryId,
    searchStateId,
    searchBy: storeSearchBy,
    searchText: storeSearchText,
    searchName,
  } = useSelector((state) => state.searchReducer);
  const userType = useSelector((state) => state.user?.userType);
  useEffect(() => {
    if (searchCategoryId) {
      setCategory(fetchCategoryName(categories, searchCategoryId));
      if (searchSubCategoryId) {
        const category = categories.find(
          (item) => item.category_id === searchCategoryId
        );
        const subCategory = fetchCategoryName(
          category.subCatagories,
          searchSubCategoryId
        );
        setSubCategory(subCategory);
      }
      searchStateId && setStateId(searchStateId);
    }
    if (storeSearchBy) setSearchBy(storeSearchBy);
    if (storeSearchText) setSearchText(storeSearchText);
    if (searchName) setSearchText(searchName);
  }, [searchCategoryId, searchStateId, storeSearchBy]);
  useEffect(() => {
    // FETCH CATEGORIES
    const { url, method } = routes.categories;
    if (!categories?.length) {
      callApi(
        url,
        method,
        {},
        {
          headers: {
            Authorization: null,
          },
          onSuccess: ({ data }) => {
            const categories = [...data.data];
            // if (userType === "INDIVIDUAL") {
            //   categories.filter((category) => category.isIndividual);
            // } else if (userType === "BUSINESS") {
            //   categories.filter((category) => category.isBusiness);
            // }
            dispatch(addCategories(categories));
          },
          turnOffNotification: true,
        }
      );
    }
    //  else if (userType === "INDIVIDUAL" && categories?.length) {
    //   const filteredByIndividual = categories.filter(
    //     (category) => category.isIndividual
    //   );
    //   dispatch(addCategories(filteredByIndividual));
    // } else if (userType === "BUSINESS" && categories?.length) {
    //   const filteredByBusiness = categories.filter(
    //     (category) => category.isBusiness
    //   );
    //   dispatch(addCategories(filteredByBusiness));
    // }
  }, [userType]);
  useEffect(() => {
    //FETCH STATES
    const { url, method } = routes.states;
    !states?.length &&
      callApi(
        url,
        method,
        {},
        {
          onSuccess: ({ data }) => {
            const sortedStates = sortData(data?.data, "asc", "name");
            dispatch(addStates(sortedStates));
          },
          turnOffNotification: true,
        }
      );
  }, []);

  useEffect(() => {
    if (searchText && searchBy) setIsError(null);
    if (!searchText && searchBy !== "PRACTICE_AREA")
      setIsError("Please Enter text to search");
  }, [searchText]);
  useEffect(() => {
    setIsError(null);
  }, [searchType]);
  const categoryChangeHandler = (category) => {
    // empty state when changed category
    setStateId(null);
    setSubCategory({});
    if (category?.category_id) {
      setIsError(null);
      setCategory(category);
    } else {
      setCategory({});
    }
  };
  const subCategoryChangeHandler = (subCategory) => {
    // empty state when changed category
    setStateId(null);
    if (subCategory) {
      setSubCategory(subCategory);
    } else {
      setSubCategory({});
    }
  };
  const searchByChangeHandler = (searchByValue) => {
    //empty previous state and category
    setCategory({});
    setStateId(null);
    setSearchText("");
    setIsError(null);
    setSubCategory({});
    setSearchBy(searchByValue);
  };

  const handleSearch = () => {
    if (searchBy && (searchText || category.category_id)) {
      setIsError(null);
      dispatch(
        updateSearchQuery({
          searchCategoryId: category?.category_id ?? null,
          searchSubCategoryId: subCategory?._id ?? null,
          searchStateId: stateId ?? null,
          searchType: ADVANCED_SEARCH,
          searchBy,
          searchText,
        })
      );
      dispatch(
        updateFilterArrays({ schools: [], associations: [], locations: [], languages: [] })
      );
      navigate("/searchresult");
    } else {
      if (!searchBy) {
        setIsError("Please select search by field!");
      }
      if (searchBy && searchBy !== "PRACTICE_AREA" && !searchText) {
        setIsError("Please Enter text to search");
      }
      if (searchBy && searchBy === "PRACTICE_AREA" && !category?.category_id) {
        setIsError("Please select category");
      }
    }
  };

  const updateSearchText = (value) => {
    let _tempText = value.replace(/^ /, "");
    if (_tempText) {
      setSearchText(_tempText);
    } else {
      setSearchText("");
    }
  };

  return isLoading ? (
    <div className="my-6 w-[60%]">
      <Loader width="50px" height="50px" />
    </div>
  ) : categories?.length ? (
    <div
      className={`my-${navSearch ? 0 : 6} w-[70%] sm:w-[50%] sm:w-[${
        navSearch ? 95 : 50
      }%] ${navSearch && "xl:w-[80%]"}`}
    >
      <div className="flex items-center justify-center border-2 border-[#c6c7c481] w-full lg:h-[50px]  sm:mt-[10px] rounded-sm">
        <div className="flex items-center outline-none flex-grow  border-r-2  border-[#c6c7c481]  px-4 w-[50%] h-full flex-wrap lg:flex-nowrap lg:h-[2rem] ">
          <SearchByAutoComplete
            onChange={searchByChangeHandler}
            searchBy={searchBy}
          />
          {searchBy === "PRACTICE_AREA" && (
            <RenderCategories
              options={categories}
              onChange={(category) => categoryChangeHandler(category)}
              defaultValue={category}
            />
          )}
          {!!category?.subCatagories?.length &&
            searchBy === "PRACTICE_AREA" && (
              <RenderCategories
                options={category?.subCatagories}
                onChange={(subCategory) =>
                  subCategoryChangeHandler(subCategory)
                }
                defaultValue={subCategory}
                showSubCatagories={true}
              />
            )}
          {category?.isLocationDependent && searchBy === "PRACTICE_AREA" && (
            <div className="w-[120%] flex-grow border-[#c6c7c481]">
              <RenderStates
                onChange={(id) => setStateId(id)}
                stateId={stateId}
              />
            </div>
          )}
          {searchBy !== "PRACTICE_AREA" && !!searchBy && (
            <TextField
              value={searchText}
              onChange={(e) => updateSearchText(e.target.value)}
              variant="standard"
              placeholder="Enter text . . ."
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                flexBasis: "100% !important",
              }}
            />
          )}
        </div>

        <div className="mx-4 pr-1 border-[#c6c7c481] [#c6c7c481]">
          <i
            onClick={handleSearch}
            className="fa-solid fa-magnifying-glass text-lg md:text-3xl text-[#5c5e5eb2] hover:text-[#0099CB] cursor-pointer"
          ></i>
        </div>
      </div>
      {isError && <p className="w-full text-center text-red-600 ">{isError}</p>}
    </div>
  ) : (
    <div>
      <h3>Failed to fetch categories!</h3>
    </div>
  );
};

export default AdvancedSearch;
