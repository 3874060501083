import { routes } from "utils/appConfig";

export const updateUserData = async (callApi, data, callBack) => {
  const { url, method } = routes.user;
  try {
    callApi(url, method, data, {
      onSuccess: ({ data }) => callBack(data),
      onError: (res) => callBack(null, res),
      turnOffNotification: true,
    });
  } catch (e) {
    throw new Error(e.response);
  }
};
