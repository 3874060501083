import { PortableText } from "@portabletext/react";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { getServicesPage } from "../sanity/lib/sanityClient";

// Configuration constants
const STRIPE_CONFIG = {
  SCRIPT_URL: "https://js.stripe.com/v3/pricing-table.js",
  PUBLISHABLE_KEY: "pk_live_51MEpelAUThQnZI67ndXHzVzaXSDKpQtYgrr2nWUTxw7N4D3rnV3PJt87SxfopSgTn0bCBwZkWNlxeKZwQthDGsq500rtGIjtWu",
  PRICING_TABLE_ID: "prctbl_1QRdXLAUThQnZI67W2nOiu2T",
};

const ServicesPage = () => {
  const [pageData, setPageData] = useState(null);
  const [isTableLoaded, setIsTableLoaded] = useState(false);
  const [error, setError] = useState(null);
  const pricingTableRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;

    const loadPricingTable = async () => {
      try {
        // Load page data
        const data = await getServicesPage();
        if (isMounted) setPageData(data);

        // Create and load script
        const script = document.createElement("script");
        script.src = STRIPE_CONFIG.SCRIPT_URL;
        script.async = true;

        script.onload = () => {
          if (!isMounted) return;

          if (pricingTableRef.current) {
            // Clear existing content
            pricingTableRef.current.innerHTML = "";

            // Create pricing table
            const pricingTable = document.createElement("stripe-pricing-table");
            pricingTable.setAttribute(
              "pricing-table-id",
              STRIPE_CONFIG.PRICING_TABLE_ID
            );
            pricingTable.setAttribute(
              "publishable-key",
              STRIPE_CONFIG.PUBLISHABLE_KEY
            );

            pricingTableRef.current.appendChild(pricingTable);
            setIsTableLoaded(true);
          }
        };

        script.onerror = () => {
          if (isMounted) {
            setError("Failed to load pricing table");
            setIsTableLoaded(false);
          }
        };

        document.body.appendChild(script);
      } catch (err) {
        if (isMounted) {
          console.error("Error loading pricing table:", err);
          setError("An error occurred while loading the pricing table");
          setIsTableLoaded(false);
        }
      }
    };

    loadPricingTable();

    return () => {
      isMounted = false;
      const script = document.querySelector(
        `script[src="${STRIPE_CONFIG.SCRIPT_URL}"]`
      );
      if (script) script.remove();
    };
  }, []);

  if (!pageData) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-16 h-16 border-4 rounded-full border-t-blue-500 animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <Helmet>
        <title>{pageData.seoTitle || "For Lawyers"}</title>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16808356865">
        </script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());

            gtag('config', 'AW-16808356865');
          `}
        </script>
        <script>
          {`
            !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);
            rdt('init', 'a2_g3xz74w8chkg');
            rdt('track', 'PageVisit');
          `}
        </script>
        <meta name="description" content={pageData.seoDescription} />
        {pageData.ogImage && (
          <meta property="og:image" content={pageData.ogImage} />
        )}
        {/* Enhanced CSS for horizontal layout */}
        <style>
          {`
            stripe-pricing-table {
              width: 100% !important;
              max-width: none !important;
            }
            
            stripe-pricing-table > div {
              max-width: none !important;
            }

            @media (min-width: 768px) {
              stripe-pricing-table > div {
                display: flex !important;
                flex-direction: row !important;
                gap: 20px !important;
                justify-content: center !important;
              }

              stripe-pricing-table .stripe-pricing-table--price-table {
                flex: 1 1 0 !important;
                min-width: 0 !important;
                max-width: none !important;
                margin: 0 !important;
              }
            }

            @media (max-width: 767px) {
              stripe-pricing-table > div {
                display: flex !important;
                flex-direction: column !important;
                gap: 20px !important;
              }
            }
          `}
        </style>
      </Helmet>

      <div className="max-w-7xl px-6 py-16 mx-auto">
        {/* Title and Description Section */}
        <div className="mb-16 text-center text-secondary">
          <h1 className="mb-8 text-4xl font-bold text-primary">For Lawyers</h1>
          {pageData.description && (
            <div className="max-w-3xl mx-auto prose prose-lg text-secondary">
              {typeof pageData.description === "string" ? (
                <p className="text-lg text-secondary font-[600]">
                  {pageData.description}
                </p>
              ) : (
                <PortableText
                  value={pageData.description}
                  components={{
                    block: {
                      normal: ({ children }) => (
                        <p className="mb-4 text-lg text-secondary font-[600]">
                          {children}
                        </p>
                      ),
                    },
                  }}
                />
              )}
            </div>
          )}
        </div>

        {/* Video Section */}
        {pageData.videoUrl && (
          <div className="relative mb-20 overflow-hidden rounded-lg aspect-video">
            <iframe
              className="absolute inset-0 w-full h-full"
              src={pageData.videoUrl}
              title="Service Overview"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        )}

        {/* Stripe Pricing Table */}
        <div className="mb-16">
          {!isTableLoaded && !error && (
            <div className="flex items-center justify-center py-12">
              <div className="w-16 h-16 border-4 rounded-full border-t-blue-500 animate-spin"></div>
            </div>
          )}
          {error && (
            <div className="text-red-500 text-center py-4 bg-red-50 rounded-lg">
              {error}
            </div>
          )}
          <div
            ref={pricingTableRef}
            className="w-full min-h-[600px] flex justify-center items-center"
          />
        </div>

        {/* Rest of the component remains the same */}
        {pageData.outro && (
          <div className="max-w-3xl mx-auto mt-20 text-center">
            {typeof pageData.outro === "string" ? (
              <p className="text-lg font-[600] text-secondary">
                {pageData.outro}
              </p>
            ) : (
              <PortableText
                value={pageData.outro}
                components={{
                  block: {
                    normal: ({ children }) => (
                      <p className="mb-4 text-lg font-medium leading-relaxed text-secondary">
                        {children}
                      </p>
                    ),
                  },
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ServicesPage;