import { routes } from "utils/appConfig";

export const fetchInvoices = (callApi, cb) => {
  const { url, method } = routes.invoices;
  try {
    callApi(
      url,
      method,
      {},
      {
        onSuccess: ({ data }) => {
          cb && cb(data);
          return data;
        },
        onError: (error) => {
          cb && cb(null, error);
          throw new Error(error);
        },
        turnOffNotification: true,
      }
    );
  } catch (e) {
    console.log("fetchMatters~catch", e);
    throw new Error();
  }
};
