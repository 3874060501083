export const routes = {
  newsletterSubscribe: {
    url: "/newsletter/subscribe",
    method: "POST",
    source: "NEWSLETTER_SUBSCRIBE",
  },
  signIn: {
    url: "/auth/login",
    method: "POST",
    source: "SIGN_IN",
  },
  signUp: {
    url: "/auth/register",
    method: "POST",
    source: "SIGN_UP",
  },
  emailVerification: {
    url: "/auth/send-email-code",
    method: "POST",
    source: "EMAIL_VERIFICATION",
  },
  otpVerification: {
    url: "/auth/verify-email-code",
    method: "POST",
    source: "OTP_VERIFICATION",
  },
  subscription: {
    url: "/payments/subscription",
    method: "POST",
    source: "SUBSCRIPTION",
  },
  subscriptionPlans: {
    url: "/payments/plans",
    method: "GET",
    source: "SUBSCRIPTION_PLANS",
  },
  forgotPassword: {
    url: "/auth/send-email-code",
    method: "POST",
    source: "FORGOT_PASSWORD",
  },
  me: {
    url: "/user/me",
    method: "GET",
    source: "ME",
  },
  resetPassword: {
    url: "/auth/update-password",
    method: "POST",
    source: "RESET_PASSWORD",
  },
  user: {
    url: "/user",
    method: "PATCH",
    source: "USER",
  },
  states: {
    url: "/states",
    method: "GET",
    source: "STATES",
  },
  languages: {
    url: "/languages",
    method: "GET",
    source: "LANGUAGES",
  },
  media: {
    url: "/media",
    method: "GET",
    source: "MEDIA",
  },
  categories: {
    url: "/categories",
    method: "GET",
    source: "CATEGORIES",
  },
  search: {
    url: "/search",
    method: "GET",
    source: "SEARCH",
  },
  textSearch: {
    url: "/text-search",
    method: "GET",
    source: "TEXT-SEARCH",
  },
  textSearchUnauthenticated: {
    url: "/text-search-unauthenticated",
    method: "GET",
    source: "TEXT-SEARCH",
  },
  bookmark: {
    POST: {
      url: (searchId) => `/search/${searchId}/bookmark`,
      method: "POST",
      source: "BOOKMARK",
    },
    DELETE: {
      url: (searchId) => `/search/${searchId}/bookmark`,
      method: "DELETE",
      source: "BOOKMARK",
    },
  },
  mySearches: {
    url: "/user/my-searches",
    method: "POST",
    source: "MY-SEARCHES",
  },
  fetchUserByName: {
    url: (userName) => `/user/name/${userName}`,
    method: "GET",
    source: "FETCH_USER",
  },
  fetchUser: {
    url: (userId) => `/user/${userId}`,
    method: "GET",
    source: "FETCH_USER",
  },
  getStats: {
    url: "/provider/get-stats",
    method: "POST",
    source: "GET_STATS",
  },
  searchById: {
    url: (searchId) => `/search/${searchId}`,
    method: "GET",
    source: "GET_SEARCH_BY_ID",
  },
  business: {
    post: {
      url: "/business",
      method: "POST",
      source: "BUSINESS",
    },
    get: {
      url: "/business-list",
      method: "GET",
      source: "BUSINESS",
    },
  },
  businessById: {
    get: {
      url: (searchId) => `/business/${searchId}`,
      method: "GET",
      source: "GET_BUSINESS_BY_ID",
    },
    patch: {
      url: (searchId) => `/business/${searchId}`,
      method: "PATCH",
      source: "PATCH_BUSINESS_BY_ID",
    },
    delete: {
      url: (searchId) => `/business/${searchId}`,
      method: "DELETE",
      source: "DELETE_BUSINESS_BY_ID",
    },
  },
  fetchNAICCodes: {
    url: "/naic",
    method: "GET",
    source: "FETCH_NAIC_CODES",
  },
  paymentCards: {
    url: "/payments/cards",
    method: "GET",
    source: "FETCH_PAYMENT_CARDS",
  },
  deleteCard: {
    url: (paymentMethodId) => `/payments/card/${paymentMethodId}`,
    method: "DELETE",
    source: "DELETE_CARD",
  },
  addCard: {
    url: "/payments/card",
    method: "POST",
    source: "ADD_CARD",
  },
  markAsDefault: {
    url: "/payments/make-card-default",
    method: "POST",
    source: "MARK_AS_DEFAULT",
  },
  matters: {
    url: "/my-matters",
    method: "GET",
    source: "MATTERS",
  },
  matterById: {
    url: (id) => `/matter/${id}`,
    method: "GET",
    source: "MATTERS_BY_ID",
  },
  bookafySSO: {
    url: "/user/bookafy-sso",
    method: "GET",
    source: "BOOKAFY_SSO",
  },
  filters: {
    url: (searchId) => `/filter-values/search/${searchId}`,
    method: "GET",
    source: "FILTERS",
  },
  deleteAccount: {
    url: "/user",
    method: "DELETE",
    source: "DELETE_ACCOUNT",
  },
  changePassword: {
    url: "/auth/update-password",
    method: "POST",
    source: "CHANGE_PASSWORD",
  },
  newEmail: {
    sendCode: {
      url: "/user/email-update/send-code",
      method: "PUT",
      source: "SEND_CODE",
    },
    verifyCode: {
      url: "/user/email-update/verify-code",
      method: "PUT",
      source: "VERIFY_CODE",
    },
  },
  inquiry: {
    sendInquiry: {
      url: "/inquiry",
      method: "POST",
      source: "SEND_INQUIRY",
    },
    myInquiries: {
      url: "/my-inquiries",
      method: "GET",
      source: "GET_INQUIRES",
    },
    inquiryDetail: {
      url: (id) => `/inquiries/${id}`,
      method: "GET",
      source: "INQUIRY_DETAIL",
    },
  },
  linkedInSearch: {
    url: "/linkedin/company/search",
    method: "GET",
  },
  companyDataAdvanced: {
    url: "/user/company-data",
    method: "POST",
  },
  suggestNewPracticeArea: {
    url: "/user/practice-area",
    method: "POST",
  },
  schools: {
    url: "/school",
    method: "GET",
  },
  createSchool: {
    url: "/school",
    method: "POST",
  },
  applyCoupon: {
    url: (couponId) => `/payments/coupon/${couponId}`,
    method: "GET",
  },
  invoices: {
    url: "/payments/invoices",
    method: "GET",
  },
  admin: {
    getUsers: {
      url: "/admin/users/list",
      method: "GET",
    },
    updateUser: {
      url: (userId) => `/admin/user/${userId}`,
      method: "PATCH",
    },
    changePassword: {
      url: (userId) => `/admin/user/password/${userId}`,
      method: "PUT",
    },
    deleteUser: {
      url: (userId) => `/admin/user/${userId}`,
      method: "DELETE",
    },
    enableUser: {
      url: (userId) => `/admin/user/${userId}`,
      method: "PATCH",
    },
  },
  calAtoms: {
    createAppointment: {
      url: "/calatoms/appointment",
      method: "POST",
      source: "CREATE_APPOINTMENT",
    },
    deleteAppointment: {
      url: (appointmentId) => `calatoms/appointment/${appointmentId}`,
      method: "DELETE",
    },
    updateAppointment: {
      url: (appointmentId) => `calatoms/updateAppointment/${appointmentId}`,
      method: "POST",
      source: "UPDATE_APPOINTMENT",
    },
  },
};
