import "@calcom/atoms/globals.min.css";
import { CalProvider } from '@calcom/atoms';
import { useParams, useLocation } from "react-router-dom";
import {
  CAL_ATOMS_CLIENT_ID,
  CAL_ATOMS_API_URL,
  CAL_ATOMS_REFRESH_URL,
} from "utils/constants/constants";
import BookingManager from "pages/BookingManager";
import Bookings from "pages/Bookings";
  
const BookingManagerMain = ({
    calAtomsBookingId = null,
    calAtomsEventSlug = null,
    calAtomsUserName = null,
    isCancelAppointment = false
  }) => {
    const location = useLocation();
    const bookingId = calAtomsBookingId ?? useParams().id
    const eventSlug = calAtomsEventSlug ?? new URLSearchParams(location.search).get('slug')
    const calUsername = calAtomsUserName ?? new URLSearchParams(location.search).get('username')
  
    return (
        <div>
            {isCancelAppointment && (
                <div className="flex justify-center">
                    <p className="text-red-500 text-xl font-bold">
                        Are you sure you want to cancel this meeting? Click "Cancel" to cancel
                    </p>
                </div>
            )}
            <CalProvider
                clientId={CAL_ATOMS_CLIENT_ID}
                options={{
                apiUrl: CAL_ATOMS_API_URL,
                refreshUrl: CAL_ATOMS_REFRESH_URL 
                }}
            >
                <BookingManager
                    bookingId={bookingId}
                    eventSlug={eventSlug}
                    calUsername={calUsername}
                />
            </CalProvider>
        </div>
    );
  };

  export default BookingManagerMain;