import { useNavigate } from "react-router-dom";
import backgroundImage from "../assets/background.png";
import overlay from "../assets/overlay.png";

const HelpBanner = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/services");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className="h-max help bg-cover bg-right mb-[7rem]"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div
        className="h-full bg-cover help1"
        style={{ backgroundImage: `url(${overlay})` }}
      >
        <div className="w-[47%] banner p-[5rem] py-[7rem]">
          <h1 className="text-[2.875rem] text-secondary leading-tight font-bold">
            Lawyers – Join the New Legal Paradigm!
          </h1>
          <p className="text-[1.25rem] helpp mt-3 text[20px] font-semibold md:text-[15px] text-[#5A5A5A]">
            Leverage the power of artificial intelligence to match with clients
            looking for your specific experience.
          </p>
          <button
            className="bg-primary mt-6 py-3 px-6 rounded-sm text-white w-[183px] h-[56px] hover:bg-[#0099CB] duration-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
            onClick={handleNavigation}
          >
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default HelpBanner;
