import { Button, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { updateUserEmail } from "services/updateUserEmali";
import { useAxios } from "utils/hooks/useAxios";
import Loader from "utils/loader/Loader";
import * as Yup from "yup";

const SendEmailForm = ({
  userEmail,
  showSuccessMessage,
  setShowSuccessMessage,
}) => {
  const { isLoading, callApi } = useAxios();
  const sendEmailForm = useFormik({
    initialValues: {
      newEmail: userEmail,
    },
    validationSchema: Yup.object().shape({
      newEmail: Yup.string().required().email().label("New Email"),
    }),
    onSubmit: async (values) => {
      if (userEmail !== values.newEmail) {
        updateUserEmail(callApi, values, (res) => {
          if (res.success) {
            setShowSuccessMessage(true);
          }
        });
      } else {
        sendEmailForm.setFieldError(
          "newEmail",
          "New Email should be different from old Email"
        );
      }
    },
  });

  return (
    <form onSubmit={sendEmailForm.handleSubmit}>
      <Stack direction="row" gap={1} alignItems="center">
        <TextField
          id="newEmail"
          name="newEmail"
          label="New Email"
          size="small"
          fullWidth
          {...sendEmailForm.getFieldProps("newEmail")}
          error={
            sendEmailForm.touched.newEmail &&
            Boolean(sendEmailForm.errors.newEmail)
          }
          helperText={
            sendEmailForm.touched.newEmail && sendEmailForm.errors.newEmail
          }
        />
        <Button size="small" onClick={sendEmailForm.handleSubmit}>
          {isLoading ? <Loader /> : "Update"}
        </Button>
      </Stack>
      {showSuccessMessage && (
        <Typography variant="body">
          Please enter the code sent to your new email to update your email.
        </Typography>
      )}
    </form>
  );
};

export default SendEmailForm;
