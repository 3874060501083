import { ExpandMore, KeyboardArrowUp } from "@mui/icons-material";
import { Checkbox, Typography } from "@mui/material";

const TreeViewLabel = ({
  categoryName,
  checked,
  icon,
  isExpanded,
  handleItemClick,
  handleCheckboxClick,
  enableCursor = false,
  enableSelection = false,
}) => {
  return (
    <div
      className={`flex justify-between items-center cursor-${
        enableCursor ? "pointer" : "default"
      }`}
      onClick={handleItemClick}
    >
      <span className="flex items-center">
        {enableSelection && (
          <Checkbox
            checked={checked ?? false}
            onClick={(e) => e.stopPropagation()}
            onChange={handleCheckboxClick}
          />
        )}
        {!enableSelection && <li />}
        <Typography variant="p" fontSize={18} fontWeight={600}>
          {categoryName}
        </Typography>
      </span>
      {icon && !isExpanded && <ExpandMore />}
      {icon && isExpanded && <KeyboardArrowUp />}
    </div>
  );
};

export default TreeViewLabel;
