import React, { useCallback } from "react";
import { findCheckedCategories } from "components/modals/practiceArea/PracticeArea";
import TreeViewLabel from "./TreeViewLabel";
import { useEffect, useState } from "react";
import { findItemIndex } from "utils/helperFunctions/helper";

const TreeViewComponent = ({
  data,
  selectedCategories,
  enableSelection,
  inputValue,
  practiceAreas,
}) => {
  const [categories, setCategories] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isExpanded, setIsExpanded] = useState({});

  useEffect(() => {
    if (enableSelection) {
      const _temp = JSON.parse(JSON.stringify(data));
      let matchedItem;
      let matchedItemIndex;
      practiceAreas?.forEach((practiceArea) => {
        if (practiceArea?.checked) {
          matchedItem = practiceArea;
          matchedItemIndex = findItemIndex(
            _temp,
            "category_id",
            practiceArea.category_id
          );
          setIsExpanded((pre) => ({
            ...pre,
            [practiceArea.category_id]: true,
          }));
          _temp.splice(matchedItemIndex, 1, matchedItem);
          return;
        }
        matchedItem = _temp.find(
          (item) => item.category_id === practiceArea.category_id
        );
        matchedItemIndex = findItemIndex(
          _temp,
          "category_id",
          practiceArea.category_id
        );
        if (practiceArea?.subCatagories?.length) {
          setIsExpanded((pre) => ({
            ...pre,
            [practiceArea.category_id]: true,
          }));
          practiceArea?.subCatagories?.forEach((subItem) => {
            const subItemIndex = matchedItem.subCatagories.findIndex(
              (temp) => temp._id === subItem._id
            );
            if (subItemIndex >= 0) {
              matchedItem.subCatagories[subItemIndex].checked = true;
            }
          });
        }
        _temp.splice(matchedItemIndex, 1, matchedItem);
      });
      setCategories(_temp);
    } else if (!enableSelection) {
      // expand the all the categories that have subCategories
      data.forEach((item) => {
        if (item?.subCatagories?.length) {
          setIsExpanded((pre) => ({
            ...pre,
            [item.category_id]: true,
          }));
        }
      });
      setCategories(data);
    }
  }, [data]);

  useEffect(() => {
    if (inputValue) {
      const _temp = [];
      categories.forEach((category) => {
        // if category matched then push the category to the results
        if (category.name.toLowerCase().includes(inputValue)) {
          _temp.push(category);
        } else if (category.subCatagories?.length) {
          // if subCategory match the input then push the category with subCategories matched
          let subCatagories = [];
          category.subCatagories.forEach((subCategory) => {
            if (subCategory.name.toLowerCase().includes(inputValue)) {
              subCatagories.push(subCategory);
            }
          });
          subCatagories?.length && _temp.push({ ...category, subCatagories });
        }
      });
      setSearchResults(_temp);
    } else {
      setSearchResults([]);
    }
  }, [inputValue]);

  const updateIsExpanded = (categoryId) => {
    setIsExpanded((pre) => ({
      ...pre,
      [categoryId]: !pre[categoryId],
    }));
  };

  const handleCategoryClick = (category) => {
    if (category?.subCatagories?.length) {
      updateIsExpanded(category.category_id);
    }
  };

  const handleCategoryCheckboxClick = useCallback(
    (category) => {
      const categoryIndex = categories.findIndex(
        (item) => item.category_id === category.category_id
      );
      if (category?.checked && category?.subCatagories?.length) {
        category?.subCatagories?.map((subCategory) => {
          subCategory.checked = false;
          return subCategory;
        });
      } else if (category?.subCatagories?.length) {
        category?.subCatagories?.map((subCategory) => {
          subCategory.checked = true;
          return subCategory;
        });
      }
      category.checked = !category?.checked;
      setCategories((pre) => {
        pre.splice(categoryIndex, 1, category);
        selectedCategories(findCheckedCategories(pre));
        return pre;
      });
      if (!isExpanded?.[category?.category_id] && category?.checked) {
        handleCategoryClick(category);
      }
    },
    [categories, isExpanded]
  );

  const handleSubCategoryCheckboxClick = (category, subCategory) => {
    setCategories((pre) => {
      const categoryIndex = pre.findIndex(
        (item) => item.category_id === category.category_id
      );
      const subCategoryIndex = category.subCatagories?.findIndex(
        (item) => item._id === subCategory._id
      );
      // toggle the subCategory
      subCategory.checked = !subCategory?.checked;
      const CheckedItems = category?.subCatagories.filter(
        (item) => item.checked
      );

      // toggle the main category if subCategories selected items are equal to subCatagories.length
      if (CheckedItems.length === category?.subCatagories?.length) {
        category.checked = true;
      } else {
        category.checked = false;
      }
      category.subCatagories?.splice(subCategoryIndex, 1, subCategory);
      pre.splice(categoryIndex, 1, category);
      selectedCategories(findCheckedCategories(pre));
      return [...pre];
    });
  };

  return (
    <div className="px-3">
      {(!!categories?.length || !!searchResults?.length) &&
        (searchResults?.length ? searchResults : categories).map((category) => {
          return (
            <div key={category.category_id} className="mb-2">
              <TreeViewLabel
                categoryName={category.name}
                icon={!!category?.subCatagories?.length}
                handleItemClick={() => handleCategoryClick(category)}
                isExpanded={isExpanded[category.category_id]}
                checked={category?.checked}
                handleCheckboxClick={() =>
                  handleCategoryCheckboxClick(category)
                }
                enableCursor={!!category?.subCatagories?.length}
                enableSelection={enableSelection}
              />
              {!!category?.subCatagories?.length &&
                isExpanded[category?.category_id] &&
                category.subCatagories.map((subCategory) => {
                  return (
                    <div key={subCategory._id} className="pl-4 mt-1">
                      <TreeViewLabel
                        categoryName={subCategory.name}
                        handleCheckboxClick={() =>
                          handleSubCategoryCheckboxClick(category, subCategory)
                        }
                        checked={category?.checked || subCategory?.checked}
                        enableSelection={enableSelection}
                      />
                    </div>
                  );
                })}
            </div>
          );
        })}
    </div>
  );
};

export default React.memo(TreeViewComponent);
