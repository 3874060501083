import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import Association from "./imgs/association.svg";
import { Link } from "react-router-dom";
import { ACHIEVEMENTS } from "components/PremiumProviderEditProfileComponents/constants/editProfileConstants";

const Associations = ({ editAble, associations }) => {
  return (
    <Box className=" w-full rounded-[8px] border border-borderColor p-6 mt-4">
      <Stack direction="row" alignItems="center" my={2}>
        <Typography sx={{ color: "#002A5C", fontSize: 22, fontWeight: 700 }}>
          Associations
        </Typography>
        {editAble && (
          <Link to="/profile/edit" state={{ goToStep: ACHIEVEMENTS }}>
            <Typography
              sx={{
                color: "#00A4DA",
                fontSize: 16,
                fontWeight: 600,
                pl: 1,
                pt: 0.5,
              }}
            >
              Edit
            </Typography>
          </Link>
        )}
      </Stack>
      <Stack
        direction="row"
        spacing={0}
        sx={{ flexWrap: "wrap", gap: { xs: "10px", sm: "60px" } }}
      >
        {associations.map((item, index) => {
          return (
            <Box key={index} sx={{ display: "flex", gap: "20px" }}>
              <Box>
                <img src={Association} alt="association" />
              </Box>
              <Box>
                <Typography
                  variant="h4"
                  sx={{ color: "#002A5C", fontSize: "18px" }}
                >
                  {item?.title}
                </Typography>
                <Typography sx={{ color: "#5A5A5A80" }}>
                  {item?.date}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default Associations;
