import { routes } from "utils/appConfig";
import { serializeParams } from "utils/helperFunctions/helper";

export const getRecentSearches = (
  callApi,
  params,
  cb,
  isBookmarkedOnly = false
) => {
  const { url, method } = routes.mySearches;
  try {
    callApi(
      url + serializeParams(params),
      method,
      {
        filters: {
          isBookmarkedOnly,
        },
      },
      {
        onSuccess: ({ data }) => {
          cb(data.data);
        },
        onError: (error) => {
          cb(null, error);
        },
        turnOffNotification: true,
      }
    );
  } catch (e) {
    console.log("getRecentSearches~catch", e);
    throw new Error();
  }
};
