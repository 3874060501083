import React from "react";
import Zoom from "@mui/material/Zoom";
import Tooltip from "@mui/material/Tooltip";

const CustomTooltip = ({ label, children, ...rest }) => {
  return (
    <Tooltip
      title={label}
      arrow
      {...rest}
      TransitionComponent={Zoom}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "#00A4DA",
            fontSize: "14px",
            "& .MuiTooltip-arrow": {
              color: "#00A4DA",
            },
          },
        },
      }}
    >
      <div>{children}</div>
    </Tooltip>
  );
};

// const Tooltip = ({ label, children, ...rest }) => {
//   return (
//     <div className="bg-gray-100 ">
//       <div className="relative py-3" {...rest}>
//         <div className="group cursor-pointer relative inline w-28 text-center">
//           {children}
//           <div className="opacity-0 w-fit bg-primary text-white text-center text-sm rounded-lg py-2 absolute z-[100] group-hover:opacity-100 bottom-full -left-1/2 ml-14 px-3 pointer-events-none">
//             {label}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export default CustomTooltip;
