import { routes } from "utils/appConfig";

export const verifyEmailCode = async (callApi, data, callBack) => {
  const { url, method } = routes.newEmail.verifyCode;
  try {
    callApi(url, method, data, {
      onSuccess: ({ data }) => callBack(data),
      onError: (res) => callBack(null, res),
      successMessage: "Email updated successfully!",
    });
  } catch (e) {
    throw new Error(e);
  }
};
