import { useDispatch, useSelector } from "react-redux";
import { signOut } from "store/actions/authActions";
import lawFirmLogo from "assets/juricon-logo-white-small.svg";
import { useNavigate } from "react-router-dom";
import useCookies from "utils/hooks/useCookie";
import { BL_RF_TOKEN } from "utils/constants/constants";
import { ClickAwayListener } from "@mui/material";
import { useAxios } from "utils/hooks/useAxios";
import { fetchUserInfoById } from "services/fetchUserInfo";
import { signInAction } from "store/actions/authActions";
import { ConstructionSharp } from "@mui/icons-material";

const NavDropDown = ({ name, setShowMenu, businessList }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { deleteCookie } = useCookies();
  const user = useSelector((state) => state.user || {});
  const { userType, isAdmin } = user;
  const { isLoading, callApi } = useAxios();

  const handleNavigateToManageCalendar = () => {
    // Fetch latest user info from the DB to ensure cal access token is updated to latest after refresh by the cal atoms SDK
    fetchUserInfoById(callApi, user._id, (data) => {
      dispatch(signInAction(data.data));
      setShowMenu(false);
      navigate("/managecalendar", {state: data.data ?? user});
    });
  };

  return (
    <ClickAwayListener onClickAway={() => setShowMenu(false)}>
      <div
        style={{ borderRadius: "8px" }}
        className="absolute w-[170px] pb-4 bg-white top-[3.5rem] right-1 shadow-[0px_4px_10px_1px_rgba(0,0,0,0.09)] z-[5000]"
      >
        <h2 className="w-100 h-[54.5px] flex items-center text-[#002A5C] text-[16px] font-[700] leading-[19.2px] ">
          <div className="pl-4">{name}</div>
        </h2>
        <div className="flex flex-col justify-around px-4 pb-4 gap-3 ">
          <hr className="border border-[#d9d9d959] " />
          <button
            className="w-fit hover:text-primary"
            onClick={() => {
              setShowMenu(false);
              navigate("/dashboard");
            }}
          >
            Dashboard
          </button>
          {!!isAdmin && (
            <button
              className="w-fit hover:text-primary"
              onClick={() => {
                setShowMenu(false);
                navigate("/admin");
              }}
            >
              Admin panel
            </button>
          )}
          <button
            className="w-fit hover:text-primary"
            onClick={() => {
              setShowMenu(false);
              navigate("/profile");
            }}
          >
            Profile
          </button>
          {userType === "PROVIDER" && (
            <>
              <button
                className="w-fit hover:text-primary"
                onClick={() => {
                  setShowMenu(false);
                  navigate("/billing");
                }}
              >
                Billing
              </button>
              { user?.calAtomsUserId && !!user?.subscription && (
                <button
                  className="w-fit hover:text-primary"
                  onClick={handleNavigateToManageCalendar}
                >
                  Manage Calendar
                </button>
              )}
            </>
          )}
          <button
            className="w-fit hover:text-primary"
            onClick={() => {
              setShowMenu(false);
              navigate("/settings");
            }}
          >
            Settings
          </button>
          <button
            className="w-fit hover:text-primary"
            onClick={() => {
              setShowMenu(false);
              dispatch(signOut());
              deleteCookie(BL_RF_TOKEN);
              navigate("/");
            }}
          >
            Logout
          </button>
          {!!businessList?.length && (
            <hr className="border border-[#d9d9d959] " />
          )}
        </div>
        <div className="overflow-auto max-h-[500px]">
          {!!businessList?.length &&
            businessList.map((business) => {
              const logo = business?.businessLogo?.includes("http")
                ? business.businessLogo
                : null;
              return (
                <div
                  key={business._id}
                  className="flex items-center px-4 gap-1 mb-2"
                >
                  <img
                    className="w-[35px] h-[35px] rounded-xs"
                    src={logo ?? lawFirmLogo}
                    alt=""
                  />
                  <h3
                    className="text-[14px] font-[500] break-before-auto break-all hover:text-primary cursor-pointer"
                    onClick={() => {
                      setShowMenu(false);
                      navigate(`/firm/${business._id}`);
                    }}
                  >
                    {business.name}
                  </h3>
                </div>
              );
            })}
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default NavDropDown;
