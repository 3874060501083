import React from "react";

const Error = ({
  formik,
  errorField,
  className,
  property,
  index,
  force,
  errorLabel,
}) => {
  if (property) {
    let str = formik?.errors?.[property]?.[index]?.[errorField];
    let DynamicError = "";
    if (str) {
      let _temp = str?.split(" ");
      _temp[0] = errorLabel;
      DynamicError = _temp.join(" ");
    }

    return formik?.touched?.[property]?.[index]?.[errorField] &&
      formik?.errors?.[property]?.[index]?.[errorField] ? (
      <span className={`text-[red] ${className}`}>
        {DynamicError
          ? DynamicError
          : formik.errors[property][index][errorField]}
      </span>
    ) : null;
  } else if (formik?.touched?.[errorField] && formik?.errors?.[errorField]) {
    return formik?.touched?.[errorField] && formik?.errors?.[errorField] ? (
      <span className={`text-[red] ${className}`}>
        {formik.errors[errorField]}
      </span>
    ) : null;
  } else if (force) {
    return formik?.errors?.[errorField] ? (
      <span className={`text-[red] ${className}`}>
        {formik.errors[errorField]}
      </span>
    ) : null;
  }
};

export default Error;
