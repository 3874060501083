import React, { useEffect, useState } from "react";
import { useAxios } from "utils/hooks/useAxios";

import {
  Typography,
  Container,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Pagination,
  Stack,
  TextField,
  Switch,
} from "@mui/material";

import AdminNav from "components/admin/AdminNav";
import Loader from "utils/loader/Loader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { notify } from "utils/toast/toast";
import { fetchAdminPanelUsers } from "services/fetchAdminPanelUsers";
import NoDataAvailable from "components/noDataAvailable/NoDataAvailable";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import FilterPopover from "components/filterPopOver/FilterPopover";
import { adminActions, adminPanelFilters } from "data/adminPanelData";
import AdminActionMenu from "components/admin/AdminActionMenu";
import AdminUserInfoUpdateModal from "components/admin/AdminUserInfoUpdateModal";
import { deleteUserByAdmin } from "services/deleteUserByAdmin";
import Dialog from "components/dialog/Dialog";
import PasswordChangeForm from "components/admin/PasswordChangeForm";
import { useFormik } from "formik";
import { passwordRegex } from "components/modals/helper";
import * as Yup from "yup";
import { changePasswordByAdmin } from "services/changePasswordByAdmin";
import { enableUserByAdmin } from "services/enableUserByAdmin";
import { formatPhone } from "utils/helperFunctions/helper";
import { adminPanelUserUpdate } from "services/adminPanelUserUpdate";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Please enter password to update")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      passwordRegex,
      " Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
});

const AdminPanel = () => {
  const limit = 10;
  const loggedInUser = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { isLoading, callApi } = useAxios();
  const { isLoading: adminActionLoading, callApi: adminActionApi } = useAxios();
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filterBy, setFilterBy] = useState({ label: "", value: "" });
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [isAppearInSearchesLoading, setIsAppearInSearchesLoading] = useState(
    {}
  );
  const { callApi: appearInSearchApi } = useAxios();

  const updateAppearInSearchesLoading = (id) => {
    setIsAppearInSearchesLoading((pre) => ({
      ...pre,
      [id]: !pre[id],
    }));
  };

  const passwordForm = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      changePasswordByAdmin(adminActionApi, selectedUser._id, values, (res) => {
        if (res?.success) {
          resetForm();
          setOpenPasswordDialog(false);
        }
      });
    },
  });

  const adminPanelData = (pageNumber, filter) => {
    const params = { page: pageNumber, limit };
    if (filter) {
      params.userType = filter;
    }
    fetchAdminPanelUsers(callApi, params, (data, error) => {
      if (data) {
        data?.users?.length && setUsers(data.users);
        setTotalRecords(data.total);
      }
      if (error) {
        console.log("error in admin panel users~: ", error);
      }
    });
  };

  useEffect(() => {
    if (!loggedInUser?.isAdmin) {
      navigate("/");
      notify("Only Admin user can access this route.", "error");
    }
  }, []);

  useEffect(() => {
    if (filterBy.value) {
      adminPanelData(1, filterBy.value);
    } else {
      adminPanelData(1);
    }
  }, [filterBy.value]);

  const handlePageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      adminPanelData(pageNumber, filterBy?.value);
    }
  };

  const handleActionMenuClick = (action, user) => {
    setSelectedUser(user);
    if (action === "editUser") {
      setOpenModal(true);
      // navigate(`/admin/user/${user._id}`);
    } else if (action === "changePassword") {
      setOpenPasswordDialog(true);
    } else if (action === "deleteUser") {
      setOpenDeleteDialog(true);
    } else if (action === "enableUser") {
      enableUser(user?._id);
    }
  };

  const deleteUser = () => {
    deleteUserByAdmin(adminActionApi, selectedUser._id, (res, error) => {
      if (res?.success) {
        setOpenDeleteDialog(false);
        setUsers((pre) => {
          const index = pre.findIndex((user) => selectedUser._id === user._id);
          const user = pre.find((user) => selectedUser._id === user._id);
          user.status = "DELETED";
          pre.splice(index, 1, user);
          return [...pre];
        });
      }
    });
  };

  const enableUser = (userId) => {
    enableUserByAdmin(adminActionApi, userId, (res, error) => {
      if (res?.success) {
        setUsers((pre) => {
          const index = pre.findIndex((user) => userId === user._id);
          const user = pre.find((user) => userId === user._id);
          user.status = "ACTIVE";
          pre.splice(index, 1, user);
          return [...pre];
        });
      } else if (error) {
        console.log("Error in enabling the User", error);
      }
    });
  };

  const handleAppearInSearchesToggle = (user) => {
    updateAppearInSearchesLoading(user._id);
    adminPanelUserUpdate(
      appearInSearchApi,
      user._id,
      { isAppearInSearches: !user.isAppearInSearches },
      (res, error) => {
        updateAppearInSearchesLoading(user._id);
        if (res.success) {
          const updatedUsers = [...users].map((provider) => {
            if (user._id === provider._id) {
              provider.isAppearInSearches = res.data.isAppearInSearches;
            }
            return provider;
          });
          setUsers(updatedUsers);
        } else {
          notify("Something went wrong!", "error");
          console.log("AdminUserInfoUpdateModal ~ error~: ", error);
        }
      }
    );
  };
  return (
    <>
      <AdminNav />
      <Container maxWidth="xl" sx={{ padding: 2 }}>
        <Typography variant="h5" sx={{ color: "#00A4DA", fontWeight: "bold" }}>
          Users
        </Typography>

        <Box sx={{ overflow: "scroll" }} minHeight={600}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography fontWeight="bold">Full Name</Typography>
                </TableCell>
                <TableCell>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Typography fontWeight="bold">User Type</Typography>
                    <FilterPopover
                      icon={
                        filterBy?.value ? (
                          <FilterAltIcon />
                        ) : (
                          <FilterAltOffIcon />
                        )
                      }
                      options={adminPanelFilters}
                      title="Filter By: "
                      state={filterBy}
                      setState={setFilterBy}
                      setCurrentPage={setCurrentPage}
                      adminPanelData={adminPanelData}
                    />
                  </Stack>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">Email</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">Phone</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">Appear In Searches</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold" align="center">
                    Status
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">Actions</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading &&
                !!users?.length &&
                users?.map((user) => {
                  return (
                    <TableRow key={user._id}>
                      <TableCell>
                        {user?.firstName} {user?.lastName}
                      </TableCell>
                      <TableCell>{user?.userType}</TableCell>
                      <TableCell>{user?.email}</TableCell>
                      <TableCell>
                        {!isNaN(user?.phone) ? formatPhone(user?.phone) : "-"}
                      </TableCell>
                      <TableCell>
                        <div className="flex justify-center">
                          {!isAppearInSearchesLoading?.[user._id] ? (
                            <Switch
                              checked={!!user?.isAppearInSearches}
                              onChange={() =>
                                handleAppearInSearchesToggle(user)
                              }
                            />
                          ) : (
                            <Loader />
                          )}
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <span
                          className={`${
                            user?.status === "DELETED" && "text-red-500"
                          }`}
                        >
                          {user?.status ?? "-"}
                        </span>
                      </TableCell>
                      <TableCell>
                        <AdminActionMenu
                          options={adminActions}
                          onMenuClick={(action) =>
                            handleActionMenuClick(action, user)
                          }
                          disabled={user?.status === "DELETED"}
                          userType={user?.userType}
                          isLoading={adminActionLoading}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {!users?.length && !isLoading && <NoDataAvailable />}
          {isLoading && (
            <Stack
              justifyContent="center"
              alignItems="center"
              width="100%"
              minHeight={540}
            >
              <Loader width={35} height={35} />
            </Stack>
          )}
        </Box>
        {totalRecords > 10 && !isLoading && (
          <Pagination
            count={Math.ceil(totalRecords / limit)}
            page={currentPage}
            onChange={(_, pageNumber) => handlePageChange(pageNumber)}
          />
        )}
      </Container>
      <AdminUserInfoUpdateModal
        open={openModal}
        setOpen={setOpenModal}
        user={selectedUser}
        setSelectedUser={setSelectedUser}
        setUsers={setUsers}
      />
      {/* Delete Dialog */}
      <Dialog
        title="Delete User"
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        agree={deleteUser}
        agreeText="Delete"
        loading={adminActionLoading}
      >
        Are you sure to delete!
      </Dialog>
      {/* Password Update Dialog*/}
      <Dialog
        title="Change Password"
        open={openPasswordDialog}
        onClose={() => {
          passwordForm.resetForm();
          setOpenPasswordDialog(false);
        }}
        agree={passwordForm.handleSubmit}
        agreeText="Update"
        loading={adminActionLoading}
      >
        <PasswordChangeForm formik={passwordForm} />
      </Dialog>
    </>
  );
};

export default AdminPanel;
