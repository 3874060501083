import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeCurrentModal, openModal } from "store/actions/modalActions";
import { ClickAwayListener, Typography } from "@mui/material";
import MembershipPlans from "components/membership/MembershipPlans";
import { PLANS_MODAL, SELECT_CARD_MODAL } from "utils/constants/constants";

const PlansModal = () => {
  const dispatch = useDispatch();
  const plan = useSelector(
    (state) => state.membershipReducer?.changeMembershipTo
  );

  const handleNext = () => {
    dispatch(openModal(SELECT_CARD_MODAL));
  };
  const handleClose = () => {
    dispatch(closeCurrentModal());
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className="relative bg-[#fff] w-[326px] sm:w-[484px] md:h-[580px] rounded-lg shadow dark:bg-gray-700 rounded-[20px] flex flex-col justify-between py-8 px-11">
        <div className="flex flex-col gap-2">
          <Typography fontWeight={700} fontSize={22} sx={{ color: "#002A5C" }}>
            Change Package
          </Typography>
          <Typography
            sx={{ color: "#5A5A5A", marginTop: "9px", marginBottom: "38px" }}
          >
            Upgrade plans to enjoy exciting features.
          </Typography>
          <MembershipPlans type={plan?.type} price={plan?.price} active />
        </div>
        {/* <div className="radio mt-6">
          <input id="radio-1" name="plans" type="radio" checked disabled />
          <label htmlFor="radio-1" className="radio-label">
            Upgrade now to get 12% discount!
          </label>
        </div>

        <div className="radio mt-3">
          <input id="radio-2" name="plans" type="radio" disabled />
          <label htmlFor="radio-2" className="radio-label">
            Upgrade later at the end of billing cycle.
          </label>
        </div> */}
        <div className="flex flex-col gap-2">
          <button
            type="submit"
            className="w-full mt-6 bg-primary text-white  hover:bg-blue-800 py-3 rounded-sm focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm "
            onClick={handleNext}
          >
            Next
          </button>
          <button
            type="submit"
            className="bg-borderColor w-full px-3 py-2 mt-3 rounded-xs text-white hover:bg-hoverColor duration-300"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default PlansModal;
