import { useState } from "react";
import axios from "axios";
import { notify } from "../toast/toast";
import { useDispatch, useSelector } from "react-redux";
import { signInAction } from "store/actions/authActions";
import { getCookie } from "utils/helperFunctions/helper";
import { BL_RF_TOKEN } from "utils/constants/constants";

export const useAxios = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const renewAccessToken = (failedRequest) => {
    // eslint-disable-next-line no-undef
    const baseURL = process.env.REACT_APP_BASE_URL;
    const refreshToken = getCookie(BL_RF_TOKEN);
    return fetch(`${baseURL}/auth/refresh-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refreshToken: refreshToken }),
    })
      .then(async (response) => {
        const data = await response.json();
        if (user) {
          dispatch(signInAction({ ...user, accessToken: data.accessToken }));
        }
        failedRequest.headers["Authorization"] = data.accessToken;

        return axios.request(failedRequest);
      })
      .catch((error) => {
        throw error;
      });
  };

  // eslint-disable-next-line no-undef
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error?.config;

      if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        return renewAccessToken(originalRequest);
      }

      return Promise.reject(error);
    }
  );

  const [isLoading, setLoading] = useState(false);
  const token = useSelector((state) => state?.user?.accessToken);

  const callApi = async (url, method, data, props) => {
    const {
      errorMessage,
      successMessage,
      onSuccess,
      onError,
      headers = {},
      turnOffNotification,
    } = props;
    setLoading(true);
    try {
      if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      }
      const response = await axios.request({ url, method, data, headers });
      if (response?.status >= 200 && response?.status <= 299) {
        let message = response?.data?.message;
        if (typeof message === "string" && !turnOffNotification) {
          notify(successMessage ?? message, "success");
        } else {
          !turnOffNotification &&
            notify(successMessage ?? "Form submitted successfully", "success");
        }
        onSuccess && onSuccess(response);
      }
      return response;
    } catch (error) {
      errorHandler(error, errorMessage, turnOffNotification);
      onError && onError(error);
      return error;
    } finally {
      setLoading(false);
    }
  };

  return { isLoading, callApi };
};

export const errorHandler = (res, errorMessage, turnOffNotification) => {
  let message = res?.response?.data?.message;
  if (typeof message === "string" && !turnOffNotification) {
    notify(errorMessage ?? message, "error");
  } else if (res?.message && !turnOffNotification) {
    notify(errorMessage ?? res.message, "error");
  } else if (!turnOffNotification) {
    notify(errorMessage ?? "Something went wrong", "error");
  }
};
