import React from "react";
import { Avatar, Stack, Typography } from "@mui/material";

const GroupOfImages = ({ avatars }) => {
  const remaining = avatars?.length - 4;
  return (
    <Stack direction="row" alignItems="center">
      {avatars?.slice(0, 4).map((provider, i) => (
        <Avatar
          key={i}
          src={provider?.profilePicture}
          alt={provider?.firstName}
          sx={{
            marginLeft: i > 0 ? "-12px" : "0px",
            backgroundColor: i === 3 && "rgba(0,0,0,0.3) !important",
          }}
        />
      ))}
      {remaining > 0 && (
        <Typography fontSize={"18px"} ml={-4.3} zIndex={1500}>
          +{remaining < 10 && 0}
          {remaining}
        </Typography>
      )}
    </Stack>
  );
};

export default GroupOfImages;
