import React from "react";
import Footer from "components/Footer";
import Nav from "components/Individualconsumer/Nav";
import ConsumerProfile from "components/Individualconsumer/ConsumerProfile";

const IndividualConsumerProfile = () => {
  return (
    <>
      <div className="flex justify-center h-[100vh]">
        <div className="w-[85%]">
          <Nav />
          <div className="flex pt-2 pb-3">
            <a href="#">
              <label
                htmlFor="Home"
                className="block text-sm font-semibold text-[#5A5A5A]"
              >
                Home
              </label>
            </a>
            <label
              htmlFor=">"
              className="block px-2 text-sm font-semibold text-[#5A5A5A]"
            >
              <i className="text-sm font-medium text-[#5A5A5A] fa-solid fa-greater-than"></i>
            </label>
            <a href="#">
              <label
                htmlFor="Consumer"
                className="block text-sm font-semibold text-primary hover:text-[#00a4dabe]"
              >
                My Profile
              </label>
            </a>
          </div>
          <label
            htmlFor="Contact Person"
            className="block py-2 text-xl font-bold text-secondary"
          >
            Contact Person
          </label>
          <ConsumerProfile />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default IndividualConsumerProfile;
