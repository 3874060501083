import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function CustomAccordion({ label, value, children }) {
  return (
    <div className="flex ">
      <Accordion
        sx={{
          fill: "#FAFAFA",
          color: "#5A5A5A",
          backgroundColor: "#FAFAFA",
          boxShadow: "none",
          "&:hover": {
            bgcolor: "#FAFAFA",
          },
          width: { xs: "150px", sm: "auto" },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography
            sx={{
              fontWeight: "500",
              text: " 14px",
              color: "#5A5A5A",
            }}
          >
            {label}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {value && (
            <Typography
              sx={{
                fontWeight: "500",
                text: " 14px",
                color: "#979797",
                whiteSpace: "wrap",
                wordBreak: "break-all",
              }}
            >
              {value}
            </Typography>
          )}
          {children && children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
