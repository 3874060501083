export const PERSONAL_INFO = "personalInfo";
export const ADDRESS = "address";
export const LICENSE = "license";
export const BILLING = "billing";
export const LANGUAGE = "language";
export const EXPERIENCE = "experience";
export const BUSINESS = "business";
export const EDUCATION = "education";
export const WORK_INFO = "workInfo";
export const ACHIEVEMENTS = "achievements";
export const REPRESENTATION = "representation";
