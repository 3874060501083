/* eslint-disable no-useless-escape */
import * as Yup from "yup";
import showPasswordImg from "../../assets/show.png";
import hidePasswordImg from "../../assets/hide.png";
import { isValidPhoneNumber } from "react-phone-number-input";

export const phoneRegExp =
  // eslint-disable-next-line no-useless-escape
  /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/gm;
export const numberRegex = /^[0-9]+$/g;
export const passwordRegex =
  // eslint-disable-next-line no-useless-escape
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
export const emailValidation = Yup.string().email().required().label("Email");
export const alphanumericWithSpacesRegex = /^[a-zA-Z0-9\s]+$/;
export const alphanumericWithOutSpacesRegex = /^[a-zA-Z0-9]+$/;
export const EINRegex = /^(\d{2})-(\d{7})$/;
export const websiteRegex =
  /^(https?|ftp):\/\/[^\s\/$.?#]+\.[^\s\/$.?#]+[^\s]*$/;
export const passwordValidation = Yup.string()
  .min(4)
  .trim()
  .max(50)
  .matches(
    passwordRegex,
    " Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  )
  .required()
  .label("Password");

export const isRequired = (label) => Yup.string().required().label(label);

export const nameValidation = (label) =>
  Yup.string()
    .trim()
    .min(3)
    .max(50)
    .matches(/^[A-Za-z]+$/, " Only alphabets are allowed")
    .required()
    .label(label);
export const fullNameValidation = (label) =>
  Yup.string()
    .required()
    .trim()
    .min(3)
    .max(50)
    .matches(/^[A-Za-z\-\s]*$/, " Only alphabets and spaces are allowed.")
    .label(label);
export const alphaNumericValidation = (label) =>
  Yup.string()
    .trim()
    .matches(
      alphanumericWithSpacesRegex,
      ` ${label} must contain only alphanumeric characters and spaces.`
    )
    .label(label);
export const phoneValidation = (label) =>
  Yup.string()
    .required()
    .matches(phoneRegExp, " Phone is not valid")
    .label(label ?? "Phone");

export const confirmPasswordValidation = Yup.string()
  .trim()
  .min(4)
  .max(50)
  .required("Please enter Confirm Password")
  .oneOf([Yup.ref("password"), null], "Passwords must match")
  .label("Confirm Password");

export const signUpValidationSchema = Yup.object().shape({
  firstName: fullNameValidation("First Name"),
  lastName: fullNameValidation("Last Name"),
  email: emailValidation,
  phone: Yup.string()
    .required(" Phone is required")
    .test({
      name: "phone",
      exclusive: true,
      message: " Phone is invalid",
      test: (value) => {
        return isValidPhoneNumber(value ?? "");
      },
    }),
  password: passwordValidation,
  confirmPassword: confirmPasswordValidation,
  postalCode: Yup.string().required().min(5).max(9).label(" Zip Code"),
  // name: Yup.string()
  //   .trim()
  //   .when("userType", {
  //     is: (userType) => userType === "BUSINESS" || userType === "PROVIDER",
  //     then: Yup.string().required().label(" Business Name"),
  //   }),
});

export const signInValidation = Yup.object().shape({
  email: emailValidation,
  password: passwordValidation,
});
export const numberValidation = (label) =>
  Yup.string()
    .min(4)
    .required()
    .matches(numberRegex, " Only numbers are allowed")
    .label(label);
export const otpValidation = Yup.object().shape({
  verificationToken: numberValidation("Verification Code"),
});

export const forgotPasswordValidation = Yup.object().shape({
  email: emailValidation,
});

export const resetPasswordValidation = Yup.object().shape({
  password: passwordValidation,
  confirmPassword: confirmPasswordValidation,
});

export const cardDetailModalSchema = Yup.object().shape({
  name: fullNameValidation("Name"),
});

export const checkValidationError = ({ errors, touched }) => {
  try {
    const touchedFields = Object.keys(touched);
    const error = touchedFields.some((field) => errors[field]);
    return error;
  } catch (e) {
    return false;
  }
};

export const PasswordVisibility = ({
  showPassword,
  setShowPassword,
  className,
}) => {
  return (
    <div
      className={`absolute w-[25px] h-[25px] opacity-50 cursor-pointer ${className}`}
      onClick={() => setShowPassword(!showPassword)}
    >
      <img src={showPassword ? showPasswordImg : hidePasswordImg} alt="" />
    </div>
  );
};
