import {
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Fragment, useState } from "react";
import Loader from "utils/loader/Loader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NoDataAvailable from "components/noDataAvailable/NoDataAvailable";

export default function MyTable(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categoriesReducer.categories);
  const { rows, columns, setLists, extendedTemplate, isLoading } = props;
  const [clickedActionsMap, setClickedActionsMap] = useState({});
  const handleActionClick = (id) => {
    setClickedActionsMap((pre) => ({
      ...pre,
      [id]: !pre?.[id],
    }));
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, i) => (
              <TableCell
                key={i}
                align={column.align}
                sx={{ width: column.width }}
              >
                <Typography fontWeight="bold">{column.label}</Typography>
              </TableCell>
            ))}
            {/* empty cell rendered in case of Bookmarked tab because there is no column header */}
            {extendedTemplate && <TableCell />}
          </TableRow>
        </TableHead>
        {!isLoading && !!rows?.length && (
          <TableBody>
            {rows.map((row, index) => (
              <Fragment key={index}>
                <TableRow>
                  {columns.map((column, i) => (
                    <TableCell key={i} align={column.align}>
                      {column?.render
                        ? column?.render(row, setLists, column?.extended, {
                            navigate,
                            dispatch,
                            categories,
                          })
                        : row[column.field]}
                    </TableCell>
                  ))}
                  {extendedTemplate && (
                    <TableCell sx={{ width: "2%" }}>
                      <IconButton
                        cursor="pointer"
                        onClick={() => handleActionClick(row?.id || index)}
                        size="large"
                      >
                        <KeyboardArrowDownIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
                {extendedTemplate &&
                  clickedActionsMap?.[row.id] &&
                  extendedTemplate(row)}
              </Fragment>
            ))}
          </TableBody>
        )}
      </Table>
      {isLoading && (
        <Stack justifyContent="center" minHeight="540px">
          <Loader width="50px" height="50px" />
        </Stack>
      )}
      {!rows?.length && !isLoading && <NoDataAvailable />}
    </>
  );
}
