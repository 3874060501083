import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { IconButton, MenuItem, Stack } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

export default function FilterPopover({
  icon,
  options,
  title,
  state,
  setState,
  setCurrentPage,
  adminPanelData,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFilterByClick = (value) => {
    setCurrentPage(1);
    setState(value);
    handleClose();
  };

  const handleClearFilter = async () => {
    setCurrentPage(1);
    setState({ label: "", value: "" });
    // adminPanelData(1);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton
        aria-describedby={id}
        variant="text"
        onClick={handleClick}
        sx={{
          fontSize: "30px",
        }}
        size="large"
      >
        {icon}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack sx={{ minWidth: "177px" }}>
          {!!title && (
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <Typography p={1} variant="p">
                {title}
              </Typography>
              <IconButton onClick={handleClearFilter} size="large">
                <FilterAltOffIcon />
              </IconButton>
            </Stack>
          )}
          {!!options.length &&
            options.map((filter) => (
              <MenuItem
                key={filter.value}
                selected={state.value === filter.value}
                onClick={() => handleFilterByClick(filter)}
              >
                {filter.label}
              </MenuItem>
            ))}
        </Stack>
      </Popover>
    </div>
  );
}
