import React, { useEffect } from "react";
import { ADVANCED_SEARCH } from "utils/constants/constants";
import GeneralSearch from "components/search/GeneralSearch";
import SearchLayout from "components/search/SearchLayout";
import AdvancedSearch from "components/search/AdvancedSearch";
import { handleWhatYouAreLookingClick } from "components/navbar/NavSearch";
import { useDispatch, useSelector } from "react-redux";
import { resetSearchReducer } from "store/actions/actions";

const Search = () => {
  const { searchType } = useSelector((state) => state.searchReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetSearchReducer());
  }, []);
  return (
    <SearchLayout
      searchType={searchType}
      setSearchType={() => handleWhatYouAreLookingClick(dispatch, searchType)}
    >
      {searchType === ADVANCED_SEARCH ? (
        <AdvancedSearch searchType={searchType} />
      ) : (
        <GeneralSearch />
      )}
    </SearchLayout>
  );
};

export default Search;
