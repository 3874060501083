import React from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { closeCurrentModal, openModal } from "store/actions/modalActions";
import vectorMaster from "components/modals/authModal/imgs/vector.png";
import Error from "components/error/Error";
import Button from "components/button/Button";
import { stripeModalValidationSchema } from "components/modals/subscriptionModal/helper";

const StripeModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: "",
      number: "",
      expiry: "",
      cvv: "",
      isSave: false,
    },
    validationSchema: stripeModalValidationSchema,
    onSubmit: (values) => {
      const { expiry, isSave, ...rest } = values;
      rest.exp_month = expiry.split("/")[0];
      rest.exp_year = expiry.split("/")[1];
      console.log({ card: { ...rest }, isSave });
    },
  });
  const { getFieldProps } = formik;
  return (
    <div className="relative w-[326px] md:w-[544px] bg-[#fff] shadow dark:bg-gray-700 rounded-[20px] p-[2rem]">
      <button
        type="button"
        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex  dark:hover:text-primary text-textColor"
        data-modal-toggle="authentication-modal"
      >
        <svg
          onClick={() => (onClose ? onClose() : dispatch(closeCurrentModal()))}
          aria-hidden="true"
          className="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
        <span className="sr-only">Close modal</span>
      </button>
      <div className="px-6 py-6 lg:px-8">
        <h3 className="mb-1  text-[22px] font-[700] text-secondary">
          Credit Card
        </h3>
        <label className="block mb-5  text-[16px] font-[400] text-gray-900 text-textColor">
          Enter your card details
        </label>
        <form className="space-y-6" onSubmit={formik.handleSubmit}>
          <div>
            <label className="block mb-2  text-[14px] font-[500] text-textColor">
              Card Number
            </label>
            <div className="relative">
              <input
                type="number"
                id="number"
                {...getFieldProps("number")}
                className="relative rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
              />
              <div className="absolute top-[.6rem] right-3">
                <img className="w-6 mt-2" src={vectorMaster} alt="" />
              </div>
            </div>
            <Error formik={formik} errorField="number" />
          </div>
          <div>
            <label
              htmlFor="name"
              className="block mb-2  text-[14px] font-[500] text-textColor"
            >
              Cardholder Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              className=" rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none "
              placeholder="Jane Lawyer"
              {...getFieldProps("name")}
            />
            <Error formik={formik} errorField="name" />
          </div>

          <div className="flex flex-col md:flex-row w-full gap-3">
            <div className="md:w-[50%]">
              <label className="block mb-2  text-[14px] font-[500] text-textColor">
                Expiration Date
              </label>
              <input
                id="expiry"
                {...getFieldProps("expiry")}
                className="rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
              ></input>
              <Error formik={formik} errorField="expiry" />
            </div>
            <div className="md:w-[50%] ">
              <label className="block mb-2  text-[14px] font-[500] text-textColor">
                CVV/CVC
              </label>
              <input
                type="number"
                id="cvv"
                {...getFieldProps("cvv")}
                className="rounded-sm w-full border border-borderColor bg-white pl-5 h-[55px] text-base font-medium outline-none focus:border-primary text-secondary bg-none"
              ></input>
              <Error formik={formik} errorField="cvv" />
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="isSave"
                  type="checkbox"
                  {...getFieldProps("isSave")}
                  className="w-4 h-4 border border-textColor rounded-sm"
                />
              </div>
              <label
                htmlFor="remember"
                className="ml-2  text-[14px] font-[500] text-gray-900 dark:text-gray-300 text-textColor"
              >
                Save Details for Later Payment Processing
              </label>
            </div>
          </div>
          <Button
            type="submit"
            className="hover:bg-hoverColor w-[100%] h-[50px] text-[16px] font-[600]"
            // disabled={ checkValidationError(formik) || isLoading}
            // loader={isLoading}
          >
            Proceed Payment
          </Button>
          <div className="text-sm font-medium text-gray-500 dark:text-gray-300 text-textColor text-center flex justify-center">
            <p
              className="text-[14px] font-[400] hover:text-hoverColor hover:underline dark:text-blue-500 text-center text-primary cursor-pointer"
              onClick={() => dispatch(openModal("subscriptionModal"))}
            >
              Back to Subscription
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default StripeModal;
