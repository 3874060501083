import {
  CLOSE_CURRENT_MODAL,
  CURRENT_SIGNUP_TAB,
  OPEN_FORGOT_PASSWORD_MODAL,
  OPEN_MODAL,
  OPEN_SIGN_IN_MODAL,
  OPEN_SIGN_UP_MODAL,
  OPEN_SIGN_UP_OTP_MODAL,
  OPEN_SUCCESS_MODAL,
  OPEN_VERIFY_EMAIL_MODAL,
} from "../../utils/constants/constants";

export const openSignInModal = () => ({
  type: OPEN_SIGN_IN_MODAL,
});

export const openSignUpModal = (currentTab) => {
  if (currentTab) {
    return {
      type: OPEN_SIGN_UP_MODAL,
      payload: {
        currentTab: CURRENT_SIGNUP_TAB,
        currentModal: OPEN_SIGN_UP_MODAL,
      },
    };
  }
  return {
    type: OPEN_SIGN_UP_MODAL,
  };
};

export const openSignUpOtpModal = () => ({
  type: OPEN_SIGN_UP_OTP_MODAL,
});

export const openSuccessModal = () => ({
  type: OPEN_SUCCESS_MODAL,
});

export const openVerifyEmailModal = () => ({
  type: OPEN_VERIFY_EMAIL_MODAL,
});

export const openForgotPasswordModal = () => ({
  type: OPEN_FORGOT_PASSWORD_MODAL,
});
export const openModal = (modalName, values=null) => ({
  type: OPEN_MODAL,
  payload: modalName,
  values: values
});
export const closeCurrentModal = () => ({
  type: CLOSE_CURRENT_MODAL,
});
