import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeCurrentModal } from "store/actions/modalActions";
import { Box, Stack, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
import close from "../../../assets/close.svg";
import { updateFilters } from "store/actions/actions";

const FilterModal = () => {
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [selectedAssociations, setSelectedAssociations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedLanguages, setselectedLanguages] = useState([]);

  const schools = useSelector((state) => state.filtersReducer.schools);
  const locations = useSelector((state) => state.filtersReducer.locations);
  const associations = useSelector((state) => state.filtersReducer.associations);
  const languages = useSelector((state) => state.filtersReducer.languages);

  const filters = useSelector((state) => state.filtersReducer.filters);
  const allLanguages = useSelector((state) => state?.categoriesReducer.languages);
  const dispatch = useDispatch();

  const relevantLanguages = allLanguages.reduce((acc, item) => {
    if (languages.some(x => x.language === item._id)) {
      acc.push(item);
    }
    return acc;
  }, [])

  const relevantSchools = schools.filter((item, index) => index === schools.findIndex(x => x.university === item.university))

  useEffect(() => {
    if (filters?.selectedSchools?.length) {
      setSelectedSchools(filters.selectedSchools);
    }
    if (filters?.selectedAssociations?.length) {
      setSelectedAssociations(filters.selectedAssociations);
    }
    if (filters?.selectedLocations?.length) {
      setSelectedLocations(filters.selectedLocations);
    }
    if (filters?.selectedLanguages?.length) {
      setselectedLanguages(filters.selectedLanguages);
    }
  }, [filters]);

  const handleClose = () => {
    dispatch(closeCurrentModal());
  };
  const handleApplyClick = () => {
    dispatch(
      updateFilters({
        selectedSchools,
        selectedAssociations,
        selectedLocations,
        selectedLanguages,
      })
    );
    dispatch(closeCurrentModal());
  };
  return (
    <div className="relative pb-11 bg-[#fff] w-[326px] sm:w-[500px] min-h-[200px] rounded-lg shadow dark:bg-gray-700 rounded-[20px] flex flex-col py-8 px-8 sm:px-11">
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography fontWeight={700} fontSize={22} sx={{ color: "#002A5C" }}>
          Filters
        </Typography>
        <Box sx={{ cursor: "pointer" }} onClick={handleClose}>
          <img src={close} alt="close" />
        </Box>
      </Box>
      <Stack sx={{ marginTop: "28px", paddingX: { sm: "14px" } }}>
        {!!relevantSchools?.length && (
          <Box sx={{ marginBottom: "18px" }}>
            <Typography
              fontSize={16}
              fontWeight={600}
              sx={{ color: "#5A5A5A", marginBottom: "8px" }}
            >
              School
            </Typography>
            <Autocomplete
              // limitTags={1}
              id="school"
              options={relevantSchools}
              value={selectedSchools?.[0] ?? { university: "" }}
              onChange={(event, value) => (value != undefined) ? setSelectedSchools([value]) : setSelectedSchools([])}
              getOptionLabel={(option) => option?.university}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={
                        selected ||
                        option?.university === selectedSchools[0]?.university
                      }
                    />
                    {option?.university}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select School" />
              )}
            />
          </Box>
        )}
        {!!associations?.length && (
          <Box sx={{ marginBottom: "18px" }}>
            <Typography
              fontSize={16}
              fontWeight={600}
              sx={{ color: "#5A5A5A", marginBottom: "8px" }}
            >
              Association
            </Typography>
            <Autocomplete
              // multiple
              limitTags={1}
              id="association"
              options={associations}
              disableCloseOnSelect
              defaultValue={selectedAssociations[0]}
              onChange={(event, value) => setSelectedAssociations([value])}
              getOptionLabel={(option) => option?.title}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={
                      selected ||
                      option?.title === selectedAssociations[0]?.title
                    }
                  />
                  {option.title}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Association"
                  placeholder="Select Association"
                />
              )}
            />
          </Box>
        )}
        {!!locations?.length && (
          <Box sx={{ marginBottom: "30px" }}>
            <Typography
              fontSize={16}
              fontWeight={600}
              sx={{ color: "#5A5A5A", marginBottom: "8px" }}
            >
              Location
            </Typography>
            <Autocomplete
              // multiple
              limitTags={1}
              id="location"
              options={locations}
              disableCloseOnSelect
              defaultValue={selectedLocations[0]}
              onChange={(event, value) => setSelectedLocations([value])}
              getOptionLabel={(option) => option?.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={
                      selected || option?._id === selectedLocations[0]?._id
                    }
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Location"
                  placeholder="Select Location"
                />
              )}
            />
          </Box>
        )}
        {!!relevantLanguages?.length && (
          <Box sx={{ marginBottom: "18px" }}>
            <Typography
              fontSize={16}
              fontWeight={600}
              sx={{ color: "#5A5A5A", marginBottom: "8px" }}
            >
              Language
            </Typography>
            <Autocomplete
              // limitTags={1}
              id="language"
              options={relevantLanguages}
              value={selectedLanguages[0] ?? { name: "" }}
              onChange={(event, value) => (value != undefined) ? setselectedLanguages([value]) : setselectedLanguages([])}
              getOptionLabel={(option) => option?.name}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={
                        selected ||
                        option?._id === selectedLanguages[0]?._id
                      }
                    />
                    {option?.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Language" />
              )}
            />
          </Box>
        )}
        <button
          type="submit"
          className="w-[100%]  bg-primary text-white  hover:bg-blue-800 py-3 rounded-sm focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm "
          onClick={handleApplyClick}
        >
          Apply
        </button>
      </Stack>
    </div>
  );
};

export default FilterModal;
