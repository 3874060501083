import { routes } from "utils/appConfig";

export const updateAppointment = (callApi, appointmentId, data, callBack) => {
  const { url, method } = routes.calAtoms.updateAppointment;
  try {
    callApi(
      url(appointmentId),
      method,
      data,
      {
        onSuccess: ({ res }) => {
            callBack(res);
        },
        onError: (error) => {
            callBack(null, error);
        },
        turnOffNotification: true,
      }
    );
  } catch (e) {
    console.log("updateAppointment~catch", e);
    throw new Error();
  }
};