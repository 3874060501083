import {
  emailValidation,
  fullNameValidation,
  phoneValidation,
} from "components/modals/helper";
import {
  ADDRESS,
  PERSONAL_INFO,
} from "components/PremiumProviderEditProfileComponents/constants/editProfileConstants";
import { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";

export const stepData = [
  {
    stepName: "Personal Info",
    stepNumber: "01",
    key: PERSONAL_INFO,
    profile: "INDIVIDUAL",
  },
  {
    stepName: "Address",
    stepNumber: "02",
    key: ADDRESS,
    profile: "INDIVIDUAL",
  },
];
export const individualValidationSchema = () =>
  Yup.object().shape({
    firstName: fullNameValidation("First!!! Name"),
    lastName: fullNameValidation("Last Name"),
    phone: Yup.string().test({
      name: "phone",
      exclusive: true,
      message: " Phone is invalid",
      test: (value) => {
        return isValidPhoneNumber(value ?? "");
      },
    }),
    email: emailValidation,
    designation: Yup.string(),
  });
// .transform(function (current, original) {
//   const currentKeys = Object.keys(current);
//   if (currentKeys.includes("designation")) {
//     this.fields.designation =
//       original?.designation !== undefined
//         ? Yup.string()
//         : Yup.string().required();
//   }
//   return current;
// });
