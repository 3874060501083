import { urlRegex } from "components/editProviderNewScope/helper";
import { isRequired, phoneValidation } from "components/modals/helper";
import { ADDRESS } from "components/PremiumProviderEditProfileComponents/constants/editProfileConstants";
import { isValidPhoneNumber } from "react-phone-number-input";
import { FIRM_INFO } from "utils/constants/constants";
import * as Yup from "yup";

export const stepDataFirm = [
  {
    stepName: "Firm Info",
    stepNumber: "01",
    key: FIRM_INFO,
    profile: "INDIVIDUAL",
  },
  {
    stepName: "Address",
    stepNumber: "02",
    key: ADDRESS,
    profile: "INDIVIDUAL",
  },
];

export const createFirmValidation = () =>
  Yup.object().shape({
    name: Yup.string().required().label(" Firm Name"),
    businessPhone: Yup.string()
      .required(" Phone is required")
      .test({
        name: "businessPhone",
        exclusive: true,
        message: " Phone is invalid",
        test: (value) => {
          return isValidPhoneNumber(value ?? "");
        },
      }),
    registeredDate: Yup.string()
      .matches(
        /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2[0-8])\/(19|20)\d{2}$/,
        "Invalid Date"
      )
      .label("Formed Date"),
    website: Yup.string()
      .required()
      .matches(urlRegex, "Please input valid website url!")
      .label(" Website"),
    businessDescription: isRequired(" Description"),
  });
export const firmInfoData = [
  {
    title: "Formed Date",
    key: "registeredDate",
    value: "03/23/2023",
  },
  {
    title: "Phone Number",
    key: "businessPhone",
    value: "1212121212",
  },
  {
    title: "Website",
    key: "website",
    value: "http://localhost:300",
  },
];

export const addressesData = [
  {
    officeType: "VIRTUAL",
    address: "3401 110th st Inglewood, CA 90303-2225, USA",
  },
  {
    officeType: "PHYSICAL",
    address: "3401 110th st Inglewood, CA 90303-2225, USA",
  },
  {
    officeType: "PHYSICAL",
    address: "3401 110th st Inglewood, CA 90303-2225, USA",
  },
];
